import { ReactNode } from "react";

/**
 * Props for the TaskFilter component.
 * @interface TaskFilterProps
 */
interface TaskFilterProps {
  /** The text label for the filter */
  label: string;
  /** The content of the filter (e.g., input fields, dropdowns) */
  children: ReactNode;
  /** Additional CSS classes to apply to the component */
  className?: string;
}

/**
 * TaskFilter component creates a consistent layout for filter controls in a task management interface.
 * It provides a labeled container for various filter inputs or selectors.
 *
 * @param {TaskFilterProps} props - The props for the TaskFilter component
 * @returns {JSX.Element} A labeled container for filter controls
 *
 * @example
 * <TaskFilter label="Status">
 *   <select>{/* status options *\/}</select>
 * </TaskFilter>
 */
export function TaskFilter({ children, className, label }: TaskFilterProps) {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <label className="text-sm font-medium w-24 shrink-0">{label}</label>
      <div className="flex-grow">{children}</div>
    </div>
  );
}
