import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "App";
import { get_recommendations, submitGetRecommendationsJob, usePublishCarePlanToEHR } from "backend/functions";
import { QUERY_KEYS } from "backend/queryKeys";
import { CarePlanGenerationStatuses } from "backend/resources/carePlanGenerationStatus";
import { useDeleteDraftGoalsMutation, type GoalWithStatus } from "backend/resources/goal/goal";
import { fetchRecommendationsFromGoalId, insertUserRecommendations } from "backend/resources/goal/goalUserRecommendationStatus";
import { UserAdlo, useActiveUserAdlo } from "backend/resources/userAdlo";
import { useInsertCarePlanGenerationStatus } from "backend/resources/userAssessment";
import { supabase } from "clients/supabaseClient";
import { useCarePlanStore } from "state/carePlan/carePlan";
import type { Database } from "types/supabase";

const TABLE = "care_plan_goal";

export type CarePlanGoal =
  Database["public"]["Tables"]["care_plan_goal"]["Row"];

export type CarePlanGoalInsert =
  Database["public"]["Tables"]["care_plan_goal"]["Insert"];

export type CarePlanGoalUpdate =
  Database["public"]["Tables"]["care_plan_goal"]["Update"];

export type CarePlanGoalWithProgress = GoalWithStatus & { status?: string };

export type RecommendationStatus = {
  rating: number | null;
  is_marked_done: boolean;
  recommendation_id: string;
};
type UserRecommendationWithRecommendationStatus = { user_recommendation: { recommendation_id: string } };
export type GoalData = Database["public"]["Tables"]["goal"]["Row"] & { goal_user_recommendation_status: UserRecommendationWithRecommendationStatus[] };


export enum CarePlanStatuses {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum CarePlanGoalStatuses {
  INCLUDED = "included",
  REPLACED = "replaced",
  ARCHIVED = "archived",
}

export async function fetchGoalsData(carePlanId: string) {
  const { data: goalsData, error: goalsError } = await supabase
    .from("care_plan_goal")
    .select(
      "status ,goal(*, goal_user_recommendation_status(user_recommendation(recommendation_id)))"
    )
    .eq("care_plan_id", carePlanId)
    .returns<
      {
        status: string;
        goal: GoalData;
      }[]
    >();
  return goalsData;
}

export async function fetchRecommendationStatusData(recommendationIds: string[], userAdloId?: string) {
  if (!userAdloId || recommendationIds.length === 0) return null;

  const chunkSize = 50;
  const recommendationIdChunks = [];
  for (let i = 0; i < recommendationIds.length; i += chunkSize) {
    recommendationIdChunks.push(recommendationIds.slice(i, i + chunkSize));
  }

  const fetches = recommendationIdChunks.map(chunk =>
    supabase
      .from("recommendation_status")
      .select("rating, is_marked_done, recommendation_id")
      .in("recommendation_id", chunk)
      .eq("user_adlo_id", userAdloId)
  );

  const results = await Promise.all(fetches);
  const recommendationStatusData = results.reduce<RecommendationStatus[]>((acc, result) => {
    if (result.data) {
      acc.push(...result.data);
    }
    return acc;
  }, []);

  return recommendationStatusData;
}

export function createRecommendationStatusMap(recommendationStatusData: RecommendationStatus[]) {
  return recommendationStatusData?.reduce<{ [key: string]: { rating: number | null; is_marked_done: boolean } }>((acc, curr) => {
    acc[curr.recommendation_id] = {
      rating: curr.rating,
      is_marked_done: curr.is_marked_done
    };
    return acc;
  }, {}) ?? {};
}
type RecommendationStatusMap = {
  [key: string]: {
    rating: number | null;
    is_marked_done: boolean;
  };
};

export function calculateGoalProgress(goal: GoalData, recommendationStatusMap: RecommendationStatusMap, status?: string) {
  const recommendationStatuses = goal?.goal_user_recommendation_status?.map(
    (status) => recommendationStatusMap[status.user_recommendation?.recommendation_id]
  )
  const totalStatuses = recommendationStatuses?.length;
  const doneStatuses = recommendationStatuses?.filter(
    (status) => status?.is_marked_done
  ).length;
  const progress = totalStatuses
    ? Math.round((doneStatuses / totalStatuses) * 100)
    : 0;
  const ratedStatuses = recommendationStatuses?.filter(
    (status) => status && status?.rating !== null
  );

  const totalRatedStatuses = ratedStatuses.length;
  const rating = totalRatedStatuses > 0
    ? ratedStatuses.reduce(
      (sum, status) => sum + (status?.rating ?? 0),
      0
    ) / totalRatedStatuses
    : null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { goal_user_recommendation_status, ...goalWithoutStatus } = goal;
  return {
    ...goalWithoutStatus,
    progress,
    rating,
    status,
  };
}

export async function getDeltaForGoal({
  existingGoalId,
  newGoalId,
  userAdlo,
}: {
  existingGoalId?: string,
  newGoalId?: string,
  userAdlo?: UserAdlo
}
) {

  if (!existingGoalId || !newGoalId || !userAdlo) return {}

  const [oldRecs, newRecs] = await Promise.all([
    fetchRecommendationsFromGoalId(existingGoalId),
    fetchRecommendationsFromGoalId(newGoalId)
  ]);
  const recommendationStatusForExistingRecs = await fetchRecommendationStatusData(
    oldRecs?.map(rec => rec.recommendation.id) ?? [], userAdlo?.id)

  const recsInProgress = oldRecs?.filter((rec) =>
    recommendationStatusForExistingRecs?.some((status) =>
      status.recommendation_id === rec.recommendation.id && (status.is_marked_done))
  );

  const recsToAdd = newRecs?.filter(rec => !oldRecs?.some(oldRec => oldRec.recommendation.id === rec.recommendation.id));

  // Filter out the recommendations to delete by checking if they exist in the new recommendations
  // and are not in progress
  const recsToDelete = oldRecs?.filter((rec) =>
    !newRecs?.some(newRec => newRec.recommendation.id === rec.recommendation.id)
    && !recsInProgress?.some(keepRec => keepRec.recommendation.id === rec.recommendation.id)
  );

  const recsToKeep = oldRecs?.filter((rec) =>
    !recsToDelete?.some(delRec => delRec.recommendation.id === rec.recommendation.id)
  );

  // We add recsToKeep to the newGoal, but ensure no dupes
  const recsToAddToNewGoal = recsToKeep?.filter((rec) =>
    !newRecs?.some(oldRec => oldRec.recommendation.id === rec.recommendation.id)
  );
  return { recsToAdd, recsToDelete, recsToKeep, recsToAddToNewGoal }
}


export async function deleteCarePlanGoal(carePlanId: string, goalId: string) {
  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .eq("care_plan_id", carePlanId)
    .eq("goal_id", goalId)
    .select();
  return data;
}

export function useUpsertCarePlanGoalsMutation() {
  return useMutation({
    mutationFn: async (carePlanGoals: CarePlanGoalInsert[]) => {
      const { data, error } = await supabase
        .from(TABLE)
        .upsert(carePlanGoals, { onConflict: "goal_id,care_plan_id" });

      return data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carePlanGoal],
      });
    },
  });
}

export function useCurrentCarePlanGoalsQuery() {
  return useCarePlanGoalsQuery(CarePlanStatuses.PUBLISHED);
}

export function useDraftCarePlanGoalsQuery() {
  return useCarePlanGoalsQuery(CarePlanStatuses.DRAFT);
}

export function useLatestCarePlan(status?: CarePlanStatuses) {
  const userAdlo = useActiveUserAdlo();
  return useQuery({
    queryKey: [QUERY_KEYS.carePlan, { userAdloId: userAdlo?.id, status }],
    queryFn: async () => {
      if (!userAdlo?.id) return null
      let query = supabase
        .from("care_plan")
        .select("*")
        .order("created_at", { ascending: false })
        .eq("user_adlo_id", userAdlo?.id);

      if (status) {
        query = query.eq("status", status);
      }

      const { data } = await query.limit(1).maybeSingle();
      return data
    }
  })
}

export function useCarePlanGoalsQuery(status: CarePlanStatuses) {
  const userAdlo = useActiveUserAdlo();
  return useQuery({
    queryKey: [QUERY_KEYS.carePlanGoal, { userAdloId: userAdlo?.id, status }],
    queryFn: async () => {
      if (!userAdlo?.id) return undefined
      const { data, error } = await supabase
        .from("care_plan")
        .select("*")
        .eq("user_adlo_id", userAdlo.id)
        .eq("status", status)
        .limit(1)
        .maybeSingle();

      if (error || !data) {
        return undefined
      }
      const goalsData = await fetchGoalsData(data?.id);
      const recommendationIds = goalsData?.flatMap(goalData => goalData.goal.goal_user_recommendation_status.map(status => status.user_recommendation?.recommendation_id));
      if (recommendationIds) {
        const recommendationStatusData = await fetchRecommendationStatusData(recommendationIds, userAdlo?.id);
        const recommendationStatusMap = createRecommendationStatusMap(recommendationStatusData ?? []);
        return goalsData?.map((carePlanGoal) => {
          return calculateGoalProgress(carePlanGoal.goal, recommendationStatusMap, carePlanGoal.status);
        });
      } else {
        return []
      }
    },
  });
}

export function useRecommendationStatuses(recommendationIds: string[]) {
  const userAdlo = useActiveUserAdlo();
  return useQuery({
    queryKey: [QUERY_KEYS.recommendationStatus, { recommendationIds, userAdloId: userAdlo?.id }],
    queryFn: async () =>
      fetchRecommendationStatusData(recommendationIds, userAdlo?.id)
  });
}


/**
 * This function handles the publishing of the care plan.
 * It saves the current draft care plan, deletes the existing published care plan,
 * and updates the draft care plan to be published. Additionally, it processes
 * the replacement, archiving, and inclusion of goals within the care plan.
 * 
 * When a goal is replaced, the function checks for any associated recommendations.
 * If there are recommendations linked to the goal, it keeps the old recommendations
 * since they could have been edited. The function deletes the recommendations in the new goal
 * to ensure that the goal retains the original recommendations and user progress.
 * 
 * 
 */
export function usePublishCarePlanMutation() {
  // TODO: move this to the backend  https://app.shortcut.com/craniometrix/story/3057/frontend-backend-move-publish-care-plan-mutation-to-backend
  const userAdlo = useActiveUserAdlo();
  const saveDraftCarePlan = useSaveDraftCarePlan().mutateAsync;
  const publishCarePlanToEHR = usePublishCarePlanToEHR().mutateAsync;

  return useMutation({
    mutationFn: async () => {
      if (!userAdlo) return null
      // save the changes made so far
      await saveDraftCarePlan();
      // Delete existing published care plan
      await supabase
        .from("care_plan")
        .delete()
        .eq("user_adlo_id", userAdlo?.id)
        .eq("status", CarePlanStatuses.PUBLISHED);

      // upgrade the current draft plan to be published
      const { data: newPlan } = await supabase
        .from("care_plan")
        .update({ status: CarePlanStatuses.PUBLISHED })
        .eq("user_adlo_id", userAdlo?.id)
        .eq("status", CarePlanStatuses.DRAFT)
        .select("id")
        .order('created_at', { ascending: true })
        .limit(1)
        .maybeSingle();

      if (!newPlan) return null;

      // get all the goals in the new care plan
      const { data: carePlanGoals } = await supabase
        .from("care_plan_goal")
        .select("*, goal(title)")
        .eq("care_plan_id", newPlan?.id)
        .returns<(CarePlanGoal & { goal: { title: string } })[]>();

      if (!carePlanGoals || !newPlan) return null
      const promises = carePlanGoals.map(async (carePlanGoal) => {
        if (carePlanGoal.status === CarePlanGoalStatuses.ARCHIVED) {
          // Delete the care_plan_goal row
          await supabase
            .from("care_plan_goal")
            .delete()
            .eq("goal_id", carePlanGoal.goal_id)
            .eq("care_plan_id", newPlan.id);

          await supabase
            .from("goal")
            .update({ is_archived: true })
            .eq("id", carePlanGoal.goal_id);

        } else if (carePlanGoal.status === CarePlanGoalStatuses.REPLACED) {
          // get the carePlanGoal that replaces the carePlanGoal
          const updatedGoal = carePlanGoals?.find((cpGoal) => cpGoal?.goal?.title == carePlanGoal.goal.title && cpGoal.status === CarePlanGoalStatuses.INCLUDED)

          if (!updatedGoal) return null

          // Delete the care_plan_goal row
          await supabase
            .from("care_plan_goal")
            .delete()
            .eq("goal_id", carePlanGoal.goal_id)
            .eq("care_plan_id", newPlan.id);

          // get the recs delta
          const { recsToAddToNewGoal, recsToKeep } = await getDeltaForGoal({
            newGoalId: updatedGoal.goal_id,
            existingGoalId: carePlanGoal.goal_id,
            userAdlo: userAdlo
          })
          
          // Delete user recommendations in updatedGoal that have the same recommendation_id as the ones in recsToKeep
          // Since we want to keep the recsToKeep (they could've been edited), we need to delete the ones that are not in recsToKeep
          const duplicateShareableRecs = recsToKeep?.map(rec => rec.recommendation.id)??[];
          const {data: duplicateShareableRecsData} = await supabase
            .from("user_recommendation")
            .select("id, recommendation_id, goal_user_recommendation_status!inner(id, goal_id)")
            .eq("goal_user_recommendation_status.goal_id", updatedGoal.goal_id)
            .in("recommendation_id", duplicateShareableRecs)
            ;

          const duplicateShareableRecsIds = duplicateShareableRecsData?.map(rec => rec.id)??[];
          await supabase
            .from("user_recommendation")
            .delete()
            .in("id", duplicateShareableRecsIds);

          await insertUserRecommendations(updatedGoal.goal_id, [...(recsToAddToNewGoal ?? []), ...(recsToKeep ?? [])]?.map(rec => rec.id) ?? [])
        } else if (carePlanGoal.status === CarePlanGoalStatuses.INCLUDED) {
          // Update the goal it maps to so that it "is_draft" is false
          await supabase
            .from("goal")
            .update({ is_draft: false, is_suggested: false, is_archived: false })
            .eq("id", carePlanGoal.goal_id);
        }
      });
      await Promise.all(promises);
      return newPlan

    },
    onSuccess: async (newPlan) => {
      if (newPlan) {
        await publishCarePlanToEHR({ care_plan_id: newPlan.id })
      }
      queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.carePlanGoal],
      });
    },
  });
}



export function useSaveDraftCarePlan() {
  const userAdlo = useActiveUserAdlo();
  const upsertCarePlanGoals = useUpsertCarePlanGoalsMutation().mutateAsync;
  const { currentCarePlanGoalsByAdlo } =
    useCarePlanStore();
  const goalsToSave = userAdlo ? currentCarePlanGoalsByAdlo[userAdlo.id] ?? [] : [];

  return useMutation({
    mutationFn: async () => {
      if (!userAdlo?.id) return null
      let { data: draftCarePlan } = await supabase
        .from("care_plan")
        .select("*")
        .eq("user_adlo_id", userAdlo?.id)
        .eq("status", CarePlanStatuses.DRAFT)
        .limit(1)
        .maybeSingle();
      if (!draftCarePlan) {
        const { data: newDraftCarePlan, error: newDraftCarePlanError } =
          await supabase
            .from("care_plan")
            .insert([{ user_adlo_id: userAdlo?.id, status: CarePlanStatuses.DRAFT }])
            .select()
            .limit(1)
            .maybeSingle();

        if (newDraftCarePlanError) {
          throw new Error(newDraftCarePlanError.message);
        }

        draftCarePlan = newDraftCarePlan;
      }
      const carePlan = draftCarePlan
      if (carePlan !== null) {
        await upsertCarePlanGoals(
          goalsToSave.map((goal) => ({
            goal_id: goal.id,
            care_plan_id: carePlan.id,
            status: goal.status,
          }))
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carePlanGoal],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carePlan],
      });
    },
  }
  );
}

export function useGoalDelta(existingGoalId?: string, newGoalId?: string) {
  const userAdlo = useActiveUserAdlo();
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.carePlanGoalDelta, { existingGoalId, newGoalId, userAdloId: userAdlo?.id }],
    queryFn: async () => getDeltaForGoal({
      existingGoalId,
      newGoalId,
      userAdlo
    })
  });

  return {
    recsToAdd: data?.recsToAdd,
    recsToDelete: data?.recsToDelete,
    recsToKeep: data?.recsToKeep,
    recsToAddToNewGoal: data?.recsToAddToNewGoal,
    isLoading
  }
}

export function useGenerateCarePlan() {
  const insertPendingGenerationStatus = useInsertCarePlanGenerationStatus().mutateAsync;
  const deleteAllDraftGoals = useDeleteDraftGoalsMutation().mutateAsync;

  return useMutation({
    mutationFn: async () => {
      // TODO: move this to backend, maybe use a optimistic mutation to show this as pending
      const carePlanGenerationStatus = await insertPendingGenerationStatus();
      if (carePlanGenerationStatus) {
        // TODO: move this to backend
        await deleteAllDraftGoals();
        const { error } = import.meta.env.VITE_IS_CIRCUMVENTING_BATCH === "true"
          ? await get_recommendations({ care_plan_generation_status_id: carePlanGenerationStatus.id })
          : await submitGetRecommendationsJob({ care_plan_generation_status_id: carePlanGenerationStatus.id });
        if (error) {
          await supabase
            .from("care_plan_generation_status")
            .update({ status: CarePlanGenerationStatuses.ERROR })
            .eq("id", carePlanGenerationStatus.id);
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.carePlanGenerationStatus],
          });
        }
        return carePlanGenerationStatus.id
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carePlan],
      });
    },
  });
}
