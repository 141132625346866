import { Cell, Row } from "@tanstack/react-table";
import { CARESPACE_TABLE_CELL_CONTENT_MAP } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTableCellContent";
import { CarespaceTableType } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import { FC } from "react";

interface CarespaceTableCellProps {
  cell: Cell<CarespaceTableType, unknown>;
  row: Row<CarespaceTableType>;
}
export const CarespaceTableCell: FC<CarespaceTableCellProps> = ({ cell, row }) => {
  const content = CARESPACE_TABLE_CELL_CONTENT_MAP[cell.column.id as keyof typeof CARESPACE_TABLE_CELL_CONTENT_MAP];
  const value = String(cell.getValue())
  return (
    <div className="carespace-table-cell">
      {typeof content === 'function' ? content(value) : content}
    </div>
  );
}
