import { useAssessment } from "backend/resources/assessment";
import { CarePlanGenerationStatuses, useCarePlanGenerationStatus } from 'backend/resources/carePlanGenerationStatus';
import { useActiveUserAdlo } from 'backend/resources/userAdlo';
import { useHasAssessmentInProgress, useStartUserAssessmentMutation, useSubmitAssessment, useUserAssessmentById } from "backend/resources/userAssessment";
import AdloComponent from 'components/AdloComponent/AdloComponent';
import AssessmentCaregiverSelectionPage from "components/AssessmentComponent/subpages/AssessmentCaregiverSelectionPage";
import AssessmentEmptyAnswersPage from "components/AssessmentComponent/subpages/AssessmentEmptyAnswersPage";
import AssessmentImmediateAttentionPage from "components/AssessmentComponent/subpages/AssessmentImmediateAttentionPage";
import AssessmentQuestionsPage from "components/AssessmentComponent/subpages/AssessmentQuestionsPage";
import { AssessmentSelectorPage } from "components/AssessmentComponent/subpages/AssessmentSelectorPage";
import AssessmentSubmissionPage from "components/AssessmentComponent/subpages/AssessmentSubmissionPage";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useAssessmentStore } from "state/assessment";
import AssessmentDropdown from "./AssessmentDropdown";

export enum AssessmentType {
  COMPREHENSIVE = "comprehensive",
  SCREENINGS = "screenings",
}

const NO_ANSWERS_FOUND_ERROR_MESSAGE = "No answers found"

type BaseProps = {
  onClose: () => void;
  isOpen: boolean;
};

type NewAssessmentProps = BaseProps & {
  newAssessmentType?: AssessmentType;
  assessmentId?: string;
  viewModeOptions?: never;
};

type ViewModeProps = BaseProps & {
  newAssessmentType?: never;
  assessmentId?: string;
  viewModeOptions: {
    isInViewMode: boolean;
    userAssessmentId?: string;
  };
};


type Props = NewAssessmentProps | ViewModeProps;

export function AssessmentComponent({ isOpen, onClose, viewModeOptions, newAssessmentType, assessmentId }: Props) {
  const userAdlo = useActiveUserAdlo();


  /**
   * States
   */
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<string | undefined>(assessmentId)
  const [currentPage, setCurrentPage] = useState<NewAssessmentPage>(NewAssessmentPage.SELECT_ASSESSMENT)
  const [selectedCaregiver, setSelectedCaregiver] = useState<string | undefined>();
  const [immediateAttentionMessage, setImmediateAttentionMessage] = useState<string | undefined>();
  const [isAssessmentCreationBlocked, setIsAssessmentCreationBlocked] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  /**
   * Stores
   */

  const setActiveUserAssessmentId = useAssessmentStore((state) => state.setActiveUserAssessmentId);
  const activeUserAssessmentId = useAssessmentStore((state) => state.activeUserAssessmentId);
    
  /**
   * Hooks
   */
  const { data: activeUserAssessment } = useUserAssessmentById(activeUserAssessmentId)
  const { data: activeAssessment } = useAssessment(selectedAssessmentId ?? activeUserAssessment?.assessment_id)
  const {
    data: inProgressUserAssessment,
    isLoading: isInProgressUserAssessmentLoading,
  } = useHasAssessmentInProgress(selectedAssessmentId, selectedCaregiver);
  const { data: generationStatus } = useCarePlanGenerationStatus();

  /**
   * Mutations
   */

  const startUserAssessment = useStartUserAssessmentMutation().mutateAsync;
  const submitAssessment = useSubmitAssessment().mutateAsync;

  /**
   * Effects
   */

  useEffect(() => {
    if (viewModeOptions?.userAssessmentId) {
      setActiveUserAssessmentId(viewModeOptions.userAssessmentId)
    }
  }, [viewModeOptions?.userAssessmentId])

  useEffect(() => {
    // set initial page
    if (activeAssessment?.subject === "caregiver" && !selectedCaregiver && currentPage === NewAssessmentPage.ANSWER_QUESTIONS && !viewModeOptions?.isInViewMode) {
      setCurrentPage(NewAssessmentPage.CAREGIVER_SELECTION)
    } else if (viewModeOptions?.isInViewMode && currentPage === NewAssessmentPage.SELECT_ASSESSMENT && activeAssessment?.subject !== "caregiver") {
      setCurrentPage(NewAssessmentPage.ANSWER_QUESTIONS);
    } else if (assessmentId && !viewModeOptions?.isInViewMode && currentPage === NewAssessmentPage.SELECT_ASSESSMENT) {
      // if assessmentId is provided... skip the assessment selection page
      setCurrentPage(NewAssessmentPage.ANSWER_QUESTIONS)
    }
  }, [viewModeOptions?.isInViewMode, activeAssessment, isOpen, selectedCaregiver, currentPage, assessmentId]);

  useEffect(() => {
    const userAssessment = inProgressUserAssessment ?? activeUserAssessment
    if (userAssessment) {
      setActiveUserAssessmentId(userAssessment.id);
      if (userAssessment.immediate_attention_message) {
        setImmediateAttentionMessage(userAssessment.immediate_attention_message)
        setCurrentPage(NewAssessmentPage.IMMEDIATE_RESPONSE_POPUP)
      }
    }
  }, [inProgressUserAssessment, activeUserAssessment]);

  useEffect(() => {
    // start assessment if not in view mode
    if ((assessmentId && !inProgressUserAssessment && currentPage === NewAssessmentPage.ANSWER_QUESTIONS) || (selectedAssessmentId && !viewModeOptions?.isInViewMode && !inProgressUserAssessment && !isInProgressUserAssessmentLoading && isOpen && currentPage === NewAssessmentPage.ANSWER_QUESTIONS)) {
      startUserAssessment({ assessmentId: selectedAssessmentId, selectedCaregiver: selectedCaregiver }).then((newUserAssessment) => {
        setActiveUserAssessmentId(newUserAssessment?.id);
      });
    }
  }, [selectedAssessmentId, viewModeOptions?.isInViewMode, selectedCaregiver, currentPage, assessmentId]);


  /**
   * Handlers
   */

  function closeModal() {
    onClose();
    setCurrentPage(NewAssessmentPage.SELECT_ASSESSMENT);
    setSelectedAssessmentId(assessmentId);
    setSelectedCaregiver(undefined);
  }

  async function handleNextOrSubmit() {
    switch (currentPage) {
      case NewAssessmentPage.SELECT_ASSESSMENT:
        if (activeAssessment?.subject === "caregiver") {
          setCurrentPage(NewAssessmentPage.CAREGIVER_SELECTION)
        } else {
          setCurrentPage(NewAssessmentPage.ANSWER_QUESTIONS)
        }
        break;
      case NewAssessmentPage.CAREGIVER_SELECTION:
        setCurrentPage(NewAssessmentPage.ANSWER_QUESTIONS)
        break;
      case NewAssessmentPage.ANSWER_QUESTIONS:
        if (generationStatus?.status === CarePlanGenerationStatuses.PENDING) {
          setIsAssessmentCreationBlocked(true);
          return;
        }
        const response = await submitAssessment();
        if (response.immediate_attention_message) {
          setImmediateAttentionMessage(response.immediate_attention_message)
          setCurrentPage(NewAssessmentPage.IMMEDIATE_RESPONSE_POPUP)
        } else if (response?.error === NO_ANSWERS_FOUND_ERROR_MESSAGE) {
          setCurrentPage(NewAssessmentPage.EMPTY_STATE_POPUP)
        } else {
          setCurrentPage(NewAssessmentPage.SUBMISSION_POPUP);
        }
        break;
      case NewAssessmentPage.EMPTY_STATE_POPUP:
        setCurrentPage(NewAssessmentPage.ANSWER_QUESTIONS)
        break;
      case NewAssessmentPage.IMMEDIATE_RESPONSE_POPUP:
        if (viewModeOptions?.isInViewMode) {
          setCurrentPage(NewAssessmentPage.ANSWER_QUESTIONS)
        } else {
          closeModal()
        }
        break;
      case NewAssessmentPage.SUBMISSION_POPUP:
        closeModal()
        break;
    }
  }

  // Constants

  const pageComponentMapping = {
    [NewAssessmentPage.SELECT_ASSESSMENT]: () => (
      <AssessmentSelectorPage
        selectedAssessmentId={selectedAssessmentId}
        newAssessmentType={newAssessmentType}
        setSelectedAssessmentId={setSelectedAssessmentId}
      />
    ),
    [NewAssessmentPage.CAREGIVER_SELECTION]: () => (
      <AssessmentCaregiverSelectionPage selectedCaregiver={selectedCaregiver} setSelectedCaregiver={setSelectedCaregiver} />
    ),
    [NewAssessmentPage.ANSWER_QUESTIONS]: () => (
      <AssessmentQuestionsPage
        isPrinting={isPrinting}
        selectedCaregiver={selectedCaregiver}
        isInViewMode={viewModeOptions?.isInViewMode ?? false}
        activeAssessment={activeAssessment}
        totalScore={activeUserAssessment?.total_score as number | { distress: number, severity: number }}
      />
    ),
    [NewAssessmentPage.SUBMISSION_POPUP]: () => (
      <AssessmentSubmissionPage
        isOpen={true}
        onClose={handleNextOrSubmit}
        activeAssessment={activeAssessment}
      />
    ),
    [NewAssessmentPage.EMPTY_STATE_POPUP]: () => (
      <AssessmentEmptyAnswersPage onClose={handleNextOrSubmit} />
    ),
    [NewAssessmentPage.IMMEDIATE_RESPONSE_POPUP]: () => (
      <AssessmentImmediateAttentionPage onClose={handleNextOrSubmit} message={immediateAttentionMessage} />
    )
  };

  const pageTitleMapping = {
    [NewAssessmentPage.SELECT_ASSESSMENT]: "Select Assessment",
    [NewAssessmentPage.CAREGIVER_SELECTION]: "Select Caregiver",
    [NewAssessmentPage.ANSWER_QUESTIONS]: activeAssessment?.name as string,
    [NewAssessmentPage.SUBMISSION_POPUP]: "Submission",
    [NewAssessmentPage.EMPTY_STATE_POPUP]: "Empty State",
    [NewAssessmentPage.IMMEDIATE_RESPONSE_POPUP]: "Action Needed",
  }
  // TODO move to util class
  useEffect(() => {
    if (isPrinting && activeAssessment?.id) {
      const input = document.getElementById(activeAssessment.id);

      if (input) {
        html2canvas(input, {
          scale: 1,
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;

          // Set up PDF dimensions (A4 in this case)
          const pdfWidth = 210; // A4 width in mm
          const pdfHeight = 297; // A4 height in mm
          const margin = 10; // Margin in mm

          const pdf = new jsPDF({
            orientation: imgWidth > imgHeight ? 'l' : 'p',
            unit: 'mm',
            format: [pdfWidth, pdfHeight]
          });

          let heightLeft = imgHeight;
          let position = 0;

          while (heightLeft > 0) {
            const scale = (pdfWidth - 2 * margin) / imgWidth;
            const pageHeight = (pdfHeight - 2 * margin) / scale;
            const y = position === 0 ? margin : -position * scale + margin; // Adjust y position for each page

            pdf.addImage(imgData, 'PNG', margin, y, imgWidth * scale, imgHeight * scale);
            heightLeft -= pageHeight;
            position += pageHeight;

            if (heightLeft > 0) {
              pdf.addPage();
            }
          }

          pdf.save(`${activeAssessment?.name} ${new Date().toLocaleDateString()}.pdf`);
          setIsPrinting(false);
        });
      }
    }
  }, [isPrinting, activeAssessment]);

  const printDocument = () => {
    setIsPrinting(true);
  };
  // ------*----------*----------*----------*----------*----------*----------*-------
  return (
    <ResponsiveModal
      isOpen={isOpen}
      closeText="Close"
      onClose={closeModal}
      customWidth={"max-w-5xl"}
      footerButtons={viewModeOptions?.isInViewMode ?
        (currentPage === NewAssessmentPage.CAREGIVER_SELECTION ?
          <ButtonWithIcon
            onClick={handleNextOrSubmit}
            text={"Next"}
            size={"small"}
            icon={IconOption.ARROW}
          /> : <ButtonWithIcon
            onClick={() => {
              printDocument();
            }}
            text={"Download as PDF"}
            size={"small"}
            icon={IconOption.DOWNLOAD}
          />)
        :
        <>
          <ButtonWithIcon
            onClick={closeModal}
            text={"Finish Later"}
            size={"small"}
            icon={IconOption.LATER}
          />
          <ButtonWithIcon
            size={"small"}
            className="self-end"
            onClick={handleNextOrSubmit}
            text={currentPage === NewAssessmentPage.ANSWER_QUESTIONS ? "Submit" : "Next"}
            icon={IconOption.ARROW}
          />
        </>
      }
      fixedHeight="h-[90vh]"
    >
      <div id={activeAssessment?.id} className="flex flex-col gap-5 pb-3 px-2">
        <AssessmentCreationBlockedDuringGenerationModal isOpen={isAssessmentCreationBlocked} onClose={() => setIsAssessmentCreationBlocked(false)} />

        <div className='flex flex-col gap-2 px-3'>
          <div className='w-full bg-brand-orange h-2 rounded-full mt-4 mb-4' />
          <div className='flex flex-wrap items-center gap-2 px-3'>
            <p className='text-start text-3xl'>{pageTitleMapping[currentPage]}</p>
            {viewModeOptions?.isInViewMode ? (
              <AssessmentDropdown
                assessmentId={activeAssessment?.id}
                selectedCaregiver={selectedCaregiver}
                viewMode={isPrinting ? 'text' : 'dropdown'}
              />
            ) : null}
          </div>
        </div>
        <AdloComponent userAdlo={userAdlo} />
        {pageComponentMapping[currentPage]()}
      </div>
    </ResponsiveModal>
  );
}

enum NewAssessmentPage {
  SELECT_ASSESSMENT,
  CAREGIVER_SELECTION,
  ANSWER_QUESTIONS,
  SUBMISSION_POPUP,
  EMPTY_STATE_POPUP,
  IMMEDIATE_RESPONSE_POPUP,
}




function AssessmentCreationBlockedDuringGenerationModal({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) {
  return <ResponsiveModal isOpen={isOpen} onClose={onClose}
    footerButtons={
      <ButtonWithIcon
        onClick={onClose}
        text={"OK"}
        size={"small"}
        icon={IconOption.CHECKMARK}
      />}>
    <p className='text-center'>You currently have a care plan being generated. Please wait until it’s completed before submitting another assessment.</p>
  </ResponsiveModal>
}



