import { useQuery } from "@tanstack/react-query";
import { Database } from "../../../../types/supabase";
import { supabase } from "clients/supabaseClient";
import { QUERY_KEYS } from "backend/queryKeys";

export type GuideTask = Database["public"]["Tables"]["guide_task"]["Row"];
const TABLE = "guide_task";
const GUIDE_TASK_ID_FOR_INCOMING_CALLS = "3.99.1";

export function useGuideTask(guideTaskId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.guideTask, guideTaskId],
    queryFn: async () => {
      if (!guideTaskId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, guide_category(*,guide_pre_requisite(*)), guide_pre_requisite(*)")
        .eq("id", guideTaskId)
        .maybeSingle();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  }
  );
}

export function useGuideTasks() {
  return useQuery({
    queryKey: [QUERY_KEYS.guideTask],
    queryFn: async () => {
      const { data } = await supabase
        .from(TABLE)
        .select("*, guide_category(title)")
      return data;
    },
  });
}

export function useIncomingCallGuidePlanEntryByNetworkId(networkId: string | null | undefined) {
  return useQuery({
    queryKey: [QUERY_KEYS.guidePlanEntryIncomingCall, { networkId }],
    queryFn: async () => {
      if (!networkId) return null;
      // first, query the guide task id with GUIDE_IDENTIFIER 3.99.1
      const { data: guideTaskId } = await supabase
        .from(TABLE)
        .select("id")
        .eq("GUIDE_identifier", GUIDE_TASK_ID_FOR_INCOMING_CALLS)
        .limit(1)
        .maybeSingle();
      if (!guideTaskId) return null;

      const { data } = await supabase
        .from("plan_entry")
        .select("id")
        .eq("is_service_ticket", false)
        .eq("guide_task_id", guideTaskId.id)
        .eq("network_id", networkId)
        .limit(1)
        .maybeSingle();

      return data?.id ?? null;
    },
  });
}