import { useDeleteUserPrescription } from "backend/resources/userPrescription/userPrescription";
import { useNavigateBack } from "lib/routing";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { BottomSheet } from "components/Sheet";

interface Props {
  onClose: () => void;
  drugId?: string;
}

export function MedicationsDeletePrescription({ onClose, drugId }: Props) {
  const deleteUserPrescription = useDeleteUserPrescription().mutateAsync;
  const navigateBack = useNavigateBack();

  const handleDelete = async () => {
    if (drugId) {
      await deleteUserPrescription(drugId);
      onClose();
      navigateBack();
    }
  };

  return (
    <BottomSheet
      isOpen={true}
      onClose={onClose}>
      <div className="flex flex-col items-center text-sm p-6">
        <span className="p-2 text-center">
          Are you sure you want to delete this prescription?
        </span>
        <div className="flex gap-3">
          <ButtonWithIcon
            onClick={onClose}
            className="p-2"
            text="Cancel"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={handleDelete}
            className="p-2"
            text="Yes, delete"
            icon={IconOption.CHECKMARK}
          />
        </div>
      </div>
    </BottomSheet>
  );
}
