import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useUpdateNetworkPCP } from "backend/resources/network/network"
import { fetchServiceEngagementByServiceResourceIdAndNetworkId, useCreateServiceEngagement, useUpdateServiceEngagement } from "backend/resources/services/serviceEngagement"
import { useServiceResourceByCategory, useUpsertServiceResource } from "backend/resources/services/serviceResource"
import { AddNewProviderManuallyForm, ExtendedYellowPagesListingSchema } from "components/AddNewProviderPage/AddNewProviderManually"
import { DefaultCheckbox } from "components/Checkbox"
import { Select } from "components/Select"
import { UseFormReturn, useForm } from "react-hook-form"
import { v4 as uuidv4 } from 'uuid'
import { z } from "zod"
import { CustomFormField, Form, FormRoot } from "../../ui/form"
import { MultiPageFormProps, YellowPagesListingSchema } from "../types"


export const PCPSchema = YellowPagesListingSchema.extend({
  name: z.string(),
  phone: z.string(),
  email: z.string(),
  categories: z.string(),
  id: z.string().optional(),
  should_use_existing_pcp: z.boolean(),
  listing_id: z.string().optional(),
}).refine((data) => data.should_use_existing_pcp || data.listing_id, {
  message: "listing_id is required",
  path: ["listing_id"],
})

export type PCPFormHook = UseFormReturn<z.infer<typeof PCPSchema>>

export const usePCPForm = () => useForm<z.infer<typeof PCPSchema>>({
  resolver: zodResolver(PCPSchema),
  mode: "onSubmit",
  defaultValues: {
    should_use_existing_pcp: true,
  }
});

export const useSubmitPCPForm = () => {
  // Mutation
  const updateNetworkPCP = useUpdateNetworkPCP().mutateAsync
  const upsertServiceResource = useUpsertServiceResource().mutateAsync;
  const createServiceEngagement = useCreateServiceEngagement().mutateAsync;
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;
  return useMutation({
    mutationFn: async ({ data, networkId }: { data: z.infer<typeof PCPSchema>, networkId: string }) => {
      const { should_use_existing_pcp, ...dataWithoutPCP } = data;
      const pcpId = should_use_existing_pcp ? data.id : (await upsertServiceResource(dataWithoutPCP)).data?.id;

      if (pcpId && networkId) {
        const promises: Promise<any>[] = [
          updateNetworkPCP({ pcpId, networkId })
        ];
        const existingServiceEngagement = await fetchServiceEngagementByServiceResourceIdAndNetworkId(pcpId, networkId)

        if (existingServiceEngagement) {
          promises.push(updateServiceEngagement({
            id: existingServiceEngagement.id,
            is_active: false,
            became_inactive_at: new Date().toISOString()
          }));
        }

        promises.push(createServiceEngagement({
          serviceRequestId: pcpId,
          serviceEngagement: {
            service_resource_id: pcpId,
            network_id: networkId,
            name: "Primary Caregiver",
            details: "Primary Caregiver",
          }
        }));

        await Promise.all(promises);
      }
    }
  })
}

export default function PCPForm(props: MultiPageFormProps<z.infer<typeof PCPSchema>> & { step?: number }
) {
  const formValues = props.form.watch()
  // Queries 
  const { data: serviceResources } = useServiceResourceByCategory("Physician")

  // Constants
  const pcpOptions = serviceResources?.map((serviceResource) => ({
    label: serviceResource.name,
    value: serviceResource,
  })) ?? []

  return <div className="flex flex-col gap-2">
    <Form {...props.form} >
      <FormRoot id={"add-pcp-form"} >
        <CustomFormField
          labelName=""
          form={props.form}
          fieldName="should_use_existing_pcp"
        >
          {(field) => <DefaultCheckbox
            label="Use existing PCP"
            onChange={(isChecked: boolean) => {
              props.form.reset({
                should_use_existing_pcp: isChecked,
                listing_id: uuidv4(),
                categories: "Physicians & Surgeons, Primary Care Physicians",
              })
            }}
            checked={field.value}
          />}
        </CustomFormField>

        {formValues.should_use_existing_pcp &&
          <CustomFormField
            labelName="PCP"
            form={props.form}
            fieldName="id">
            {(field) =>
              <Select
                placeHolder="Please select..."
                classNames={`p-0.5 pr-2 h-[2.5rem] w-full bg-white`}
                borderClass="border-2 border-faint-gray"
                currentOption={pcpOptions.find(
                  (option) => option.value.id === formValues.id
                )}
                options={pcpOptions}
                onChange={
                  (option) => {
                    if (option && option.id) {
                      props.form.reset({
                        should_use_existing_pcp: true,
                        id: option.id,
                        name: option.name,
                        phone: option.phone,
                        email: option.email,
                        categories: option.categories,
                      })
                    }
                  }
                }
              />
            }
          </CustomFormField>
        }
      </FormRoot>
    </Form >
    {!formValues.should_use_existing_pcp &&
      <AddNewProviderManuallyForm form={props.form as unknown as UseFormReturn<z.infer<typeof ExtendedYellowPagesListingSchema>>} />
    }
  </div>
}

