import { useWindowSize } from "hooks/useWindowSize";
import AlfredIcon from "components/Alfred/Alfred";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarDiscussionsPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"discussionsPage"}
        pageType="discussionsPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50  text-sm px-4 py-4 rounded-md">
        {/* intro PP */}
        <p>
          This is where you have all of your interactions with your Care Team.
          Whenever you need assistance and need to contact someone, you can ask
          in one of the discussion and they will respond as soon as
          they can.
        </p>
      </div>
    </div>
  );
}
