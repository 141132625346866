import type { PlanEntry } from "backend/resources/planEntry";
import { getRoleLabel } from "backend/resources/orgRole";
import { useUserQuery } from "backend/resources/user";
import { UserWithRole } from "backend/resources/userRole";
import { useUserStore } from "state/user";
import { ProfileImage } from "components/Profile/ProfileImage";
import { Select } from "components/Select";
import { getUserInitials, useUsersInActiveNetworkAndOrganization } from "components/MyPlanPage/utils";


export enum UserType {
  ORG_USERS,
  NETWORK_USERS,
  BOTH,
  ORG_USERS_IN_NETWORK,
}

interface WhoProps {
  entry?: PlanEntry | undefined | null;
  isEditing?: boolean;
  setPlanEntryOwner?: any;
  selectedPlanEntryOwnerId: string | undefined;
  networkId?: string | null | undefined;
  userType?: UserType,
  className?: string;
  disabled?: boolean;
  fontSize?: string;
}

export default function Who({
  entry,
  isEditing,
  setPlanEntryOwner,
  selectedPlanEntryOwnerId,
  networkId,
  userType = UserType.NETWORK_USERS,
  className,
  disabled,
  fontSize,
}: WhoProps) {
  const authUser = useUserStore((state) => state.user);

  // Queries
  const { user: selectedPlanEntryOwner, isUserLoading, } = useUserQuery(selectedPlanEntryOwnerId || entry?.user_id);
  const { usersInNetwork, usersInOrg, usersInOrgAndNetwork, orgUsersInNetwork } = useUsersInActiveNetworkAndOrganization(networkId ?? undefined)



  const filteredUsers: UserWithRole[] = {
    [UserType.ORG_USERS]: usersInOrg,
    [UserType.NETWORK_USERS]: usersInNetwork ?? [],
    [UserType.BOTH]: usersInOrgAndNetwork,
    [UserType.ORG_USERS_IN_NETWORK]: orgUsersInNetwork,
  }[userType];

  const userOptions = filteredUsers.map((userWithRole) => {
    const role = getRoleLabel(userWithRole.role)
    return {
      label: userWithRole.user.id === authUser?.id ? "Me" : `${userWithRole.user.first_name} ${userWithRole.user.last_name} (${role})`,
      value: userWithRole.user.id,
      icon: <ProfileImage
        userId={userWithRole.user.id}
        size={20}
      />
    }
  })

  return (
    <div className="flex items-center gap-2 w-full">
      {isEditing ? (
        <Select
          fontSize={fontSize}
          disabled={disabled}
          classNames={`${className} whitespace-nowrap  border-zinc-300 w-full`}
          placeHolder="Please Select"
          currentOption={userOptions.find((option) => option.value === selectedPlanEntryOwnerId)}
          options={userOptions}
          onChange={(newValue) => {
            setPlanEntryOwner(newValue)
          }}
        />
      ) : (
        getUserInitials(selectedPlanEntryOwner, authUser)
      )}
    </div>
  );

}
