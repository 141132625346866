import type { ChatGptMessage } from "backend/resources/chatGptMessage";
import { UserBookmarkType } from "backend/resources/userBookmark";
import { ActionBar } from "components/ActionBar";
import AlfredIcon from "components/Alfred/Alfred";
import { renderContentWithBreaks } from "components/ChatGptSideBar/Message/utils";

interface Props {
  message: ChatGptMessage;
}

export function AssistantMessage({ message }: Props) {
  return (
    <div className="relative w-full flex flex-col">
      <div className="flex min-w-0 gap-3">
        <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
        <p className="bg-gray-50 pt-4 text-sm px-4 py-2 rounded-md">
          {renderContentWithBreaks(message.content)}
        </p>
      </div>
      <div className="absolute bottom-0 right-0">
        <ActionBar
          bookmarkableItem={{
            itemId: message.id,
            type: UserBookmarkType.CHAT_GPT_MESSAGE,
          }}
          onlyShowBookmark={true}
        />
      </div>
    </div>
  );
}
