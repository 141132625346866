import type { ChangeEvent, FormEvent } from "react";
import { useState } from "react";
import { useQueryLibraryFolders } from "backend/resources/libraryFolder";
import { supabase } from "clients/supabaseClient";
import { useWindowSize } from "hooks/useWindowSize";
import { useLibraryFoldersStore } from "state/libraryFolders";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Popup } from "components/Popup";
import { BottomSheet } from "components/Sheet";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";

interface Props {
  close: () => void;
}

export function CreateNewFolderPopup({ close }: Props) {
  const { isMobile } = useWindowSize();
  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={close}>
        <div className="px-6 pb-6">
          <CreateNewFolderComponent close={close} />
        </div>
      </BottomSheet>
    );
  }
  return (
    <Popup
      containerClassName="w-[400px]"
      closeOnClickOutside={false}
      close={close}>
      <CreateNewFolderComponent close={close} />
    </Popup>
  );
}

function CreateNewFolderComponent({ close }: Props) {
  const [name, setName] = useState<string>("");
  const networkId = useActiveNetworkId();

  const [description, setDescription] = useState<string>("");
  const authUser = useUserStore((state) => state.user);
  const [error, setError] = useState<string>();

  const { refetchLibraryFolders } = useQueryLibraryFolders();
  const setActiveFolder = useLibraryFoldersStore(
    (state) => state.setActiveFolder
  );
  function onChangeName(e: ChangeEvent<HTMLInputElement>) {
    setError(""); // errors if library_folder.name already exists, clear error message on changes
    setName(e.currentTarget.value);
  }

  function onChangeDescription(e: ChangeEvent<HTMLTextAreaElement>) {
    setDescription(e.currentTarget.value);
  }

  async function createNewFolder(
    e: FormEvent<HTMLFormElement> | FormEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    if (authUser?.id) {
      const { data, error } = await supabase
        .from("library_folder")
        .insert({
          name,
          description,
          network_id: networkId,
          user_id: authUser.id,
        })
        .select();

      if (data && data.length > 0) {
        await refetchLibraryFolders();
        setActiveFolder(data[0]);
        close();
      } else if (error?.message.includes("unique")) {
        setError(`You already have a folder named "${name}"`);
      } else {
        setError("Something went wrong.");
      }
    }
  }
  return (
    <form
      className="flex flex-col gap-3 pb-20 md:pb-0"
      onSubmit={createNewFolder}>
      <h1 className="text-xl">Create New Folder</h1>
      <div className="flex flex-col gap-1">
        <p>Name</p>
        <TextInput
          data-hj-allow
          name={name}
          onChange={onChangeName}
        />
      </div>
      <div className="flex flex-col gap-1">
        <p>Description</p>
        <TextArea
          data-hj-allow
          name={description}
          onChange={onChangeDescription}
        />
      </div>
      <FooterButtons>
        <ButtonWithIcon
          text="Cancel"
          onClick={close}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          text="Done"
          onClick={createNewFolder}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
      {error && (
        <p className="self-center text-center text-red-500 text-sm">{error}</p>
      )}
    </form>
  );
}
