import { Slider } from "components/AssessmentComponent/Input/inputTypes/SliderInput";
import { YesOrNoOptions } from "components/AssessmentComponent/Input/inputTypes/YesOrNoInput";
import { DefaultCheckbox } from "components/Checkbox";

export type NPIQUserAssessmentAnswer = { yes_or_no?: string, severity?: number, distress?: number };

enum NPIQInputField {
  YES_OR_NO = 'yes_or_no',
  SEVERITY = 'severity',
  DISTRESS = 'distress'
}


interface NPIQInputProps {
  disabled: boolean;
  currentAnswer: NPIQUserAssessmentAnswer | undefined;
  updateAnswer: (obj: { answer?: NPIQUserAssessmentAnswer; context?: string }) => void;
}
export default function NPIQInput({ disabled, currentAnswer, updateAnswer }: NPIQInputProps) {

  // Handlers
  const updateAnswerField = ({ field, answer, context }: { field: NPIQInputField, answer?: string | number | undefined, context?: string | undefined }) => {
    updateAnswer({
      context,
      answer: { ...currentAnswer, [field]: answer }
    });
  }

  return <div className="flex flex-col w-full md:grid grid-cols-[1fr,1fr,2fr] md:gap-8 text-sm items-start">
    {/* Yes/No */}
    <div className="flex gap-3 mt-[5px] items-center">
      <DefaultCheckbox
        disabled={disabled}
        checked={currentAnswer?.yes_or_no === YesOrNoOptions.YES}
        onChange={() => updateAnswerField({ field: NPIQInputField.YES_OR_NO, answer: YesOrNoOptions.YES })}
      />
      <p className="mr-2">Yes</p>

      <DefaultCheckbox
        disabled={disabled}
        checked={currentAnswer?.yes_or_no === YesOrNoOptions.NO}
        onChange={() =>
          // update the answer to NO and reset the severity and distress
          updateAnswer({ answer: { yes_or_no: YesOrNoOptions.NO, severity: 0, distress: 0 } })
        }
      />
      <p>No</p>
    </div>
    {/* Severity */}
    <div className="flex gap-5 flex-grow w-full ">
      <p className="mt-2">Severity</p>
      <Slider
        disabled={disabled || currentAnswer?.yes_or_no !== YesOrNoOptions.YES}
        currentAnswer={currentAnswer?.severity ?? 0}
        updateAnswer={({ answer, context }) => updateAnswerField({ field: NPIQInputField.SEVERITY, answer, context })}
        scale={3}
      />
    </div>
    {/* Distress */}
    <div className="flex gap-5 flex-grow w-full">
      <p className="mt-2">Distress</p>
      <Slider
        disabled={disabled || currentAnswer?.yes_or_no !== YesOrNoOptions.YES}
        currentAnswer={currentAnswer?.distress ?? 0}
        updateAnswer={({ answer, context }) => updateAnswerField({ field: NPIQInputField.DISTRESS, answer, context })}
        scale={5}
      />
    </div>
  </div>;
}

