import { Header, MobileHeader } from "components/Header";
import { useWindowSize } from "hooks/useWindowSize";
import "react-loading-skeleton/dist/skeleton.css";

interface AppHeaderProps {
  hideSideNav?: boolean;
}

export const AppHeader: React.FC<AppHeaderProps> = ({ hideSideNav = false }) => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return <MobileHeader />;
  } else {
    return <Header />;
  }
};
