import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useServiceRequests, useUpdatePlanEntry } from "backend/resources/planEntry";
import { useServiceEngagements } from "backend/resources/services/serviceEngagement";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeaderWithNetworkName } from "components/PageMainHeader/PageMainHeaderWithNetwork";
import { LocalServicesSearch } from "components/ResourcesPage/components/LocalServicesSearch";
import ServicesTable, { ServiceFields } from "components/Tables/ServicesTable";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields, TaskTableType } from "components/Tables/TaskTable/TaskTableTypes";
import Tabs from "components/Tabs/Tabs";
import {
  CraniometrixProduct,
  useProductAccess,
} from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { SharedRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useState } from "react";
import { useActiveNetworkId } from "state/network/network";
import BackButton from "components/BackButton/BackButton";


export enum LocalSearchTabs {
  SEARCH = "Search",
  SERVICE_REQUESTS = "Requests",
  SERVICES = "Active Services",
}

export default function ResourcesPage() {
  const navigateBack = useNavigateBack();
  const navigate = useAppNavigate();


  // store
  const { isMobile } = useWindowSize();
  const activeNetworkId = useActiveNetworkId();

  // state
  const [currentTab, setCurrentTab] = useState<LocalSearchTabs>(
    LocalSearchTabs.SEARCH
  );

  // Queries
  useAlfredPageSideBar("servicesPage")
  const { data: serviceRequests } = useServiceRequests();
  const { data: productAccess, isLoading: isLoadingProductAccess } =
    useProductAccess();
  const { data } = useServiceEngagements();

  // Mutations
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  if (isLoadingProductAccess) {
    return (
      <div className="flex flex-col items-center justify-center">
        <LoadingSpinner className="w-8 h-8" />
      </div>
    );
  } else {
    return (
      <PageContainer>
        {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
          <BackButton className="mb-2" />
        ) : null}
        <PageMainHeaderWithNetworkName
          text={"Services"}
          className="text-center md:text-start w-full pb-3"
        />
        <Tabs
          tabs={Object.values(LocalSearchTabs)}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        {/* Description */}

        {currentTab === LocalSearchTabs.SEARCH ? (
          <>
            <p className='text-sm py-4'>
              Enter your zip code and search for local resources to help with your
              care and other related services
            </p>
            <LocalServicesSearch
              hideCheckbox
              showReferralButton
              selectedLocalResources={undefined}
              onSelectLocalResource={() => { }}
              backgroundColor="bg-gray-100/80"
            />
          </>


        ) : null}
        {currentTab === LocalSearchTabs.SERVICE_REQUESTS ? (
          <TaskTable
            updateTask={updatePlanEntry}
            data={serviceRequests}
            sortFunction={(a: TaskTableType, b: TaskTableType) => new Date(b[TaskTableFields.CreatedAt]).getTime() - new Date(a[TaskTableFields.CreatedAt]).getTime()}
            hideCheckbox
            filterConfig={{ customAdditionalFilter: (row) => row.network_id === activeNetworkId }}
            handleClick={(cell, row) => navigate({
              path: SharedRoute.SERVICE_REQUEST_VIEW, params: {
                id: row.original[TaskTableFields.Id]
              }
            },)}
            hiddenColumns={[TaskTableFields.Category, TaskTableFields.Carespace]}
          />
        ) : null}
        {currentTab === LocalSearchTabs.SERVICES ? (
          <ServicesTable
            data={data}
            filterConfig={{ customAdditionalFilter: (row) => row.network_id === activeNetworkId }}
            hiddenColumns={isMobile ? [ServiceFields.Carespace, ServiceFields.ServiceType, ServiceFields.Provider, ServiceFields.ActiveOrInactiveDate] : [ServiceFields.Carespace]} />
        ) : null}
      </PageContainer>
    );
  }
}
