import { AlertCircle } from "lucide-react"

import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "shared/ui/alert"

export function InactivityAlert() {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertDescription>
        Your session has expired. Please log in again.
      </AlertDescription>
    </Alert>
  )
}

export function LogoutAlert() {
  return (
    <Alert variant="default">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Signed Out</AlertTitle>
      <AlertDescription>
        You've been logged out successfully.
      </AlertDescription>
    </Alert>
  )
}


export function WrongLinkAlert() {
  return (
    <Alert variant="destructive">
      <AlertCircle className="h-4 w-4" />
      <AlertTitle>Error</AlertTitle>
      <AlertDescription>
        The link you followed is not associated with this account. 
        For security reasons, we've logged you out. 
        Please sign in with the correct account to use the link.
      </AlertDescription>
    </Alert>
  )
}
