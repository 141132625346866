import * as Sentry from "@sentry/react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "App";
import { supabase } from "clients/supabaseClient";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";
import { QUERY_KEYS } from "backend/queryKeys";
import type { ShareableInterventionUpsertType } from "backend/resources/shareableIntervention/shareableIntervention";
import { useSaveInterventionsToRecommendation } from "backend/resources/shareableIntervention/shareableIntervention";
import {
  useAddUserCreatedRecommendationMutation,
  useDeleteUserCreatedRecommendationMutation,
} from "backend/resources/userCreatedRecommendations/userCreatedRecommendations";

const TABLE = "recommendation";

export type ShareableRecommendationType =
  Database["public"]["Tables"]["recommendation"]["Row"];

export type ShareableRecommendationUpsertType =
  Database["public"]["Tables"]["recommendation"]["Update"];
export type GoalTemplateToRecommendation =
  Database["public"]["Tables"]["goal_template_to_recommendation"]["Row"] & {
    recommendation: ShareableRecommendationType;
  };
export function useShareableRecommendationsFromShareableRecommendationIds(
  recommendationIds: string[]
): UseQueryResult<ShareableRecommendationType[]> {
  return useQuery({
    queryKey: [QUERY_KEYS.goalTemplateToRecommendation, recommendationIds],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select("*")
        .in("id", recommendationIds);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      return data;
    },
  });
}

export const useCreateShareableRecommendationMutation = (
  recommendationId: string | undefined
) => {
  const authUser = useUserStore((state) => state.user);
  const activeOrganizationId = useActiveOrganizationId();
  const saveToUserCreatedRecommendations =
    useAddUserCreatedRecommendationMutation().mutateAsync;
  const saveInterventionsForRecommendation =
    useSaveInterventionsToRecommendation(recommendationId).mutateAsync;
  return useMutation({
    mutationFn: ({
      shareableRecommendation,
    }: {
      shareableRecommendation: ShareableRecommendationUpsertType;
      interventions: ShareableInterventionUpsertType[];
    }) => {
      return upsertShareableRecommendation(
        shareableRecommendation as ShareableRecommendationType
      );
    },
    onSuccess: async (data, variables) => {
      if (authUser && data && activeOrganizationId) {
        await saveToUserCreatedRecommendations({
          created_by_id: authUser.id,
          recommendation_id: data?.id,
          organization_id: activeOrganizationId,
        });
        const a = await saveInterventionsForRecommendation(
          variables.interventions.map((intervention) => ({
            ...intervention,
            recommendation_id: data?.id,
          }))
        );
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.goalTemplateToRecommendation] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.userCreatedRecommendations] });
    },
  });
};
async function upsertShareableRecommendation(
  shareableRecommendation: ShareableRecommendationType
) {
  const { data, error } = await supabase
    .from(TABLE)
    .upsert({
      ...shareableRecommendation,
      deprecated: true,
    })
    .select()
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
  }

  return data;
}

export function useDeleteShareableRecommendationHook() {
  const deleteFromListOfUserCreateRecommendations =
    useDeleteUserCreatedRecommendationMutation().mutateAsync;
  return useMutation({
    mutationFn: (shareableRecommendationId?: string) => {
      return deleteFromListOfUserCreateRecommendations(
        shareableRecommendationId
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.goalTemplateToRecommendation] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.userCreatedRecommendations] });
    },
  });
}
