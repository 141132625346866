import { z } from "zod";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { orgMemberSchema } from "shared/forms/CarespaceCreationWizard/AddOrgMemberNavigatorForm";
import { carespaceInformationFormSchema } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";
import { inviteCaregiverFormSchema } from "shared/forms/CarespaceCreationWizard/InviteCaregiverForm";
import { PCPSchema } from "shared/forms/CarespaceCreationWizard/PCPForm";

export enum CarespaceCreationPage {
  CARESPACE_INFO = "Carespace Info",
  INVITE_PROVIDER = "Invite Provider",
  INVITE_CARE_NAVIGATOR = "Invite Care Navigator",
  CHANGE_PCP = "Change PCP",
  INVITE_CAREGIVER = "Invite Caregiver",
}

type State = {
  // progress
  currentPage: CarespaceCreationPage;
  // pages
  carespaceInformationValues?: z.infer<typeof carespaceInformationFormSchema>;
  inviteCaregiverValues?: z.infer<typeof inviteCaregiverFormSchema>;
  provider?: z.infer<typeof orgMemberSchema>;
  careNavigator?: z.infer<typeof orgMemberSchema>;
  pcp?: z.infer<typeof PCPSchema>;
  // submission state
  isCreatingCarespace: boolean;
  createdCarespaceId?: string;
};

type Actions = {
  setCurrentPage: (currentPage: CarespaceCreationPage) => void;

  setIsCreatingCarespace: (value: boolean) => void;

  setCreatedCarespaceId: (id: string) => void;

  setCarespaceInformationValues: (
    values: z.infer<typeof carespaceInformationFormSchema>
  ) => void;

  setInviteCaregiverValues: (
    values: z.infer<typeof inviteCaregiverFormSchema>
  ) => void;

  setAddProviderValues: (values: z.infer<typeof orgMemberSchema>) => void;

  setAddCareNavigatorValues: (values: z.infer<typeof orgMemberSchema>) => void;

  setPCP: (values: z.infer<typeof PCPSchema>) => void;

  nextPage: () => void;

  previousPage: () => void;

  reset: () => void;
};

const initialState: State = {
  currentPage: CarespaceCreationPage.CARESPACE_INFO,
  //
  carespaceInformationValues: undefined,
  inviteCaregiverValues: undefined,
  provider: undefined,
  careNavigator: undefined,
  //
  isCreatingCarespace: false,
  createdCarespaceId: undefined,
};

/**
 * Hook to access the action bar store
 */
export const useCarespaceCreationWizard = create<State & Actions>()(
  immer((set, get) => ({
    /**
     * state
     */
    ...initialState,

    /**
     * setters
     */
    setCurrentPage: (currentPage: CarespaceCreationPage) =>
      set((state) => {
        state.currentPage = currentPage;
      }),

    setIsCreatingCarespace: (value: boolean) =>
      set((state) => {
        state.isCreatingCarespace = value;
      }),

    setCreatedCarespaceId: (id: string) =>
      set((state) => {
        state.createdCarespaceId = id;
      }),

    setCarespaceInformationValues: (values) =>
      set((state) => {
        state.carespaceInformationValues = values;
      }),

    setAddProviderValues: (values) =>
      set((state) => {
        state.provider = values;
      }),

    setAddCareNavigatorValues: (values) =>
      set((state) => {
        state.careNavigator = values;
      }),

    setInviteCaregiverValues: (values) =>
      set((state) => {
        state.inviteCaregiverValues = values;
      }),

    setPCP: (values) =>
      set((state) => {
        state.pcp = values;
      }),

    /**
     * form actions
     */
    nextPage: () => {
      set((state) => {
        const pages = Object.values(CarespaceCreationPage);
        const currentIndex = pages.indexOf(state.currentPage);
        if (currentIndex < pages.length - 1) {
          state.currentPage = pages[currentIndex + 1];
        }
      });
    },

    previousPage: () => {
      set((state) => {
        const pages = Object.values(CarespaceCreationPage);
        const currentIndex = pages.indexOf(state.currentPage);
        if (currentIndex > 0) {
          state.currentPage = pages[currentIndex - 1];
        }
      });
    },

    reset: () => set((state) => Object.assign(state, initialState)),
  }))
);
