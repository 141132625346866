import type { VariantProps } from "class-variance-authority";
import { useState, type ReactNode } from "react";
import { ReactComponent as Bookmark } from "assets/bookmark.svg";
import { ReactComponent as FilledInBookmark } from "assets/filled-in-bookmark.svg";
import { ReactComponent as GrayNotes } from "assets/gray-notes.svg";
import { ReactComponent as GrayTag } from "assets/gray-tag.svg";
import { ReactComponent as Notes } from "assets/notes.svg";
import { ReactComponent as Tag } from "assets/tag.svg";
import type { UserAdlo } from "backend/resources/userAdlo";
import type { BookmarkableItem } from "backend/resources/userBookmark";
import {
  useBookmarkQueryByBookmarkableItem,
  useQueryTagsAndFoldersBookmark,
} from "backend/resources/userBookmark";
import { useUserIsFamilyMember } from "backend/resources/userRole";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user";
import AddNotePopup from "components/AddNotePopup/AddNotePopup";
import { AddTagsPopup } from "components/AddTagsPopup";
import { AdloInitials } from "components/AdloComponent/AdloInitials";
import Rating from "components/GoalPage/Rating";
import Tags from "components/LibraryPage/Bookmarks/Bookmark/Tags/Tags";
import {
  deleteBookmark,
  getBookmarkType,
  getIdOfBookmarkedItem,
  saveBookmark,
} from "components/LibraryPage/Bookmarks/Bookmark/utils";
import type { cardStyles } from "components/Card/Card";
import { Card } from "components/Card/Card";

export default function BookmarkableCard({
  children,
  bookmarkableItem,
  refetchBookmarks,
  userAdlo,
  rating,
  showRating,
  ...variantProps
}: {
  children: ReactNode;
  bookmarkableItem: BookmarkableItem;
  refetchBookmarks: () => void;
  showRating?: boolean;
  userAdlo?: UserAdlo | null;
  rating?: number | null;
} & VariantProps<typeof cardStyles>) {
  const [tagsIsOpen, setTagsIsOpen] = useState(false);
  const [noteIsOpen, setNoteIsOpen] = useState(false);
  const authUser = useUserStore((state) => state.user);

  const { userIsFamilyMember } = useUserIsFamilyMember();
  const activeNetworkId = useActiveNetworkId();
  const { userBookmark } = useBookmarkQueryByBookmarkableItem(bookmarkableItem);
  const { isLoadingTags, isFetchingTags, tags, refetchTags } =
    useQueryTagsAndFoldersBookmark(userBookmark?.id);
  return (
    <Card
      padding={"none"}
      color={"aqua"}
      {...variantProps}>
      <div className="flex flex-col justify-center flex-grow h-max overflow-clip ">
        <div className="flex h-[70%] items-center px-4">{children}</div>
        {variantProps.size !== "thumbnail" && !userIsFamilyMember ? (
          <div className="flex w-full justify-between border-t-[1px] border-white h-min pb-0">
            {tagsIsOpen && (
              <AddTagsPopup
                close={() => setTagsIsOpen(false)}
                bookmarkId={userBookmark.id}
                tags={tags}
                refetchTags={() => {
                  refetchTags();
                }}
                isLoadingTags={isLoadingTags}
                isFetchingTags={isFetchingTags}
              />
            )}
            {noteIsOpen && (
              <AddNotePopup
                close={() => setNoteIsOpen(false)}
                bookmarkItemId={getIdOfBookmarkedItem(userBookmark)}
                bookmarkType={getBookmarkType(userBookmark)}
              />
            )}
            {userAdlo ? (
              <div className="flex items-center pl-2">
                <AdloInitials
                  userAdlo={userAdlo}
                  size="small"
                />
              </div>
            ) : null}
            <Tags
              bookmarkableItem={bookmarkableItem}
              limit={showRating && rating ? 0 : 1}
            />
            {showRating ? (
              <div className="items-center flex flex-grow pl-2">
                <Rating
                  rating={rating ?? null}
                  size={"large"}
                />
              </div>
            ) : null}
            <div className="flex gap-4 p-3 justify-end w-full">
              <button
                disabled={!userBookmark}
                onClick={(e) => {
                  e.stopPropagation();
                  setTagsIsOpen(true);
                }}>
                {userBookmark ? (
                  <Tag className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
                ) : (
                  <GrayTag className="w-6 h-6" />
                )}
              </button>
              <button
                disabled={!userBookmark}
                onClick={(e) => {
                  e.stopPropagation();
                  setNoteIsOpen(true);
                }}>
                {userBookmark ? (
                  <Notes className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
                ) : (
                  <GrayNotes className="w-6 h-6" />
                )}
              </button>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (userBookmark) {
                    deleteBookmark(
                      userBookmark,
                      authUser?.id,
                      refetchBookmarks
                    );
                  } else {
                    saveBookmark(
                      bookmarkableItem,
                      authUser?.id,
                      activeNetworkId,
                      refetchBookmarks
                    );
                  }
                }}>
                {userBookmark ? (
                  <FilledInBookmark className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
                ) : (
                  <Bookmark className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
                )}
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Card>
  );
}
