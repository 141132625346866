export enum Timeline {
  SHORT_TERM = "short",
  MID_TERM = "mid",
  LONG_TERM = "long",
}

export class TimeFrame {
  static SHORT_TERM = new TimeFrame(Timeline.SHORT_TERM, "Short Term");

  static MID_TERM = new TimeFrame(Timeline.MID_TERM, "Medium Term");

  static LONG_TERM = new TimeFrame(Timeline.LONG_TERM, "Long Term");

  private constructor(private value: string, private label: string) {}

  getValue(): string {
    return this.value;
  }

  getLabel(): string {
    return this.label;
  }

  static getDropDownOptions(): { label: string; value: string }[] {
    return [TimeFrame.SHORT_TERM, TimeFrame.MID_TERM, TimeFrame.LONG_TERM].map(
      (timeFrame) => ({
        label: timeFrame.getLabel(),
        value: timeFrame.getValue(),
      })
    );
  }

  static getLabelFromValue(value: string): string {
    switch (value) {
      case Timeline.SHORT_TERM: {
        return TimeFrame.SHORT_TERM.getLabel();
      }
      case Timeline.MID_TERM: {
        return TimeFrame.MID_TERM.getLabel();
      }
      case Timeline.LONG_TERM: {
        return TimeFrame.LONG_TERM.getLabel();
      }
      default: {
        return "";
      }
    }
  }
}
