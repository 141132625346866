import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { FC } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";


interface CarespaceGuideStatusResponsiveModalProps {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onSuccess: () => void;
}

export const CarespaceGuideStatusResponsiveModal: FC<CarespaceGuideStatusResponsiveModalProps> = ({
  isOpen, text, onClose, onSuccess
}) => {
  return (
    <ResponsiveModal 
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="text-center p-4">{text}</div>
      <div className="flex justify-between p-4">
        <div>
          <ButtonWithIcon 
            icon={IconOption.CANCEL}
            onClick={onClose}
            text={"Cancel"}
          />
        </div>
        <div>
          <ButtonWithIcon 
            icon={IconOption.CHECKMARK}
            onClick={onSuccess}
            text={"Yes"}
          />
        </div>
      </div>
    </ResponsiveModal>
  )
}