import {
  useAlfredPageSideBar
} from "backend/resources/chatGptConversation";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { VideoLibraryPage } from "components/VideoLibraryPage";

export default function EducationPage() {
  useAlfredPageSideBar("educationPage")
  return (
    <PageContainer>
      <PageMainHeader
        text={"Education"}
        className="text-center md:text-start w-full pb-2"
      />
      <VideoLibraryPage />
    </PageContainer>
  );
}
