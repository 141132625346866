export function renderContentWithBreaks(content: string) {
  const splitContent = content.split(/<br\s*\/?>/);

  return splitContent.map((line, index) => {
    // Check if the current line and previous line are both empty
    const isConsecutiveEmpty =
      line.trim() === "" &&
      (index === 0 || splitContent[index - 1].trim() === "");

    return (
      <>
        {line}
        {!isConsecutiveEmpty && index !== splitContent.length - 1 && <br />}
      </>
    );
  });
}
