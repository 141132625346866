

// ...

import { useMutation } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useUserStore } from "state/user";

export function uploadProfileImageMutation() {
  const authUser = useUserStore((state) => state.user);

  return useMutation({
    mutationFn: async (file: File) => {
      const filename = `${authUser?.id}/profile_${Date.now()}.${file.type.replace(/(.*)\//g, "")}`;

      const { data, error } = await supabase.storage
        .from("profile-images")
        .upload(filename, file, {
          cacheControl: "3600",
          upsert: true,
        });
      return { data, error }
    },
  }
  );
}
