import type { VariantProps } from "class-variance-authority";
import { formatDistance } from "date-fns";
import type { NewsMetadata } from "backend/resources/userGuidanceResource";
import type { cardStyles } from "components/Card/Card";

interface Props {
  metadata: NewsMetadata;
}

export default function NewsComponent({
  metadata,
  ...variantProps
}: Props & VariantProps<typeof cardStyles>) {
  const url = metadata.image?.thumbnail.contentUrl
    ? `${metadata.image.thumbnail.contentUrl}&h=250&p=0`
    : undefined;
  if (!url) return null;
  const isThumbnail = variantProps.size === "thumbnail"; // Check if the size is "thumbnail"

  return (
    <div className="flex flex-col items-center h-full w-full justify-center ">
      <div className="flex overflow-clip w-full gap-2 items-center rounded-md h-[80px] md:h-[120px]">
        <img
          src={url}
          alt={metadata.name}
          className={`flex flex-2/3 md:flex-[54%] md:mt-2 ${
            isThumbnail ? "w-full h-full" : "w-full h-full"
          } h-[110%] md:h-[90%] object-cover object-left rounded-md 
          `}
        />
        {!isThumbnail ? (
          <div className="h-full  w-full md:w-[200px] md:px-2 flex flex-col justify-between">
            <p
              className="line-clamp-3  text-xs md:text-sm leading-[0.95rem] mt-1 md:mt-4"
              title={metadata.name}>
              {metadata.name}
            </p>

            <div className="items-end h-max w-full flex justify-between">
              <img
                src={metadata.provider[0].image?.thumbnail.contentUrl}
                alt={metadata.provider[0].name}
                className=" w-[12px] h-[12px] mb-1  md:w-[20px] md:h-[20px]  object-top object-cover"
              />
              <p className="text-xs text-right w-full md:self-end">
                {formatDistance(new Date(metadata.datePublished), new Date(), {
                  addSuffix: true,
                }).replace("about", "")}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
