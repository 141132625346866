export function CookieUse() {
  return (
    <div className="w-full h-full">
      <iframe
        className="w-full h-full"
        title="cookie use"
        src="./cookie-use.html"
      />
    </div>
  );
}
