/**
 * @fileoverview Generic DS TextArea component.
 */
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { DetailedHTMLProps, TextareaHTMLAttributes } from "react";

const textAreaStyles = cva(
  [
    "bg-white",
    "border-2",
    "border-faint-gray",
    "rounded",
    "focus:border-faint-gray",
  ],
  {
    variants: {
      intent: {
        primary: [],
      },
      size: {
        small: ["text-sm", "py-1", "px-2"],
        medium: ["text-base", "py-2", "px-4"],
      },
      length: {
        short: [],
        long: [],
      },
    },
    defaultVariants: {
      intent: "primary",
      size: "medium",
      length: "long",
    },
  }
);

type TextAreaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  VariantProps<typeof textAreaStyles>;

/**
 * Generic TextArea component
 *
 * @param props HTML text area props
 * @returns textarea element
 */
export function TextArea(props: TextAreaProps) {
  const { className, intent, size, length, disabled, ...rest } = props;
  const maxLength = length === "short" ? 150 : undefined;
  return (
    <textarea
      data-hj-allow
      className={textAreaStyles({ intent, size, className })}
      disabled={disabled}
      maxLength={maxLength}
      {...rest}></textarea>
  );
}
