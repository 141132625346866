import { ReactComponent as WarningIcon } from "../../../assets/warning.svg";
import { CarePlanGoalStatuses, CarePlanGoalWithProgress } from "../../../backend/resources/carePlan/carePlan";
import { GoalWithStatus } from "../../../backend/resources/goal/goal";
import { CarePilotRoute, useAppNavigate } from "../../../lib/routing";
import { ButtonWithIcon, IconOption } from "../../ButtonWithIcon";
import { TimeFrame, Timeline } from "../../GoalPage/utils";

export function CarePlanTimelineComponent({
  timeline,
  goals,
  middleColumn,
  buttonAction,
  hasDuplicateTitle,
  isNewGoal
}: {
  timeline: Timeline;
  goals: CarePlanGoalWithProgress[];
  middleColumn?: string;
  buttonAction: (goal: CarePlanGoalWithProgress) => void;
  hasDuplicateTitle: (goal: GoalWithStatus) => boolean;
  isNewGoal: (goal: GoalWithStatus) => boolean;
}) {
  
  const hasGoals = goals && goals.length > 0;
  const navigate = useAppNavigate();

  const sortedGoals = goals.sort((a, b) =>
    a.created_at > b.created_at
      ? -1
      : b.created_at > a.created_at
        ? 1
        : 0
  )

  return (
    <div className="pb-5">
      <div className="grid grid-cols-[1fr,.5fr,.5fr] gap-2 mt-4 text-center items-center">
        <>
          <h2 className="font-bold text-sm text-start">
            {TimeFrame.getLabelFromValue(timeline)}
          </h2>
          <p /> <p />
          {!hasGoals ? <p className="text-start text-sm">No goals</p> : null}
          {sortedGoals
            .map((carePlanGoal: CarePlanGoalWithProgress) => (
              <>
                <button
                  className={`line-clamp-2 text-sm text-left flex items-center break-words ${carePlanGoal.status === CarePlanGoalStatuses.ARCHIVED ? "text-gray-300" : ""
                    }`}
                  onClick={() => {
                    navigate({
                      path: CarePilotRoute.GOAL_ID,
                      params: {
                        id: carePlanGoal.id
                      }
                    })
                  }}>
                  <p
                    className={`line-clamp-2 text-sm text-left break-words ${isNewGoal(carePlanGoal) ? "text-blue-500" : ""
                      }`}>
                    {carePlanGoal.title} {!isNewGoal(carePlanGoal) && hasDuplicateTitle(carePlanGoal) && <WarningIcon className="w-3 h-3 mb-[3px] inline" />}
                  </p>
                </button>
                {middleColumn ? (
                  <p
                    className={`text-sm ${carePlanGoal.progress === 0 ? "text-gray-300" : ""
                      }`}>
                    {carePlanGoal.progress}%
                  </p>
                ) : (
                  <p></p>
                )}
                <ButtonWithIcon
                  onClick={() => buttonAction(carePlanGoal)}
                  className=" flex justify-center"
                  text={""}
                  size="small"
                  icon={
                    carePlanGoal.status === CarePlanGoalStatuses.ARCHIVED
                      ? IconOption.RESTORE
                      : carePlanGoal.status === CarePlanGoalStatuses.INCLUDED ?
                        IconOption.ARCHIVE
                        : carePlanGoal.is_archived ?
                          IconOption.PLUS : IconOption.ARROW
                  }
                />
              </>
            ))}
        </>
      </div>
    </div>
  );
}
