import { FC } from "react";

interface CarespaceTableTextCellProps {
  label: string;
}

export const CarespaceTableTextCell: FC<CarespaceTableTextCellProps> = ({label}) => {
  return (
    <div className="flex w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
      {label}
    </div>
  )}
