import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { ReactComponent as TrashIcon } from "assets/trash-new.svg";
import { useConversationDocumentsByConversationId } from "backend/resources/conversation_document/conversation_document";
import { useNetworkName } from "backend/resources/network/network";
import { useActiveOrg, useOrgs } from "backend/resources/orgRole";
import type { TaskStatus } from "backend/resources/planEntry";
import {
  PlaceTypeSchema,
  TaskStatusColor,
  TaskStatusLabel,
  useAddConversationToPlanEntry,
  useDeletePlanEntry,
  usePlanEntryAtendeeByPlanEntry,
  usePlanEntryData
} from "backend/resources/planEntry";
import { useServiceEngagementFromPlanEntryId } from "backend/resources/services/serviceEngagementToPlanEntry";
import { useBookmarkQuery } from "backend/resources/userBookmark";
import { AssessmentComponent } from "components/AssessmentComponent/AssessmentComponent";
import BackButton from "components/BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import Carousel from "components/Carousel/Carousel";
import { RECURRING_INTERVAL_OPTIONS } from "components/GUIDETaskPage/GUIDETaskPage";
import { Bookmark } from "components/LibraryPage/Bookmarks/Bookmark";
import ModalView from "components/ModalView/ModalView";
import AddAttendeesButtonPopup from "components/MyPlanPage/components/AddAttendeesButtonPopup";
import EntryParentLinkComponent from "components/MyPlanPage/components/EntryParentLinkComponent";
import VideoCallButton from "components/MyPlanPage/components/VideoCallButton";
import Who from "components/MyPlanPage/components/Who";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import { GoogleAutocomplete } from "components/PlacesAutocomplete";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { Select } from "components/Select";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import { TextArea } from "components/TextArea";
import dayjs from "dayjs";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActionButtons from "shared/ui/action-buttons";
import { useNetworkStore } from "state/network/network";

export function EntryViewDetail() {
  const { id: entryId } = useParams();
  const navigateBack = useNavigateBack();

  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        closeText="Back"
        onClose={() => {
          navigateBack()
        }}>
        <EntryViewDetailComponents />
      </ModalView>
    );
  } else {
    return <EntryViewDetailComponents />;
  }
}

export function EntryViewDetailComponents() {
  const { id: entryId } = useParams();
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize();

  // state
  const [assessmentId, setAssessmentId] = useState<string | null>(null);

  // Queries
  const { hasCareCentralAccess } = useOrgs();
  const { isLoading, error, data: entryData, isFetching } = usePlanEntryData(entryId)
  const { data: attendees, isLoading: isLoadingAttendees } = usePlanEntryAtendeeByPlanEntry(entryId);
  const { data: optionalServiceEngagement } = useServiceEngagementFromPlanEntryId(entryId)
  const { data: documents, isLoading: isDocumentsLoading } = useConversationDocumentsByConversationId(
    entryData?.conversation_id ?? undefined
  )
  const setActiveNetworkId = useNetworkStore((state) => state.setActiveNetworkId);
  const { bookmarks, refetchBookmarks } = useBookmarkQuery();
  const { networkName } = useNetworkName(entryData?.network_id ?? undefined)
  const { data: activeOrg } = useActiveOrg()

  // Mutations
  const addConversationToPlanEntry = useAddConversationToPlanEntry().mutateAsync;


  // filter based on loaded entry
  const filteredBookmarks = bookmarks?.filter((bookmark) =>
    entryData?.user_bookmark_ids.includes(bookmark.id)
  );

  const scheduledDateTime = entryData?.scheduled_date_time
    ? dayjs(entryData.scheduled_date_time)
    : null;

  const parsedLocation = () => {
    try {
      return PlaceTypeSchema.parse(entryData?.location);
    } catch {
      return null;
    }
  };

  useEffect(() => {
    if (entryData && !entryData.conversation_id) {
      addConversationToPlanEntry({
        taskId: entryData.id,
        isExternal: false,
        external_participant_id: entryData.user_id,
        networkId: entryData.network_id!!,
      });
    }
  }, [entryData, entryData?.conversation_id])

  useEffect(() => {
    if (entryData?.network_id) {
      setActiveNetworkId(entryData.network_id);
    }
  }, [entryData?.network_id])

  if (isLoading || isLoadingAttendees) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full gap-4">
      {/* action header */}

      <div className="flex">
        {!isMobile && <BackButton />}
        <div className="flex flex-col flex-grow gap-2">
          {/* buttons */}
          <div className="flex flex-grow justify-end gap-4">
            <ActionButtons>
              {/* edit entry button */}
              <ButtonWithIcon
                onClick={() => {
                  if (entryId) {
                    navigate({
                      path: CarePilotRoute.PLAN_EDIT,
                      params: {
                        id: entryId
                      }
                    })
                  }
                }}
                disabled={!entryId}
                size="small"
                text={"Edit entry"}
                icon={IconOption.EDIT}
              />
              {/* trash button */}
              <PlanEntryDeleteButtonPopover entryId={entryId || ""} />
            </ActionButtons>
          </div>
        </div>
      </div>

      {/* plan entry view */}
      {error ? (
        <div>Plan entry not found.</div>
      ) : isLoading || isFetching ? (
        <div>Loading...</div>
      ) : (
        entryData && (
          <div className="flex flex-col gap-4 max-w-[65ch] pb-28">
            {/* header */}
            <div className="flex gap-1">
              <HeaderNamePill text={activeOrg?.name} color="#5D63BA" />
              <HeaderNamePill text={networkName} color="#176590" />
            </div>
            {entryData.is_private && <div className="text-zinc-500 italic">This task is private and only visible to you</div>}
            {isMobile ? (
              <div className="flex justify-center">
                <PageMainHeader text={entryData.name} />
              </div>
            ) : (
              <div className="flex gap-5 items-center">
                <PageMainHeader text={entryData.name} />
              </div>
            )}
            {/* description group */}
            <label htmlFor="new_plan_entry_description">Description</label>
            <TextArea
              data-hj-allow
              id="new_plan_entry_description"
              value={entryData?.description || ""}
              disabled
            />
            {entryData.assessment_id && <ButtonWithIcon
              onClick={() => {
                setAssessmentId(entryData.assessment_id);
              }}
              text="Take Assessment"
              icon={IconOption.ARROW}
            />}
            {assessmentId && <AssessmentComponent
              isOpen={true}
              onClose={() => setAssessmentId(null)}
              assessmentId={assessmentId}
            />}

            {/* Link to parent Task/Request/Service */}
            <EntryParentLinkComponent
              isClickable
              parent_plan_entry_id={entryData.parent_plan_entry_id}
              service_engagement_id={optionalServiceEngagement?.id ?? null}
            />
            {/* date, time, status row */}
            <div className="grid grid-cols-2 gap-4">
              {/* date */}
              <div className="flex flex-col gap-2">
                <label>Date</label>
                <DatePicker
                  className="w-full"
                  value={scheduledDateTime}
                  disabled
                  format="YYYY-MM-DD"
                />
              </div>
              {/* time */}
              <div className="flex flex-col gap-2">
                <label>Time</label>
                <TimePicker
                  ampm={true}
                  className="w-full"
                  value={scheduledDateTime}
                  disabled
                />
              </div>
              {/* recurring interval */}
              <div className="flex flex-col gap-2">
                <label>Recurring Interval</label>
                <Select
                  disabled
                  onChange={() => { }}
                  options={RECURRING_INTERVAL_OPTIONS}
                  currentOption={entryData.recurring_interval ? RECURRING_INTERVAL_OPTIONS.find(option => option.value === entryData.recurring_interval) : { value: "None", label: "N/A" }}
                  classNames='w-[250px]'
                />
              </div>
              {/* status */}
              <div className="flex flex-col gap-2">
                <label>Status</label>
                <Select
                  disabled
                  classNames="text-sm w-full h-full text-white"
                  options={Object.entries(TaskStatusLabel).map(
                    ([key, label]) => {
                      return {
                        value: key,
                        label,
                        background: undefined,
                      };
                    }
                  )}
                  currentOption={{
                    value: entryData.status,
                    label:
                      TaskStatusLabel[entryData.status as TaskStatus],
                    background:
                      TaskStatusColor[entryData.status as TaskStatus],
                  }}
                  onChange={() => { }}
                />
              </div>
              {/* location */}
              <div className="flex flex-col gap-2">
                <label>Location</label>
                <GoogleAutocomplete
                  initialValue={parsedLocation()}
                  disabled
                  onValueChange={() => { }}
                />
              </div>
              {/* user tags */}
              <div className="flex flex-col gap-2">
                <label htmlFor="new_plan_entry_who">Who</label>
                {entryId &&
                  <div className="py-4">
                    <Who entry={entryData} selectedPlanEntryOwnerId={entryData.user_id} />
                  </div>}
              </div>
            </div>
            {/* assessment assignment */}


            {/* Add Attendee(s) */}
            <AddAttendeesButtonPopup
              initialAttendeeIds={attendees ?? []}
            />
            {/* Google Meeting Button */}
            <VideoCallButton
              hideAddButton
              googleMeetingCode={entryData.google_meeting_code}
              setGoogleMeetingCode={() => { }} />
            {/* bookmarks */}
            {!hasCareCentralAccess && <div className="flex flex-col gap-4">
              <label>Related bookmarks</label>
              {/* if there are bookmarks, show */}
              {filteredBookmarks && filteredBookmarks.length > 0 && (
                <Carousel
                  items={filteredBookmarks?.map((bookmark) => {
                    return (
                      <Bookmark
                        key={bookmark.id}
                        bookmark={bookmark}
                        refetchBookmarks={refetchBookmarks}
                        hideActionbar={true}
                        isInPlanEntry={true}
                      />
                    );
                  })}
                />
              )}
              {/* else show empty placeholder */}
              {filteredBookmarks && filteredBookmarks.length === 0 && (
                <div className="w-full h-[100px] border border-dashed border-zinc-200 text-zinc-500 italic p-3">
                  No bookmarks attached
                </div>
              )}
            </div>}

            {/* notes */}
            <div className="flex flex-col w-full">
              <label htmlFor="new_plan_entry_notes">Notes</label>
              <TextArea
                data-hj-allow
                className="min-h-[200px]"
                value={entryData?.notes || ""}
                disabled
              />
            </div>

            {/* Documents section */}
            {entryData && <DocumentsUploadSection
              documents={documents ?? []}
              networkId={entryData?.network_id!!}
              conversationId={entryData?.conversation_id}
              isExternal={false} />
            }

          </div>
        )
      )}
    </div>
  );
}

function PlanEntryDeleteButtonPopover({ entryId }: { entryId: string }) {
  const deletePlanEntry = useDeletePlanEntry().mutateAsync;
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack()
  const handleClick = async () => {
    if (isMobile) {
      await deletePlanEntry({ planEntryId: entryId });
      handleClose();
      navigateBack()
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    deletePlanEntry({ planEntryId: entryId });
    handleClose();
    navigateBack()
  };
  return (
    <div>
      <button
        // aria-describedby={id}
        className={`flex items-center gap-2 text-sm rounded-sm ${isOpen && "bg-zinc-50"
          }`}
        onClick={handleClick}>
        <TrashIcon className="w-6 h-6" />
        Delete entry
      </button>
      <ResponsivePopup
        isOpen={isOpen}
        title="Are you sure you want to delete this entry?"
        onClose={handleClose}
        footerButtons={
          <div className="flex gap-3">
            <ButtonWithIcon
              onClick={handleClose}
              className="p-2"
              text="Cancel"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={handleDelete}
              className="p-2"
              text="Yes, delete entry"
              icon={IconOption.CHECKMARK}
            />
          </div>
        }>
        <span>Are you sure you want to delete this entry?</span>
      </ResponsivePopup>
    </div>
  );
}
