import { Rating } from "@mui/material";
import { SplideSlide } from "@splidejs/react-splide";
import { useRef } from "react";
import type { AmazonMetadata } from "backend/resources/userGuidanceResource";
import Carousel from "components/Carousel/Carousel";

export default function AmazonCarousel({
  metadata,
}: {
  metadata: AmazonMetadata;
}) {
  const scrollContainer = useRef(null);

  function removeDuplicates(metadata: AmazonMetadata | undefined) {
    const uniqueIds = new Set();
    const uniqueProducts = [];
    if (!metadata?.amazonMetadata) return [];
    for (const product of metadata.amazonMetadata) {
      if (!uniqueIds.has(product.id)) {
        uniqueIds.add(product.id);
        uniqueProducts.push(product);
      }
    }

    return uniqueProducts;
  }

  const products = removeDuplicates(metadata);

  return (
    // </div>
    (<Carousel
      items={products?.map((product: any) => (
        <SplideSlide>
          <a
            href={product.link}
            target="_blank"
            className="w-full md:w-[160px] p-3 flex flex-col items-center">
            {product.thumbnail && (
              <img
                className="w-[160px]  h-[160px] flex-shrink-0 object-top object-cover "
                src={product.thumbnail}
                alt={product.title}
              />
            )}
            <div className="w-[160px] ">
              <p className="mt-2 line-clamp-4 ">{product.title}</p>
              <div className="flex items-center">
                {product.rating ? (
                  <Rating
                    size="small"
                    value={product.rating?.split("out of")[0]}
                    readOnly
                  />
                ) : null}
                {product.review_count ? (
                  <label className="items-center ml-1  text-sm">
                    {product.review_count}
                  </label>
                ) : null}
              </div>
            </div>

            {product.price ? (
              <p className="mt-2  text-[#B12704] text-lg font-semibold">
                ${product.price}
              </p>
            ) : null}
          </a>
        </SplideSlide>
      ))}></Carousel>)
  );
}
