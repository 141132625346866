import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "App";
import { QUERY_KEYS } from "backend/queryKeys";
import { ConversationType, useMutateChatGptConversations } from "backend/resources/chatGptConversation";
import { useNetworksInOrganization } from "backend/resources/network/network";
import type {
  PlanEntryWithBookmarks
} from "backend/resources/planEntry";
import { BOOKMARKS_TABLE, fetchAllPlanEntriesForUser, filterPlanEntries, PlanEntry, PlanEntryUpdate, TABLE, TaskWithGuideInfo } from "backend/resources/planEntry";
import { deletePlanEntry, fetchPlanEntryById, savePlanEntry, updatePlanEntryBookmarks } from "backend/resources/planEntry/planEntryActions";
import { saveUsersInPlanEntryMutation } from "backend/resources/planEntry/planEntryAttendee";
import { supabase } from "clients/supabaseClient";
import { getInitials } from "components/AdloComponent/AdloComponent";
import { TaskTableRowType } from "components/Tables/TaskTable/TaskTableTypes";
import { FilterConfig, TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { useMyPlanStore } from "state/myPlan";
import { useActiveNetworkId } from "state/network/network";
import { useActiveOrganizationId } from "state/organization/organization";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";
import { useUserStore } from "state/user";
import { fetchAllDataInChunks, fetchDataPage } from "utils";

export function usePlanEntries({ parentPlanEntryId, includeAll }: { parentPlanEntryId?: string, includeAll?: boolean } = {}) {
  const authUser = useUserStore((state) => state.user);

  const myPlanFiltersState = useMyPlanStore(
    (state) => state.myPlanFiltersState
  );

  const myPlanSortsState = useMyPlanStore((state) => state.myPlanSortsState);

  const {
    isLoading,
    data: planEntries,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEYS.planEntries, { userId: authUser?.id, parentPlanEntryId, includeAll, ...myPlanSortsState }],
    queryFn: () => fetchAllPlanEntriesForUser({ userId: authUser?.id, parentPlanEntryId, includeAll }),
    enabled: !!authUser,
  });

  function filterAndSortPlanEntries(planEntries: PlanEntryWithBookmarks[]) {
    // 2 - filter by status
    let planEntriesFilteredByStatus: PlanEntryWithBookmarks[];
    // Get the keys with a value of true
    const trueStatusKeys = Object.entries(myPlanFiltersState.status_filters)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    if (trueStatusKeys.length === 0) {
      // If no keys, filter out 'done' entries
      planEntriesFilteredByStatus = planEntries.filter(
        (entry) => entry.status !== "done"
      );
    } else {
      // If 'done' is present, show all entries
      planEntriesFilteredByStatus = planEntries;
    }

    // 2 - sort by sort key
    let planEntriesSortedAndFiltered: PlanEntryWithBookmarks[];
    planEntriesSortedAndFiltered = planEntriesFilteredByStatus.sort((a, b) => {
      let comparison = 0;

      switch (myPlanSortsState.activeSortKey) {
        case "who": {
          const aName = getInitials(a.user);
          const bName = getInitials(b.user);
          comparison = aName.localeCompare(bName);
          break;
        }
        case "name": {
          comparison = a.name?.localeCompare(b.name ?? "");
          break;
        }
        case "scheduled_date_time": {
          if (a.scheduled_date_time && b.scheduled_date_time) {
            comparison = a.scheduled_date_time?.localeCompare(
              b.scheduled_date_time
            );
          } else if (a.scheduled_date_time) {
            comparison = -1; // a is non-null, b is null; a comes first
          } else if (b.scheduled_date_time) {
            comparison = 1; // b is non-null, a is null; b comes first
          }
          break;
        }
        default: {
          break;
        }
      }

      return myPlanSortsState.activeSortOrder === "ascending"
        ? comparison
        : -comparison;
    });

    return planEntriesSortedAndFiltered;
  }

  const sortedAndFilteredPlanEntries = filterAndSortPlanEntries(
    planEntries || []
  );

  return {
    isLoading,
    refetch,
    planEntries,
    sortedAndFilteredPlanEntries,
  };
}


export function usePlanEntryWithGuideTask(planEntryId: string | null | undefined) {
  const networkId = useActiveNetworkId();
  return useQuery({
    queryKey: [QUERY_KEYS.guidePlanEntryWithGUIDETask, { planEntryId }],
    queryFn: async () => {
      if (!networkId || !planEntryId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, guide_task(*, guide_category(*,guide_pre_requisite(*)), guide_pre_requisite(*), guide_sub_category(id)), user!plan_entry_user_id_fkey(first_name, last_name), network(*), plan_entry_attendee(user_id)")
        .eq("is_service_ticket", false)
        .eq("id", planEntryId)
        .limit(1)
        .maybeSingle();

      return data;
    },
  });
}


export function useBaseAllPlanEntries(filterConfig: FilterConfig) {
  const activeOrgId = useActiveOrganizationId();
  const guideFilterState = useTaskFilterStore();
  const { networks } = useNetworksInOrganization();
  const networkIds = networks?.map(n => n.id)
  const authUser = useUserStore((state) => state.user);
  const guideFilterStateWithoutHideDone: FilterConfig = { ...guideFilterState,...filterConfig, [TaskFilterTypes.HIDE_DONE]: undefined };
  return useQuery({
    queryKey: [QUERY_KEYS.baseAllPlanEntries, { activeOrgId, ...guideFilterStateWithoutHideDone, userId: authUser?.id, networkIds }],
    queryFn: async () => {
      if (!activeOrgId || !authUser) return [];
      const joinTypeForGUIDE = guideFilterState.guideTask || guideFilterState.category ? "inner" : "left"

      let queryBuilder = supabase
        .from(TABLE)
        .select(`id , status,scheduled_date_time,network_id, guide_task_id, is_service_ticket, google_meeting_code, user_id, guide_task!${joinTypeForGUIDE}(guide_category!inner(title))`)
        .order("id", { ascending: true });

      queryBuilder = filterPlanEntries(queryBuilder, guideFilterStateWithoutHideDone, guideFilterState, authUser.id, networkIds)
      const data = await fetchAllDataInChunks<TaskWithGuideInfo>(queryBuilder);
      return data as TaskWithGuideInfo[];
    }
  })
}

export function useAllPlanEntries(filterConfig: FilterConfig) {
  const guideFilterState = useTaskFilterStore();
  const { networks } = useNetworksInOrganization();
  const networkIds = networks?.map((network) => network.id) ?? [];
  const authUser = useUserStore((state) => state.user);
  const query = useInfiniteQuery(
    {
      queryKey: [QUERY_KEYS.guidePlanEntry, { networkIds,...filterConfig, ...guideFilterState, userId: authUser?.id }],
      queryFn: async (ctx) => {
        if (!authUser || !networkIds) return [];
        // For guide task filters (guideTask, category), use an inner join with the guide_category table to get the title.
        // For other filters, use a left join to include tasks without a guide_task_id.
        const joinTypeForGUIDE = ((guideFilterState.guideTask && guideFilterState.guideTask !== "All") || (guideFilterState.category && guideFilterState.category !== "All")) && guideFilterState.taskType !== TaskTableRowType.GENERAL ? "inner" : "left"
        let queryBuilder = supabase
          .from(TABLE)
          .select(`*, chat_gpt_conversation!public_plan_entry_conversation_id_fkey(*, conversation_document(id)), guide_task!${joinTypeForGUIDE}(*, guide_category!inner(*), guide_sub_category(id)), network!inner(name), user!plan_entry_user_id_fkey(first_name, last_name), subRows:plan_entry!parent_plan_entry_id(*), plan_entry_attendee(user_id)`)
          .order("scheduled_date_time", { ascending: true })
          .order("id", { ascending: true })

        queryBuilder = filterPlanEntries(queryBuilder, filterConfig, guideFilterState, authUser.id, networkIds)

        const data = await fetchDataPage<TaskWithGuideInfo>(queryBuilder, ctx.pageParam, 50);
        return data as TaskWithGuideInfo[];
      },
      initialPageParam: 0,
      getNextPageParam: (_lastGroup, groups) => {
        const lastGroupLength = _lastGroup?.length ?? 0;
        const groupsLength = groups?.length ?? 0;
        return lastGroupLength < 50 ? undefined : groupsLength;
      },
    }

  );
  const data = query.data?.pages.flatMap(d => d) ?? []

  return { ...query, data }
}

export function useAllGuidePlanEntriesForCategoryAndNetwork(categoryName: string | null | undefined, networkId: string | null | undefined) {
  return useQuery({
    queryKey: [QUERY_KEYS.guidePlanEntryForCategory, { networkId, categoryName }],
    queryFn: async (ctx) => {
      if (!networkId) return []
      const { data } = await supabase
        .from(TABLE)
        .select("*, guide_task(*, guide_category(*), guide_sub_category(id)), user!plan_entry_user_id_fkey(first_name, last_name), network(name, organization_id), subRows:plan_entry!parent_plan_entry_id(*)")
        .eq("network_id", networkId)
        .eq("is_service_ticket", false)
        .order("scheduled_date_time", { ascending: true })
      const filteredData = data?.filter((entry) => entry.guide_task?.guide_category?.title === categoryName);
      return filteredData as TaskWithGuideInfo[];
    },
  });
}

export function useAllGuidePlanEntriesForCall(networkId: string | null | undefined) {
  return useQuery(
    {
      queryKey: [QUERY_KEYS.guidePlanEntryForCall, { networkId }],
      queryFn: async (ctx) => {
        if (!networkId) return []
        const { data } = await supabase
          .from(TABLE)
          .select("*, guide_task(*, guide_category(*), guide_sub_category(id)), user!plan_entry_user_id_fkey(first_name, last_name), network(name, organization_id), subRows:plan_entry!parent_plan_entry_id(*)")
          .eq("network_id", networkId)
          .eq("is_service_ticket", false)
          .not("guide_task_id", "is", null)

        return data as TaskWithGuideInfo[];
      },
    }
  );
}

export function useServiceRequests() {
  const activeOrgId = useActiveOrganizationId();
  const { networks } = useNetworksInOrganization();
  const networkIds = networks?.map((network) => network.id) ?? []
  return useQuery({
    queryKey: [QUERY_KEYS.serviceRequests, { activeOrgId, networkIds }],
    queryFn: async () => {
      const queryBuilder = supabase
        .from(TABLE)
        .select(`
          *,
          service_request_to_engagement(service_engagement(*)),
          guide_task(guide_category(*), guide_sub_category(id)), 
          user!plan_entry_user_id_fkey(first_name, last_name),
          network(name, organization_id), 
          service_resource_plan_entry(*,service_resource(*))
          `)
        .eq("is_service_ticket", true)
        .in("network_id", networkIds);;

      const data = await fetchAllDataInChunks<TaskWithGuideInfo>(queryBuilder);
      const filteredData = data.filter(entry => entry.network?.organization_id === activeOrgId);
      return filteredData;
    },
  }
  );
}

export function usePlanEntryData(entryId: string | null | undefined) {
  const activeOrgId = useActiveOrganizationId();
  const authUser = useUserStore((state) => state.user);
  return useQuery({
    queryKey: [QUERY_KEYS.planEntries, { entryId, userId: authUser?.id, activeOrgId }],
    queryFn: () => {
      return fetchPlanEntryById(entryId, authUser?.id);
    },
    enabled: !!authUser && !!entryId,
  });
}

export function useUpdatePlanEntry() {
  const authUser = useUserStore((state) => state.user);
  const guideFilterState = useTaskFilterStore();
  const { networks } = useNetworksInOrganization();
  const networkIds = networks?.map((network) => network.id) ?? []
  const activeOrgId = useActiveOrganizationId();

  return useMutation({
    mutationFn: async (entry: PlanEntryUpdate) => {
      const result = await savePlanEntry({
        upsertRecord: { ...entry, network_id: entry.network_id },
      });
      if (entry.plan_entry_attendee && result?.id) {
        await saveUsersInPlanEntryMutation(entry.plan_entry_attendee?.map(attendee => attendee.user_id) ?? [], result?.id)
      }
      return { ...result, plan_entry_attendee: entry.plan_entry_attendee };
    },
    onMutate: async (newPlanEntry) => {
      const queryKeys = [QUERY_KEYS.guidePlanEntry];
      // Cancel any outgoing refetches for the specified query keys
      await Promise.all(queryKeys.map(key => queryClient.cancelQueries({ queryKey: [key] })));

      // Optimistically update to the new value for each query key
      queryClient.setQueryData(
        [QUERY_KEYS.guidePlanEntry, { networkIds, ...guideFilterState, userId: authUser?.id }],
        (oldData: { pages: TaskWithGuideInfo[][] }) => {
          const dataAsList = oldData?.pages.flat() ?? [];
          let updated = false;
          // Update main entries or sub-entries
          for (const entry of dataAsList) {
            if (entry.id === newPlanEntry.id) {
              Object.assign(entry, newPlanEntry);
              updated = true;
              break;
            }
            const subEntry = entry.subRows?.find(sub => sub.id === newPlanEntry.id);
            if (subEntry) {
              Object.assign(subEntry, newPlanEntry);
              updated = true;
              break;
            }
          }
          // If no update has been made, it could be a new entry
          if (!updated) {
            dataAsList.push(newPlanEntry as TaskWithGuideInfo);
          }
          return { pages: [dataAsList], pageParams: [0] };
        }
      );
      const guideFilterStateWithoutHideDone: FilterConfig = { ...guideFilterState, [TaskFilterTypes.HIDE_DONE]: undefined };

      // Optimistically update the guide task 
      queryClient.setQueryData(
        [QUERY_KEYS.baseAllPlanEntries, { activeOrgId, ...guideFilterStateWithoutHideDone, userId: authUser?.id, networkIds }],
        (oldData: TaskWithGuideInfo[] | undefined) => {
          oldData = oldData ?? []
          let updated = false;
          // Update main entries or sub-entries
          for (const entry of oldData) {
            if (entry.id === newPlanEntry.id) {
              Object.assign(entry, newPlanEntry);
              updated = true;
              break;
            }
            const subEntry = entry.subRows?.find(sub => sub.id === newPlanEntry.id);
            if (subEntry) {
              Object.assign(subEntry, newPlanEntry);
              updated = true;
              break;
            }
          }
          // If no update has been made, it could be a new entry
          if (!updated) {
            oldData.push(newPlanEntry as TaskWithGuideInfo);
          }
          return oldData;
        }
      );
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    // Always refetch after error or success:
    onSettled: async () => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.chatGptConversation,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.planEntries,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.guidePlanEntry,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.serviceRequests,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.guidePlanEntryForCategory,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.guidePlanEntryForCall,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.baseAllPlanEntries,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.guidePlanEntryWithGUIDETask,
        ]
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.guidePlanEntryIncomingCall,
        ]
      });
    },
  });
}

export function useAddConversationToPlanEntry() {
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;
  const chatGptConversationMutation = useMutateChatGptConversations();
  return useMutation({
    mutationFn: async ({ taskId, isExternal, external_participant_id, networkId }: { taskId: string | undefined, isExternal: boolean, external_participant_id: string | undefined, networkId: string | undefined }) => {
      if (!taskId) return
      const newConversation = await chatGptConversationMutation.mutateAsync({ type: ConversationType.Request, external_participant_id: isExternal ? external_participant_id : undefined, networkId });
      const conversationIdField = isExternal ? "external_conversation_id" : "conversation_id"
      return await updatePlanEntry({ id: taskId, [conversationIdField]: newConversation?.id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.planEntries,
        ]
      });
    }
  });
}

export function useUpdatePlanEntryBookmarks() {
  const authUser = useUserStore((state) => state.user);

  return useMutation({
    mutationFn: async ({
      planEntryIds,
      bookmarkId,
    }: {
      planEntryIds: string[];
      bookmarkId: string;
    }) => {
      await supabase
      .from(BOOKMARKS_TABLE)
      .delete()
      .eq("user_bookmark_id", bookmarkId)
      .select();
      return updatePlanEntryBookmarks({ planEntryIds, bookmarkId });
    },
    onSettled: () => {
      if (authUser?.id) {
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.planEntries,
          ]
        });
      }
    },
  });
}

export function useDeletePlanEntry() {
  const authUser = useUserStore((state) => state.user);

  return useMutation({
    mutationFn: ({
      planEntryId }: {
        planEntryId: string;
      }) => {
      return deletePlanEntry(planEntryId);
    },
    // When mutate is called:
    onMutate: async ({ planEntryId, }: { planEntryId: string; }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      if (authUser?.id) {
        await queryClient.cancelQueries({
          queryKey: [QUERY_KEYS.planEntries, { userId: authUser.id }],
        });

        // Snapshot the previous value
        const previousPlanEntries = queryClient.getQueryData([
          QUERY_KEYS.planEntries,
          { userId: authUser.id },
        ]);

        // Optimistically update to the new value
        queryClient.setQueryData(
          // query key
          [QUERY_KEYS.planEntries, { userId: authUser.id }],
          // updater fn
          (oldPlanEntries) => {
            if (oldPlanEntries) {
              return [...(oldPlanEntries as PlanEntry[])];
            } else {
              return [];
            }
          }
        );

        // Return a context object with the snapshotted value
        return { previousPlanEntries };
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (
      err,
      { planEntryId, }: { planEntryId: string; },
      context
    ) => {
      if (authUser?.id) {
        queryClient.setQueryData(
          // query key
          [QUERY_KEYS.planEntries, { userId: authUser.id }],
          // what we're setting the key to
          context?.previousPlanEntries
        );
      }
    },
    // Always refetch after error or success:
    onSettled: (
      data,
      error,
      { planEntryId, }: { planEntryId: string; }
    ) => {
      if (authUser?.id) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.planEntries, { userId: authUser.id }],
        });
      }
    },
  });
}