import { Cell, CellContext, ExpandedState, Row, createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ButtonWithIcon, IconOption } from 'components/ButtonWithIcon';
import { DefaultCheckbox } from 'components/Checkbox';
import { CloseButton } from 'components/CloseButton/CloseButton';
import { DiscussionFields } from 'components/DiscussionComponent/components/DiscussionsFilter';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useWindowSize } from 'hooks/useWindowSize';
import { Arrow } from "icons/Arrow";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import "./tables.css";

const CHECKBOX = "Checkbox"
const DELETE = "Delete"

interface CheckboxOptions<T> {
  disabled?: (row: Row<T>) => boolean;
  onCheck: (row: Row<T>, isChecked: boolean) => void;
  isChecked: (row: Row<T>) => boolean;
  hideStrikeThrough?: boolean;
  isRadio?: boolean;
}


export interface DeleteOptions<T> {
  onDelete: (row: Row<T>) => void;
}

export interface PaginationOptions<T> {
  fetchNextPage: () => Promise<void>;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}

interface InfiniteScrollingTableProps<T> {
  data: T[] | undefined;
  hiddenColumns?: string[];
  mobileColumns?: string[];
  hiddenColumnHeaders?: string[];
  columnFields: string[];
  handleClick: (cell: Cell<T, unknown>, row: Row<T>) => void;
  CellContent: (props: { cell: Cell<T, unknown>, row: Row<T> }) => JSX.Element;
  shouldPreventClicks?: boolean;
  checkboxOptions?: CheckboxOptions<T>;
  deleteOptions?: DeleteOptions<T>;
  paginationOptions?: PaginationOptions<T>;
  overriddenHeaderNames?: { [key: string]: string };
  emptyText?: string;
  maxHeight?: string;
  isLoading?: boolean;
  headersToCenter?: string[];
  cellsToCenter?: string[];
}
export type TableDataRowType<T> = { subRows?: TableDataRowType<T>[] };

export function InfiniteScrollingTable<T extends TableDataRowType<T>>({
  data,
  hiddenColumns,
  mobileColumns,
  hiddenColumnHeaders,
  columnFields,
  handleClick,
  CellContent,
  shouldPreventClicks,
  checkboxOptions,
  deleteOptions,
  paginationOptions,
  overriddenHeaderNames,
  emptyText,
  maxHeight,
  isLoading,
  headersToCenter,
  cellsToCenter,
}: InfiniteScrollingTableProps<T>) {
  // Columns
  const { isMobile } = useWindowSize();
  const columnHelper = createColumnHelper<T>();
  const columnTypes = checkboxOptions ? [CHECKBOX, ...columnFields] : deleteOptions ? [DELETE, ...columnFields] : columnFields;
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false)
  const [count, setCount] = useState(0)
  const [isScrollNearBottom, setIsScrollNearBottom] = useState(false)
  const [uniqueClass] = useState(() => `table-${Math.random().toString(36).substr(2, 9)}`);

  const tableData = useMemo(
    () => (isLoading ? Array(30).fill((_: any, index: number) => ({ id: index.toString() })) : data),
    [isLoading, data]
  );

  const columns = columnTypes.map(key =>
    columnHelper.accessor((row: T) => row[key as keyof T], {
      cell: info => {
        return (
          <RenderComponentCellContent
            key={key}
            info={info}
            deleteOptions={deleteOptions}
            checkboxOptions={checkboxOptions}
            setExpanded={setExpanded}
            isCellCentered={cellsToCenter?.includes(key)}
          />
        )
      },
      footer: info => info.column.id,
      id: key,
      header: info => {
        const hiddenHeaders = [CHECKBOX, DELETE, "subRows", ...(hiddenColumnHeaders ?? [])];
        return <p className={headersToCenter?.includes(key) ? 'text-center' : 'text-start'}>{hiddenHeaders.includes(key) ? "" : overriddenHeaderNames?.[key] ?? info.column.id}</p>;
      },
    })
  );

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
          ...column,
          Cell: <Skeleton />,
        }))
        : columns,
    [isLoading, columns]
  );
  const getSubRows = (row: T) => {
    return row.subRows ?? []
  }

  const table = useReactTable({
    data: tableData ?? [],
    columns: tableColumns,
    state: {
      expanded,
    },
    getRowId: (row, index) => (row as any).id ?? index,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => getSubRows(row),
    initialState: {
      columnVisibility: {
        ...Object.fromEntries(hiddenColumns?.map((col: string) => [col, false]) || []),
      }
    }
  });


  // For smooth scrolling:
  const scrollableRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => scrollableRef.current,
    estimateSize: (index) => {
      const row = rows[index];
      return row?.depth === 0 ? 60 : 40;
    },
  })
  const virtualSize = rowVirtualizer.getTotalSize();
  const virtualItems = rowVirtualizer.getVirtualItems();


  // Effects
  useEffect(() => {
    const finalHiddenColumns = [
      ...(isMobile && mobileColumns ? columnFields.filter(field => !mobileColumns.includes(field)) : []),
      ...(hiddenColumns ?? []),
      DiscussionFields.UNDERLYING_OBJECT_ID,
      DiscussionFields.network_id,
      DiscussionFields.isExternal,
      DiscussionFields.Id
    ];
    // Updates visibility of columns
    const newColumnVisibility = {
      ...Object.fromEntries(finalHiddenColumns?.map((col: string) => [col, false]) || []),
      ...(!data || data.length === 0 ? Object.fromEntries([CHECKBOX, DELETE, ...(hiddenColumnHeaders ?? [])].map((col: string) => [col, false])) : {}),

    }
    table.setColumnVisibility(newColumnVisibility);
  }, [hiddenColumns, mobileColumns, table, data]);

  // /////
  // Make sure Header is sticky (https://github.com/TanStack/virtual/issues/640#issuecomment-1871446220)
  /////////

  // callback to adjust the height of the pseudo element
  const handlePseudoResize = useCallback(() => {
    return adjustTableHeight(tableRef, virtualSize, data, uniqueClass);
  }, [tableRef, virtualSize, data, uniqueClass]);

  // Add unique class to table to make sure the height of pseudo element is table-specific
  useEffect(() => {
    if (tableRef.current) {
    tableRef.current.classList.add(uniqueClass);
  }
}, [uniqueClass, tableRef]);

  // callback to handle scrolling, checking if we are near the bottom
  const handleScroll = useCallback(() => {
    if (parentRef.current) {
      const scrollPosition = parentRef.current?.scrollTop;
      const visibleHeight = parentRef.current?.clientHeight;
      setIsScrollNearBottom(
        scrollPosition > virtualSize * 0.99 - visibleHeight,
      );
    }
  }, [parentRef, virtualSize]);

  // add an event listener on the scrollable parent container and resize the
  // pseudo element whenever the table renders with new data
  useEffect(() => {
    const scrollable = parentRef.current;
    if (scrollable) scrollable.addEventListener("scroll", handleScroll);
    handlePseudoResize();

    return () => {
      if (scrollable) scrollable.removeEventListener("scroll", handleScroll);
    };
  }, [data, handleScroll, handlePseudoResize]);

  // if we are near the bottom of the table, resize the pseudo element each time
  // the length of virtual items changes (which is effectively the number of table
  // rows rendered to the DOM). This ensures we don't scroll too far or too short.
  useEffect(() => {
    if (isScrollNearBottom) handlePseudoResize();
  }, [isScrollNearBottom, virtualItems.length, handlePseudoResize]);


  // Pagination
  useEffect(() => {
    async function paginate() {
      let lastItemIndex = -1
      const [lastItem] = [...virtualItems].reverse()
      if (lastItem) {
        lastItemIndex = lastItem.index
      }
      if (
        data && data.length > 0 && paginationOptions &&
        lastItemIndex >= data.length - 10 &&
        paginationOptions.hasNextPage &&
        !paginationOptions.isFetchingNextPage &&
        !isFetchingNextPage
      ) {
        setIsFetchingNextPage(true)
        await paginationOptions.fetchNextPage()
        setExpanded({})
        setIsFetchingNextPage(false)
      }
    }
    paginate()
  }, [
    paginationOptions?.hasNextPage,
    paginationOptions?.fetchNextPage,
    paginationOptions?.isFetchingNextPage,
    paginationOptions,
    count,
    virtualItems,
    data
  ])

  useEffect(() => {
    // add the max number of subrows to the count to account for any subrows that might need to expand
    const getMaxSubRows = (rows: T[]): number => {
      return rows.reduce((maxSubRows, row) => {
        if (row.subRows) {
          const subRowsCount = row.subRows.length + getMaxSubRows(row.subRows as T[]);
          return Math.max(maxSubRows, subRowsCount);
        }
        return maxSubRows;
      }, 0);
    };
    const count = (data?.length ?? 0) + getMaxSubRows(data ?? []);
    setCount(count)
  }, [data])

  return (
    <div ref={parentRef} style={{ width: data && data?.length > 0 ? 'min-content' : '' }} className='relative'>
      <div ref={scrollableRef}
        style={{ maxHeight: maxHeight ? maxHeight : '50vh' }}
        className={`overflow-y-auto text-left flex flex-col gap-4  pb-5`}>
        {(scrollableRef.current?.scrollTop) ?
          <ButtonWithIcon
            className='absolute bottom-0 self-center  z-10'
            onClick={() => {
              if (scrollableRef.current) {
                scrollableRef.current.scrollTop = 0;
              }
            }}
            text={''} icon={IconOption.UP_ARROW} /> : null}
        <table ref={tableRef} className='border-separate virtual-table border-spacing-y-2' >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className={`virtual-table-sticky-header ${uniqueClass}`}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className='pr-4 py-2 h-full text-center w-[20px]'>
                    <div className='h-[2.5rem] relative '>
                      <p className='absolute bottom-0  w-full '>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </p>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='text-sm w-full'>

            {isLoading ? tableData?.map((_, index) =>
              <tr
                key={index}
                style={{
                  height: 60,
                  cursor: shouldPreventClicks ? "" : "pointer",
                }}
                className={'relative'}
              >
                {table.getHeaderGroups().map((headerGroup, index) => headerGroup.headers.map((header, index) =>
                  <td
                    style={{
                      paddingLeft: index === 0 ? '20px' : '0px'
                    }}
                    key={header.id}
                    className={`pr-4`}
                    onClick={(e) => {
                      e.preventDefault();

                    }} ><Skeleton className='w-full h-[40px]' /></td>))}
              </tr>) :
              table.getRowModel().rows.length > 0 ? virtualItems.map((virtualRow, index) => {
                const row = rows[virtualRow.index] as Row<T>
                if (row) {
                  return (
                    <tr
                      key={row.id}
                      style={{
                        height: row.depth === 0 ? virtualRow.size : virtualRow.size / 2,
                        transform: `translateY(${virtualRow.start - (index * (virtualRow.size))
                          }px)`,
                        cursor: shouldPreventClicks ? "" : "pointer",
                      }}
                      className={'relative'}
                    >

                      {
                        row?.getVisibleCells().map((cell, index) =>
                          <td
                            style={{
                              paddingLeft: index === 0 ? '20px' : '0px'
                            }}
                            key={cell.id}
                            className={`pr-4`}
                            onClick={(e) => {
                              e.preventDefault();
                              if (!shouldPreventClicks) {
                                handleClick(cell, row)
                              }
                            }} >
                            {/* Strike through */}
                            {checkboxOptions?.isChecked(row) && !checkboxOptions.hideStrikeThrough &&
                              <div className='w-[90%] h-[1px] bg-gray-500 absolute top-1/2 left-[38px]' />
                            }
                            {row.depth === 0 && <div style={{ height: virtualRow.size, top: 0 }} className='w-full z-[-1] h-full border-2 rounded-xl absolute self-center left-0' />}
                            {/* Content */}
                            {![CHECKBOX, DELETE].includes(cell.column.id) && !isLoading ?
                              <CellContent cell={cell} row={row} /> :
                              <p>{flexRender(cell.column.columnDef.cell, cell.getContext())}</p>
                            }
                          </td>)}

                    </tr>

                  )
                }
              }
              ) :
                <tr>
                  <td colSpan={columns.length}>
                    <p className='w-max mt-2 text-center'>{emptyText ?? "There are no rows that match this status"} </p>
                  </td>
                </tr>}
          </tbody>
        </table>

      </div>
      {/* Show loading spinner */}
      {paginationOptions?.isFetchingNextPage
        && paginationOptions.hasNextPage
        && scrollableRef.current &&
        scrollableRef.current.scrollTop + scrollableRef.current.clientHeight >= scrollableRef.current.scrollHeight - 5 && (
          <div className='w-full flex justify-center absolute -bottom-10'>
            <LoadingSpinner className='w-10 self-center p-2' />
          </div>
        )
      }
    </div>
  )
}

// Functions
interface RenderComponentCellContentProps<T> {
  info: CellContext<T, T[keyof T]>;
  checkboxOptions?: CheckboxOptions<T>;
  deleteOptions?: DeleteOptions<T>;
  setExpanded: (expanded: ExpandedState) => void;
  isCellCentered?: boolean;
}

function RenderComponentCellContent<T>({ info, checkboxOptions, deleteOptions, setExpanded, isCellCentered = false}: RenderComponentCellContentProps<T>) {
  const { isMobile } = useWindowSize();
  const cellAlignment = isCellCentered ? "text-center" : "text-start";

  switch (info.column.id) {
    case CHECKBOX:
      return (
        <div className={`flex ${cellAlignment} gap-2 w-[26px] md:w-[42px]`}>
          {info.row.depth === 1 && <BranchConnector row={info.row} />}
          <div style={{ justifyContent: info.row.getCanExpand() ? 'space-between' : isMobile ? 'start' : 'flex-end' }} className={`flex ${cellAlignment} gap-2 relative w-min-[42px] w-[42px]`}>
            {info.row.getCanExpand() && <ExpandedArrow row={info.row} setExpanded={setExpanded} />}
            <DefaultCheckbox
              checked={checkboxOptions?.isChecked(info.row) ?? false}
              onChange={(isChecked: boolean) => {
                // We close rows when clicking checkbox because it might cause new rows to be added, and having sub rows open can create an issue where rows are overlaying
                if (info.row.getIsExpanded()) {
                  info.row.toggleExpanded()
                }
                checkboxOptions?.onCheck(info.row, isChecked)
              }}
              disabled={checkboxOptions?.disabled?.(info.row) ?? false}
              iconHeight={16}
              iconWidth={16}
            />
          </div>
        </div>
      );
    case DELETE:
      return (
        <button
          type="button"
          className={`hover:scale-[1.15] transition-transform p-1 ${cellAlignment}`}
          onClick={(event) => {
            event.preventDefault();
            deleteOptions?.onDelete(info.row)
          }}>
          <CloseButton />
        </button>
      );
    default:
      return <div className={`${info.row.depth === 1 ? 'pl-[1.5rem]' : ''} ${cellAlignment}`}>{info.getValue() as ReactNode}</div>;
  }
}

function ExpandedArrow<T>({ row, setExpanded }: { row: Row<T>, setExpanded: (expanded: ExpandedState) => void }) {
  return (
    <div className='items-center flex'>
      <button
        onClick={(e) => {
          e.stopPropagation()
          row.toggleExpanded()
          setExpanded({ [row.id]: !row.getIsExpanded() }); // Ensure only one row is expanded at a time
        }}
      >
        <Arrow size={16} className={`${row.getIsExpanded() ? '' : 'rotate-180'} transition-all fill-brand-orange`} />
      </button>
    </div>
  )
}

function BranchConnector<T>({ row }: { row: Row<T> }) {
  return (
    <div >
      <div className='w-[1px] h-[10px] bg-black' />
      <div className='items-center w-[10px] h-[1px] bg-black' />
    </div>
  )
}


const adjustTableHeight = <T,>(tableRef: React.RefObject<HTMLTableElement>, virtualHeight: number, data: T[] | undefined, uniqueClass: string) => {
  if (!tableRef.current) {
    return;
  } else if (data?.length === 0) {
    const styleSheet = document.styleSheets[0];
    const ruleIndex = Array.from(styleSheet.cssRules).findIndex(rule => rule.cssText.startsWith(`.${uniqueClass}::after`));
    if (ruleIndex !== -1) {
      styleSheet.deleteRule(ruleIndex);
    }
    styleSheet.insertRule(`.${uniqueClass}::after { height: 0px; }`, styleSheet.cssRules.length);
    return 0;
  }

  // calculate the height for the pseudo element after the table
  const existingPseudoElement = window.getComputedStyle(tableRef.current, '::after');
  const existingPseudoHeight = parseFloat(existingPseudoElement.height) || 0;
  const tableHeight = tableRef.current.clientHeight - existingPseudoHeight;
  const pseudoHeight = Math.max(virtualHeight - tableHeight, 0);

  const styleSheet = document.styleSheets[0];
  const ruleIndex = Array.from(styleSheet.cssRules).findIndex(rule => rule.cssText.startsWith(`.${uniqueClass}::after`));
  if (ruleIndex !== -1) {
    styleSheet.deleteRule(ruleIndex);
  }
  styleSheet.insertRule(`.${uniqueClass}::after { height: ${pseudoHeight}px; }`, styleSheet.cssRules.length);

  return pseudoHeight;
};