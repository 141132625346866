import "components/FancyLoadingState/FancyLoadingState.css";

const COUNT = 16;
const SIZE = 10;
const STEP = SIZE / COUNT;
const SPEED = 12;

export function FancyLoadingState() {
  const elements = Array.from({ length: COUNT });

  return (
    <div className="flex justify-center items-center h-[10vh] w-full">
      <div className="fancyLoadingStateContainer">
        {elements.map((_, index) => {
          const circleSize = `calc(${SIZE}vh - ${STEP} * ${index}vh)`;

          return (
            <div
              key={`fancy-loading-state-${index}`}
              className="fancyLoadingStateCircle"
              data-index={index}
              style={{
                width: circleSize,
                height: circleSize,
                left: `calc(${STEP}vh * ${index} / 2)`,
                top: `calc(${STEP}vh * ${index} / 2)`,
                animation: `fancyLoadingStateSpin ${
                  SPEED / index
                }s infinite linear`,
              }}></div>
          );
        })}
      </div>
    </div>
  );
}
