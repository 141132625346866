import { useState } from "react";
import { useRemoveBookmarkFromFolderMutation } from "backend/resources/userBookmarkToLibraryFolder";
import { useActionBarStore } from "state/actionBar";
import { useLibraryFoldersStore } from "state/libraryFolders";
import { Button } from "components/Button";
import { Popup } from "components/Popup";

interface Props {
  close: () => void;
  userBookmarkId: string;
}

export function RemoveFromFolderPopup({ userBookmarkId, close }: Props) {
  const activeFolder = useLibraryFoldersStore((state) => state.activeFolder);
  const [error, setError] = useState<string>("");
  const setIsDisabled = useActionBarStore((state) => state.setIsDisabled);

  const removeBookmarkFromFolder =
    useRemoveBookmarkFromFolderMutation().mutateAsync;

  function closeRemoveFromFolderPopup() {
    setIsDisabled(false);
    close();
  }

  if (activeFolder === undefined) return null;

  return (
    <Popup
      containerClassName="w-[300px]"
      closeOnClickOutside={false}
      close={closeRemoveFromFolderPopup}>
      <div className="flex flex-col gap-6">
        <p className="text-center">
          Are you sure you want to remove this from the{" "}
          <span className="font-bold">{activeFolder.name}</span> folder?
        </p>
        <div className="w-full justify-center flex gap-3">
          <button
            className="text-gray-500 border border-gray-500 p-2 rounded-md hover:bg-gray-50"
            onClick={closeRemoveFromFolderPopup}>
            Cancel
          </button>
          <Button
            onClick={() => {
              setIsDisabled(false);
              removeBookmarkFromFolder({ activeFolder, userBookmarkId });
            }}>
            Yes
          </Button>
          {error && (
            <p className="text-red-500 self-center text-sm text-center">
              {error}
            </p>
          )}
        </div>
      </div>
    </Popup>
  );
}
