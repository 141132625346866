import { useAssessments } from "backend/resources/assessment";
import { RadioButtonsGroup } from "components/RadioGroup";
import { AssessmentType } from "components/AssessmentComponent/AssessmentComponent";




interface AssessmentSelectorPageProps {
  selectedAssessmentId: string | undefined;
  setSelectedAssessmentId: (value: string) => void;
  newAssessmentType: AssessmentType | undefined;
}

export function AssessmentSelectorPage({
  selectedAssessmentId,
  setSelectedAssessmentId,
  newAssessmentType,
}: AssessmentSelectorPageProps) {
  const { data: assessments } = useAssessments({ newAssessmentType });
  return (
    <div>
      <RadioButtonsGroup
        id={"assessments"}
        options={assessments?.map((assessment) => ({ value: assessment.id, label: assessment.name })) ?? []}
        value={selectedAssessmentId}
        onChange={(value) => setSelectedAssessmentId(value)}
        row={false}
      />
    </div>
  )
}