import { TaskWithGuideInfo } from "backend/resources/planEntry";

export enum TaskFilterTypes {
  CARESPACE = "Carespace",
  CATEGORY = "Category",
  WHO = "Who",
  DUE_DATE = "Due Date",
  TASK_TYPE = "Type",
  HIDE_DONE = "HIDE_DONE",
  STATUS = "Status",
  GUIDE_TASK = "Task",
}

export interface FilterConfig {

  [TaskFilterTypes.CARESPACE]?: boolean;
  [TaskFilterTypes.CATEGORY]?: boolean;
  [TaskFilterTypes.WHO]?: boolean;
  [TaskFilterTypes.DUE_DATE]?: boolean;
  [TaskFilterTypes.TASK_TYPE]?: boolean;
  [TaskFilterTypes.STATUS]?: boolean;
  [TaskFilterTypes.HIDE_DONE]?: boolean;
  [TaskFilterTypes.GUIDE_TASK]?: boolean;
  overrideFilter?: (row: TaskWithGuideInfo) => boolean;
  customAdditionalFilter?: (row: TaskWithGuideInfo) => boolean;
}
