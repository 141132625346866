export const legalSexOptions = {
  male: { value: "male", label: "Male" },
  female: { value: "female", label: "Female" },
  unknown: { value: "unknown", label: "Unknown" },
  "choose not to disclose": {
    value: "choose not to disclose",
    label: "Choose not to disclose",
  },
};

export type LegalSexOptionsKeys = keyof typeof legalSexOptions;
