interface Props {
  children: React.ReactNode;
}
export default function FooterButtons({ children }: Props) {
  return (
    <div className="flex w-full items-center justify-around md:justify-end py-6 mt-4 gap-4 fixed md:static bottom-0 left-0 bg-white z-[2] md:z-[0]">
      {children}
    </div>
  );
}
