
import { ReactComponent as InformationSvg } from "assets/info-circle.svg";
import { useIncomingCallGuidePlanEntryByNetworkId } from "backend/resources/guide/guideTask";
import { type NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { useOrgs } from "backend/resources/orgRole";
import { useAdloNoteQuery } from "backend/resources/userNote";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CarespaceInfoBanner } from "components/CarespacePage";
import { AdloNotesModal } from "components/CarespacePage/AdloNotesModal";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ServicesHubTabs } from "components/Services Hub/ServicesHubPage";
import {
  CareCentralRoute,
  CarePilotRoute,
  useAppNavigate
} from "lib/routing";
import { FC, useState } from "react";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";
import { useDiscussionFilterStore } from "state/discussionsFilter/discussionsFilter";
import { useServiceFilterStore } from "state/serviceFilters/serviceFilters";
import { formatTimeToHourWithAMPM } from "utils/timeUtil";

interface CarespaceMainTabProps {
  network?: NetworksWithAdlosAndCaregivers | null;
}


export const CarespaceMainTab: FC<CarespaceMainTabProps> = ({network}) => {
  const { isSuperSuperUser } = useOrgs()
  const navigate = useAppNavigate();
  const { setCarespace: setServiceFilterCarespace } = useServiceFilterStore()
  const firstAdlo = network?.user_adlo && network.user_adlo[0]; // TODO: replace with active adlo & dropdown for selecting
  const { adloNote, isLoadingAdloNote } = useAdloNoteQuery(firstAdlo?.id);
  const [isAddPrimaryCaregiverModalOpen, setIsAddPrimaryCaregiverModalOpen] = useState(false);
  const { setCarespace, setType } = useDiscussionFilterStore();
  const { data: incomingCallGuidePlanEntryId } = useIncomingCallGuidePlanEntryByNetworkId(network?.id);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState<boolean>(false);

  if (isLoadingAdloNote) {
    return (
      <div className="flex justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {firstAdlo && 
        <CarespaceInfoBanner 
          network={network}
        />
      }
      {/* buttons */}
      <div className="flex flex-col gap-3 mt-8">
        <ButtonWithIcon
          className=""
          onClick={(e: any) => {
            e.stopPropagation();
            if (firstAdlo?.id) {
              setIsNotesModalOpen(true);
            }
          }}
          text={`Notes ${adloNote?.adlo_note_updated_at
            ? `(Last updated: ${formatTimeToHourWithAMPM(
              new Date(adloNote?.adlo_note_updated_at)
            )}, ${new Date(
              adloNote?.adlo_note_updated_at
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })})`
            : ""
            }`}
          icon={IconOption.ARROW}
        />
        <ButtonWithIcon
          className=""
          onClick={(e: any) => {
            e.stopPropagation();
            setCarespace(network?.id)
            setType(undefined)
            navigate({
              path: CareCentralRoute.DISCUSSIONS,
            });
          }}
          text="Discussions"
          icon={IconOption.ARROW}
        />
        <ButtonWithIcon
          className=""
          onClick={(e: any) => {
            e.stopPropagation();
            navigate({
              path: CarePilotRoute.FAMILY_HUB,
            });
          }}
          text="Family Hub"
          icon={IconOption.ARROW}
        />
        <ButtonWithIcon
          className=""
          onClick={(e: any) => {
            e.stopPropagation();
            // Navigates to the guide navigator, pre-filtered by the carespace
            setServiceFilterCarespace(network?.id)
            navigate({
              path: CareCentralRoute.SERVICE_HUB,
              queryParams: { tab: ServicesHubTabs.PROVIDERS }
            });
          }}
          text="Service Hub"
          icon={IconOption.ARROW}
        />
        {isSuperSuperUser && <ButtonWithIcon
          className=""
          onClick={(e: any) => {
            e.stopPropagation();
            navigate({
              path: CarePilotRoute.PLAN_NEW,
              queryParams: {
                network_id: network?.id ?? null,
                parent_plan_entry_id: incomingCallGuidePlanEntryId ?? null,
              }
            });
          }}
          text="Log Support Call"
          icon={IconOption.ARROW}
        />}
      </div>
      {/* Modals */}
      <PopupOrSheet
        isOpen={isAddPrimaryCaregiverModalOpen}
        title={""}
        icon={<InformationSvg className="w-10 h-10" />}
        footerButtons={<ButtonWithIcon onClick={() => setIsAddPrimaryCaregiverModalOpen(false)} text={"Done"} icon={IconOption.CHECKMARK} />}>
        <p> There is no primary caregiver. Please add one so they can take their self care assessment.</p>
      </PopupOrSheet>
      {/* Notes modal */}
      <AdloNotesModal adloId={firstAdlo?.id} textAreaHeight="300px" isOpen={isNotesModalOpen} onClose={() => setIsNotesModalOpen(false)} />
    </div>
  );
}
