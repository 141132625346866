import { useState } from "react";
import { useParams } from "react-router-dom";
import { useOrgs } from "backend/resources/orgRole";
import { useServiceResource } from "backend/resources/services/serviceResource";
import { useNavigateBack } from "lib/routing";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import Tabs from "components/Tabs/Tabs";
import ServiceProviderProfileTab from "components/ServiceProviderPage.tsx/components/ServiceProviderProfileTab";
import ServiceProviderServiceEngagementsTab from "components/ServiceProviderPage.tsx/components/ServiceProviderServiceEngagementsTab";
import ServiceProviderServicesRequestsTab from "components/ServiceProviderPage.tsx/components/ServiceProviderServiceRequestsTab";

export enum ServiceProviderTabs {
  PROFILE = "Profile",
  SERVICES = "Services",
  SERVICE_REQUESTS = "Service Requests",
}

export default function ServiceProviderPage() {
  const navigateBack = useNavigateBack()

  // State
  const [currentTab, setCurrentTab] = useState<ServiceProviderTabs>(ServiceProviderTabs.PROFILE);

  // Queries
  const { id: service_resource_id } = useParams<{ id: string }>();
  const { data: serviceProviderData, isLoading } = useServiceResource(service_resource_id);
  const { hasCareCentralAccess, isLoading: isHasCareCentralAccessLoading } = useOrgs();
  const tabs = hasCareCentralAccess ? Object.values(ServiceProviderTabs) : [ServiceProviderTabs.PROFILE];

  if (isLoading || isHasCareCentralAccessLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner className="w-20" />
      </div>
    );
  }

  return (
    <PageContainer>
      <div className="flex flex-col gap-5">
        <ButtonWithIcon
          onClick={navigateBack}
          text={'Back'}
          icon={IconOption.BACK_ARROW}
          size="small"
          type="button"
        />
        <PageMainHeader text={serviceProviderData?.name} />
        <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {currentTab === ServiceProviderTabs.SERVICES && <ServiceProviderServiceEngagementsTab />}
        {currentTab === ServiceProviderTabs.SERVICE_REQUESTS && <ServiceProviderServicesRequestsTab />}
        {currentTab === ServiceProviderTabs.PROFILE && <ServiceProviderProfileTab />}
      </div>
    </PageContainer>
  );

}

