import type { AmazonMetadata } from "backend/resources/userGuidanceResource";

type Props = {
  onClick: () => void;
  className?: string;
  name: string;
  metadata: AmazonMetadata;
};

export default function AmazonButton(props: Props) {
  return (
    <div>
      <div className="flex w-full px-10 mt-2">
        <label className="text-sm font-bold text-center w-full overflow-ellipsis whitespace-nowrap overflow-hidden">
          {props.name}
        </label>
      </div>
      <div className="flex justify-center rounded-lg">
        <div className="grid grid-cols-2 m-4 max-h-[200px] max-w-[200px]">
          {props.metadata?.amazonMetadata?.slice(0, 4).map((product) => (
            <img
              className=" object-top object-cover max-h-[50px] w-full h-[50px] border border-gray-300"
              src={product.thumbnail}
              alt={product.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
