import "components/TypingIndicator/typingIndicator.css";

export function TypingIndicator() {
  return (
    <div className="typing w-fit">
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  );
}
