export function formatTimeToHourWithAMPM(date: Date) {
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  const formattedTime = `${hours} ${ampm}`;
  return formattedTime;
}

export function formatDateNoCurrentYear(date: Date): string {
  const currentYear = new Date().getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return year === currentYear ? `${month}/${day}` : `${month}/${day}/${year}`;
}
