import type { ComponentProps } from "react";
import { YouTubeVideo } from "components/YouTubeVideo";

type YouTubeVideoProps = ComponentProps<typeof YouTubeVideo>;

type Props = {
  onClick: () => void;
  className?: string;
} & YouTubeVideoProps;

export function YouTubeButton({ onClick, className, ...props }: Props) {
  function handleOnClick() {
    onClick();
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleOnClick}
      className="w-full h-full"
      aria-hidden={true}>
      <YouTubeVideo
        disabled
        {...props}
      />
    </div>
  );
}
