import AdminIcon from "assets/nav/admin_icon_orange.png";
import AssessmentsIcon from "assets/nav/assessments_icon_orange.png";
import BillingIcon from "assets/nav/billing_icon_orange.png";
import CarePlanIcon from "assets/nav/care_plan_icon_orange.png";
import CarespacesIcon from "assets/nav/carespace_icon_orange.png";
import DashboardIcon from "assets/nav/dashboard_orange.png";
import DirectoryIcon from "assets/nav/directory_icon_orange.png";
import DiscussionIcon from "assets/nav/discussion_icon_orange.png";
import EducationIcon from "assets/nav/education_icon_orange.png";
import FamilyIcon from "assets/nav/family_icon_orange.png";
import FavoritesIcon from "assets/nav/favorites_icon_orange.png";
import HomeIcon from "assets/nav/home_icon_orange.png";
import LocalSearchPng from "assets/nav/local-search-icon.png";
import MedicationsIcon from "assets/nav/medication_icon_orange.png";
import MyCareIcon from "assets/nav/my_care_icon_orange.png";
import ServiceHubIcon from "assets/nav/service_hub_icon_orange.png";
import ServicesIcon from "assets/nav/services_icon_orange.png";
import TasksIcon from "assets/nav/tasks_icon_orange.png";
import TemplatesIcon from "assets/nav/templates_icon_orange.png";
import ToolsIcon from "assets/nav/tools_icon_orange.png";
import { ReactComponent as UpdateIconSvg } from "assets/nav/updates.svg";
import SearchPNG from "assets/search.png";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { CraniometrixProduct } from 'hooks/product/product';
import { CareCentralRoute, CarePilotRoute, SharedRoute, SuperSuperUserRoute } from 'lib/routing';
import { ReactNode } from 'react';

export enum NavItemName {
  Home = "Home",
  MyDashboard = "My Dashboard",
  Favorites = "Favorites",
  Tasks = "Tasks",
  MyCare = "My Care",
  Admin = "Admin",
  FamilyHub = "Family Hub",
  Updates = "Updates",
  Discussions = "Discussions",
  Templates = "Templates",
  Services = "Services",
  Education = "Education",
  PatientSearch = "Patient Search",
  Carespaces = "Carespaces",
  ServiceHub = "Service Hub",
  CarePlan = "Care Plan",
  Medications = "Medications",
  Assessments = "Assessments",
  Tools = "Tools",
  Directory = "Directory",
  Billing = "Billing",
}

export const NAV_ITEMS_CONFIG: Record<NavItemName, { route: string, icon: ReactNode, subNavItems?: SubNavItem[] }> = {
  Home: { route: SharedRoute.HOME, icon: <img src={HomeIcon} alt="Home" /> },
  "My Dashboard": { route: SharedRoute.HOME, icon: <img src={DashboardIcon} alt="My Dashboard" /> },
  Favorites: { route: CarePilotRoute.FAVORITES, icon: <img src={FavoritesIcon} alt="Favorites" /> },
  Tasks: { route: CarePilotRoute.PLAN, icon: <img src={TasksIcon} alt="Tasks" /> },
  "My Care": { route: CarePilotRoute.MY_CARE, icon: <img src={MyCareIcon} alt="My Care" /> },
  Admin: { route: SharedRoute.ADMIN, icon: <img src={AdminIcon} alt="Admin" /> },
  "Family Hub": { route: CarePilotRoute.FAMILY_HUB, icon: <img src={FamilyIcon} alt="Family Hub" /> },
  Updates: { route: CarePilotRoute.FAMILY_HUB, icon: <UpdateIconSvg /> },
  Discussions: { route: CareCentralRoute.DISCUSSIONS, icon: <img src={DiscussionIcon} alt="Discussions" /> },
  Templates: { route: CareCentralRoute.TEMPLATES, icon: <img src={TemplatesIcon} alt="Templates" /> },
  Services: { route: CarePilotRoute.LOCAL_SEARCH, icon: <img src={LocalSearchPng} alt="Services" /> },
  Education: { route: CarePilotRoute.EDUCATION, icon: <img src={EducationIcon} alt="Education" /> },
  "Patient Search": { route: SuperSuperUserRoute.PATIENT_SEARCH, icon: <img src={SearchPNG} alt="Patient Search" /> },
  Carespaces: { route: CareCentralRoute.CARESPACES, icon: <img src={CarespacesIcon} alt="Carespaces" /> },
  "Service Hub": { route: CareCentralRoute.SERVICE_HUB, icon: <img src={ServiceHubIcon} alt="Service Hub" /> },
  "Care Plan": { route: CarePilotRoute.CARE_PLAN, icon: <img src={CarePlanIcon} alt="Care Plan" /> },
  Medications: { route: CarePilotRoute.MEDICATIONS, icon: <img src={MedicationsIcon} alt="Medications" /> },
  Directory: { route: CareCentralRoute.DIRECTORY, icon: <img src={DirectoryIcon} alt="Directory" /> },
  Assessments: { route: CarePilotRoute.ASSESSMENTS, icon: <img src={AssessmentsIcon} alt="Assessments" /> },
  Billing: { route: CareCentralRoute.BILLING_PAGE, icon: <img src={BillingIcon} alt="Billing" /> },
  Tools: {
    route: CarePilotRoute.ASSESSMENTS, icon: <img src={ToolsIcon} alt="Tools" className="w-6 h-6" />, subNavItems: [
      { name: "Favorites", pathname: CarePilotRoute.FAVORITES, icon: <img className="w-4 h-4" src={FavoritesIcon} alt="Favorites" /> },
      { name: "Assessments", pathname: CarePilotRoute.ASSESSMENTS, icon: <img className="w-4 h-4" src={AssessmentsIcon} alt="Assessments" /> },
      { name: "Services", pathname: CarePilotRoute.LOCAL_SEARCH, icon: <img className="w-4 h-4" src={ServicesIcon} alt="Services" /> },
      { name: "Education", pathname: CarePilotRoute.EDUCATION, icon: <img className="w-4 h-4" src={EducationIcon} alt="Education" /> },
    ]
  },
};


const CARE_CENTRAL_NAV_ITEM_NAMES: NavItemName[] = [NavItemName.MyDashboard, NavItemName.Discussions, NavItemName.Carespaces, NavItemName.ServiceHub, NavItemName.Directory, NavItemName.Templates];
const CARE_CENTRAL_NAV_ITEM_NAMES_FOR_USER_WITH_ADMIN_PRIVILEGES: NavItemName[] = [...CARE_CENTRAL_NAV_ITEM_NAMES, NavItemName.Billing, NavItemName.Admin];
const CARE_CENTRAL_NAV_ITEM_NAMES_FOR_SUPER_SUPER_USER: NavItemName[] = [...CARE_CENTRAL_NAV_ITEM_NAMES_FOR_USER_WITH_ADMIN_PRIVILEGES, NavItemName.PatientSearch];
const FAMILY_MEMBER_NAV_ITEM_NAMES: NavItemName[] = [NavItemName.Updates];
const CAREGIVER_ITEM_NAMES: NavItemName[] = [NavItemName.Home, NavItemName.Tasks, NavItemName.CarePlan, NavItemName.Medications, NavItemName.Discussions, NavItemName.FamilyHub, NavItemName.Tools, NavItemName.MyCare];
const PRIMARY_CAREGIVER_ITEM_NAMES: NavItemName[] = [...CAREGIVER_ITEM_NAMES, NavItemName.Admin];



const roleToItemsMap: Record<NetworkRoleType, NavItemName[]> = {
  [NetworkRoleType.FAMILY_MEMBER]: FAMILY_MEMBER_NAV_ITEM_NAMES,
  [NetworkRoleType.CAREGIVER]: CAREGIVER_ITEM_NAMES,
  [NetworkRoleType.DOCTOR]: CARE_CENTRAL_NAV_ITEM_NAMES,
  [NetworkRoleType.CARE_NAVIGATOR]: CARE_CENTRAL_NAV_ITEM_NAMES,
  [NetworkRoleType.ADMIN]: CARE_CENTRAL_NAV_ITEM_NAMES_FOR_USER_WITH_ADMIN_PRIVILEGES,
  [NetworkRoleType.PRIMARY_CAREGIVER]: PRIMARY_CAREGIVER_ITEM_NAMES,
};


export type SubNavItem = {
  name: string;
  pathname: string;
  icon: ReactNode;
};


export class NavItem {
  name: NavItemName;
  icon: ReactNode;
  route: string;
  isExpanded: boolean;
  subNavItems?: SubNavItem[];

  constructor(name: NavItemName) {
    this.name = name;
    this.icon = NAV_ITEMS_CONFIG[name].icon;
    this.route = NAV_ITEMS_CONFIG[name].route;
    this.isExpanded = false;
    this.subNavItems = NAV_ITEMS_CONFIG[name].subNavItems;
  }
}


export class RoleBasedNav {
  items: NavItem[];

  constructor(itemNames: NavItemName[]) {
    this.items = itemNames.map(name => new NavItem(name));
  }
}

export function determineItemsBasedOnRole(role: NetworkRoleType, product: CraniometrixProduct | undefined, isSuperUser: boolean | undefined, isSuperSuperUser: boolean | undefined) {
  if (isSuperSuperUser) {
    return CARE_CENTRAL_NAV_ITEM_NAMES_FOR_SUPER_SUPER_USER.map(name => new NavItem(name));
  } else if (product === CraniometrixProduct.CARE_CENTRAL && isSuperUser) {
    return CARE_CENTRAL_NAV_ITEM_NAMES_FOR_USER_WITH_ADMIN_PRIVILEGES.map(name => new NavItem(name));
  } else {
    const itemNames = roleToItemsMap[role] || [];
    return new RoleBasedNav(itemNames).items;
  }
}
