/**
 * @fileoverview Generic DS Button component.
 */
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

const buttonStyles = cva(["font-semibold", "rounded", "border"], {
  variants: {
    intent: {
      primary: [
        "bg-brand-orange",
        "text-white",
        "border-transparent",
        "hover:bg-orange-300",
      ],
      secondary: [
        "bg-white",
        "text-black",
        "border-[1px]",
        "border-[#9C9191]",
        "rounded-lg",
        "truncate",
        "w-fit",
        "shadow-md",
        "hover:bg-gray-50",
        "hover:border",
        "focus:border-brand-orange",
        "font-[300]",
      ],
      transparent: ["bg-transparent"],
    },
    size: {
      small: ["text-sm", "py-1", "px-2"],
      medium: ["text-base", "py-2", "px-4"],
      large: ["text-base", "min-w-[200px]", "py-3"],
    },
    disabled: {
      true: ["opacity-50", "cursor-not-allowed"],
    },
  },
  compoundVariants: [],
  defaultVariants: {
    intent: "primary",
    size: "medium",
    disabled: false,
  },
});

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  VariantProps<typeof buttonStyles>;

/**
 * Generic Button component.
 *
 * @param props HTML Button props
 * @returns HTML button element
 */
export function Button({ ...props }: Props) {
  const { className, intent, size, disabled, ...rest } = props;
  return (
    <button
      className={buttonStyles({ intent, size, disabled, className })}
      {...rest}
    />
  );
}
