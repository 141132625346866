import { useParams } from "react-router-dom";
import { useChatGptMessages } from "backend/resources/chatGptMessage";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function PrivateDiscussion() {
  
  const { conversation_id } = useParams();
  const { messages } = useChatGptMessages(conversation_id, "private");
 
  return (
    <div className="flex flex-col max-h-[40rem] flex-grow w-full h-full">
      <MessagesThread
        threadId={conversation_id}
        pageType="private">
        {!messages && <InitialContent />}{" "}
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex flex-col pt-6 text-sm">
      <p>No Discussion yet. Write into the chat below to get started.</p>
    </div>
  );
}
