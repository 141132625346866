import { DefaultCheckbox } from "components/Checkbox";
import { useEffect, useState } from "react";

interface AssessmentSelectInputProps {
  currentAnswer: number;
  updateAnswer: (obj: { answer?: number; context?: string }) => void;
  disabled: boolean;
  options: string[];
  isVertical: boolean;
}

export function AssessmentSelectInput({
  currentAnswer,
  updateAnswer,
  disabled,
  options,
  isVertical
}: AssessmentSelectInputProps) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(null);

  useEffect(() => {
    if (currentAnswer) {
      setSelectedOptionIndex(currentAnswer);
    }
  }, [currentAnswer]);

  const handleCheckboxChange = (optionIndex: number) => {
    if (selectedOptionIndex === optionIndex) {
      setSelectedOptionIndex(null);
      updateAnswer({ answer: undefined });
    } else {
      setSelectedOptionIndex(optionIndex);
      updateAnswer({ answer: optionIndex });
    }
  };

  return (
    <div
      style={{ [isVertical ? "gridTemplateColumns" : "gridTemplateRows"]: `repeat(${options.length + 1}, minmax(0, 1fr))` }}
      className={`grid ${isVertical ? "" : "mt-5"} gap-x-16 gap-y-5 w-full`}>

      {isVertical && options.map((option) => <div className="flex justify-center w-[60px]">
        <p className="text-center flex text-xs  font-bold">{option}</p>
      </div>)}
      <div />
      {options.map((_, index) => (
        <div style={{justifyContent: isVertical? "center":'end', width: isVertical? "60px": "full"}} className="flex items-center">
          <DefaultCheckbox
            key={index}
            checked={selectedOptionIndex === index}
            disabled={disabled}
            onChange={() => handleCheckboxChange(index)}
          />
        </div>
      ))}
    </div>
  );
}

