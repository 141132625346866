import { useEffect } from "react"
import { useAssessments } from "backend/resources/assessment"
import { useQuestionsInAssessment } from "backend/resources/assessmentQuestion/assessmentQuestion"
import { DyadTier } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm"
import { Month, useAnalyticsPageFilterStore } from "state/analyticsPageFilter/analyticsPageFilter"
import { Select } from "components/Select"


const MIN_AGE = 40
const AGE_RANGE = 10

export default function analyticsPageFilters() {
  const { assessmentId, questionId, dyad, sex, hasCaregiver, monthRange: dateRange, ageRange, setAssessmentId, setQuestionId, setDyad, setSex, setHasCaregiver, setMonthRange: setDateRange, setAgeRange } = useAnalyticsPageFilterStore()
  // Queries
  const { data: assessments } = useAssessments({ newAssessmentType: undefined })
  const { data: questions } = useQuestionsInAssessment(assessmentId)

  // Constants
  const questionOptions = questions?.map((q) => ({ label: q.title, value: q.id })) || []
  const assessmentOptions = assessments?.map((a) => ({ label: a.name, value: a.id })) || []
  const dyadOptions = [{ label: "All", value: "All" }, ...Object.values(DyadTier).map((dyad) => ({ label: dyad, value: dyad }))]
  const hasCaregiverOptions = [{ label: "All", value: "All" }, { label: "Yes", value: "True" }, { label: "No", value: "False" }]
  const sexOptions = [{ label: "All", value: "All" }, { label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Other", value: "other" }]
  const monthOptions = [{ label: "YTD", value: "YTD" }, ...Object.values(Month).map((month) => ({ label: month, value: month }))]

  const ageRangeOptions = [{ label: "All", value: "All" }, { label: "<40", value: "<40" }, ...Array.from({ length: 7 }, (_, i) => {
    const start = MIN_AGE + i * AGE_RANGE;
    const end = start + AGE_RANGE - 1;
    return { label: `${start}-${end}`, value: `${start}-${end}` };
  }), { label: "110+", value: "110>" }];

  // Effects
  useEffect(() => {
    if (questions) {
      setQuestionId(questions?.[0].id)
    }
  }, [questions])

 
  return (
    <div>
      <div className="grid grid-cols-[1fr,5fr,1fr,5fr] gap-4  items-center">
        <>
          <p className="w-min">Assessment</p>
          <Select
            classNames="w-[250px] max-w-[250px]"
            options={assessmentOptions}
            currentOption={assessmentOptions.find((q) => q.value === assessmentId)}
            onChange={setAssessmentId}
          />
        </>

        <>
          <p className="w-min">Dyad</p>
          <Select
            classNames="w-[250px] max-w-[250px]"
            options={dyadOptions}
            currentOption={dyadOptions.find((q) => q.value === dyad)}
            onChange={setDyad}
          />
        </>
        <>
          <p className="w-min">Sex</p>
          <Select
            classNames="w-[250px] max-w-[250px]"
            options={sexOptions}
            currentOption={sexOptions.find((q) => q.value === sex)}
            onChange={setSex}
          />
        </>
        <>
          <p className="w-min">Has Caregiver</p>
          <Select
            classNames="w-[250px] max-w-[250px]"
            options={hasCaregiverOptions}
            currentOption={hasCaregiverOptions.find((q) => q.value === hasCaregiver)}
            onChange={setHasCaregiver}
          />
        </>

        <>
          <p className="w-min">Date Range</p>
          <Select
            classNames="w-[250px] max-w-[250px]"
            options={monthOptions}
            currentOption={monthOptions.find((q) => q.value === dateRange)}
            onChange={setDateRange}
          />
        </>

        <>
          <p className="w-min">Age Range</p>
          <Select
            classNames="w-[250px] max-w-[250px]"
            options={ageRangeOptions}
            currentOption={ageRangeOptions.find((q) => q.value === ageRange)}
            onChange={setAgeRange}
          />
        </>
      </div>
      <div className="flex gap-6 mt-4 items-center">
        <p className="w-min">Question</p>
        <Select
          classNames="w-[full max-w-[650px]"
          options={questionOptions}
          currentOption={questionOptions.find((q) => q.value === questionId)}
          onChange={setQuestionId}
        />
      </div>
    </div >)
}
