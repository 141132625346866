import type { RefObject } from "react";
import { useEffect } from "react";

export function useClickOutside({
  ref,
  isEnabled,
  onClickOutside,
  excludedElementRefs,
}: {
  ref: RefObject<HTMLElement> | null;
  isEnabled: boolean;
  onClickOutside: () => void;
  excludedElementRefs?: (RefObject<HTMLElement> | null)[];
}) {
  useEffect(() => {
    function checkClick(this: Window, e: MouseEvent) {
      const element = ref?.current;
      const excludedElements = excludedElementRefs?.map(
        (excludedElementRef) => {
          return excludedElementRef?.current;
        }
      );
      const clickedElement = e.target as Node;

      const clickedInside =
        element?.contains(clickedElement) ||
        excludedElements?.some((excludedElement) =>
          excludedElement?.contains(clickedElement)
        );

      if (!clickedInside && isEnabled) {
        onClickOutside();
      }
    }

    const timer = isEnabled
      ? setTimeout(() => {
          window.addEventListener("click", checkClick);
        }, 50)
      : undefined; // A short delay (e.g. 50ms)

    return () => {
      clearTimeout(timer);
      window.removeEventListener("click", checkClick);
    };
  }, [ref, isEnabled, onClickOutside, excludedElementRefs]);
}
