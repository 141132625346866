import { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { CarespaceTableCell } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTableCell";
import { CarespaceTableFields, CarespaceTableType } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import {
  getCarespaceNameFromCarespace,
  getCarespaceStatusFromCarespace,
  getOrganizationNameFromCarespace,
  getPrimaryCarePhysicianNameFromCarespace,
  getUserAdloDobFromCarespace,
  getUserAdloDyadFromCarespace,
  getUserAdloSexFromCarespace,
  getUserRoleMapFromCarespace,
  useFilteredCarespaces
} from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/util";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { FC, useEffect, useState } from "react";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";


interface CarespaceTableProps {
  carespaces: NetworksWithAdlosAndCaregivers[];
  isLoading: boolean;
}

export const CarespaceTable: FC<CarespaceTableProps> = ({
  carespaces, isLoading
}) => {
  const navigate = useAppNavigate();
  const { org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace } = useCarespaceFilterStore();
  const [tableData, setTableData] = useState<CarespaceTableType[]>([]);
  const filteredData = useFilteredCarespaces(carespaces); // Removed the object literal to match the expected parameter type

  useEffect(() => {
    const data = filteredData.map(carespace => {
      const userRolesMap = getUserRoleMapFromCarespace(carespace);
      return {
        id: carespace.id,
        [CarespaceTableFields.CARESPACE]: getCarespaceNameFromCarespace(carespace),
        [CarespaceTableFields.DOB]: getUserAdloDobFromCarespace(carespace),
        [CarespaceTableFields.SEX]: getUserAdloSexFromCarespace(carespace),
        [CarespaceTableFields.DYAD]: getUserAdloDyadFromCarespace(carespace),
        [CarespaceTableFields.ORG]: getOrganizationNameFromCarespace(carespace),
        [CarespaceTableFields.STATUS]: getCarespaceStatusFromCarespace(carespace),
        [CarespaceTableFields.PCP]: getPrimaryCarePhysicianNameFromCarespace(carespace),
        [CarespaceTableFields.DOCTOR]: userRolesMap[NetworkRoleType.DOCTOR],
        [CarespaceTableFields.PCG]: userRolesMap[NetworkRoleType.PRIMARY_CAREGIVER]?? "Pending",
        [CarespaceTableFields.CN]: userRolesMap[NetworkRoleType.CARE_NAVIGATOR],
      } as CarespaceTableType;
    })
    setTableData(data)
  }, [carespaces, org, dob, sex, dyad, doctor, pcg, cn, pcp, status, carespace])

  return (
    <InfiniteScrollingTable
      data={tableData}
      isLoading={isLoading}
      handleClick={({ row }) => 
        navigate({
          path: CareCentralRoute.CARESPACE,
          params: {
            carespaceId: row?.id
          }
        })
      }
      CellContent={({cell, row}) => <CarespaceTableCell cell={cell} row={row}/>}
      columnFields={Object.values(CarespaceTableFields)}
      headersToCenter={[CarespaceTableFields.SEX, CarespaceTableFields.DYAD, CarespaceTableFields.ORG, CarespaceTableFields.STATUS]}
    />
  )
}