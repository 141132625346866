import type { RadioProps } from "@mui/material/Radio";
import Radio from "@mui/material/Radio";

/**
 * Props for the DefaultRadioInput component.
 */
export interface DefaultRadioInputProps extends RadioProps {
  /**
   * The width to apply to the icon components.
   * @default 20
   */
  iconWidth?: number;
  /**
   * The height to apply to the icon components.
   * @default 20
   */
  iconHeight?: number;
}

/**
 * A custom Radio input component that uses custom SVG icons for the checked and unchecked states.
 * It adjusts the size of the icons based on the provided `iconWidth` and `iconHeight` props.
 *
 * @param {DefaultRadioInputProps} props - The props for the component.
 * @returns {JSX.Element} The rendered Radio component with custom icons.
 */
export function DefaultRadioInput({ iconWidth = 20, iconHeight = 20, ...props }: DefaultRadioInputProps) {
  return (
    <Radio
      {...props}
    />
  );
}
