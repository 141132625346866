/**
 * Downloads a Blob object as a file.
 * 
 * This function creates a temporary anchor element to facilitate the download of a Blob object
 * as a file in the user's browser. It generates a URL for the Blob, sets the download attribute
 * of the anchor to the specified file name, and programmatically clicks the anchor to trigger
 * the download. After the download is initiated, the anchor is removed from the document and
 * the Blob URL is revoked to free up resources.
 *
 * @param {Blob} blob - The Blob object to be downloaded.
 * @param {string} file - The name of the file to be downloaded, including the file extension.
 */
export function downloadBlob(blob: Blob, file: string) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = file;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
}