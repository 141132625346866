import { useDeleteConversationDocumentMutation, useInsertConversationDocumentMutation } from "backend/resources/conversation_document/conversation_document";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DocumentTableFields, DocumentsTable } from "components/Tables/DocumentsTable";
import { formatDistanceToNow } from "date-fns";
import { ChangeEvent, useRef, useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import * as z from 'zod';

export const DocumentSchema = z.object({
  file: z.any().optional(),
  document_file_name: z.string(),
  uploader_user_id: z.string(),
  created_at: z.string(),
  is_new: z.boolean().optional(),
});

export type DocumentSchemaType = z.infer<typeof DocumentSchema>;

interface DocumentsUploadSectionProps {
  documents: DocumentSchemaType[],
  networkId: string,
  conversationId: string | undefined | null,
  isExternal: boolean,
  titleSize?: "sm" | "lg" | "md"
}

export default function DocumentsUploadSection({ documents, networkId, conversationId, isExternal, titleSize = "lg" }: DocumentsUploadSectionProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const insertConversationDocument = useInsertConversationDocumentMutation().mutateAsync;
  const deleteConversationDocument = useDeleteConversationDocumentMutation().mutateAsync;
  const [isDoubleUploadModalOpen, setIsDoubleUploadModalOpen] = useState(false);

  const handleDocumentUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (documents.some(doc => doc.document_file_name === file.name)) {
      setIsDoubleUploadModalOpen(true);
    } else {
      insertConversationDocument({ file, conversationId, networkId, isExternal })
    }
    // Reset input:
    e.target.value = '';
  };

  return <div className='flex flex-col gap-2'>
    <DoubleUploadModal isOpen={isDoubleUploadModalOpen} onClose={() => setIsDoubleUploadModalOpen(false)} />
    <div className='flex gap-5 items-center'>
      <p className={`text-${titleSize}`}>Documents</p>
      <ButtonWithIcon
        type='button'
        onClick={() => inputRef.current?.click()}
        text={'Upload'}
        icon={IconOption.UPLOAD}
        size={"small"}
      />
      <input
        type="file"
        accept="*"
        className="hidden"
        ref={inputRef}
        onChange={handleDocumentUpload}
        data-hj-allow
      />
    </div>
    {documents && documents.length > 0 ?
      <DocumentsTable
        documents={documents?.map(doc => ({
          [DocumentTableFields.Name]: doc.document_file_name,
          [DocumentTableFields.Date]: formatDistanceToNow(new Date(doc.created_at)),
          [DocumentTableFields.Who]: doc.uploader_user_id,
        })) ?? []}
        deleteOptions={{
          onDelete: (row) => deleteConversationDocument({ document_name: row.original.Name, conversationId })
        }}
        networkId={networkId}
      /> : <p className="text-sm text-zinc-400">No documents added yet</p>}
  </div >
}

function DoubleUploadModal({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) {
  return <ResponsiveModal isOpen={isOpen} onClose={onClose} closeText="Close" footerButtons={<ButtonWithIcon type="button" onClick={onClose} text="Close" icon={IconOption.CANCEL} />}>
    <div className="flex flex-col gap-2 text-center">
      <p className="text-base">Please note: you can’t upload the same document more than once.</p>
    </div>
  </ResponsiveModal>
}

