import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as ModalInfo } from "assets/modal-info.svg";
import { ReactComponent as TemplateButtonClickedIcon } from "assets/template-orange-saved.svg";
import { ReactComponent as TemplateButtonUnclickedIcon } from "assets/template-orange.svg";
import { ReactComponent as WarningIcon } from "assets/warning.svg";
import { CarePlanGoalStatuses, useGoalDelta } from "backend/resources/carePlan/carePlan";
import {
  useGoalQueryById,
  useUpdateGoalMutation,
} from "backend/resources/goal/goal";
import { useUserRecommendationsForGoal } from "backend/resources/goal/goalUserRecommendationStatus";
import {
  useDeleteGoalTemplateMutation,
  useSaveGoalTemplateMutation,
} from "backend/resources/goalTemplate/goalTemplate";
import type { RecommendationType } from "backend/resources/userRecommendation";
import {
  CraniometrixProduct,
  useHasPlanBinderControl,
  useProductAccess,
} from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useCarePlanStore } from "state/carePlan/carePlan";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";
import BackButton from "components/BackButton/BackButton";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { RecommendationCard } from "components/GuidancePage/Recommendations/Recommendation";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { GoalRecSuggestionsBreadcrumbs } from "components/GoalPage/GoalRecSuggestionsBreadcrumbs";
import GoalRating from "components/GoalPage/Rating";
import { TimeFrame } from "components/GoalPage/utils";

export default function GoalPage() {
  const { id: goalId } = useParams();
  const { isMobile } = useWindowSize();
  const [state, setState] = useState<{
    title: string;
    details: string;
    recommendations: RecommendationType[];
    timeline: string;
    progress: number;
    rating: number | null;
  }>({
    title: "",
    details: "",
    timeline: "",
    recommendations: [],
    progress: 0,
    rating: null,
  });
  const authUser = useUserStore((state) => state.user);
  const { goal } = useGoalQueryById(goalId);
  const { data: userRecommendationsInTemplate } = useUserRecommendationsForGoal(
    goal?.id
  );
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const { data: productAccess, isLoading } = useProductAccess();
  const activeOrganizationId = useActiveOrganizationId();

  const updateGoal = useUpdateGoalMutation().mutateAsync;
  const deleteGoalTemplate = useDeleteGoalTemplateMutation().mutateAsync;
  const saveGoalTemplate = useSaveGoalTemplateMutation(undefined).mutateAsync;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showDeletionPopup, setShowDeletionPopup] = useState(false);
  const [showDeletionConfirmationPopup, setShowDeletionConfirmationPopup] =
    useState(false);

  const { data: hasCarePlanControl } = useHasPlanBinderControl();


  // If the current Goal is a "replacement", we ant to show the updated form:

  const { currentCarePlanGoalsByAdlo } =
    useCarePlanStore();


  const goalsToSaveInEditCarePlan = goal?.adlo_id ? currentCarePlanGoalsByAdlo[goal?.adlo_id] ?? [] : [];
  const goalBeingReplaced = goalsToSaveInEditCarePlan?.find((cpGoal) => cpGoal?.title === goal?.title && cpGoal.status === CarePlanGoalStatuses.REPLACED)

  const { recsToAddToNewGoal, recsToKeep } = useGoalDelta(/*existing goal*/ goalBeingReplaced?.id, goalId)
  async function deleteTemplateAssociatedWithGoal() {
    const { progress, rating, ...goalWithoutStatus } = goal || {};

    if (
      goalWithoutStatus &&
      "goal_template_id" in goalWithoutStatus &&
      goalWithoutStatus.goal_template_id
    ) {
      await updateGoal({
        ...goal,
        goal_template_id: null,
      });

      await deleteGoalTemplate(goalWithoutStatus.goal_template_id);
    }
  }
  const { data: recommendationsData } = useUserRecommendationsForGoal(goalId);
  useEffect(() => {
    if (goalId && recommendationsData && goal) {


      // Extract the IDs of the recommendations that need to be kept
      const recsToKeepIds = recsToKeep?.map((rec) => rec.recommendation.id);
      // Filter out the recommendations that are already in the list of recommendations to keep
      const filteredRecommendations = recommendationsData.filter((rec) => !recsToKeepIds?.includes(rec.recommendation.id));
      // Combine the filtered recommendations, the recommendations to keep, and the recommendations to add to the new goal
      const recommendations = [...filteredRecommendations, ...(recsToKeep ?? []), ...(recsToAddToNewGoal || [])];
      setState({
        title: goal.title,
        details: goal.description,
        recommendations,
        timeline: TimeFrame.getLabelFromValue(goal.timeline),
        progress: goal.progress,
        rating: goal.rating,
      });
    }
  }, [recommendationsData, goal, goalId, recsToKeep]);

  return (
    <>
      <ResponsivePopup
        isOpen={showDeletionConfirmationPopup}
        onClose={() => setShowDeletionConfirmationPopup(false)}>
        <div className="w-full flex flex-col items-center gap-4 pb-6">
          <ModalInfo className="w-8" />

          <label className="mb-4">Goal removed from your Templates</label>
          <ButtonWithIcon
            onClick={() => setShowDeletionConfirmationPopup(false)}
            text={"OK"}
            size="small"
            icon={IconOption.CHECKMARK}
          />
        </div>
      </ResponsivePopup>
      <ResponsivePopup
        isOpen={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        footerButtons={
          <ButtonWithIcon
            onClick={() => setShowConfirmationPopup(false)}
            text={"OK"}
            size="small"
            icon={IconOption.CHECKMARK}
          />
        }>
        <div className="w-full flex flex-col items-center gap-4 text-center">
          <label className="text-lg">Goal added to your Templates</label>
        </div>
      </ResponsivePopup>
      <ResponsivePopup
        isOpen={showDeletionPopup}
        onClose={() => setShowDeletionPopup(false)}>
        <div className="w-full flex flex-col items-center gap-2 px-4 pb-20 md:pb-2 text-center">
          <WarningIcon className="w-8" />
          <label className="mt-5">
            This will delete the goal from your Templates, including any edits
            you made to it. Are you sure?
          </label>
          <FooterButtons>
            <ButtonWithIcon
              size={"small"}
              onClick={() => setShowDeletionPopup(false)}
              text={"Cancel"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              size={"small"}
              onClick={async () => {
                await deleteTemplateAssociatedWithGoal();
                setShowDeletionPopup(false);
                setShowDeletionConfirmationPopup(true);
              }}
              text={"Yes, Delete it"}
              icon={IconOption.CHECKMARK}
            />
          </FooterButtons>
        </div>
      </ResponsivePopup>
      <PageContainer>
        <div className="flex flex-col w-full max-w-full gap-4">
          <div className="flex justify-between">
            <BackButton />
          </div>
          <div className="flex flex-col gap-1">
            {/* breadcrumbs */}
            <div className="flex justify-between">
              <GoalRecSuggestionsBreadcrumbs />
              {!isMobile && (
                <div className="flex gap-2">
                  {hasCarePlanControl &&
                    <ButtonWithIcon
                      onClick={() => {
                        if (goalId) {
                          navigate({
                            path: CarePilotRoute.GOAL_EDIT_ID,
                            params: {
                              id: goalId
                            }
                          })
                        }
                      }}
                      text={"Edit"}
                      size={"small"}
                      icon={IconOption.EDIT}
                    />}
                  {/* TODO: reuse? */}
                  {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
                    <button
                      className="text-black"
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={async (event) => {
                        event.stopPropagation();
                        const { progress, rating, ...goalWithoutStatus } =
                          goal || {};

                        if (goal?.goal_template_id) {
                          setShowDeletionPopup(true);
                        } else {
                          const shareableRecommendationsIds =
                            userRecommendationsInTemplate?.map(
                              (userRec) => userRec.recommendation.id
                            );
                          if (
                            goal &&
                            activeOrganizationId &&
                            authUser &&
                            shareableRecommendationsIds
                          ) {
                            const newGoalTemplate = await saveGoalTemplate({
                              goalTemplate: {
                                title: goal.title,
                                description: goal.description,
                                timeline: goal.timeline,
                                organization_id: activeOrganizationId,
                                user_id: authUser?.id,
                              },
                              recommendations: userRecommendationsInTemplate ?? [],
                            });
                            const res = await updateGoal({
                              ...goalWithoutStatus,
                              goal_template_id: newGoalTemplate?.id,
                            });
                          }
                          setShowConfirmationPopup(true);
                        }
                      }}>
                      {goal?.goal_template_id ? (
                        <div className="flex items-center">
                          <TemplateButtonClickedIcon className="w-6 h-6" />
                          <div className="shrink-0">Delete template</div>
                        </div>
                      ) : (
                        <div className="flex gap-1 items-center shrink-0">
                          <TemplateButtonUnclickedIcon className="w-6 h-6" />
                          <div className="shrink-0">Save as template</div>
                        </div>
                      )}
                    </button>
                  ) : null}
                </div>
              )}
            </div>
            {/* title */}
            <div className="flex justify-between items-center">
              <PageMainHeader
                text={state.title}
                className="break-words "
              />
            </div>
          </div>

          <h1>{state.details.replace('"', "").replace('"', "")}</h1>
          <div className="flex w-full justify-between text-sm items-center">
            <div>
              <label className="flex gap-1">
                Time frame: <p className="">{state.timeline}</p>
              </label>
              <label className="flex gap-1">
                Progress: <p className="font-bold">{state.progress}%</p>
              </label>
            </div>
            {state.rating != null ? (
              <label className="flex gap-1 items-center">
                Rating:{" "}
                <GoalRating
                  rating={state.rating}
                  size="large"
                />
              </label>
            ) : null}
          </div>
          {/* <h1>{state.timeline}</h1> */}

          <div className="flex flex-wrap gap-4 justify-center md:justify-start pb-40">
            <div className="flex w-full">
              Recommendations ({state.recommendations.length})
            </div>

            {state.recommendations
              ?.sort((a, b) => {
                return a.title.localeCompare(
                  b.title
                );
              })
              ?.map((recommendation) => (
                <RecommendationCard recommendation={recommendation} />
              ))}
          </div>
        </div>
        <BottomActionBar
          centerSlot=
          {hasCarePlanControl &&
            <ButtonWithIcon
              onClick={() => {
                if (goalId) {
                  navigate({
                    path: CarePilotRoute.GOAL_EDIT_ID,
                    params: {
                      id: goalId
                    }
                  })
                }
              }}
              text={""}
              className="bg-white rounded-full"
              icon={IconOption.EDIT}
            />
          }
        />
      </PageContainer>
    </>
  );
}
