import type {
  BookmarkableItem,
  UserBookmark,
} from "backend/resources/userBookmark";
import {
  UserBookmarkType,
  deleteChatGptMessageBookmark,
  deleteInterventionBookmark,
  deleteInterventionResourceBookmark,
  saveChatGptMessageBookmark,
  saveInterventionBookmark,
  saveInterventionResourceBookmark,
} from "backend/resources/userBookmark";

export function getBookmarkType(bookmark: UserBookmark) {
  if (bookmark.user_intervention_id) {
    return UserBookmarkType.INTERVENTION;
  } else if (bookmark.user_guidance_resource_id) {
    return UserBookmarkType.GUIDANCE_RESOURCE;
  } else {
    return UserBookmarkType.CHAT_GPT_MESSAGE;
  }
}

export function getIdOfBookmarkedItem(bookmark: UserBookmark) {
  if (bookmark.user_intervention_id) {
    return bookmark.user_intervention_id;
  } else if (bookmark.user_guidance_resource_id) {
    return bookmark.user_guidance_resource_id;
  } else {
    return bookmark.chat_gpt_message_id || "";
  }
}

export async function deleteBookmark(
  bookmark: UserBookmark,
  userId: string | undefined,
  refetchBookmarks: () => void
) {
  let deleteBookmark;
  switch (getBookmarkType(bookmark)) {
    case UserBookmarkType.CHAT_GPT_MESSAGE: {
      deleteBookmark = deleteChatGptMessageBookmark;
      break;
    }
    case UserBookmarkType.INTERVENTION: {
      deleteBookmark = deleteInterventionBookmark;
      break;
    }
    case UserBookmarkType.GUIDANCE_RESOURCE: {
      deleteBookmark = deleteInterventionResourceBookmark;
      break;
    }
    default: {
      throw new Error("bookmark type doesn't exist");
    }
  }
  if (userId) {
    const { data, error } = await deleteBookmark(bookmark);
    if (data && data.length > 0) {
      refetchBookmarks();
    }
  }
}

export async function saveBookmark(
  bookmarkableItem: BookmarkableItem,
  userId: string | undefined,
  networkId: string | undefined,
  refetchBookmarks: () => void
) {
  let saveBookmark;
  switch (bookmarkableItem.type) {
    case UserBookmarkType.CHAT_GPT_MESSAGE: {
      saveBookmark = saveChatGptMessageBookmark;
      break;
    }
    case UserBookmarkType.INTERVENTION: {
      saveBookmark = saveInterventionBookmark;
      break;
    }
    case UserBookmarkType.GUIDANCE_RESOURCE: {
      saveBookmark = saveInterventionResourceBookmark;
      break;
    }
    default: {
      throw new Error("bookmark type doesn't exist");
    }
  }
  if (bookmarkableItem.itemId && userId && networkId) {
    const { data, error } = await saveBookmark({
      userId,
      itemId: bookmarkableItem.itemId,
      networkId,
    });
    if (data && data.length > 0) {
      refetchBookmarks();
    }
  }
}
