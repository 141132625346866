export function TermsOfServicePage() {
  return (
    <div className="w-full h-full">
      <iframe
        className="w-full h-full"
        title="terms of service"
        src="./terms-of-service.html"
      />
    </div>
  );
}
