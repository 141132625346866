import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import ModalView from "components/ModalView/ModalView";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { useWindowSize } from "hooks/useWindowSize";
import { useNavigateBack } from "lib/routing";
import { ReactNode } from "react";


/**
 * This component renders a modal view on mobile devices and a regular page view on desktop devices.
 * 
 * @param {Object} props - The properties object.
 * @param {ReactNode} props.children - The content to be displayed inside the modal or page.
 * @param {Function} props.onClose - The function to call when the modal or page is closed.
 * @param {string} props.title - Optional title of the modal or page.
 */
interface ModalInMobileViewProps {
  children: ReactNode;
  onClose: () => void;
  title?: string;
}

export default function ModalInMobileView({ children, onClose, title }: ModalInMobileViewProps) {
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title={title}
        closeText="Back"
        onClose={() => {
          onClose();
          navigateBack()
        }}>
        <PageContainer>
          {children}
        </PageContainer>
      </ModalView>
    );
  } else {
    return (
      <PageContainer>
        <ButtonWithIcon
          onClick={() => {
            navigateBack();
            onClose();
          }}
          text="Back"
          size="small"
          className="w-full justify-center flex md:justify-start mb-4"
          icon={IconOption.BACK_ARROW}
        />
        {title && <PageMainHeader text={title} />}
        {children}
      </PageContainer>
    );
  }
}