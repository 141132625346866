import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import BadRating from "assets/bad-rating.png";
import GoodRating from "assets/good-rating.png";
import MediumRating from "assets/medium-rating.png";

const ratingStyles = cva([], {
  variants: {
    size: {
      small: ["w-4", "h-4"],
      large: ["w-6 min-w-6", "h-6 min-h-6"],
    },
  },
  defaultVariants: {
    size: "small",
  },
});
enum RatingType {
  BAD_RATING,
  MEDIUM_RATING,
  GOOD_RATING,
}

function ratingToRatingType(rating: number | null) {
  if (!rating && rating !== 0) {
    return null;
  } else if (rating <= 3) {
    return RatingType.BAD_RATING;
  } else if (rating <= 7) {
    return RatingType.MEDIUM_RATING;
  } else {
    return RatingType.GOOD_RATING;
  }
}

export default function GoalRating({
  rating,
  ...variantProps
}: { rating: number | null } & VariantProps<typeof ratingStyles>) {
  let imageName;
  if (rating === null) {
    imageName = MediumRating;
  } else if (rating <= 3) {
    imageName = BadRating;
  } else if (rating <= 7) {
    imageName = MediumRating;
  } else {
    imageName = GoodRating;
  }
  return (
    <div className={`${ratingStyles(variantProps)}flex justify-center`}>
      <img
        src={imageName}
        alt=""
        className={rating === null ? "saturate-0" : ""}
      />
    </div>
  );
}

export function GoalRatings({
  currentRating,
  setRating,
  ...variantProps
}: {
  currentRating: number | null;
  setRating: (rating: number) => Promise<void>;
} & VariantProps<typeof ratingStyles>) {
  return (
    <div className="flex justify-between gap-1">
      <button
        className={`${ratingStyles(variantProps)} ${
          ratingToRatingType(currentRating) === RatingType.BAD_RATING
            ? "saturate-100"
            : "saturate-0  hover:saturate-50"
        }`}
        onClick={async () => setRating(0)}>
        <img
          src={BadRating}
          alt=""
        />
      </button>
      <button
        className={`${ratingStyles(variantProps)} ${
          ratingToRatingType(currentRating) === RatingType.MEDIUM_RATING
            ? "saturate-100"
            : "saturate-0 hover:saturate-50"
        }`}
        onClick={() => setRating(5)}>
        <img
          src={MediumRating}
          alt=""
        />
      </button>
      <button
        className={`${ratingStyles(variantProps)} ${
          ratingToRatingType(currentRating) === RatingType.GOOD_RATING
            ? "saturate-100"
            : "saturate-0 hover:saturate-50"
        }`}
        onClick={() => setRating(10)}>
        <img
          src={GoodRating}
          alt=""
        />
      </button>
    </div>
  );
}
