import create from "zustand";
import { Timeline } from "components/GoalPage/utils";

type GoalState = {
  sessions: {
    [sessionId: string]: {
      title: string | undefined;
      details: string | undefined;
      timeframe: Timeline;
      recommendationIds: string[];
      activeTemplateId: string | undefined;
    };
  };
};

type GoalActions = {
  setSession: (sessionId: string, data: Partial<GoalState["sessions"][string]>) => void;
  resetSession: (sessionId: string) => void;
};

export const useGoalStore = create<GoalState & GoalActions>((set, get) => ({
  sessions: {},
  setSession: (sessionId, data) => {
    set((state) => ({
      sessions: {
        ...state.sessions,
        [sessionId]: {
          ...state.sessions[sessionId],
          ...data,
        },
      },
    }));
  },
  resetSession: (sessionId) => {
    set((state) => {
      const { [sessionId]: _, ...rest } = state.sessions;
      return { sessions: rest };
    });
  },
}));