import { FC } from "react";


interface PillProps {
  label: string;
  background: string; // hexcode of the background color
  className?: string;
}

export const Pill: FC<PillProps> = ({label, className, background}) => {
  const classNameString = `rounded-full truncate text-xs py-1 text-center px-4 w-min-[150px] w-[150px] max-w-[150px] text-white ${className}`
  return (
    <span 
      className={classNameString}
      style={{ background }}
    >
      {label}
    </span>
  )
}