import { AdminPage } from "components/AdminPage/AdminPage";
import BookmarkDetailPage from "components/BookmarkDetailPage/BookmarkDetailPage";
import CareBinder from "components/CareBinder/CareBinder";
import { AssessmentsTab } from "components/CareBinder/components/AssessmentsTab";
import CarePlan from "components/CarePlan/CarePlan";
import EditCarePlanPage from "components/CarePlan/EditCarePlanPage";
import ConversationPage from "components/ConversationPage/ConversationPage";
import EducationPage from "components/EducationPage/ResourcesPage";
import GeneralDiscussionPage from "components/GeneralDiscussionPage/GeneralDiscussionPage";
import CreateOrEditGoalPage from "components/GoalPage/Goal/CreateOrEditGoalPage";
import GoalPage from "components/GoalPage/Goal/GoalPage";
import { ViewAllRecommendationsInUserUpdatePage } from "components/GuidancePage";
import EditInterventionPage from "components/GuidancePage/Recommendations/Recommendation/Interventions/EditInterventionPage";
import InterventionPage from "components/GuidancePage/Recommendations/Recommendation/Interventions/InterventionPage";
import EditRecommendationPage from "components/GuidancePage/Recommendations/RecommendationPage/EditRecommendationPage";
import RecommendationPage from "components/GuidancePage/Recommendations/RecommendationPage/RecommendationPage";
import CreateOrEditShareableRecommendationPage from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/CreateOrEditShareableRecommendationPage";
import { LibraryPage } from "components/LibraryPage";
import { MedicationIntakePage } from "components/MedicationsPage";
import MyAccountPage from "components/MyAccountPage/MyAccountPage";
import MyCarePage from "components/MyCarePage/MyCarePage";
import { MyPlanPage } from "components/MyPlanPage";
import { NanasDay } from "components/NanasDay";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import ResourcesPage from "components/ResourcesPage/ResourcesPage";
import SettingsPage from "components/SettingsPage/SettingsPage";
import UpdatesPage from "components/UpdatesPage/UpdatesPage";
import VideoCategoryPage from "components/VideoLibraryPage/VideoCategoryPage/VideoCategoryPage";
import { CareCentralRoute, CarePilotRoute, SharedRoute } from "lib/routing";
import "react-loading-skeleton/dist/skeleton.css";
import { RouteLayout, RoutLayoutObject } from "routes";

export const carePilotRoutes: RoutLayoutObject[] = [
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.MEDICATION_INTAKE,
    element: <MedicationIntakePage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.CARE_PLAN,
    element: (
      <PageContainer>
        <div className="flex flex-col gap-4 pb-20">
          <PageMainHeader text="Care Plan" />
          <CarePlan />
        </div>
      </PageContainer>
    )
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.MEDICATIONS,
    element: (
      <PageContainer>
        <div className="flex flex-col gap-4 pb-20">
          <PageMainHeader text="Medications" />
          <NanasDay />
        </div>
      </PageContainer>
    )
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.ASSESSMENTS,
    element: (
      <PageContainer>
        <div className="flex flex-col gap-4 pb-20">
          <PageMainHeader text="Assessments" />
          <AssessmentsTab />
        </div>
      </PageContainer>
    )
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.RECOMMENDATION,
    element: <RecommendationPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.RECOMMENDATION_EDIT,
    element: <EditRecommendationPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CareCentralRoute.INTERVENTION_EDIT,
    element: <EditInterventionPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CareCentralRoute.INTERVENTION_CREATE,
    element: <EditInterventionPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.INTERVENTION,
    element: <InterventionPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.VIEW_ALL_RECOMMENDATION,
    element: <ViewAllRecommendationsInUserUpdatePage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.PLAN,
    element: <MyPlanPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.PLAN_NEW,
    element: <MyPlanPage should_display_new_task_form />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.PLAN_ID,
    element: <MyPlanPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.PLAN_NEW_TASK,
    element: <MyPlanPage should_display_new_task_form />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.PLAN_EDIT,
    element: <MyPlanPage should_display_edit_task_form />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.RECOMMENDATION_ADD,
    element: <CreateOrEditShareableRecommendationPage isSavingToGoal />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.RECOMMENDATION_ADD_ID,
    element: <CreateOrEditShareableRecommendationPage isSavingToGoal />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.MY_CARE,
    element: <MyCarePage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.MY_CARE_ID,
    element: <MyCarePage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.GOAL_ID,
    element: <GoalPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.GOAL_EDIT_ID,
    element: <CreateOrEditGoalPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.GOAL_EDIT,
    element: <CreateOrEditGoalPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.GENERAL_DISCUSSION,
    element: <GeneralDiscussionPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.CONVERSATION,
    element: <ConversationPage />
  },
  {
    // TODO: PR-2794: Remove CareBinder completely
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.BINDER,
    element: <CareBinder />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.CARE_PLAN_EDIT,
    element: <EditCarePlanPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.DAY_EDIT,
    element: <NanasDay should_display_edit_schedule_page />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.DAY_NOTES,
    element: <NanasDay should_display_notes_page />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.DAY_INTAKE,
    element: <NanasDay should_display_intake_view />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.DAY_INTAKE_NEW,
    element: <NanasDay should_display_create_form />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.DAY_PRESCRIPTION,
    element: <NanasDay should_display_detail_page />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.DAY_PRESCRIPTION_EDIT,
    element: <NanasDay should_display_edit_page />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.FAVORITES,
    element: <LibraryPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.BOOKMARK,
    element: <BookmarkDetailPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.FAMILY_HUB,
    element: <UpdatesPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.ADMIN,
    element: <AdminPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.MY_ACCOUNT,
    element: <MyAccountPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.SETTINGS,
    element: <SettingsPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.LOCAL_SEARCH,
    element: <ResourcesPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.EDUCATION,
    element: <EducationPage />
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: CarePilotRoute.EDUCATION_CATEGORY,
    element: <VideoCategoryPage />
  },
]