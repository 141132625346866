import { useSaveGalleryMediaInUserUpdateMutation } from "backend/resources/userUpdates/useUserUpdateToGalleryMedia";
import { useSaveInterventionsInUserUpdate } from "backend/resources/userUpdates/userUpdateToIntervention";
import { useSaveSelectedBookmarksInUserUpdatesMutation } from "backend/resources/userUpdates/userUpdateToUserBookmark";
import type { UserUpdate } from "backend/resources/userUpdates/userUpdates";
import { useUpdateUserUpdateMutation } from "backend/resources/userUpdates/userUpdates";
import { useCallback, useEffect, useRef } from "react";
import { useAssessmentStore } from "state/assessment";
import { create } from "zustand";
import { persist, redux } from "zustand/middleware";

interface UserUpdateState {
  activeUserUpdateId: string | undefined;
  inProgressUserUpdate: UserUpdate | undefined;
  userUpdates: UserUpdate[];
  galleryMediaInUserUpdate: string[];
  interventionIdsInUserUpdate: string[];
  bookmarkIdsInUserUpdate: string[];
  summary: string | undefined;
}

const initialState: UserUpdateState = {
  activeUserUpdateId: undefined,
  inProgressUserUpdate: undefined,
  userUpdates: [],
  galleryMediaInUserUpdate: [],
  interventionIdsInUserUpdate: [],
  bookmarkIdsInUserUpdate: [],
  summary: undefined,
};

enum ActionTypes {
  SET_ACTIVE_USER_UPDATE = "SET_ACTIVE_USER_UPDATE",
  SET_IN_PROGRESS_USER_UPDATE = "SET_IN_PROGRESS_USER_UPDATE",
  SET_USER_UPDATES = "SET_USER_UPDATES",
  ADD_GALLERY_MEDIA = "ADD_GALLERY_MEDIA",
  ADD_INTERVENTIONS = "ADD_INTERVENTIONS",
  SET_SUMMARY = "SET_SUMMARY",
  RESET = "RESET",
  DELETE_BOOKMARK = "DELETE_BOOKMARK",
  SET_BOOKMARK_IDS = "SET_BOOKMARK_IDS",
  DELETE_GALLERY_MEDIA = "DELETE_GALLERY_MEDIA",
  SET_GALLERY_MEDIA = "SET_GALLERY_MEDIA",
  DELETE_INTERVENTIONS = "DELETE_INTERVENTIONS",
  SET_INTERVENTION_IDS = "SET_INTERVENTION_IDS",
}

export const userUpdateReducer = (state: UserUpdateState, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_USER_UPDATE: {
      return { ...state, activeUserUpdateId: action.payload };
    }
    case ActionTypes.SET_IN_PROGRESS_USER_UPDATE: {
      return { ...state, inProgressUserUpdate: action.payload };
    }

    case ActionTypes.SET_USER_UPDATES: {
      return { ...state, userUpdates: action.payload };
    }

    case ActionTypes.ADD_GALLERY_MEDIA: {
      return {
        ...state,
        galleryMediaInUserUpdate: [
          ...state.galleryMediaInUserUpdate,
          action.payload,
        ],
      };
    }

    case ActionTypes.ADD_INTERVENTIONS: {
      return {
        ...state,
        interventionIdsInUserUpdate: [
          ...state.interventionIdsInUserUpdate,
          ...action.payload,
        ],
      };
    }

    case ActionTypes.SET_SUMMARY: {
      return { ...state, summary: action.payload };
    }

    // Inside the userUpdateReducer switch statement:

    case ActionTypes.DELETE_BOOKMARK: {
      return {
        ...state,
        bookmarkIdsInUserUpdate: state.bookmarkIdsInUserUpdate.filter(
          (id) => id !== action.payload
        ),
      };
    }

    case ActionTypes.SET_BOOKMARK_IDS: {
      return {
        ...state,
        bookmarkIdsInUserUpdate: action.payload,
      };
    }

    case ActionTypes.DELETE_GALLERY_MEDIA: {
      return {
        ...state,
        galleryMediaInUserUpdate: state.galleryMediaInUserUpdate.filter(
          (filename) => filename !== action.payload
        ),
      };
    }

    case ActionTypes.SET_GALLERY_MEDIA: {
      return {
        ...state,
        galleryMediaInUserUpdate: action.payload,
      };
    }

    case ActionTypes.DELETE_INTERVENTIONS: {
      const interventionIdsToDelete = new Set(action.payload);
      return {
        ...state,
        interventionIdsInUserUpdate: state.interventionIdsInUserUpdate.filter(
          (id) => !interventionIdsToDelete.has(id)
        ),
      };
    }

    case ActionTypes.SET_INTERVENTION_IDS: {
      return {
        ...state,
        interventionIdsInUserUpdate: action.payload,
      };
    }

    case ActionTypes.RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

// TODO: remove this as a persistent store, it's a potential HIPAA violation.
// See https://app.shortcut.com/craniometrix/story/3099/frontend-remove-persist-from-user-update-store
export const userUpdatesStore = create(
  persist(redux(userUpdateReducer, initialState), {
    name: "user-updates-store", // replace with your preferred storage name
    getStorage: () => localStorage, // replace with your preferred storage option
  })
);


export const setUserUpdates = (updates: UserUpdate[]) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_USER_UPDATES,
    payload: updates,
  });
};

export const setSummary = (summary?: string | null) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_SUMMARY,
    payload: summary,
  });
};

export const deleteBookmark = (bookmarkId: string) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.DELETE_BOOKMARK,
    payload: bookmarkId,
  });
};

export const setBookmarkIds = (bookmarkIds: string[]) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_BOOKMARK_IDS,
    payload: bookmarkIds,
  });
};

export const addGalleryMedia = (filename: string) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.ADD_GALLERY_MEDIA,
    payload: filename,
  });
};

export const deleteGalleryMedia = (mediaId: string) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.DELETE_GALLERY_MEDIA,
    payload: mediaId,
  });
};

export const setGalleryMedia = (mediaIds: string[]) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_GALLERY_MEDIA,
    payload: mediaIds,
  });
};

export const addInterventions = (interventionIds: string[]) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.ADD_INTERVENTIONS,
    payload: interventionIds,
  });
};

export const deleteInterventions = (interventionIds: string[]) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.DELETE_INTERVENTIONS,
    payload: interventionIds,
  });
};

export const getInterventionIds = () => {
  return userUpdatesStore.getState().interventionIdsInUserUpdate;
};

export const setInterventionIds = (interventionIds: string[]) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_INTERVENTION_IDS,
    payload: interventionIds,
  });
};

export const setActiveUserUpdateId = (id?: string) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_ACTIVE_USER_UPDATE,
    payload: id,
  });
};

export const setInProgressUserUpdate = (userUpdate: UserUpdate) => {
  userUpdatesStore.dispatch({
    type: ActionTypes.SET_IN_PROGRESS_USER_UPDATE,
    payload: userUpdate,
  });
};

export const resetUserUpdateStore = () => {
  userUpdatesStore.dispatch({
    type: ActionTypes.RESET,
  });
};

export const getCurrentUserUpdate = () => {
  const state = userUpdatesStore.getState();
  return (
    state.userUpdates.find(
      (update) => update.id === state.activeUserUpdateId
    ) ||
    (state.inProgressUserUpdate?.id === state.activeUserUpdateId
      ? state.inProgressUserUpdate
      : undefined)
  );
};

export const useSaveCurrentUserUpdate = () => {
  const stateRef = useRef(userUpdatesStore.getState());
  const activeUserAssessmentId = useAssessmentStore(
    (state) => state.activeUserAssessmentId
  );

  //  Mutations
  const updateUserUpdate = useUpdateUserUpdateMutation();
  const saveSelectedBookmarksInUserUpdate =
    useSaveSelectedBookmarksInUserUpdatesMutation(
      stateRef.current.activeUserUpdateId
    ).mutateAsync;
  const saveGalleryMediaInUserUpdate = useSaveGalleryMediaInUserUpdateMutation(
    stateRef.current.activeUserUpdateId
  ).mutateAsync;
  const saveInterventionsInUserUpdate = useSaveInterventionsInUserUpdate(
    stateRef.current.activeUserUpdateId
  ).mutateAsync;

  // Subscribe to changes in the state
  useEffect(() => {
    return userUpdatesStore.subscribe(
      // The callback is called whenever the state changes
      (state) => {
        // Update the stateRef with the new state
        stateRef.current = state;
      }
    );
  }, []);

  return useCallback(
    async ({ publish }: { publish: boolean }) => {


      const state = stateRef.current;
      await updateUserUpdate.mutateAsync({
        id: state.activeUserUpdateId,
        // we diferentiate published vs draft by checking the
        // 'completed_at' timestamp:
        completed_at: publish
          ? new Date(
            getCurrentUserUpdate()?.completed_at || Date.now()
          ).toISOString()
          : undefined,
        user_assessment_id: activeUserAssessmentId,
        summary: state.summary,
      });
      // save all the sections:
      await saveSelectedBookmarksInUserUpdate(
        state.bookmarkIdsInUserUpdate
      );
      await saveGalleryMediaInUserUpdate(
        state.galleryMediaInUserUpdate
      );
      await saveInterventionsInUserUpdate(
        state.interventionIdsInUserUpdate
      );

      // Reset store if needed
    },
    [stateRef, updateUserUpdate, activeUserAssessmentId]
  );
};
