import { getCurrentYYYYMMDD } from "components/NanasDay/utils";
import create from "zustand";
import { immer } from "zustand/middleware/immer";

// store

type State = {
  selectedScheduleDate: string;
};

type Actions = {
  setSelectedDate: (date: string) => void;
};

const initialState: State = {
  selectedScheduleDate: getCurrentYYYYMMDD(),
};

export const useMedicationStore = create<State & Actions>()(
  immer((set, get) => ({
    /**
     * state
     */
    ...initialState,

    /**
     * actions
     */
    setSelectedDate: (date: string) =>
      set((state) => {
        state.selectedScheduleDate = date;
      }),
  }))
);
