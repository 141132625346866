import { InitialPatient, PTReferralSource } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { RadioButtonsGroup } from "components/RadioGroup";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { z } from "zod";


interface InitialAssessmentInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const InitialAssessmentInputs: React.FC<InitialAssessmentInputsProps<z.infer<typeof PAFSchema>>> = ({ form, isReadOnly }) => {
  return (
    <>
      {/* Initial Patient */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="Initial Patient"
        fieldName="initial_patient"
        children={(field) => (
          <RadioButtonsGroup 
            options={getOptionsFromEnum(InitialPatient)}
            {...field}
          />
        )}
      />
      {/* PT Referral Source */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PT Referral Source"
        fieldName="pt_referral_source"
        children={(field) => (
          <RadioButtonsGroup 
            row={false}
            options={getOptionsFromEnum(PTReferralSource)}
            {...field}
          />
        )}
      />
    </>
  )
}