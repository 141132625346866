import { useQuery } from "@tanstack/react-query";
import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import { QUERY_KEYS } from "backend/queryKeys";
import { UserBookmarkType } from "backend/resources/userBookmark";
import {
  fetchChatGptMessageNoteByChatGptMessageId,
  fetchUserInterventionNoteByInterventionId,
  fetchUserInterventionResourceNoteByInterventionResourceId,
  saveChatGptMessageNote,
  saveInterventionNote,
  saveInterventionResourceNote,
} from "backend/resources/userNote";
import { useWindowSize } from "hooks/useWindowSize";
import { useUserStore } from "state/user";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Popup } from "components/Popup";
import { BottomSheet } from "components/Sheet";
import { TextArea } from "components/TextArea";

interface Props {
  close: () => void;
  bookmarkItemId: string;
  bookmarkType: UserBookmarkType;
}

export default function AddNotePopup({
  bookmarkType,
  close,
  bookmarkItemId,
}: Props) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={close}>
        <AddNotePopupComponent
          close={close}
          bookmarkItemId={bookmarkItemId}
          bookmarkType={bookmarkType}
        />
      </BottomSheet>
    );
  } else {
    return (
      <Popup
        showCloseButton={true}
        close={() => {
          close();
        }}>
        <AddNotePopupComponent
          close={close}
          bookmarkItemId={bookmarkItemId}
          bookmarkType={bookmarkType}
        />
      </Popup>
    );
  }
}

export function AddNotePopupComponent({
  bookmarkType,
  close,
  bookmarkItemId,
}: Props) {
  const [note, setNote] = useState<string>();
  const authUser = useUserStore((state) => state.user);

  function onChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNote(e.currentTarget.value);
  }

  const { data: userNote, refetch: refetchUserNote } = useQuery({
    queryKey: [QUERY_KEYS.userNote, bookmarkItemId],
    queryFn: () => fetchUserNote(),
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    if (!note && !!userNote?.note) {
      setNote(userNote.note);
    }
  }, [userNote]);

  function fetchUserNote() {
    switch (bookmarkType) {
      case UserBookmarkType.CHAT_GPT_MESSAGE: {
        return fetchChatGptMessageNoteByChatGptMessageId(bookmarkItemId);
      }
      case UserBookmarkType.INTERVENTION: {
        return fetchUserInterventionNoteByInterventionId(bookmarkItemId);
      }
      case UserBookmarkType.GUIDANCE_RESOURCE: {
        return fetchUserInterventionResourceNoteByInterventionResourceId(
          bookmarkItemId
        );
      }
      default: {
        throw new Error("bookmark type doesn't exist");
      }
    }
  }

  function persistNote(note: string) {
    switch (bookmarkType) {
      case UserBookmarkType.CHAT_GPT_MESSAGE: {
        return saveChatGptMessageNote({
          user_id: authUser?.id || "",
          chat_gpt_message_id: bookmarkItemId,
          note,
        });
      }
      case UserBookmarkType.INTERVENTION: {
        return saveInterventionNote({
          user_id: authUser?.id || "",
          user_intervention_id: bookmarkItemId,
          note,
        });
      }
      case UserBookmarkType.GUIDANCE_RESOURCE: {
        return saveInterventionResourceNote({
          user_id: authUser?.id || "",
          user_guidance_resource_id: bookmarkItemId,
          note,
        });
      }
      default: {
        throw new Error("bookmark type doesn't exist");
      }
    }
  }

  async function saveNote() {
    if (note) {
      const { data } = await persistNote(note);
      if (data && data.length > 0) {
        setNote(data[0].note || "");
        refetchUserNote();
        close();
      }
    }
  }

  return (
    <div className=" px-10 md:px-0 pb-20 md:pb-0 flex flex-col gap-3 md:min-w-[400px] min-h-[250px]">
      <p className="text-lg">Notes</p>
      <TextArea
        className="min-h-[200px]"
        onChange={onChange}
        value={note}
        placeholder="Type your note..."
        data-hj-allow
      />
      <FooterButtons>
        <ButtonWithIcon
          text="Cancel"
          onClick={() => {
            close();
          }}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          text="Save"
          onClick={(e: any) => {
            e.stopPropagation();
            saveNote();
          }}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </div>
  );
}
