import { ReactComponent as InformationSvg } from "assets/info-circle.svg";
import { CarePlanGoalStatuses, CarePlanGoalWithProgress, useGoalDelta } from "backend/resources/carePlan/carePlan";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";
import { useCarePlanStore } from "state/carePlan/carePlan";

export default function UpdateGoalPopup({
  suggestedUpdate,
  existingGoal,
  isOpen,
  close,
}: {
  suggestedUpdate: CarePlanGoalWithProgress | null,
  existingGoal: CarePlanGoalWithProgress | null,
  isOpen: boolean,
  close: () => void,
}) {
  const {
    currentCarePlanGoalsByAdlo,
    setCurrentCarePlanGoalsByAdlo: setCurrentCarePlanGoals
  } = useCarePlanStore();


  const currentCarePlanGoals = existingGoal ? currentCarePlanGoalsByAdlo[existingGoal.adlo_id] ?? [] : [];
  // Check if suggestedUpdate is really a 

  const { recsToAdd, recsToDelete, recsToKeep, isLoading } = useGoalDelta(existingGoal?.id, suggestedUpdate?.id)
  const noChanges = (!recsToAdd || recsToAdd.length === 0) && (!recsToDelete || recsToDelete.length === 0) && !isLoading;


  if (isLoading || !existingGoal || !suggestedUpdate) {
    return null
  }
  return <PopupOrSheet
    title={
      <div className="flex flex-col gap-1 w-full items-center md:items-start">
        <span className="text-lg">{!suggestedUpdate.is_archived ? "Suggested Update to" : "Restore"} {existingGoal.title}</span>
      </div>
    }
    onClose={() => { close() }}
    isOpen={isOpen}
    icon={
      noChanges ? <InformationSvg className="w-10 h-10" /> : null
    }
    footerButtons={
      noChanges ?
        <ButtonWithIcon
          onClick={() => {
            setCurrentCarePlanGoals(
              existingGoal.adlo_id,
              currentCarePlanGoals.map(goal =>
                goal.id === existingGoal.id ? { ...goal, status: CarePlanGoalStatuses.REPLACED } : goal
              ).concat({ ...suggestedUpdate, status: CarePlanGoalStatuses.INCLUDED })
            );
            close()
          }}
          className="text-left"
          text={"OK"}
          size={"small"}
          icon={IconOption.CHECKMARK}
        /> :
        <>
          <ButtonWithIcon
            size={"small"}
            onClick={close}
            text={"Cancel"}
            className="text-left"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={() => {
              setCurrentCarePlanGoals(
                existingGoal.adlo_id,
                [...currentCarePlanGoals, { ...suggestedUpdate, status: CarePlanGoalStatuses.INCLUDED }]
              )
              close()
            }}
            className="text-left"
            text={"Add Both"}
            size={"small"}
            icon={IconOption.PLUS}
          />
          <ButtonWithIcon
            size={"small"}
            className="text-left w-min sm:w-auto"
            onClick={() => {
              setCurrentCarePlanGoals(
                existingGoal.adlo_id,
                currentCarePlanGoals.map(goal =>
                  goal.id === existingGoal.id ? { ...goal, status: CarePlanGoalStatuses.REPLACED } : goal
                ).concat({ ...suggestedUpdate, status: CarePlanGoalStatuses.INCLUDED })
              );
              close()
            }}
            text={"Update Existing"}
            icon={IconOption.ARROW}
          />
        </>
    }
  >
    <div className="flex flex-col gap-5 py-5">
      {noChanges ?

        <p> This suggested goal is currently in your existing care plan, and is still valid for the updated care plan.</p> :
        <>
          {recsToAdd && recsToAdd.length > 0 && <div>
            <p className="font-bold"> New Recs Added </p>
            {recsToAdd?.map((rec) => <p> {rec.title}</p>)}
          </div>}

          {recsToKeep && recsToKeep.length > 0 && <div>
            <p className="font-bold"> Existing Recs Kept </p>
            {recsToKeep?.map((rec) => <p> {rec.title}</p>)}
          </div>}

          {recsToDelete && recsToDelete.length > 0 && <div>
            <p className="font-bold"> Recs Removed </p>
            {recsToDelete?.map((rec) => <p> {rec.title}</p>)}
          </div>}

          {existingGoal.timeline !== suggestedUpdate.timeline &&
            <div>
              <p className="font-bold">Timeframe Change</p>
              <p>{suggestedUpdate.timeline}</p>
            </div>
          }
        </>
      }
    </div>

  </PopupOrSheet>
}