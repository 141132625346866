import { ReactComponent as EmailIconSvg } from "assets/email-icon.svg";
import { ReactComponent as PhoneIconSvg } from "assets/phone-icon.svg";
import { ReactComponent as WebIconSvg } from "assets/web-icon.svg";
import { YellowPagesListing, useCreatePrivateConversationMutation } from "backend/functions";
import { useNetworksInOrganization } from "backend/resources/network/network";
import { useServiceResource } from "backend/resources/services/serviceResource";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { ProviderEmailForm } from "components/ChatGptSideBar";
import { Select } from "components/Select";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useState } from "react";
import { EmailForm } from "shared/forms/ServiceResourceEmailForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useNetworkStore } from "state/network/network";


export default function LocalResourceDetailView({
  listing,
  shouldHideListingName,
  serviceResourceEditingOptions
}: {
  listing: YellowPagesListing;
  shouldHideListingName?: boolean
  serviceResourceEditingOptions?: {
    showEditButton: boolean
    serviceResourceId: string | undefined
    showDMButton: boolean;
  }
}) {
  const { data: serviceProviderData } = useServiceResource(serviceResourceEditingOptions?.serviceResourceId);


  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [isEditingCarespace, setIsEditingCarespace] = useState(false)

  function formatPhoneNumber(phoneNumberString: string) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    // if not match, return what they had
    return phoneNumberString;
  }

  return (
    <div className="flex flex-col gap-4 pb-40">
      <CarespaceSelectionForExternalDMModal isOpen={isEditingCarespace} onClose={() => setIsEditingCarespace(false)} serviceProviderId={serviceResourceEditingOptions?.serviceResourceId} />
      <ResponsiveModal isOpen={isEditingEmail} title={"Edit Email"} closeText="Close" onClose={() => setIsEditingEmail(false)}>
        <EmailForm isEditing
          service_resource_id={serviceResourceEditingOptions?.serviceResourceId}
          onClose={() => setIsEditingEmail(false)} />
      </ResponsiveModal>
      {!shouldHideListingName && <h1 className="pt-4 text-2xl">{listing.name}</h1>}
      {/* general info */}
      <div className="flex gap-2 ">
        {listing.image_url ?
          <img
            src={listing.image_url}
            alt="Alfred Logo"
            className="object-cover w-[6rem] w-min-[6rem] h-[6rem] overflow-clip rounded-full"
          /> : null}
        {serviceResourceEditingOptions?.showEditButton &&
          <ButtonWithIcon
            onClick={() => setIsEditingEmail(true)}
            text="Edit"
            icon={IconOption.EDIT}
          />}
      </div>
      {listing.details ? (
        <div className="flex flex-col gap-1">
          <div className="text-xl">Details</div>
          <p>{listing.details}</p>
        </div>
      ) : null}

      {listing.categories ? (
        <div className="flex flex-col gap-1">
          <div className="text-xl">Categories</div>
          <p></p>
          {listing.categories.split(",").map((category, index) => (
            <span key={index}>{category} </span>
          ))}
        </div>
      ) : null}

      {/* address */}
      {listing.address ? (
        <div className="flex flex-col gap-1">
          <div className="text-xl">Address</div>
          <div className="flex flex-col">
            <p>{listing.address}</p>
            <p>
              {listing.city}, {listing.state || ""} {listing.zip || ""}
            </p>
          </div>
        </div>
      ) : null}

      {/* hours */}
      {listing.hours ? (
        <div className="flex flex-col gap-1">
          <div className="text-xl">Hours</div>
          {listing.hours.split(",").map((hour, index) => (
            <span key={index}>{hour} </span>
          ))}
        </div>
      ) : null}

      {/* phone */}
      <div className="flex items-center gap-3">
        <PhoneIconSvg className="w-8 h-8 shrink-0" />
        <a href={`tel:${listing.phone}`}>
          {formatPhoneNumber(listing.phone || "N/A")}
        </a>
      </div>


      {/* website */}
      <div className="flex items-center gap-3">
        <WebIconSvg className="w-8 h-8 shrink-0" />
        <a href={`${listing.website}`} target="_blank">{listing.website || "N/A"}</a>
      </div>
      {/* email */}
      <div className="flex items-center gap-3">
        <EmailIconSvg className="w-8 h-8 shrink-0" />
        <a >{listing.email || "N/A"}</a>
      </div>
      {serviceResourceEditingOptions?.showDMButton
        && serviceProviderData
        && (!serviceProviderData?.email ?
          <ProviderEmailForm resourceId={serviceProviderData?.id} /> :
          <ButtonWithIcon
            onClick={() => setIsEditingCarespace(true)}
            text="Send Private Message"
            size="large"
            icon={IconOption.MESSAGE}
          />)
      }
    </div>
  );
}


function CarespaceSelectionForExternalDMModal({ isOpen, onClose, serviceProviderId }: { isOpen: boolean, onClose: () => void, serviceProviderId: string | undefined }) {
  const navigate = useAppNavigate()
  const [selectedNetworkId, setSelectedNetworkId] = useState<string | undefined>(undefined)
  const { carespacesOptionsWithoutAll } = useNetworksInOrganization()
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  const createPrivateConversation = useCreatePrivateConversationMutation().mutateAsync;
  return (
    <ResponsiveModal
      isOpen={isOpen}
      title={"Select Carespace"}
      closeText="Close"
      onClose={onClose}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={async () => {
              if (!selectedNetworkId || !serviceProviderId) return;
              const extConversationId = await createPrivateConversation({ network_id: selectedNetworkId, service_provider_id: serviceProviderId })
              setActiveNetworkId(selectedNetworkId)
              navigate({ path: CarePilotRoute.CONVERSATION, params: { conversation_id: extConversationId } });
              onClose()
            }}
            text="Send Message"
            icon={IconOption.MESSAGE}
          />
        </>
      }>
      <div className="flex flex-col gap-4">
      <p>Select the carespace you want to discuss with this provider.</p>
        <Select
          placeHolder="Select Carespace"
          currentOption={carespacesOptionsWithoutAll.find((option) => option.value === selectedNetworkId)}
          options={carespacesOptionsWithoutAll}
          onChange={(carespaceId) => {
            setSelectedNetworkId(carespaceId)
          }}
        />
      </div>
    </ResponsiveModal>
  )
}

