import { useState } from "react";
import { useActiveOrg } from "backend/resources/orgRole";
import { EditOrganizationForm } from "shared/forms/EditOrganizationForm";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";


export const DEFAULT_HELPLINE_PHONE_NUMBER = "917-982-2873"

export default function GeneralOrganizationTab() {
  const { data: activeOrg } = useActiveOrg()
  return (
    <div className="flex flex-col gap-5">
      {/* Organization Name */}
      <div>
        <div className="flex gap-2">
          <p className="text-lg">Organization Name</p>
          <EditOrganizationButton />
        </div>
        <p className="text-sm">{activeOrg?.name} </p>
      </div>
      <div>
        <p className="text-lg">Address</p>
        <p className="text-sm">{activeOrg?.address} </p>
        <p className="text-sm">{activeOrg?.city}, {activeOrg?.state} {activeOrg?.zip}</p>
      </div>

      <div>
        <p className="text-lg">Helpline Phone Number</p>
        <p className="text-sm">{activeOrg?.helpline_phone_number ?? DEFAULT_HELPLINE_PHONE_NUMBER} </p>
      </div>
    </div>)
}


function EditOrganizationButton() {
  const [isEditOrganizationModalOpen, setIsEditOrganizationModalOpen] =
    useState(false);
  return <>
    {
      isEditOrganizationModalOpen ? (
        <EditOrganizationForm
          onClose={() => {
            setIsEditOrganizationModalOpen(false);
          }}
        />
      ) : null
    }
    <ButtonWithIcon
      onClick={() => setIsEditOrganizationModalOpen(true)}
      size={"small"}
      text={"Edit Organization"}
      icon={IconOption.EDIT}
    />
  </>

}