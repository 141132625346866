import { ReactComponent as CheckedIcon } from 'assets/checkbox_icon.svg';
import { ReactComponent as UncheckedIcon } from 'assets/checkbox_icon_unchecked.svg';
import { Checkbox, CheckboxProps } from 'components/Checkbox';

export interface DefaultCheckboxProps extends Omit<CheckboxProps, 'icon' | 'uncheckedIcon'> {}

import { forwardRef } from 'react';

export const DefaultCheckbox = forwardRef<HTMLDivElement, DefaultCheckboxProps>((props, ref) => {
  return (
    <div onClick={(e) => {    
      e.stopPropagation();
    }}>
      <Checkbox 
        ref={ref}
      icon={<CheckedIcon />}
      uncheckedIcon={<UncheckedIcon />}
      {...props}
    />
    </div>
  );
});
