import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "App";
import { supabase } from "clients/supabaseClient";
import { checkCareCentralAccess, checkIsSuperSuperUser } from "lib";
import { OrgMember } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";
import { fetchAllDataInChunks } from "utils";
import { QUERY_KEYS } from "backend/queryKeys";
import { useActiveNetwork } from "backend/resources/network/network";
import type { User } from "backend/resources/user";
import { UserAdlo } from "backend/resources/userAdlo";
import { NetworkRoleType, NetworkRoleTypeToLabel, OrgRoleType, OrgRoleTypeToLabel } from "backend/resources/userRole/types";

const TABLE = "organization_role";

export type Organization = Database["public"]["Tables"]["organization"]["Row"];
export type OrganizationRole =
  Database["public"]["Tables"]["organization_role"]["Row"];
export type OrganizationRoleInsert =
  Database["public"]["Tables"]["organization_role"]["Insert"];
export type OrganizationRoleUpdate =
  Database["public"]["Tables"]["organization_role"]["Update"];

export type OrgAndIdentityReturnType = OrganizationRole & {
  organization: Organization | null; // asserts 1-to-1 relationship in join type to avoid [] || {} || null union type, known supabase bug
  user: User;
};

export function getRoleLabel(role: any): string {
  if (Object.values(OrgRoleType).includes(role)) {
    return OrgRoleTypeToLabel[role as OrgRoleType];
  } else if (Object.values(NetworkRoleType).includes(role)) {
    return NetworkRoleTypeToLabel[role as NetworkRoleType];
  } else {
    return role;
  }
}




/**
 * Query functions
 */
async function fetchAllOrgAndIdentities() {
  const { data, error } = await supabase
    .from(TABLE)
    .select("*, organization(*), user(*)");

  if (error) {
    Sentry.captureException(error);
  }

  return data as OrgAndIdentityReturnType[];
}

async function fetchCraniometrixEmployees() {
  const { data, error } = await supabase
    .from("organization")
    .select("*, organization_role(user(*),role)")
    .eq("is_super_org", true)
    .limit(1)
    .maybeSingle();
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data?.organization_role.map((role) => {
    return {
      ...role.user,
      organization_id: data.id,
      org_role: OrgRoleType.CARE_NAVIGATOR,
      user_id: role.user?.id
    }
  }) as OrgMember[];
}

async function fetchOrgById(organization_id?: string | null) {
  if (!organization_id) return null;
  const { data, error } = await supabase
    .from("organization")
    .select("*")
    .eq("id", organization_id)
    .limit(1)
    .maybeSingle();

  // .eq("user_id", userId)
  // without below returns method, there's a bug in return type
  // regardless of unique constraints, returns `[] | {} | null` type
  // this has been fixed in postgrest but not supabase.
  // https://github.com/orgs/supabase/discussions/7610#discussioncomment-5515519
  // .returns<OrgAndIdentityReturnType[]>(); // asserts one-to-one relationship

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data;
}


type PrivateOrgType = { id: string, network: { id: string, user_adlo: UserAdlo[] | undefined }[] }
async function fetchAllPrivateOrganizations(): Promise<PrivateOrgType[]> {
  const queryBuilder = supabase
    .from("organization")
    .select("id, network(id, user_adlo(*))")
    .eq("is_family_organization", true)

  const allData = await fetchAllDataInChunks<PrivateOrgType>(queryBuilder);
  const filteredData = allData.filter(org => org.network?.[0]?.user_adlo?.[0]?.first_name);

  return filteredData;
}



async function fetchOrgRole(organization_id?: string, user_id?: string) {
  if (!organization_id || !user_id) return null;
  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("organization_id", organization_id)
    .eq("user_id", user_id)
    .limit(1)
    .maybeSingle();
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return data;
}

export async function fetchUserFromOrgRoleId(
  org_role_id: string | undefined | null,
  organization_id: string | undefined
) {
  if (!org_role_id || !organization_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, user(*)")
    .eq("id", org_role_id)
    .eq("organization_id", organization_id)
    .returns<(OrganizationRole & { user: User })[]>()
    .limit(1)
    .maybeSingle();

  return data;
}

/**
 * Query hooks
 */

export const useUserFromOrgRoleId = (org_role_id?: string | null) => {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.orgAndIdentities, { org_role_id, activeOrgId }],
    queryFn: () => fetchUserFromOrgRoleId(org_role_id, activeOrgId),
    refetchOnWindowFocus: false,
  });
};

export function usePrivateFamilyOrgs() {
  const authUser = useUserStore((state) => state.user);

  return useQuery({
    queryKey: [
      "privateOrgs", { authUser: authUser?.id },
    ],
    queryFn: () => fetchAllPrivateOrganizations(),
  });

}

export function useOrgs() {
  const authUser = useUserStore((state) => state.user);

  const { data: allOrgIdentities, isLoading: isAllOrgIdentitesLoading } =
    useQuery({
      queryKey: [
        QUERY_KEYS.orgAndIdentities,
        { authUser: authUser?.id, productAccessQuery: true },
      ],
      queryFn: () => fetchAllOrgAndIdentities(),
      refetchOnWindowFocus: false,
    });

  const isSuperSuperUser = checkIsSuperSuperUser(
    authUser?.id,
    allOrgIdentities
  );

  // We always let users see the super org... but here we don't want to show those.
  const ownOrgIdentities = isSuperSuperUser
    ? allOrgIdentities
    : allOrgIdentities?.filter((orgIdentity) => {
      return orgIdentity.organization?.is_super_org === false;
    });

  const hasCareCentralAccess = checkCareCentralAccess(ownOrgIdentities);

  const hasOneOrMoreOrgIdentities =
    ownOrgIdentities && ownOrgIdentities.length > 0;

  const { data: network } = useActiveNetwork();
  const firstOrganizationId = ownOrgIdentities?.[0]?.organization?.id ?? network?.organization_id

  // Get deduped ownOrgs
  const ownOrganizationsMap: Record<string, Organization> = {};
  if (ownOrgIdentities)
    for (const orgIdentity of ownOrgIdentities) {
      if (orgIdentity.organization) {
        ownOrganizationsMap[orgIdentity.organization_id] =
          orgIdentity.organization;
      }
    }
  const ownOrganizations = Object.values(ownOrganizationsMap);

  return {
    isLoading: isAllOrgIdentitesLoading,
    allOrgIdentities, // returns all orgs the member is part of, plus super orgs (RLS policy)
    ownOrgIdentities, // if not super user, filter out super orgs
    ownOrganizations, // own organizations (no)
    hasOneOrMoreOrgIdentities,
    firstOrganizationId,
    isSuperSuperUser,
    hasCareCentralAccess,
  };
}

export function useOrg(organizationId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.orgs, { organizationId }],
    queryFn: () => fetchOrgById(organizationId),
    refetchOnWindowFocus: false,
  });
}

export function useActiveOrg() {
  const activeOrgId = useActiveOrganizationId();

  return useQuery({
    queryKey: [QUERY_KEYS.activeOrg, { activeOrgId }],
    queryFn: () => fetchOrgById(activeOrgId),
    refetchOnWindowFocus: false,
  });
}

export function useActiveOrgRole() {
  const authUser = useUserStore((state) => state.user);
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [
      QUERY_KEYS.orgAndIdentities,
      { authUser: authUser?.id, activeOrgId },
    ],
    queryFn: () => fetchOrgRole(activeOrgId, authUser?.id),
    refetchOnWindowFocus: false,
  });
}

export function useCraniometrixEmployees() {
  return useQuery({
    queryKey: [QUERY_KEYS.orgs],
    queryFn: () => fetchCraniometrixEmployees(),
    refetchOnWindowFocus: false,
  });
}


export function useIsFamilyOrganization(orgId: string | undefined) {
  return useQuery({
    queryKey: [QUERY_KEYS.isFamilyOrganization, { orgId }],
    queryFn: () => fetchIsFamilyOrganization(orgId),
    refetchOnWindowFocus: false,
  });
}

async function fetchIsFamilyOrganization(organization_id: string | undefined) {
  if (!organization_id) return false
  const { data: organization, error } = await supabase
    .from("organization")
    .select("is_family_organization")
    .eq("id", organization_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }

  return organization?.is_family_organization ?? false;
}

/**
 * Mutation functions
 */

async function upsertOrgRole(insert?: OrganizationRoleInsert) {
  // handle null case
  if (!insert) throw new Error("No org role provided to upsertOrgRole");
  //
  const { data, error } = await supabase.from(TABLE).upsert([insert]).select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

async function updateOrgRole(orgRole: OrganizationRoleUpdate) { 
  if (!orgRole.id) return;
  const { data, error } = await supabase
    .from(TABLE)
    .update(orgRole)
    .eq("id", orgRole.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();
  if (error) {
    throw new Error(error.message);
  }

  return data;
}

/**
 * Mutation hooks
 */

export function useUpsertOrgRole() {
  const authUser = useUserStore((state) => state.user);

  return useMutation({
    mutationFn: ({
      orgRoleInsert,
    }: {
      orgRoleInsert?: OrganizationRoleInsert;
    }) => {
      return upsertOrgRole(orgRoleInsert);
    },
    // Always refetch after error or success:
    onSettled: (
      data,
      error,
      { orgRoleInsert }: { orgRoleInsert?: OrganizationRoleInsert }
    ) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
    },
  });
}

export function useUpdateOrgRole() {
  return useMutation({
    mutationFn: async ({
      roleId,
      newRole,
      isSuperUser,
      isDeactivated,
    }: {
      roleId: string;
      newRole?: OrgRoleType;
      isSuperUser?: boolean;
      isDeactivated?: boolean;
    }) => {
      const response = await updateOrgRole({
        id: roleId,
        role: newRole,
        is_deactivated: isDeactivated,
        is_superuser: isSuperUser,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.organizationInvitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.organizationInvitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMemberByInvitationId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    },
  });
}
