
import { usePlanEntryData } from "backend/resources/planEntry";
import { useEffect, useState } from "react";
import { ConversationType } from "backend/resources/chatGptConversation";
import { useOrgs } from "backend/resources/orgRole";
import { useServiceEngagementById } from "backend/resources/services/serviceEngagement";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";
import { DiscussionTypeToColor } from "components/DiscussionComponent/components/DiscussionsFilter";


export enum ParentType {
  SERVICE = "Service",
  SERVICE_REQUEST = "Service Request",
  GUIDE_TASK = "Guide Task"
}

export default function EntryParentLinkComponent({ parent_plan_entry_id, service_engagement_id, isClickable, }: { parent_plan_entry_id: string | null, service_engagement_id: string | null, isClickable?: boolean }) {
  const navigate = useAppNavigate()

  const [parentType, setParentType] = useState<ParentType | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const { setEntryId } = useTaskFilterStore();

  const { data: planEntryData } = usePlanEntryData(parent_plan_entry_id);
  const { data: serviceEngagementData } = useServiceEngagementById(service_engagement_id);
  const { hasCareCentralAccess } = useOrgs();

  useEffect(() => {
    if (planEntryData) {
      if (planEntryData.guide_task_id) {
        setParentType(ParentType.GUIDE_TASK);
        setTitle(planEntryData.name);
      } else if (planEntryData.is_service_ticket) {
        setParentType(ParentType.SERVICE_REQUEST);
        setTitle(planEntryData.name);
      }
    } else if (serviceEngagementData) {
      setParentType(ParentType.SERVICE);
      setTitle(serviceEngagementData.name);
    }
  }, [planEntryData, serviceEngagementData]);

  if (!parentType || !title) return null;

  const ParentTypeToColor: Record<ParentType, string> = {
    [ParentType.SERVICE]: DiscussionTypeToColor[ConversationType.Service],
    [ParentType.SERVICE_REQUEST]: DiscussionTypeToColor[ConversationType.Request],
    [ParentType.GUIDE_TASK]: DiscussionTypeToColor[ConversationType.General],
  };

  function handleClick() {
    if (!isClickable) return;
    switch (parentType) {
      case ParentType.SERVICE:
        navigate({ path: SharedRoute.SERVICE_PAGE, params: { id: service_engagement_id! } });
        break;
      case ParentType.GUIDE_TASK:
        setEntryId(parent_plan_entry_id)
        navigate({ path: SharedRoute.HOME });
        break;
      case ParentType.SERVICE_REQUEST:
        navigate({ path: SharedRoute.SERVICE_REQUEST_VIEW, params: { id: parent_plan_entry_id! } });
        break;
      default:
        break;
    }
  }
  if (!hasCareCentralAccess) {
    return null
  }
  return (
    <button className="grid grid-cols-2 gap-y-1 gap-x-5 text-start"
      type="button"
      style={{ cursor: isClickable ? "pointer" : "default" }}
      onClick={handleClick}>
      <p>Parent Type</p>
      <p>Parent Title</p>
      <p
        style={{ background: ParentTypeToColor[parentType] }}
        className={`rounded-full py-1 px-2 text-white w-min truncate text-sm`}>
        {parentType}
      </p>
      <p className="truncate">
        {title}
      </p>
    </button>
  );
}
