/**
 * @fileoverview Shared page header component, used as the main header for all page routes.
 */

interface Props {
  text: React.ReactNode;
  className?: string;
}

export function PageMainHeader({ text, className }: Props) {
  return (
    <h1
      className={` text-3xl truncate font-light w-full md:w-auto text-center md:text-left ${className}`}>
      <label> {text}</label>
    </h1>
  );
}
