import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, UseFormReturn } from "react-hook-form"
import "react-phone-number-input/style.css"
import * as z from "zod"
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRoot,
  FormSection,
  FormSectionHeader
} from "shared/ui/form"
import { Input } from "shared/ui/input"
import { MultiPageFormProps, PhoneNumberSchema } from "shared/forms/types"

/**
 * schema
 */
export const inviteCaregiverFormSchema = z.object({
  caregiverFirstName: z.string(),
  caregiverLastName: z.string(),
  caregiverEmail: z.string().email("Please enter a valid email address."),
  caregiverPhoneNumber: PhoneNumberSchema,
});

/**
 * form hook
 */
export const useInviteCaregiverForm = () => useForm<z.infer<typeof inviteCaregiverFormSchema>>({
  resolver: zodResolver(inviteCaregiverFormSchema),
  defaultValues: {},
  mode: "onSubmit",
});

export type InviteCaregiverFormHook = UseFormReturn<z.infer<typeof inviteCaregiverFormSchema>>

/**
 * form component
 */
export const inviteCaregiverFormId = "invite-caregiver-form"

export function InviteCaregiverForm(
  props: MultiPageFormProps<z.infer<typeof inviteCaregiverFormSchema>> & {step?: number}
) {
  const { form, step } = props

  return (
    <Form {...form}>
      <FormRoot
        id={inviteCaregiverFormId}
      >
        <FormSection>
          <FormSectionHeader title={
            <>
              <div>  {step && `Step ${step}:`} Invite a primary caregiver</div>
            </>
          } />
          <div className="grid grid-cols-[2fr,3fr] gap-2 p-1">
            <FormField
              control={form.control}
              name="caregiverFirstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="caregiverLastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-[3fr,2fr] gap-2">
            <FormField
              control={form.control}
              name="caregiverEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-[3fr,2fr] gap-2">
            <FormField
              control={form.control}
              name="caregiverPhoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Cell</FormLabel>
                  <FormControl>
                    <PhoneNumberFormInput
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </FormSection>
      </FormRoot>
    </Form>
  );
}
