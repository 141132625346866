import { useState } from "react";
import { useOrgs, usePrivateFamilyOrgs } from "backend/resources/orgRole";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useNetworkStore } from "state/network/network";
import { useOrganizationStore } from "state/organization/organization";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";

export default function OrganizationSwitcher({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) {
  const navigate = useAppNavigate();

  const { isLoading, ownOrganizations, isSuperSuperUser } = useOrgs();
  const { data: familyOrganizations } = usePrivateFamilyOrgs()

  const setActiveOrganizationId = useOrganizationStore(
    (state) => state.setActiveOrganizationId
  );

  const activeOrganizationId = useOrganizationStore(
    (state) => state.activeOrganizationId
  );

  const { setActiveNetworkId } = useNetworkStore();

  const regularOrganizations = ownOrganizations.filter(org => !org.is_family_organization);
  enum TabTypes {
    ORGS = 'Organizations',
    PRIVATE_CARESPACES = 'Private Carespaces'
  }

  const [currentTab, setCurrentTab] = useState<TabTypes>(TabTypes.ORGS);
  return (
    <ResponsiveModal
      footerButtons={<ButtonWithIcon onClick={() => close()} text={"Done"} icon={IconOption.CHECKMARK} />}
      title="Switch Organization"
      isOpen={isOpen}
      fixedHeight="100"
      closeText="Cancel"
      onClose={() => close()
      }>
      <div className="flex flex-col gap-4 pb-4 pl-5 overflow-y-scroll h-[50vh]">
        {isLoading ? (
          <LoadingSpinner className="w-5 h-5" />
        ) : (
          <>
            <div className="mt-3 flex gap-2 text-lg">
              <button
                onClick={() => setCurrentTab(TabTypes.ORGS)}
                style={{
                  color: currentTab === TabTypes.ORGS ? "#F8A01B" : "black",
                }}
              >
                {TabTypes.ORGS}
              </button>
              {isSuperSuperUser && (
                <button
                  onClick={() => setCurrentTab(TabTypes.PRIVATE_CARESPACES)}
                  style={{
                    color: currentTab === TabTypes.PRIVATE_CARESPACES ? "#F8A01B" : "black",
                  }}
                >
                  {TabTypes.PRIVATE_CARESPACES}
                </button>
              )}
            </div>
            <>
              {currentTab === TabTypes.ORGS ? (
                regularOrganizations.map((organization) => (
                  <ButtonWithIcon
                    key={organization.id}
                    onClick={() => {
                      setActiveOrganizationId(organization.id);
                      navigate({
                        path: SharedRoute.HOME
                      })
                    }}
                    disabled={organization?.id === activeOrganizationId}
                    text={organization?.name ?? ""}
                    icon={IconOption.SWAP}
                  />
                ))
              ) : (
                familyOrganizations?.map((organization) => (
                  <>
                    <ButtonWithIcon
                      key={organization.id}
                      onClick={() => {
                        setActiveOrganizationId(organization.id);
                        setActiveNetworkId(organization?.network?.[0]?.id)
                      }}
                      disabled={organization?.id === activeOrganizationId}
                      text={organization?.network?.[0]?.user_adlo?.[0]?.first_name + " " + organization?.network?.[0]?.user_adlo?.[0]?.last_name}
                      icon={IconOption.SWAP}
                    />
                  </>
                ))
              )}
            </>
          </>
        )}
      </div>
    </ResponsiveModal>
  );
}
