import { useEffect } from "react";
import {
  useLocation,
  useParams
} from "react-router-dom";
import { ReactComponent as MoreInfoIcon } from "assets/more info.svg";
import {
  ConversationType,
  useMutateChatGptConversations
} from "backend/resources/chatGptConversation";
import { useMutateChatGptMessages } from "backend/resources/chatGptMessage";
import { UserBookmarkType } from "backend/resources/userBookmark";
import { useAddConversationIdToUserIntervention, useIntervention } from "backend/resources/userIntervention";
import { useRecommendation } from "backend/resources/userRecommendation";
import { useUserIsFamilyMember } from "backend/resources/userRole";
import {
  CraniometrixProduct,
  useHasPlanBinderControl,
  useProductAccess,
} from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";
import { ChatMessageType, useGptStore } from "state/gpt";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user/user";
import { ActionBar } from "components/ActionBar";
import BackButton from "components/BackButton/BackButton";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { GoalRecSuggestionsBreadcrumbs } from "components/GoalPage/GoalRecSuggestionsBreadcrumbs";
import Tags from "components/LibraryPage/Bookmarks/Bookmark/Tags/Tags";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { Resource } from "components/GuidancePage/Recommendations/Recommendation/Interventions/Intervention/Resource";

export default function InterventionPage() {
  const navigate = useAppNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile } = useWindowSize();

  const showGoToRecommendationButton =
    queryParams.get("show-go-to-recommendation-button") === "true";

  const { id: interventionId } = useParams();

  const { intervention } = useIntervention(interventionId);

  useEffect(() => {
    setUserAssessmentChatGptInterventionId(interventionId);
  }, []);

  /**
   * Stores
   */
  const { userIsFamilyMember } = useUserIsFamilyMember();
  const { data: productAccess } = useProductAccess();
  const isInCareCentral = productAccess === CraniometrixProduct.CARE_CENTRAL;
  const authUser = useUserStore((state) => state.user);
  const setIsOpen = useGptStore((state) => state.setIsOpen);
  const setType = useGptStore((state) => state.setType);

  const setUserAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.setUserAssessmentChatGptRecommendationId
  );
  const setUserAssessmentChatGptInterventionId = useGptStore(
    (state) => state.setUserAssessmentChatGptInterventionId
  );
  const setUserAssessmentChatGptInterventionResourceId = useGptStore(
    (state) => state.setUserAssessmentChatGptInterventionResourceId
  );
  const setConversationId = useGptStore((state) => state.setConversationId);
  const streamingMessage = useGptStore((state) => state.streamingMessage);
  const setSendToGptRequest = useGptStore((state) => state.setSendToGptRequest);
  const networkId = useActiveNetworkId();
  /**
   * Queries
   */
  const { recommendation } = useRecommendation(
    intervention?.user_recommendation_id
  );
  const { data: hasCarePlanControl } = useHasPlanBinderControl();

  /**
   * Mutations
   */
  const createConversation = useMutateChatGptConversations().mutateAsync;
  const addConversationIdToUserIntervention =
    useAddConversationIdToUserIntervention().mutateAsync;
  const chatGptMessagesMutation = useMutateChatGptMessages();

  /**
   * Handlers
   */
  async function createTellMeMoreMessage(conversationId: string) {
    if (!recommendation || !intervention) return [];
    const messages = await chatGptMessagesMutation.mutateAsync({
      message: {
        chat_gpt_conversation_id: conversationId,
        role: "system",
        content: "Ok, here are some more examples:",
        type: "ai",
        send_to_chat_gpt: false,
        user_id: authUser?.id,
      },
      threadId: recommendation?.id,
    });
    return messages;
  }
  async function handleInterventionGpt() {
    if (!streamingMessage && intervention?.conversation_id) {
      setIsOpen(true);
      setUserAssessmentChatGptInterventionResourceId();
      setUserAssessmentChatGptRecommendationId(recommendation?.id);
      setUserAssessmentChatGptInterventionId(intervention?.id);
      await createTellMeMoreMessage(intervention.conversation_id);
      setSendToGptRequest({
        conversationId: intervention.conversation_id,
        text: `Intervention: ${intervention?.title}: ${intervention?.details}. \n From general recommendation: "${recommendation?.title}" `,
        message_type: ChatMessageType.ARE_YOU_SURE,
      });
    }
  }

  useEffect(() => {
    async function setupConversation() {
      setType("interventionConversationPage");
      setUserAssessmentChatGptInterventionId(intervention?.id);
      setConversationId(intervention?.conversation_id || "");
      if (intervention && !intervention.conversation_id) {
        const newConversation = await createConversation({ type: ConversationType.Alfred, networkId: networkId });
        setConversationId(newConversation?.id || "");
        addConversationIdToUserIntervention({ userInterventionId: intervention.id, conversationId: newConversation?.id })
      }
    }
    setupConversation()
  }, [intervention])

  if (!intervention || !intervention.id) {
    return null;
  }
  return (
    <PageContainer>
      <div className="flex gap-2 flex-col relative pb-40">
        <BackButton />
        <div className="flex flex-col gap-1 pt-2">
          {/* breadcrumbs */}
          <div className="flex flex-col gap-4 md:sticky -top-10 md:py-6 bg-white z-[1]">
            <div className="flex justify-between">
              <GoalRecSuggestionsBreadcrumbs />
              {/* Edit Button */}
              {hasCarePlanControl &&
                <ButtonWithIcon
                  onClick={() => {
                    if (interventionId) {
                      navigate({
                        path: CareCentralRoute.INTERVENTION_EDIT,
                        params: {
                          id: interventionId,
                          user_recommendation_id: intervention?.user_recommendation_id
                        }
                      })
                    }
                  }}
                  text={"Edit"}
                  size={"small"}
                  icon={IconOption.EDIT}
                />}
            </div>
          </div>
          <div className="flex  flex-col gap-4 md:sticky -top-10 md:py-6 bg-white z-[1]">
            <PageMainHeader text={intervention?.title} />
            {!isMobile && !isInCareCentral ? (
              <button
                className="flex gap-2"
                onClick={handleInterventionGpt}>
                <MoreInfoIcon className=" min-h-10 w-6 h-6 bg-white rounded-full cursor-pointer" />
                <p>More info</p>
              </button>
            ) : null}
            {!isInCareCentral ? (
              <ActionBar
                bookmarkableItem={{
                  itemId: intervention.id,
                  type: UserBookmarkType.INTERVENTION,
                }}
              />
            ) : null}
          </div>
        </div>

        <div className="flex flex-wrap gap-4 justify-center ">
          {intervention.details.split("\n").map((paragraph, index) => (
            <p
              key={index}
              className="font-poppins text-base font-extralight  pt-2">
              {paragraph}
            </p>
          ))}
        </div>
        <Tags
          bookmarkableItem={{
            itemId: intervention.id,
            type: UserBookmarkType.INTERVENTION,
          }}
          limit={isMobile ? 3 : 5}
        />
        <Resource interventionId={interventionId || ""} />
        {showGoToRecommendationButton && !userIsFamilyMember ? (
          <div className="w-full flex justify-center">
            <ButtonWithIcon
              onClick={() => {
                navigate({
                  path: CarePilotRoute.RECOMMENDATION,
                  params: { id: intervention.user_recommendation_id },
                })
              }}
              text={"Go to Recommendation"}
              icon={IconOption.ARROW}
            />
          </div>
        ) : null}
      </div>
      {!isInCareCentral ? (
        <BottomActionBar
          centerSlot={
            <MoreInfoIcon
              className="w-10 h-10 bg-white rounded-full cursor-pointer pointer-events-auto"
              onClick={handleInterventionGpt}
            />
          }
        />
      ) : null}
    </PageContainer>
  );
}
