import type { VariantProps } from "class-variance-authority";
import type { ShareableInterventionInsertType } from "backend/resources/shareableIntervention/shareableIntervention";
import type {
  AmazonMetadata,
  ShareableGuidanceResource,
  YouTubeMetadata,
} from "backend/resources/userGuidanceResource";
import type { UserAssessmentChatGptIntervention } from "backend/resources/userIntervention";
import type { cardStyles } from "components/Card/Card";
import { YouTubeButton } from "components/YouTubeButton";

export default function InterventionComponent({
  intervention,
  guidanceResource,
  ...variantProps
}: {
  intervention:
  | UserAssessmentChatGptIntervention
  | ShareableInterventionInsertType
  | undefined;
  guidanceResource: ShareableGuidanceResource | undefined | null;
} & VariantProps<typeof cardStyles>) {
  // const { userGuidanceResource } = useGuidanceResourceByUserInterventionId(
  //   intervention?.id
  // );
  const resource = getImageFromResource(guidanceResource);
  return (
    <div className="flex items-center gap-4 py-2 md:py-4  overflow-clip h-full">
      {guidanceResource && guidanceResource.type !== "news" && (
        <div className="flex w-full  h-full max-h-[100px] max-w-[300px] overflow-clip justify-center items-center">{resource}</div>
      )}
      {variantProps.size !== "thumbnail" || !resource ? (
        <div className="text-white h-full text-left flex flex-col justify-start gap-2 w-full   px-1 flex-grow ">
          <div className="w-full flex items-center flex-grow h-full">
            <p
              className={`${resource ? " line-clamp-[3] text-sm" : "text-base"} line-clamp-[2] ${variantProps.size === "thumbnail"
                ? "line-clamp-[3] text-center"
                : ""
                } w-full`}>
              {intervention?.title}
            </p>
          </div>
          {variantProps.size !== "thumbnail" && !resource ? (
            <div>
              <p className="text-sm font-extralight line-clamp-[2]">
                {intervention?.details.replace('"', "").replace('"', "")}
              </p>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

function getImageFromResource(
  guidanceResource: ShareableGuidanceResource | null | undefined
) {
  if (!guidanceResource) {
    return null;
  }

  if (guidanceResource?.type === "Amazon") {
    return (
      <img
        className="w-full md:w-auto h-auto max-h-full object-cover"
        src={
          (guidanceResource?.metadata as AmazonMetadata)?.amazonMetadata?.[0]
            ?.thumbnail
        }
        alt=""
      />
    );
  } else if (guidanceResource?.type === "YouTube") {
    return (
      <YouTubeButton
        className="w-[400px] h-full"
        url={`https://www.youtube.com/embed/${(guidanceResource?.metadata as YouTubeMetadata).youTubeMetadata?.id
          }`}
        onClick={() => { }}
      />
    );
  } else {
    return null;
  }
}
