import { CarespaceTableColors, CarespaceTableFields } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import { CarespaceTablePillCell } from "./CarespaceTableCellPill";
import { CarespaceTableTextCell } from "./CarespaceTableTextCell";

export const CARESPACE_TABLE_CELL_CONTENT_MAP = {
  [CarespaceTableFields.CARESPACE]: (value: string) => <CarespaceTableTextCell label={value} />,
  [CarespaceTableFields.DOB]: (value: string) => <CarespaceTableTextCell label={value} />,
  [CarespaceTableFields.SEX]: (value: string) => {
    const sexKey = value.trim() as keyof typeof CarespaceTableColors[CarespaceTableFields.SEX];
    const background = CarespaceTableColors[CarespaceTableFields.SEX][sexKey];
    return <CarespaceTablePillCell label={value} background={background}/>
  },
  [CarespaceTableFields.DYAD]: (value: string) => {
    const dyadKey = value.trim() as keyof typeof CarespaceTableColors[CarespaceTableFields.DYAD];
    const background = CarespaceTableColors[CarespaceTableFields.DYAD][dyadKey];
    return (
      <CarespaceTablePillCell label={value} background={background}/>
    );
  },
  [CarespaceTableFields.ORG]: (value: string) => (
    <CarespaceTablePillCell background={CarespaceTableColors[CarespaceTableFields.ORG]} label={value}/>
  ),
  [CarespaceTableFields.STATUS]: (value: string) => {
    const statusKey = value.trim() as keyof typeof CarespaceTableColors[CarespaceTableFields.STATUS];
    const background = CarespaceTableColors[CarespaceTableFields.STATUS][statusKey];
    return (
      <CarespaceTablePillCell label={value} background={background}/>
    );
  },
  [CarespaceTableFields.DOCTOR]: (value: string) => <CarespaceTableTextCell label={value}/>,
  [CarespaceTableFields.PCG]: (value: string) => <CarespaceTableTextCell label={value}/>,
  [CarespaceTableFields.CN]: (value: string) => <CarespaceTableTextCell label={value}/>,
  [CarespaceTableFields.PCP]: (value: string) => <CarespaceTableTextCell label={value}/>,
}