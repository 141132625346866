import { useWindowSize } from "hooks/useWindowSize";
import AlfredIcon from "components/Alfred/Alfred";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarEducationPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"educationPage"}
        pageType="educationPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50  text-sm px-4 py-4 rounded-md">
        {/* intro PP */}
        <p>
          This is the Education section, where we will be continually adding new
          videos and other content to help you with learning new and interesting
          interventions and other related care options.
        </p>
      </div>
    </div>
  );
}
