import OrganizationSwitcher from "components/Header/ProfileMenu/OrganizationSwitcher/OrganizationSwitcher";
import { ProfileImage } from "components/Profile/ProfileImage";
import {
  CraniometrixProduct,
  useProductAccess,
} from "hooks/product/product";
import { useClickOutside } from "hooks/useClickOutside";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useRef, useState } from "react";
import { resetAssessmentStore } from "state/assessment";
import { LogoutReasonType, useLogoutReasonStore } from "state/logout/logout";
import { useUserStore } from "state/user";

export function ProfileMenu() {
  const userMenuRef = useRef<HTMLDivElement>(null);
  const userMenuTriggerRef = useRef<HTMLButtonElement>(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isOrganizationSwitcherOpen, setIsOrganizationSwitcherOpen] =
    useState(false);
  const authUser = useUserStore((state) => state.user);
  const signOut = useUserStore((state) => state.signOut);
  const resetUserStore = useUserStore((state) => state.reset);
  const navigate = useAppNavigate();
  const { data: productAccess } = useProductAccess();
  useClickOutside({
    ref: userMenuRef,
    isEnabled: isUserMenuOpen,
    onClickOutside: () => setIsUserMenuOpen(false),
    excludedElementRefs: [userMenuTriggerRef],
  });

  const setLogoutReason = useLogoutReasonStore((state) => state.setLogoutReason)

  return (
    <div className="flex justify-end md:items-center w-full md:w-auto">
      {authUser && (
        <div className="relative flex justify-center">
          <button
            ref={userMenuTriggerRef}
            className="flex items-center rounded-lg"
            onClick={() =>
              setIsUserMenuOpen((prevIsUserMenuOpen) => !prevIsUserMenuOpen)
            }
            type="button">
            <ProfileImage
              size={34}
              userId={authUser.id}
            />
          </button>
          {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
            <OrganizationSwitcher
              isOpen={isOrganizationSwitcherOpen}
              close={() => setIsOrganizationSwitcherOpen(false)}
            />
          ) : null}
          {isUserMenuOpen && (
            <div
              ref={userMenuRef}
              className="z-10 absolute top-7 w-[200px] -right-3 rounded-lg mt-2 bg-white border border-faint-gray drop-shadow-md">
              <ul>
                <li className="p-2 border-b-[1px] border-faint-gray flex justify-center hover:bg-gray-100 rounded-t-lg">
                  <button
                    className="text-sm w-full"
                    onClick={() => {
                      setIsUserMenuOpen(false);
                      navigate({
                        path: SharedRoute.MY_ACCOUNT,
                        queryParams: {
                          tab: "Profile"
                        }
                      })
                    }}>
                    My Account
                  </button>
                </li>
                {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
                  <li className="p-2 border-b-[1px] border-faint-gray flex justify-center hover:bg-gray-100 rounded-t-lg">
                    <button
                      className="text-sm w-full"
                      onClick={() => {
                        setIsUserMenuOpen(false);
                        setIsOrganizationSwitcherOpen(true);
                      }}>
                      Switch Organizations
                    </button>
                  </li>
                ) : null}
                <li className="p-2 flex justify-center hover:bg-gray-100 rounded-b-lg">
                  <button
                    onClick={async () => {
                      // TODO: handle error
                      resetUserStore();
                      resetAssessmentStore();
                      setLogoutReason(LogoutReasonType.USER_INITIATED)
                      await signOut();
                    }}
                    className="text-sm w-full">
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
