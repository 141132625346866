import { useNetworksInOrganization } from "backend/resources/network/network"
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon"
import { CarespaceTable } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTable"
import { CarespaceTableFilters } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/CarespaceTableFilters"
import { PageContainer } from "components/PageContainer"
import { PageMainHeader } from "components/PageMainHeader"
import { useState } from "react"
import { CarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/CarespaceCreationWizard"
import { useCarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard"
import ActionButtons from "shared/ui/action-buttons"

export function CarespacesPage() {
  const reset = useCarespaceCreationWizard((state) => state.reset)
  const { networks, isLoading } = useNetworksInOrganization();

  const [isCreatePopoverOpen, setIsCreatePopoverOpen] =
    useState<boolean>(false);

  return (
    <PageContainer>
      <div className="flex items-center gap-5">
        <PageMainHeader text={"Carespaces"} />
      </div>
      <div className="flex justify-center md:justify-between gap-3 items-center py-3 max-w-[68rem] w-full">
        <ActionButtons>
          <ButtonWithIcon
            onClick={() => {
              reset()
              setIsCreatePopoverOpen(true)
            }}
            text={"Add Carespace"}
            icon={IconOption.PLUS}
          />
        </ActionButtons>
      </div>
      <CarespaceTableFilters 
        carespaces={networks}
        isLoading={isLoading}
      />
      <CarespaceTable
        carespaces={networks}
        isLoading={isLoading}
      />

      {/* Carespace Creation Popup */}
      {isCreatePopoverOpen &&
        <CarespaceCreationWizard
          onClose={() => {
            reset()
            setIsCreatePopoverOpen(false)
          }}
        />
      }
    </PageContainer>

  )
}