import { useNavigate } from "react-router-dom";

interface MobileMenuNavItemProps {
  isFirst?: boolean;
  href: string;
  label: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MobileMenuNavItem({
  isFirst,
  href,
  label,
  setIsOpen,
}: MobileMenuNavItemProps) {
  const navigate = useNavigate();
  return (
    <li
      className={`p-5 border-b-[1px] border-black flex justify-center hover:bg-gray-100`}>
      <button
        className="w-full"
        onClick={() => {
          setIsOpen(false);
          navigate(href);
        }}>
        {label}
      </button>
    </li>
  );
}
