import { TextField } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useUpdateQtyRemaining } from "backend/resources/userPrescription/userPrescription";
import type {
  DoseTakenInformation,
  UserPrescriptionDoseTakenInsert,
} from "backend/resources/userPrescriptionDoseTaken/userPrescriptionDoseTaken";
import {
  useDosesTakenQuery,
  useUpsertUserPrescriptionDosesTaken,
} from "backend/resources/userPrescriptionDoseTaken/userPrescriptionDoseTaken";
import type { ScheduledDoseInformation } from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { useScheduledDosesQuery } from "backend/resources/userPrescriptionScheduledDose/userPrescriptionScheduledDose";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { LoadingSpinner } from "components/LoadingSpinner";
import ModalView from "components/ModalView/ModalView";
import type { ScheduleSectionKey } from "components/NanasDay/ScheduleView";
import { TakenAtCell, sectionDetails } from "components/NanasDay/ScheduleView";
import { localTimeForPostgres } from "components/NanasDay/utils";
import dayjs from "dayjs";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, createPath, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useMedicationStore } from "state/medicationStore";
import { useUserStore } from "state/user";
import { capitalizeFirstLetter } from "utils";

type ModifiedRow = {
  ogScheduledDoseInfo: ScheduledDoseInformation | null;
  ogDoseTakenInfo?: DoseTakenInformation | null; // if the user is updating and not inserting
  modifiedDoseTaken?: number | null; // if they have modified the dose taken
  modifiedTakenAtTime?: string | null; // if they have modified the taken at time
};

type ModifiedRows = Record<string, ModifiedRow>;

export function EditScheduleView() {
  const authUser = useUserStore((state) => state.user);

  const selectedScheduleDate = useMedicationStore(
    (state) => state.selectedScheduleDate
  );

  const navigateBack = useNavigateBack();

  const { updateDose } = useUpdateQtyRemaining();

  // editing logic

  const [modifiedRows, setModifiedRows] = useState<ModifiedRows>({});

  const upsertUserPrescriptionDosesTaken =
    useUpsertUserPrescriptionDosesTaken().mutateAsync;

  async function handleSubmit() {
    // MEMO: supabase can't handle updates and inserts mixed in same bulk upsert operation
    // create an array of update objects from modifiedRows, and insert objects
    const insertArray: UserPrescriptionDoseTakenInsert[] = [];
    const updateArray: UserPrescriptionDoseTakenInsert[] = [];

    for (const id of Object.keys(modifiedRows)) {
      // grab properties from modified rows dict
      const {
        ogDoseTakenInfo,
        ogScheduledDoseInfo,
        modifiedDoseTaken,
        modifiedTakenAtTime,
      } = modifiedRows[id];
      // (1) calculate dose taken
      // initial value
      let doseTaken = ogScheduledDoseInfo?.as_needed
        ? 1
        : ogScheduledDoseInfo?.dose_count != null
          ? ogScheduledDoseInfo?.dose_count
          : 1;
      // if modified, use that value
      if (modifiedDoseTaken) {
        doseTaken = modifiedDoseTaken;
      }
      // else use previously recorded doseTaken value
      else if (ogDoseTakenInfo?.dose_taken) {
        doseTaken = ogDoseTakenInfo?.dose_taken;
      }
      // (2) calculate taken at time values
      let takenAt = new Date().toISOString();
      let takenAtLocal = localTimeForPostgres(dayjs(), selectedScheduleDate);
      let takenAtLocalDate = selectedScheduleDate;
      // if modified, use that value
      if (modifiedTakenAtTime) {
        takenAt = modifiedTakenAtTime;
        takenAtLocal = localTimeForPostgres(
          dayjs(modifiedTakenAtTime),
          selectedScheduleDate
        );
      }
      // else use previously recorded doseTaken value
      else if (
        ogDoseTakenInfo?.taken_at &&
        ogDoseTakenInfo?.taken_at_local &&
        ogDoseTakenInfo?.taken_at_local_date
      ) {
        takenAt = ogDoseTakenInfo.taken_at;
        takenAtLocal = ogDoseTakenInfo.taken_at_local;
        takenAtLocalDate = ogDoseTakenInfo.taken_at_local_date;
      }
      // (3) form insert object
      // if it's an update,
      if (ogDoseTakenInfo?.id !== null && ogDoseTakenInfo?.id !== undefined) {
        updateArray.push({
          user_id: authUser?.id,
          dose_taken: doseTaken,
          taken_at: takenAt,
          taken_at_local: takenAtLocal,
          taken_at_local_date: takenAtLocalDate,
          user_prescription_scheduled_dose_id:
            ogScheduledDoseInfo?.user_prescription_scheduled_dose_id,
          user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          // include id if this is an update
          id: ogDoseTakenInfo?.user_prescription_dose_taken_id || undefined,
        });
      }
      // else if insert, don't include id
      else {
        insertArray.push({
          user_id: authUser?.id,
          dose_taken: doseTaken,
          taken_at: takenAt,
          taken_at_local: takenAtLocal,
          taken_at_local_date: takenAtLocalDate,
          user_prescription_scheduled_dose_id:
            ogScheduledDoseInfo?.user_prescription_scheduled_dose_id,
          user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
      }
    }

    // Perform the bulk upsert
    try {
      const updateRes = await upsertUserPrescriptionDosesTaken(updateArray);
      const insertRes = await upsertUserPrescriptionDosesTaken(insertArray);
      const allRows = [...updateRes.data, ...insertRes.data];
      // if successful, update remaining qty in user prescription rows
      const updates = [];
      for (const updatedRow of allRows) {
        const { user_prescription_scheduled_dose_id, dose_taken } = updatedRow;

        const {
          ogScheduledDoseInfo: scheduledDoseInfo,
          ogDoseTakenInfo: doseTakenInfo,
        } = modifiedRows[user_prescription_scheduled_dose_id || ""];

        if (!scheduledDoseInfo) {
          continue;
        }
        // calculate the difference between the modified dose and the original dose
        const doseDelta = (dose_taken || 0) - (doseTakenInfo?.dose_taken || 0);
        // aggregate all updates to be made
        updates.push(updateDose(scheduledDoseInfo, doseDelta));
      }
      // parallelize updates
      const resArray = await Promise.allSettled(updates);
    } catch (error) {
      Sentry.captureException(error);
    }
    navigateBack()
  }

  return (
    <ModalView
      isOpen={true}
      title="Edit Schedule"
      closeText="Back"
      onClose={() => {
        navigateBack()
      }}>
      <div className="flex flex-col gap-6 px-6">
        <EditingScheduleSection
          modifiedRows={modifiedRows}
          setModifiedRows={setModifiedRows}
          sectionKey="morning"
        />
        <EditingScheduleSection
          modifiedRows={modifiedRows}
          setModifiedRows={setModifiedRows}
          sectionKey="afternoon"
        />
        <EditingScheduleSection
          modifiedRows={modifiedRows}
          setModifiedRows={setModifiedRows}
          sectionKey="evening"
        />
        <EditingScheduleSection
          modifiedRows={modifiedRows}
          setModifiedRows={setModifiedRows}
          sectionKey="bedtime"
        />
      </div>

      {/* buttons row */}
      <FooterButtons>
        <ButtonWithIcon
          onClick={() => {
            navigateBack()
          }}
          icon={IconOption.CANCEL}
          text="Cancel"
        />
        <ButtonWithIcon
          onClick={handleSubmit}
          icon={IconOption.CHECKMARK}
          text="Done"
        />
      </FooterButtons>
    </ModalView>
  );
}

function EditingScheduleSection({
  modifiedRows,
  setModifiedRows,
  sectionKey,
}: {
  modifiedRows: ModifiedRows;
  setModifiedRows: any;
  sectionKey: ScheduleSectionKey;
}) {
  const authUser = useUserStore((state) => state.user);

  const { isMobile } = useWindowSize();

  const {
    // scheduledDoses,
    isLoadingScheduledDoses,
    morningScheduledDoses,
    afternoonScheduledDoses,
    eveningScheduledDoses,
    bedtimeScheduledDoses,
    // as needed
    morningAsNeededScheduledDoses,
    afternoonAsNeededScheduledDoses,
    eveningAsNeededScheduledDoses,
    bedtimeAsNeededScheduledDoses,
  } = useScheduledDosesQuery(authUser?.id);

  function getSectionScheduledPrescriptions() {
    switch (sectionKey) {
      case "morning": {
        return morningScheduledDoses;
      }
      case "afternoon": {
        return afternoonScheduledDoses;
      }
      case "evening": {
        return eveningScheduledDoses;
      }
      case "bedtime": {
        return bedtimeScheduledDoses;
      }
    }
  }

  function getSectionAsNeededPrescriptions() {
    switch (sectionKey) {
      case "morning": {
        return morningAsNeededScheduledDoses;
      }
      case "afternoon": {
        return afternoonAsNeededScheduledDoses;
      }
      case "evening": {
        return eveningAsNeededScheduledDoses;
      }
      case "bedtime": {
        return bedtimeAsNeededScheduledDoses;
      }
    }
  }

  const prescriptionScheduledDoses = getSectionScheduledPrescriptions() || [];

  const asNeededPrescriptionScheduledDoses =
    getSectionAsNeededPrescriptions() || [];

  return (
    <div className="flex flex-col gap-4">
      {/* section header */}
      <div className="flex gap-3 items-center">
        {/* icon */}
        <div className="flex items-center justify-center w-8 h-8">
          {sectionDetails[sectionKey].icon}
        </div>
        {/* text */}
        <h2 className="font-light text-2xl">
          {sectionDetails[sectionKey].title}
        </h2>
      </div>
      {/* scheduled prescriptions section */}
      {isLoadingScheduledDoses ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <div
          className={`flex flex-col gap-4 ${!isMobile ? "pl-[2.80rem]" : ""}`}>
          {prescriptionScheduledDoses.length === 0 ? (
            <div className="text-gray-400">No scheduled prescriptions</div>
          ) : (
            <>
              {/* row header */}
              {isMobile ? (
                <EditScheduleDoseRowHeaderMobile />
              ) : (
                <EditScheduleDoseRowHeaderMobile />
              )}
              {/* rows */}
              <div className="flex flex-col gap-3">
                {prescriptionScheduledDoses.map((scheduledDoseInfo) => (
                  <EditScheduleDoseRow
                    modifiedRows={modifiedRows}
                    setModifiedRows={setModifiedRows}
                    scheduledDoseInfo={scheduledDoseInfo}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {/* as needed section */}
      {isLoadingScheduledDoses ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <>
          {asNeededPrescriptionScheduledDoses.length > 0 && (
            <div
              className={`flex flex-col gap-4 ${!isMobile ? "pl-[2.80rem]" : ""
                }`}>
              {/* header */}
              <div className="pl-1 bg-gray-300">As Needed</div>
              {/* rows */}
              <div className="flex flex-col gap-3">
                {asNeededPrescriptionScheduledDoses.map((scheduledDoseInfo) => (
                  <EditScheduleDoseRow
                    modifiedRows={modifiedRows}
                    setModifiedRows={setModifiedRows}
                    scheduledDoseInfo={scheduledDoseInfo}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function EditScheduleDoseRowHeaderMobile() {
  return (
    <>
      {/* row header */}
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr] items-end">
        {/* name */}
        <div className="text-sm font-medium">Name</div>
        {/* dosage */}
        <div className="text-sm font-medium text-center">Qty</div>
        {/* taken */}
        <div className="text-sm font-medium mx-auto text-center">Qty Taken</div>
        {/* when */}
        <div className="text-sm font-medium mx-auto">Time</div>
      </div>
    </>
  );
}

function EditScheduleDoseRow({
  modifiedRows,
  setModifiedRows,
  scheduledDoseInfo,
}: {
  modifiedRows: ModifiedRows;
  setModifiedRows: any;
  scheduledDoseInfo: ScheduledDoseInformation;
}) {
  const authUser = useUserStore((state) => state.user);

  const selectedScheduleDate = useMedicationStore(
    (state) => state.selectedScheduleDate
  );

  const { isLoadingDosesTaken, dosesTaken, refetchDosesTaken } =
    useDosesTakenQuery(authUser?.id, selectedScheduleDate);

  if (!scheduledDoseInfo.id) {
    return <div>Error retrieving medication</div>;
  }

  // pull out dose taken information
  const doseTakenInfo = dosesTaken?.find(
    (doseTaken) =>
      doseTaken.user_prescription_scheduled_dose_id ===
      scheduledDoseInfo.user_prescription_scheduled_dose_id
  );

  const doseCount = scheduledDoseInfo.dose_count
    ? scheduledDoseInfo.dose_count
    : scheduledDoseInfo.max_doses_per_day
      ? scheduledDoseInfo.max_doses_per_day
      : "0";

  // editing stat
  const [readyToEdit, setReadyToEdit] = useState(false);
  const [modifiedDoseTaken, setModifiedDoseTaken] = useState<number | null>();
  // keep parent component in sync
  useEffect(() => {
    if (
      scheduledDoseInfo?.user_prescription_scheduled_dose_id &&
      modifiedDoseTaken &&
      readyToEdit
    ) {
      setModifiedRows({
        ...modifiedRows, // making update to modifiedRows
        [scheduledDoseInfo.user_prescription_scheduled_dose_id]: {
          ...modifiedRows[
          scheduledDoseInfo.user_prescription_scheduled_dose_id
          ], // retain other properties
          ogDoseTakenInfo: doseTakenInfo,
          ogScheduledDoseInfo: scheduledDoseInfo,
          modifiedDoseTaken, // <--- field we're updating
        },
      });
    }
  }, [modifiedDoseTaken]);

  const [modifiedTakenAtTime, setModifiedTakenAtTime] =
    useState<dayjs.Dayjs | null>(dayjs(doseTakenInfo?.taken_at));
  // keep parent component in sync
  useEffect(() => {
    if (
      scheduledDoseInfo?.user_prescription_scheduled_dose_id &&
      modifiedTakenAtTime &&
      readyToEdit
    ) {
      setModifiedRows({
        ...modifiedRows, // making update to modifiedRows
        [scheduledDoseInfo.user_prescription_scheduled_dose_id]: {
          ...modifiedRows[
          scheduledDoseInfo.user_prescription_scheduled_dose_id
          ], // retain other properties
          ogDoseTakenInfo: doseTakenInfo,
          ogScheduledDoseInfo: scheduledDoseInfo,
          modifiedTakenAtTime: modifiedTakenAtTime.toISOString(), // <--- field we're updating
        },
      });
    }
  }, [modifiedTakenAtTime]);

  // keep doses in sync with selected schedule date
  useEffect(() => {
    refetchDosesTaken();
  }, [selectedScheduleDate]);

  // ensure default modified dose taken is set when dose taken info loads
  useEffect(() => {
    if (doseTakenInfo) {
      setModifiedDoseTaken(doseTakenInfo.dose_taken);
      setModifiedTakenAtTime(dayjs(doseTakenInfo?.taken_at));
    }
    setReadyToEdit(true); // don't want initial sync to update this
  }, [doseTakenInfo]);

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr] items-center min-h-[40px]">
        {/* name */}
        <a
          href={
            createPath({
              path: CarePilotRoute.DAY_PRESCRIPTION,
              params: {
                dispensable_drug_id: scheduledDoseInfo.dispensable_drug_id || ""
              }
            })
          }
          className="flex flex-grow items-center py-2 gap-2 border-zinc-100 relative cursor-pointer hover:underline">
          {capitalizeFirstLetter(scheduledDoseInfo.drug_name_desc || "")}
        </a>
        {/* dosage */}
        <div className="flex items-center justify-center">{doseCount}</div>
        {/* taken? */}
        <div className="flex items-center justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <DoseTakenCell
              isEditing={true}
              doseTaken={doseTakenInfo?.dose_taken}
              doseFormDesc={scheduledDoseInfo.dose_form_desc || ""}
              modifiedDoseTaken={modifiedDoseTaken}
              setModifiedDoseTaken={setModifiedDoseTaken}
            />
          )}
        </div>
        {/* taken at */}
        <div className="flex justify-center">
          {isLoadingDosesTaken ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <TakenAtCell
              textSize="text-xs"
              isEditing={true}
              takenAt={doseTakenInfo?.taken_at_local}
              modifiedTakenAtTime={modifiedTakenAtTime}
              setModifiedTakenAtTime={setModifiedTakenAtTime}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function DoseTakenCell({
  isEditing,
  doseTaken,
  doseFormDesc,
  modifiedDoseTaken,
  setModifiedDoseTaken,
}: {
  isEditing: boolean;
  doseTaken?: number | null;
  doseFormDesc: string;
  modifiedDoseTaken?: number | null;
  setModifiedDoseTaken: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >;
}) {
  if (isEditing) {
    return (
      <TextField
        size="small"
        className="max-w-[8ch] text-sm"
        value={modifiedDoseTaken?.toString() || "0"}
        type="number"
        onKeyDown={(e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          const value = Number(event.target.value);
          if (value > 100) {
            setModifiedDoseTaken(100);
          } else if (value < 0) {
            setModifiedDoseTaken(0);
          } else {
            setModifiedDoseTaken(value);
          }
        }}
      />
    );
  }
  if (doseTaken === undefined) {
    return <div className="flex justify-center">N/A</div>;
  } else if (doseTaken !== undefined && !isEditing) {
    return (
      <div className="flex relative h-full">
        {`${doseTaken} ${doseFormDesc}(s)`}
      </div>
    );
  } else {
    return (
      <TextField
        size="small"
        className="max-w-[8ch] text-sm"
        value={modifiedDoseTaken?.toString() || "0"}
        type="number"
        onKeyDown={(e) => {
          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
        onChange={(event) => {
          const value = Number(event.target.value);
          if (value > 100) {
            setModifiedDoseTaken(100);
          } else if (value < 0) {
            setModifiedDoseTaken(0);
          } else {
            setModifiedDoseTaken(value);
          }
        }}
      />
    );
  }
}
