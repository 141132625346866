import { clinicianSectionText } from "components/CarespacePage/PAFSubmission/constants";
import { ClinicianAttestation } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { RadioButtonsGroup } from "components/RadioGroup";
import { FC } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { z } from "zod";

interface ClinicianInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const ClinicianInputs: FC<ClinicianInputsProps<z.infer<typeof PAFSchema>>>  = ({ form, isReadOnly }) => {
  return (
    <>
      <div>{clinicianSectionText}</div>
      {/* Clinician Attestation */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="Clinician Attestation"
        fieldName="clinician_attestation"
        children={(field) => (
          <RadioButtonsGroup 
            row={false}
            id="clinician_attestation"
            options={getOptionsFromEnum(ClinicianAttestation)}
            {...field}
          />
        )}
      />
      {/* Clinician First Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="First Name"
        fieldName="clinician_first_name"
        children={(field) => (
          <Input className="max-w-[300px]" {...field} />
        )}
      />
      {/* Clinician Middle Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Middle Name"
        fieldName="clinician_middle_name"
        children={(field) => (
          <Input className="max-w-[300px]" {...field} />
        )}
      />
      {/* Clinician Last Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Last Name"
        fieldName="clinician_last_name"
        children={(field) => (
          <Input className="max-w-[300px]" {...field} />
        )}
      />
      {/* Clinician National Provider Identification Number */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="National Provider Identification Number"
        fieldName="npi"
        children={(field) => (
          <Input className="max-w-[300px]" {...field} />
        )}
      />
    </>
  )
}