import type { PlanEntryWithBookmarks } from "backend/resources/planEntry";
import { usePlanEntries, useUpdatePlanEntryBookmarks } from "backend/resources/planEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import FooterButtons from "components/FooterButtons/FooterButtons";
import {
  PlanEntryFilterButtonPopover,
  PlanEntrySortButtonPopover,
} from "components/MyPlanPage";
import { Popup } from "components/Popup";
import { BottomSheet } from "components/Sheet";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import { useActionBarStore } from "state/actionBar";
import { useMyPlanStore } from "state/myPlan";

interface Props {
  bookmarkId: string;
  close: () => void;
}

export function AddToEntryPopup({ close, bookmarkId }: Props) {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return (
      <BottomSheet
        isOpen={true}
        onClose={close}>
        <AddToEntryPopupComponent
          close={close}
          bookmarkId={bookmarkId}
        />
      </BottomSheet>
    );
  } else {
    return (
      <Popup close={close}>
        <AddToEntryPopupComponent
          close={close}
          bookmarkId={bookmarkId}
        />
      </Popup>
    );
  }
}

function AddToEntryPopupComponent({ bookmarkId, close }: Props) {
  const navigate = useAppNavigate();

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const { refetch } = usePlanEntries()
  const setNewEntryBookmarkId = useMyPlanStore(
    (state) => state.setNewEntryBookmarkId
  );

  const setIsDisabled = useActionBarStore((state) => state.setIsDisabled);

  const updatePlanEntryBookmarks = useUpdatePlanEntryBookmarks().mutateAsync;

  async function onSubmit() {
    await updatePlanEntryBookmarks({ planEntryIds: selectedEntries, bookmarkId });
    refetch()
    close();
  }

  function onCreateNew() {
    setNewEntryBookmarkId(bookmarkId);
    navigate({
      path: CarePilotRoute.PLAN_NEW
    })
  }

  useEffect(() => {
    return () => {
      setIsDisabled(false);
    };
  }, []);

  return (
    <div className="mx-10 md:mx-0 pb-24 md:pb-0 flex flex-col gap-3 w-[80vw] md:h-[80vh]">
      <h1 className="text-2xl">Add to Tasks</h1>

      <EntryPopupTopActionBar />
      <div className="flex-grow overflow-y-scroll">
        <SimpleListViewAllEntries
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
          bookmarkId={bookmarkId}
        />
      </div>
      <EntryPopupBottomActionBar
        submit={onSubmit}
        onCreateNew={onCreateNew}
      />
    </div>
  );
}
/**
 * Components
 */
function SimpleListViewAllEntries({
  selectedEntries,
  setSelectedEntries,
  bookmarkId,
}: {
  selectedEntries: string[];
  bookmarkId: string;

  setSelectedEntries: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const { sortedAndFilteredPlanEntries, isLoading: isLoadingSortedAndFilteredPlanEntries } = usePlanEntries();
  useEffect(() => {
    if (!isLoadingSortedAndFilteredPlanEntries && (!selectedEntries || selectedEntries.length === 0)) {
      setSelectedEntries(
        sortedAndFilteredPlanEntries
          .filter((planEntry) => planEntry.user_bookmark_ids.includes(bookmarkId))
          .map((planEntry) => planEntry.id)
      );
    }
  }, [sortedAndFilteredPlanEntries]);

  return (
    <div className="flex flex-col">
      {sortedAndFilteredPlanEntries.map((planEntry) => (
        <SimpleListEntryRow
          entry={planEntry}
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
        />
      ))}
    </div>
  );
}

function SimpleListEntryRow({
  entry,
  selectedEntries,
  setSelectedEntries,
}: {
  entry: PlanEntryWithBookmarks;
  selectedEntries: string[];
  setSelectedEntries: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const isChecked = selectedEntries.includes(entry.id);
  function handleOnChange() {
    if (isChecked) {
      setSelectedEntries([...selectedEntries.filter((id) => id !== entry.id)]);
    } else {
      setSelectedEntries([...selectedEntries, entry.id]);
    }
  }

  return (
    <div className={`grid group items-center grid-cols-[auto,3fr]`}>
      {/* checkbox */}
      <div className={`flex items-center h-full -b border-zinc-100 pl-3 py-2`}>
        <DefaultCheckbox
          checked={isChecked}
          onChange={handleOnChange}
        />
      </div>
      {/* name */}
      <div
        onClick={handleOnChange}
        className="flex h-full flex-grow items-center gap-2 border-zinc-100  relative pl-3 cursor-pointer">
        {entry.name}
      </div>
    </div>
  );
}

function EntryPopupTopActionBar() {
  return (
    <div className="flex items-center justify-end font-light gap-3 -mt-6">
      <PlanEntryFilterButtonPopover />
      <PlanEntrySortButtonPopover />
    </div>
  );
}

function EntryPopupBottomActionBar({
  onCreateNew,
  submit,
}: {
  onCreateNew: () => void;
  submit: () => void;
}) {
  return (
    <FooterButtons>
      <ButtonWithIcon
        size="small"
        onClick={() => onCreateNew()}
        icon={IconOption.PLUS}
        text="Create new"
      />
      <ButtonWithIcon
        size="small"
        onClick={() => submit()}
        icon={IconOption.CHECKMARK}
        text="Done"
      />
    </FooterButtons>
  );
}
