import { zodResolver } from "@hookform/resolvers/zod"
import { useCraniometrixEmployees } from "backend/resources/orgRole"
import { OrgRoleType, OrgRoleTypeToLabel } from "backend/resources/userRole/types"
import { DefaultCheckbox } from "components/Checkbox"
import { useState } from "react"
import { UseFormReturn, useForm } from "react-hook-form"
import { OrgMemberCombobox } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete"
import { PhoneNumberSchema } from "shared/forms/types"
import {
  Form,
  FormRoot,
  FormSection,
  FormSectionHeader
} from "shared/ui/form"
import { useActiveOrganizationId } from "state/organization/organization"
import * as z from "zod"

/**
 * schema
 */

export const orgMemberInnerSchema = z.object({
  organization_id: z.string(),
  org_role: z.string(),
  user_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  cell_number: PhoneNumberSchema,
  email: z.string(),
})

export const orgMemberSchema = z.object({
  orgMember: orgMemberInnerSchema
})



/**
 * hook
 */
export const useAddOrgMemberForm = () => useForm<z.infer<typeof orgMemberSchema>>({
  resolver: zodResolver(orgMemberSchema),
  defaultValues: {},
  mode: "onSubmit",
});


export type AddOrgMemberFormHook = UseFormReturn<z.infer<typeof orgMemberSchema>>

/**
 * form
 */
export const addOrgMemberFormId = "add-org-member-form"

export function AddOrgMemberNavigatorForm({ form, roleType, step }: { form: UseFormReturn<z.infer<typeof orgMemberSchema>>, roleType: OrgRoleType | undefined, step?: number }
) {
  const activeOrganizationId = useActiveOrganizationId()
  const [isUsingCraniometrixEmployee, setIsUsingCraniometrixEmployee] = useState(false)

  const { data: craniometrixEmployees, isLoading: isLoadingCraniometrixEmployees } = useCraniometrixEmployees()


  return (
    <Form {...form}>
      <FormRoot id={`${addOrgMemberFormId}-${roleType}`}>
        {/* org member details */}
        <FormSection>
          <FormSectionHeader title={
            <>
              <div className="flex flex-col">
                {step && `Step ${step}:`} Add a {roleType ? OrgRoleTypeToLabel[roleType] : "Org Member"}
              </div>
            </>
          }
          />
          <div className="flex flex-col items-center md:items-start gap-4">
            {/*  member name */}
            <div className="grid grid-cols-[2fr,3fr] items-center gap-3 w-full">
              <p>Member Name</p>
              {activeOrganizationId ? (
                <OrgMemberCombobox
                  key={roleType} // This ensures the component re-renders when roleType changes
                  form={form}
                  orgId={activeOrganizationId}
                  role_type={roleType}
                  disabled={isUsingCraniometrixEmployee}
                />
              ) : (
                null
              )}
            </div>
            {roleType === OrgRoleType.CARE_NAVIGATOR && craniometrixEmployees?.[0] &&
              <div className="flex gap-2 w-full truncate items-center">
                <DefaultCheckbox
                  checked={isUsingCraniometrixEmployee}
                  onChange={(isChecked: boolean) => {
                    form.setValue("orgMember", craniometrixEmployees?.[0])
                    setIsUsingCraniometrixEmployee(isChecked)

                  }}
                />
                <p className="text-sm">Use a Craniometrix Care Navigator</p>
              </div>}
          </div>
          {/* instructions text */}
          <p className="w-full font-light text-sm">
            Note: if the member is not found, please add them to the
            organization and then you can add them to the Carespace.
          </p>
        </FormSection>
      </FormRoot>
    </Form>
  )
}
