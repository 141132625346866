import type { ComponentProps } from "react";
import { useEffect, useRef, useState } from "react";
import { track } from "analytics/track";
import { LazyIframe } from "components/LazyIframe";

const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL;

type LazyIframeProps = ComponentProps<typeof LazyIframe>;

type Props = {
  slug?: string;
  onEnded?: () => void;
  autoPlay?: boolean;
  disabled?: boolean;
} & LazyIframeProps;

export function YouTubeVideo({
  url,
  slug,
  autoPlay,
  onEnded,
  ...props
}: Props) {
  const playerRef = useRef<HTMLIFrameElement>(null);
  const src = `${url}?enablejsapi=1&origin=${REDIRECT_URL}&modestbranding=1${
    autoPlay ? "&mute=1&autoplay=1" : ""
  }`;
  const [player, setPlayer] = useState<YT.Player>();

  function onPlayerStateChange(event: YT.OnStateChangeEvent) {
    switch (event.data) {
      case YT.PlayerState.PLAYING: {
        if (slug) {
          track({
            type: "Video Event",
            properties: { action: "play", slug },
          });
        }

        break;
      }
      case YT.PlayerState.ENDED: {
        if (slug) {
          track({
            type: "Video Event",
            properties: { action: "ended", slug },
          });
        }

        onEnded?.();
        break;
      }
      case YT.PlayerState.PAUSED: {
        if (slug) {
          track({
            type: "Video Event",
            properties: { action: "pause", slug },
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  function initializeYouTubePlayer() {
    if (playerRef.current) {
      const player = new YT.Player(playerRef.current, {
        playerVars: {
          playsinline: 1,
          autoplay: 1,
        },
        events: {
          onStateChange: onPlayerStateChange,
        },
      });
      setPlayer(player);
    }
  }

  useEffect(() => {
    return onYouTubeReady(() => {
      initializeYouTubePlayer();
    });
  }, [onEnded]);

  return (
    <LazyIframe
      ref={playerRef}
      url={src}
      {...props}></LazyIframe>
  );
}
