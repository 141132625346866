import { useState } from "react";
import { useNetworksInOrganization } from "backend/resources/network/network";
import { useOrganizationInvitationQuery } from "backend/resources/organizationInvitations/organizationInvitation";
import { AddToOrganizationForm } from "shared/forms/AddToOrganizationForm";
import { CarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/CarespaceCreationWizard";
import { useCarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard";
import ActionButtons from "shared/ui/action-buttons";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { UserDetailView } from "components/CarespacePage/OrgMemberDetailPage";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import Tabs from "components/Tabs/Tabs";
import CarespacesTab from "components/OrganizationPage/tabs/CarespacesTab";
import GeneralOrganizationTab from "components/OrganizationPage/tabs/GeneralOrganizationTab";
import MembersTab from "components/OrganizationPage/tabs/MembersTab";

enum TABS {
  GENERAL = "General",
  CARESPACES = "Carespaces",
  MEMBERS = "Members",
}

export default function OrganizationPage() {

  const { organizationInvitations, isLoadingOrganizationInvitations } =
    useOrganizationInvitationQuery();

  const { networks } = useNetworksInOrganization();

  const [currentTab, setCurrentTab] = useState<TABS>(TABS.GENERAL);

  const [isAddToOrganizationModalOpen, setIsAddToOrganizationModalOpen] =
    useState(false);


  const [isAddCarespaceViewOpen, setIsAddCarespaceViewOpen] = useState(false);

  const resetCarespaceForm = useCarespaceCreationWizard(
    (state) => state.reset
  );

  const [focusedUserId, setFocusedUserId] = useState<string | null>(
    null
  );

  if (isLoadingOrganizationInvitations) {
    return <LoadingSpinner className="w-7 h-7" />
  }

  return (
    <PageContainer>
      <div className="flex flex-col gap-3 min-w-2xl max-w-2xl w-2xl">
        {/* Organization Selector */}
        <div className="flex justify-center md:justify-start w-full gap-5">
          <PageMainHeader text={"Admin"} />
        </div>

        {/* Top row */}
        <div className="flex justify-between mb-3">
          {/* Tabs */}
          <Tabs
            tabs={Object.values(TABS)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {/* Add button */}
          <ActionButtons >
            {currentTab === TABS.MEMBERS && (
              <ButtonWithIcon
                onClick={() => setIsAddToOrganizationModalOpen(true)}
                className="whitespace-nowrap"
                text="Add Member"
                size="small"
                icon={IconOption.PLUS}
              />
            )}
            {currentTab === TABS.CARESPACES && (
              <ButtonWithIcon
                onClick={() => setIsAddCarespaceViewOpen(true)}
                className="whitespace-nowrap"
                text="Add Carespace"
                size="small"
                icon={IconOption.PLUS}
              />
            )}
          </ActionButtons>
        </div>

        {/* General Tab */}
        {currentTab === TABS.GENERAL && <GeneralOrganizationTab />}

        {/* Members Tab */}
        {currentTab === TABS.MEMBERS &&
          !isLoadingOrganizationInvitations &&
          organizationInvitations ? (
          <MembersTab setFocusedOrgMemberId={setFocusedUserId} />
        ) : null}

        {/* Carespaces Tab */}
        {currentTab === TABS.CARESPACES && networks ? <CarespacesTab /> : null}

      </div>



      {isAddToOrganizationModalOpen ? (
        <AddToOrganizationForm
          onClose={() => {
            setIsAddToOrganizationModalOpen(false);
          }}
        />
      ) : null}
      {isAddCarespaceViewOpen ? (
        <CarespaceCreationWizard
          onClose={() => {
            setIsAddCarespaceViewOpen(false);
            resetCarespaceForm()
          }}
        />
      ) : null}
      {focusedUserId ? (
        <UserDetailView
          userId={focusedUserId}
          setUserId={setFocusedUserId}
        />
      ) : null}
    </PageContainer>
  );
}