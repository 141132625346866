import { useEffect, useState } from "react";
import { ReactComponent as ModalInfo } from "assets/modal-info.svg";
import { NotificationType, sendNotification } from "backend/functions";
import { useUserAdlos } from "backend/resources/userAdlo";
import { useShowWelcomeMessage } from "backend/resources/userOnboarding";
import { useAppOnboardingStore } from "state/appOnboarding";
import { useUserStore } from "state/user";

import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";
import { resetAssessmentStore } from "state/assessment";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import SMSNotificationsApprovalModal from "components/SMSNotificationsApprovalModal/SMSNotificationsApprovalModal";
import { CarePilotWelcomeMessage } from "components/CarePilotOnboarding/components/CarePilotWelcomeMessage";

export default function CarePilotOnboarding() {
  const authUser = useUserStore((state) => state.user);

  const { shouldShowWelcomeMessage } = useShowWelcomeMessage(authUser?.id);

  async function sendCarePilotWelcomeEmail() {
    if (authUser) {
      await sendNotification(authUser.id, NotificationType.WELCOME)
    }
  }
  const { data: userAdlos, isLoading: isLoadingUserAdlos } = useUserAdlos();

  const [
    isAssessmentCanceBottomSheetOpen,
    setIsAssessmentCanceBottomSheetOpen,
  ] = useState(false);

  const currentCarePilotOnboardingPage = useAppOnboardingStore(
    (state) => state.currentCarePilotOnboardingPage
  );

  const setCurrentCarePilotOnboardingPage = useAppOnboardingStore(
    (state) => state.setCurrentCarePilotOnboardingPage
  );

  // We need this effect in case the user leaves during onboarding and returns later
  useEffect(() => {
    if (!authUser || currentCarePilotOnboardingPage) return
    const hasNoUserAdlo = !isLoadingUserAdlos && (!userAdlos || userAdlos.length === 0)
    if (shouldShowWelcomeMessage) {
      setCurrentCarePilotOnboardingPage("welcome");
    }
  }, [authUser, shouldShowWelcomeMessage, userAdlos, isLoadingUserAdlos]);

  return (
    <>
      {/* Content */}
      {!isAssessmentCanceBottomSheetOpen && <CarePilotOnboardingSwitch />}

      {/* Are You Sure You Will Quit */}
      <AreYouSureYouWillQuitMessage
        isAssessmentCanceBottomSheetOpen={isAssessmentCanceBottomSheetOpen}
        setIsAssessmentCanceBottomSheetOpen={setIsAssessmentCanceBottomSheetOpen}
      />
    </>
  );

  function CarePilotOnboardingSwitch() {
    switch (currentCarePilotOnboardingPage) {
      case "welcome": {
        return (
          <CarePilotWelcomeMessage
            onClose={() => {
              sendCarePilotWelcomeEmail();
              setCurrentCarePilotOnboardingPage("sms approval");
            }}
          />
        );
      }
      case "sms approval": {
        return <SMSNotificationsApprovalModal
          onClose={() => setCurrentCarePilotOnboardingPage("completed")}
        />
      }
      default: {
        return null;
      }
    }
  }
}


function AreYouSureYouWillQuitMessage({
  isAssessmentCanceBottomSheetOpen,
  setIsAssessmentCanceBottomSheetOpen
}: {
  isAssessmentCanceBottomSheetOpen: boolean;
  setIsAssessmentCanceBottomSheetOpen: (open: boolean) => void;
}) {
  const resetUserStore = useUserStore((state) => state.reset);
  const signOut = useUserStore((state) => state.signOut)

  async function handleLogout(e: any) {
    e.stopPropagation();
    resetUserStore();
    resetAssessmentStore();
    await signOut();
  }

  return (
    <PopupOrSheet
      isOpen={isAssessmentCanceBottomSheetOpen}
      onClose={() => setIsAssessmentCanceBottomSheetOpen(false)}
      icon={<ModalInfo />}
      footerButtons={
        <>
          <ButtonWithIcon
            size={"small"}
            onClick={handleLogout}
            text={"Logout"}
            icon={IconOption.LOGOUT}
          />
          <ButtonWithIcon
            size={"small"}
            onClick={() => {
              setIsAssessmentCanceBottomSheetOpen(false);
            }}
            text={"Take Assessment"}
            icon={IconOption.ARROW}
          />
        </>
      }
    >
      <p className="text-sm md:text-lg md:max-w-[50ch] text-center h-full">
        We need a brief assessment of your loved one’s Alzheimer’s symptoms in order to set up your carespace. Please take the assessment, or logout and come back later to complete it.
      </p>
    </PopupOrSheet>
  );
}