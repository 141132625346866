
import { useCreateGuideEvent, useGuideEvent, useGuideEventByPlanEntryId, useUpdateGuideEvent } from "backend/resources/guide/guideCall";
import { usePlanEntriesInGuideEvent, useUpsertPlanEntryToGuideEvent } from "backend/resources/guide/guideEventToPlanEntry";
import { PlanEntry, PlanEntryUpdate, TaskStatus, TaskStatusLabel, TaskWithGuideInfo, useAllGuidePlanEntriesForCall, usePlanEntryData, usePlanEntryWithGuideTask, useUpdatePlanEntry } from "backend/resources/planEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { CanonicalGuideTaskFields, CanonicalGuideTaskType, GuideTaskSelectorPopup } from "components/GuideTaskSelectorPopup/GuideTaskSelectorPopup";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Select } from "components/Select";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields, TaskTableType } from "components/Tables/TaskTable/TaskTableTypes";
import { FilterConfig, TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { useEffect, useState } from "react";
import ActionButtons from "shared/ui/action-buttons";
import { useNetworkStore } from "state/network/network";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";
import { useUserStore } from "state/user";

export default function GUIDETasksCompletedInCall({ plan_entry_id }: { plan_entry_id: string | undefined }) {
  // Queries
  const { data: entryData, isLoading: isLoadingEntryData } = usePlanEntryWithGuideTask(plan_entry_id)
  const { data: guideEvent, isLoading: isLoadingGuideEvent } = useGuideEventByPlanEntryId(plan_entry_id);
  const call_id = guideEvent?.id;
  const { data: guideCall, isLoading: isGuideCallLoading } = useGuideEvent(call_id)

  const { data: parentPlanEntry } = usePlanEntryData(guideCall?.perform_event_plan_entry_id)
  const { data: guideTasksInGuideEvent, isLoading: isGuideTasksInGuideEventLoading } = usePlanEntriesInGuideEvent(call_id)
  const { data: allAvailableGuideTasks, isLoading: isLoadingAllAvailableGuideTasks } = useAllGuidePlanEntriesForCall(guideCall?.network_id);
  const { status, hideDone, setHideDone, setStatus } = useTaskFilterStore();

  // Mutations
  const updateGuideEvent = useUpdateGuideEvent().mutateAsync;
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;
  const upsertPlanEntryToGuideEvent = useUpsertPlanEntryToGuideEvent().mutateAsync;
  const createGuideEvent = useCreateGuideEvent().mutateAsync;

  // State/Store
  const [note, setNote] = useState<string>();
  const [localAllAvailableGuideTasks, setLocalAllAvailableGuideTasks] = useState(allAvailableGuideTasks);
  const [updatedEntriesInCall, setUpdatedEntriesInCall] = useState<TaskWithGuideInfo[]>([]);
  const setActiveNetworkId = useNetworkStore((state) => state.setActiveNetworkId);

  const authUser = useUserStore((state) => state.user);
  const networkId = guideCall?.network_id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState<boolean>(false);

  // ////////
  // Effects
  // //////

  useEffect(() => {
    setHideDone(false)
  }, [])

  useEffect(() => {
    if (guideCall) {
      if (guideCall.notes) {
        setNote(guideCall.notes);
      }
      setActiveNetworkId(guideCall.network_id)
    }
  }, [guideCall]);

  useEffect(() => {
    if (!guideEvent && isLoadingGuideEvent && entryData && entryData.network_id) {
      createGuideEvent({
        network_id: entryData.network_id,
        perform_event_plan_entry_id: entryData.id,
        user_id: entryData.user_id,
        status: TaskStatus.NotStarted, // or appropriate status
        title: "Default Title", // or appropriate title
        type: "Default Type" // or appropriate type
      })
    }
  }, [guideCall])

  useEffect(() => {
    const planEntriesInGuideEventIds = new Set(guideTasksInGuideEvent?.map(row => row.id));
    const _newEntries = allAvailableGuideTasks?.filter(row => {
      return row.network_id === guideCall?.network_id &&
        (isEditing || planEntriesInGuideEventIds.has(row[TaskTableFields.Id]))
        && parentPlanEntry?.parent_plan_entry_id !== row.id; // hide the guide task for calls
    });
    setLocalAllAvailableGuideTasks(_newEntries);
  }, [isEditing, guideTasksInGuideEvent, guideCall, parentPlanEntry]);

  // ////////
  // Functions
  // //////

  const filterConfig: FilterConfig = {
    [TaskFilterTypes.STATUS]: true,
    [TaskFilterTypes.HIDE_DONE]: true,
    customAdditionalFilter: (row: TaskWithGuideInfo) => {
      const planEntriesInGuideEventIds = new Set(guideTasksInGuideEvent?.map(row => row.id));
      if (isEditing) {
        // we check if it's the same as the call's network id
        // we could also do this by setting the network_id filter, but then other pages will get affected too.
        return row.network_id === guideCall?.network_id;
      } else {
        return planEntriesInGuideEventIds.has(row[TaskTableFields.Id]);
      }
    }
  };


  const sortRowsByDateAndCategory = (a: TaskTableType, b: TaskTableType) => {
    // Primary sort is Date, Category is secondary
    if (new Date(a.Due).getTime() !== new Date(b.Due).getTime()) {
      return new Date(a.Due) < new Date(b.Due) ? -1 : 1;
    } else {
      return a.Category < b.Category ? -1 : (a.Category > b.Category ? 1 : 0);
    }
  };


  // This function is used to update a plan entry locally. It takes a planEntryUpdate object as a parameter.
  // The function first checks if the plan entry already exists in the local state. If it does, it updates the entry with the new data from planEntryUpdate.
  // If the plan entry does not exist in the local state, it creates a new entry using the guide_task object from an entry with the same guide_task in allPlanEntries.
  // The new or updated entry is then added to the updatedEntriesInCall state.
  async function updatePlanEntryLocally(planEntryUpdate: PlanEntryUpdate) {
    setLocalAllAvailableGuideTasks(prevEntries => {
      let updated = false;
      const updatedEntries = prevEntries?.map(entry => {
        if (entry.id === planEntryUpdate.id) {
          updated = true;
          setUpdatedEntriesInCall([...updatedEntriesInCall, { ...entry, ...planEntryUpdate }])
          return { ...entry, ...planEntryUpdate };
        }
        return entry;
      });

      if (!updated) {
        // create a PlanEntryWithGuideTask object by using the guide_task object 
        // in an entry with the same guide_task allPlanEntries
        // TODO: investigate if this still needed
        const matchingRow = allAvailableGuideTasks?.find(row => row.guide_task_id === planEntryUpdate.guide_task_id);
        if (matchingRow) {
          // append the new entry to updatedEntriesInCall
          setUpdatedEntriesInCall(prevEntries => {
            const matchingRow = allAvailableGuideTasks?.find(row => row.guide_task_id === planEntryUpdate.guide_task_id);
            if (matchingRow) {
              return [...prevEntries, { ...matchingRow, ...planEntryUpdate }];
            }
            return prevEntries;
          });
          updatedEntries?.push({ ...matchingRow, ...planEntryUpdate });
        }
      }
      return updatedEntries;
    });
    return planEntryUpdate as PlanEntry;
  }

  function handleTasksSelectorSubmit(data: CanonicalGuideTaskType[]) {
    for (const row of data) {
      if (row[CanonicalGuideTaskFields.IsSelected] && call_id) {
        updatePlanEntryLocally({
          name: row[CanonicalGuideTaskFields.Task],
          guide_task_id: row[CanonicalGuideTaskFields.ID],
          network_id: networkId,
          user_id: authUser?.id,
          status: TaskStatus.Done,
          recurring_interval: row[CanonicalGuideTaskFields.RecurringInterval],
          scheduled_date_time: new Date(Date.now()).toISOString(),
        })
      }
    }
  }

  if (!localAllAvailableGuideTasks || isLoadingAllAvailableGuideTasks || isGuideTasksInGuideEventLoading || isGuideCallLoading) {
    return <div className="flex justify-center items-center h-screen">
      <LoadingSpinner className="w-32" />
    </div>
  }
  return <div className="flex flex-col gap-4 pb-20 max-w-4xl">
    <GuideTaskSelectorPopup isOpen={isAddNewModalOpen} onClose={() => setIsAddNewModalOpen(false)} handleSubmit={handleTasksSelectorSubmit} networkId={networkId} />
    {/* Tags */}
    {/* Header/Title/Timestamp */}


    <div className="flex gap-5 w-full items-center">
      <p className="text-xl w-[160px]">{"GUIDE Tasks"}</p>
      <div className="flex gap-5 w-full items-center flex-wrap">
        {!isEditing && <ButtonWithIcon
          onClick={() => setIsEditing(true)}
          text={"Add Tasks"}
          icon={IconOption.PLUS}
          size="small"
        />}

        {/* Buttons */}
        {isEditing ? <div className="flex gap-5">
          {isLoading && <LoadingSpinner className="w-5" />}
          <ActionButtons>
            <ButtonWithIcon
              onClick={() => setIsEditing(false)}
              icon={IconOption.CANCEL}
              text="Cancel"
              size="small"
            />
            <ButtonWithIcon
              text="Save"
              size="small"
              onClick={async (e: any) => {
                e.stopPropagation();
                if (guideCall) {
                  setIsLoading(true)
                  Promise.all(updatedEntriesInCall?.map(entry => updatePlanEntry(entry)) ?? [])
                    .catch(error => console.error("Error updating entries: ", error));
                  await updateGuideEvent({ ...guideCall, completed_at: new Date().toISOString(), status: TaskStatus.Done, notes: note ?? null });
                  await Promise.all(updatedEntriesInCall?.map((entry) => {
                    if (entry.status === TaskStatus.Done && call_id)
                      upsertPlanEntryToGuideEvent({
                        guide_event_id: call_id,
                        plan_entry_id: entry.id
                      })
                  }) ?? []
                  )
                }
                setIsEditing(false)
              }}
              icon={IconOption.CHECKMARK}
            />
          </ActionButtons>
        </div> : null}
      </div>

    </div>
    <div className='flex gap-2 items-center'>
      <Select
        currentOption={status ? { value: status, label: status } : { value: "All", label: "All" }}
        options={[
          { value: "All", label: "All" },
          ...Object.values(TaskStatus).map((status) => ({ label: TaskStatusLabel[status], value: status })),
          { value: "Overdue", label: "Overdue" }
        ]}
        onChange={(status) => setStatus(status)}
        classNames='w-min'
      />
      {status === "All" &&
        <div className="flex gap-2 items-center">
          <DefaultCheckbox checked={hideDone} onChange={(isChecked: boolean) => setHideDone(isChecked)} />
          <p className="text-sm">Hide Done</p>
        </div>
      }
      {/* TODO: Add New button will be the whole flow */}
      {/* {isEditing ?
        <ButtonWithIcon
          size={"small"}
          onClick={() => setIsAddNewModalOpen(true)}
          text={"Add New"}
          icon={IconOption.PLUS} />
        : null
      } */}
    </div>
    {/* Table */}
    {localAllAvailableGuideTasks?.length > 0 ?
      <TaskTable
        updateTask={updatePlanEntryLocally}
        data={localAllAvailableGuideTasks}
        sortFunction={sortRowsByDateAndCategory}
        filterConfig={filterConfig}
        hiddenColumns={[TaskTableFields.Carespace, TaskTableFields.CreatedAt]}
        handleClick={!isEditing ? () => { } : undefined}
        isLoading={isGuideCallLoading || isGuideTasksInGuideEventLoading}
      /> : <p className="text-sm text-zinc-400">No tasks selected. Click Add above to add tasks for this call.</p>
    }
  </div>
}