import { Pill } from "components/Pill";
import { FC } from "react";


interface CarespaceTablePillCellProps {
  label: string;
  background: string;
}

export const CarespaceTablePillCell: FC<CarespaceTablePillCellProps> = ({label, background}) => {
  return (
    <div className="flex w-[125px]">
      <Pill label={label} background={background}/>
    </div>
  )}
