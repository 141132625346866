import { useQuery } from "@tanstack/react-query";
import { Database } from "../../../../types/supabase";
import { supabase } from "clients/supabaseClient";
import { QUERY_KEYS } from "backend/queryKeys";

export type GuideCategory = Database["public"]["Tables"]["guide_category"]["Row"];
const TABLE = "guide_category";

export function useGuideCategory(guideCategoryName?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.guideCategory, guideCategoryName],
    queryFn: async () => {
      if (!guideCategoryName) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, guide_sub_category(*), guide_task(*)")
        .eq("title", guideCategoryName)
        .maybeSingle();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });
}
