import { useState } from "react";
import { useActiveUserAdlo } from "backend/resources/userAdlo";
import { useAdloDiagnosisNote, useUpsertAdloNote } from "backend/resources/userNote";
import {  useActiveUserRole } from "backend/resources/userRole";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { TextArea } from "components/TextArea";
import { NetworkRoleType } from "backend/resources/userRole/types";

export default function DiagnosisTab() {
  // QUERIES
  const { data: diagnosisNote } = useAdloDiagnosisNote();
  const { userRole } = useActiveUserRole();
  const userAdlo = useActiveUserAdlo();
  // STATE
  const [isEditing, setIsEditing] = useState(false);
  const [note, setNote] = useState(diagnosisNote?.note ?? "")
  // MUTATIONS
  const upsertAdloDiagnosisNote = useUpsertAdloNote().mutateAsync

  function saveDiagnosis() {
    upsertAdloDiagnosisNote({ ...diagnosisNote, note, is_diagnosis: true, user_adlo_id: userAdlo?.id, adlo_note_updated_at: new Date().toISOString() })
    setIsEditing(false)
  }
  // RENDER
  return <div className="flex flex-col gap-2 w-full">
    <div className="flex justify-between items-center">
    {userRole?.role === NetworkRoleType.DOCTOR && !isEditing &&
      <ButtonWithIcon onClick={() => setIsEditing(true)}
        text={diagnosisNote ? "Edit Diagnosis" : "Create Diagnosis"}
        icon={diagnosisNote ? IconOption.EDIT : IconOption.PLUS} />
    }
    {diagnosisNote?.adlo_note_updated_at && !isEditing &&
      <p className="font-bold text-end">Last Modified {new Date(diagnosisNote?.adlo_note_updated_at).toLocaleString()}</p>
    }
    </div>
    {isEditing && <div>
      <TextArea className="w-full h-[20rem]" value={note} onChange={(e) => setNote(e.target.value)} />
      <div className="flex gap-2 mt-4">
        <ButtonWithIcon onClick={() => setIsEditing(false)} text="Cancel" icon={IconOption.CANCEL} />
        <ButtonWithIcon onClick={saveDiagnosis} text="Save" icon={IconOption.CHECKMARK} />
      </div>
    </div>}
    {!isEditing && <div>
      {diagnosisNote ? <p>{diagnosisNote?.note?.split('\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>
      })}</p> : <p>No diagnosis has been created yet by the doctor.</p>}
    </div>}
  </div>;
}
