import { useNetworksInOrganization } from "backend/resources/network/network";
import { useOrgs } from "backend/resources/orgRole";
import { ServiceEngagementWithServiceResourceAndNetwork } from "backend/resources/services/serviceEngagement";
import { ServiceResourceWithServiceEngagements, useServiceResources } from "backend/resources/services/serviceResource";
import { DefaultCheckbox } from "components/Checkbox";
import { Select } from "components/Select";
import { ServiceFilterTypes, useServiceFilterStore } from "state/serviceFilters/serviceFilters";



export interface ServiceFilterConfig<T extends ServiceEngagementWithServiceResourceAndNetwork | ServiceResourceWithServiceEngagements> {
  [ServiceFilterTypes.ORG]?: boolean;
  [ServiceFilterTypes.CARESPACE]?: boolean;
  [ServiceFilterTypes.PROVIDER]?: boolean;
  [ServiceFilterTypes.SERVICE_TYPE]?: boolean;
  [ServiceFilterTypes.HIDE_INACTIVE]?: boolean;
  customAdditionalFilter?: (row: T) => boolean;
}

interface ServiceFiltersProps<T extends ServiceEngagementWithServiceResourceAndNetwork | ServiceResourceWithServiceEngagements> {
  filterConfig: ServiceFilterConfig<T>;
}

export function ServiceFilters<T extends ServiceEngagementWithServiceResourceAndNetwork | ServiceResourceWithServiceEngagements>({ filterConfig }: ServiceFiltersProps<T>) {
  const { org, carespace, provider, serviceType, hideInactive, setOrg, setCarespace, setProvider, setServiceType, setHideInactive } = useServiceFilterStore();
  const { networks } = useNetworksInOrganization();
  const { ownOrgIdentities } = useOrgs();


  const { data: serviceResources } = useServiceResources();

  const serviceTypeOptions = [
    { value: "All", label: "All" },
    ...(Array.from(new Set(serviceResources?.flatMap((serviceResource) => {
      return serviceResource.categories?.split(',').filter(category => category.trim() !== '').map(category => category) || []
    }) ?? [])).map(category => ({
      label: category || 'Default',
      value: category,
    })))
  ]

  const providerOptions = [
    { value: "All", label: "All" },
    ...(serviceResources?.map((serviceResource) => ({
      label: serviceResource.name || 'Default',
      value: serviceResource.id,
    })) ?? [])
  ];


  const orgOptions = [
    { value: "All", label: "All" },
    ...(Array.from(new Set(ownOrgIdentities?.map((org) => org.organization_id) ?? [])).map((orgId) => {
      const org = ownOrgIdentities?.find((org) => org.organization_id === orgId);
      return {
        label: org?.organization?.name ?? "N/A",
        value: org?.organization_id,
      };
    }))
  ];

  return <div className="grid grid-cols-[.5fr,2fr,.5fr,2fr] whitespace-nowrap items-center overflow-auto text-sm h-min   w-[1000px] w-max-[1000px] gap-x-10 gap-y-3 mt-5 pt-1">

    {filterConfig[ServiceFilterTypes.ORG] && (
      <>
        <p>{ServiceFilterTypes.ORG}</p>
        <Select
          currentOption={org ? { value: org, label: org } : { value: undefined, label: "All" }}
          options={orgOptions}
          onChange={(orgId) => setOrg(orgId)}
          classNames="  w-[280px] max-w-[280px]"
        />
      </>
    )}
    {filterConfig[ServiceFilterTypes.CARESPACE] &&
      <>
        <p>{ServiceFilterTypes.CARESPACE}</p>
        <Select
          currentOption={carespace ? { value: carespace, label: carespace } : { value: undefined, label: "All" }}
          options={[{ value: "All", label: "All" }, ...(networks?.map((network) => ({ label: network.name || 'Default', value: network.id })) ?? [])]}
          onChange={(carespace) => setCarespace(carespace)}
          classNames="  w-[280px] max-w-[280px]"
        />
      </>}
    {filterConfig[ServiceFilterTypes.PROVIDER] && (
      <>
        <p>{ServiceFilterTypes.PROVIDER}</p>
        <Select
          currentOption={provider ? { value: provider, label: provider } : { value: undefined, label: "All" }}
          options={providerOptions}
          onChange={(provider) => setProvider(provider)}
          classNames="  w-[280px] max-w-[280px]"
        />
      </>
    )}
    {filterConfig[ServiceFilterTypes.SERVICE_TYPE] &&
      <>
        <p>{ServiceFilterTypes.SERVICE_TYPE}</p>
        <Select
          currentOption={serviceType ? { label: serviceType, value: serviceType, } : { value: undefined, label: "All" }}
          options={serviceTypeOptions}
          onChange={(serviceType) => setServiceType(serviceType)}
          classNames="  w-[280px] max-w-[280px]"
        />
      </>
    }
    {filterConfig[ServiceFilterTypes.HIDE_INACTIVE] && (
      <div className="flex gap-2 items-center">
        <p className="whitespace-nowrap line-clamp-1">{ServiceFilterTypes.HIDE_INACTIVE}</p>
        <DefaultCheckbox
          checked={hideInactive}
          onChange={(isChecked) => setHideInactive(isChecked)}
        />
      </div>
    )}
  </div >
}
