import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useInsertUserIntervention, useIntervention, useUpdateUserIntervention } from "backend/resources/userIntervention";
import { useAppNavigate, useNavigateBack } from "lib/routing";
import ActionButtons from "shared/ui/action-buttons";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import ModalInMobileView from "components/ModalView/ModalInMobileView";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";

export default function EditInterventionPage() {
  const navigateBack = useNavigateBack();
  const { id: interventionId, user_recommendation_id } = useParams();
  const navigate = useAppNavigate();

  // states

  const [title, setTitle] = useState<string | undefined>(undefined);
  const [details, setDetails] = useState<string | undefined>(undefined);

  // Queries
  const { intervention } = useIntervention(interventionId);

  // Mutations
  const updateUserIntervention = useUpdateUserIntervention().mutateAsync;
  const createUserIntervention = useInsertUserIntervention().mutateAsync;

  useEffect(() => {
    setTitle(intervention?.title);
    setDetails(intervention?.details);
  }, [intervention]);

  return <ModalInMobileView title="Edit Suggestion" onClose={() => { }}>
    <div className="flex flex-col gap-4 font-light pb-20">
      <div className="flex w-full justify-end gap-5 -mt-10">
        <ActionButtons>
          <>
            <ButtonWithIcon
              text={"Cancel"}
              size={"small"}
              className="flex  my-4"
              icon={IconOption.CANCEL}
              onClick={navigateBack}
            />
            <ButtonWithIcon
              text={"Save"}
              size={"small"}
              className="flex  my-4"
              icon={IconOption.CHECKMARK}
              onClick={async () => {
                if (!title || !details) {
                  return;
                }
                if (interventionId) {
                  await
                    updateUserIntervention({
                    userInterventionId: interventionId,
                    update: {
                      title: title,
                      details: details,
                    }
                  });
                } else if (user_recommendation_id) {
                  await createUserIntervention({
                    title: title,
                    details: details,
                    user_recommendation_id,
                  });
                }

                navigateBack();
              }}
            />
          </>
        </ActionButtons>
      </div>
      <label>Title</label>
      <TextInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      {/* Details */}
      <label>Details</label>
      <TextArea
        className="h-[20rem]"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
      />


    </div>
  </ModalInMobileView>
}