import { zodResolver } from "@hookform/resolvers/zod";
import { UseFormReturn, useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { z } from "zod";
import { useUpsertServiceResource } from "backend/resources/services/serviceResource";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { YellowPagesListingSchema } from "shared/forms/types";
import { CustomFormField, Form, FormRoot } from "shared/ui/form";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { yellowPageCategoryOptions } from "components/ResourcesPage/components/categories";
import { Select } from "components/Select";
import { ServicesHubTabs } from "components/Services Hub/ServicesHubPage";
import { TextInput } from "components/TextInput";

export const ExtendedYellowPagesListingSchema = YellowPagesListingSchema.extend({
  name: z.string(),
  phone: z.string(),
  email: z.string(),
  categories: z.string(),
});


export default function AddNewProviderManually() {
  const navigateBack = useNavigateBack();
  const navigate = useAppNavigate();

  // Mutations
  const upsertServiceResource = useUpsertServiceResource().mutateAsync;

  // Form
  const form = useForm<z.infer<typeof ExtendedYellowPagesListingSchema>>({
    resolver: zodResolver(ExtendedYellowPagesListingSchema),
    mode: "onSubmit",
    defaultValues: {
      listing_id: uuidv4()
    }
  });

  // Functions
  const handleSave = async (validatedForm: z.infer<typeof ExtendedYellowPagesListingSchema>) => {
    await upsertServiceResource(validatedForm)
    navigate({ path: CareCentralRoute.SERVICE_HUB, queryParams: { tab: ServicesHubTabs.PROVIDERS } })
  };

  return (
    <PageContainer>
      <div className="h-auto max-h-screen flex flex-col gap-5 max-w-2xl">
        <PageMainHeader text="Add New Provider" />
        <AddNewProviderManuallyForm form={form} />
        <div className="flex bg-white justify-end gap-5 py-2 max-w-3xl">
          <ButtonWithIcon
            onClick={navigateBack}
            text={"Cancel"}
            size={"small"}
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={form.handleSubmit((values) => handleSave(values))}
            text={"Save"}
            size={"small"}
            icon={IconOption.CHECKMARK}
          />
        </div>
      </div>
    </PageContainer>
  )
}


export function AddNewProviderManuallyForm({ form }: { form: UseFormReturn<z.infer<typeof ExtendedYellowPagesListingSchema>> }) {
  return (
    <Form {...form}>
      <FormRoot id="add-new-provider-manually">
        <CustomFormField form={form} fieldName="name" labelName="Name">
          {(field) => <TextInput className="w-full"  {...field} />}
        </CustomFormField>
        <CustomFormField form={form} fieldName="categories" labelName="Category">
          {(field) =>
            <Select
              placeHolder="Please select..."
              borderClass="border-2 border-faint-gray"
              classNames={`p-0.5 pr-2 h-[2.5rem] w-full bg-white`}
              currentOption={yellowPageCategoryOptions.find(
                (option) => option.value === field.value
              )}
              options={yellowPageCategoryOptions}
              {...field}
            />
          }
        </CustomFormField>
        <CustomFormField form={form} fieldName="phone" labelName="Phone">
          {(field) =>
            <PhoneNumberInput
              cellPhone={field.value ?? undefined}
              setCellPhone={field.onChange} />
          }
        </CustomFormField>
        <CustomFormField form={form} fieldName="email" labelName="Email">
          {(field) => <TextInput className="w-full"  {...field} />}
        </CustomFormField>

        <CustomFormField form={form} fieldName="website" labelName="Website">
          {(field) => <TextInput className="w-full" {...field} />}
        </CustomFormField>

      </FormRoot>

    </Form>
  )
}

