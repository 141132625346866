import type { ShareableInterventionType } from "backend/resources/shareableIntervention/shareableIntervention";
import { useShareableInterventionsFromShareableRecommendationId } from "backend/resources/shareableIntervention/shareableIntervention";
import {
  useCreateShareableRecommendationMutation,
  useDeleteShareableRecommendationHook,
  useShareableRecommendationsFromShareableRecommendationIds,
} from "backend/resources/shareableRecommendation/shareableRecommendation";
import { useCreateUserRecommendations } from "backend/resources/userRecommendation";
import { FreeFormText } from "components/AssessmentComponent/Input/inputTypes/FreeFormText";
import BackButton from "components/BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CloseButton } from "components/CloseButton/CloseButton";
import FooterButtons from "components/FooterButtons/FooterButtons";
import ModalView from "components/ModalView/ModalView";
import { PageContainer } from "components/PageContainer";
import { FormContainer } from "components/PageContainer/FormContainer";
import { PageMainHeader } from "components/PageMainHeader";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { TemplateTabs } from "components/TemplatesPage/TemplatesPage";
import { TextInput } from "components/TextInput";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGoalStore } from "state/goal/goal";
import { useShareableRecommendationStore } from "state/shareableRecommendation.ts/shareableRecommendation";
import ShareableIntervention from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableIntervention/ShareableIntervention";

interface Props {
  isSavingToGoal?: boolean;
}

export default function CreateOrEditShareableRecommendationPage(props: Props) {
  const { id } = useParams();
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title={id ? "Edit Recommendation" : "Create Recommendation"}
        closeText="Back"
        onClose={() => {
          navigateBack()
        }}>
        <PageContainer>
          <CreateOrEditShareableRecommendationPageComponent {...props} />
        </PageContainer>
      </ModalView>
    );
  } else {
    return (
      <PageContainer>
        <BackButton className="w-full justify-center flex md:justify-start mb-4" />
        <PageMainHeader
          text={id ? "Edit Recommendation" : "Create Recommendation"}
        />
        <CreateOrEditShareableRecommendationPageComponent {...props} />
      </PageContainer>
    );
  }
}

function CreateOrEditShareableRecommendationPageComponent({
  isSavingToGoal,
}: Props) {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const { id } = useParams();

  const {
    title,
    setTitle,
    details,
    setDetails,
    shareableInterventions,
    setShareableInterventions,
    reset,
  } = useShareableRecommendationStore();
  const [isDeleteRecTemplatePopupShowing, setIsDeleteRecTemplatePopupShowing] =
    useState(false);
  const { sessions, setSession } = useGoalStore();
  const [searchParams] = useSearchParams();
  const goalId = searchParams.get("goal_id");
  const recommendationIds = sessions[goalId ?? ""]?.recommendationIds ?? [];
  const setRecommendationIds = (ids: string[]) => {
    setSession(goalId ?? "", {
      ...sessions[goalId ?? ""],
      recommendationIds: ids,
    });
  };

  const { data: existingInterventions } =
    useShareableInterventionsFromShareableRecommendationId(id);
  const { data: recommendationData } =
    useShareableRecommendationsFromShareableRecommendationIds(id ? [id] : []);

  const saveMutation = useCreateShareableRecommendationMutation(id).mutateAsync;
  const deleteMutation = useDeleteShareableRecommendationHook().mutateAsync;
  const recommendation = recommendationData?.[0];
  const createUserRecommendations = useCreateUserRecommendations().mutateAsync;

  useEffect(() => {
    if (id) {
      setTitle(recommendation?.title ?? undefined);
      setDetails(recommendation?.details ?? undefined);
      if (existingInterventions)
        setShareableInterventions([
          ...existingInterventions,
          ...shareableInterventions,
        ]);
    }
  }, [existingInterventions, recommendation]);

  // Handler
  async function onSave() {
    if (title && details) {
      // First save the new rec
      const newRec = await saveMutation({
        shareableRecommendation: { title, details, id },
        interventions: shareableInterventions,
      });
      // once we have the rec id, we add it to the goal/template
      if (newRec?.id) {
        if (isSavingToGoal) {
          // if goal, we save recommendation as a userrec and add that id to the recs
          const userRecommendation = await createUserRecommendations([ newRec ]);
          if (userRecommendation)
            setRecommendationIds([
              ...recommendationIds,
              userRecommendation?.[0].id,
            ]);
        } else {
          setRecommendationIds([...recommendationIds, newRec.id]);
        }
      }
      reset();
    }
  }
  return (
    <div className="md:pt-10 flex flex-col gap-4 font-light pb-20">
      <FormContainer className="flex flex-col gap-4">
        {/* Title */}
        <label>Title</label>
        <TextInput
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* Details */}
        <label>Details</label>
        <FreeFormText
          answer={details}
          onChange={(value) => {
            setDetails(value?.toString());
          }}
        />
        <ButtonWithIcon
          onClick={() => {
            navigate({
              path: CareCentralRoute.SHAREABLE_INTERVENTION_ADD
            })
          }}
          text={"Suggestions"}
          size={"small"}
          className="flex justify-start my-4"
          icon={IconOption.PLUS}
        />
        {shareableInterventions?.map((shareableIntervention, index) => (
          <div className="relative w-min">
            <button
              type="button"
              className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
              onClick={() => {
                setShareableInterventions(
                  shareableInterventions.filter(
                    (_, interventionIndex) =>
                      interventionIndex !== index
                  )
                );
              }}>
              <CloseButton />
            </button>
            <ShareableIntervention
              intervention={shareableIntervention as ShareableInterventionType}
              isEditing={true}
            />
          </div>
        ))}
      </FormContainer>
      <FooterButtons>
        {id ? (
          <ButtonWithIcon
            onClick={async () => setIsDeleteRecTemplatePopupShowing(true)}
            text={"Delete"}
            size={"small"}
            icon={IconOption.TRASH}
          />
        ) : null}
        <ButtonWithIcon
          onClick={() => {
            reset();
            navigateBack()
          }}
          text={"Cancel"}
          size={"small"}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          onClick={async () => {
            await onSave();
            navigateBack()
          }}
          disabled={!details || !title}
          text={"Save"}
          size={"small"}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
      <ResponsivePopup
        isOpen={isDeleteRecTemplatePopupShowing}
        onClose={() => setIsDeleteRecTemplatePopupShowing(false)}>
        <div className="p-4 font-light flex flex-col gap-3 pb-20 md:pb-0">
          <header className="w-full text-center text-lg pb-4">
            Are you sure you want to delete this template?
          </header>
          <FooterButtons>
            <ButtonWithIcon
              onClick={() => {
                setIsDeleteRecTemplatePopupShowing(false);
              }}
              size={"small"}
              text={"Cancel"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={async () => {
                await deleteMutation(id);
                reset();
                navigate({
                  path: CareCentralRoute.TEMPLATES,
                  queryParams: {
                    tab: TemplateTabs.RECOMMENDATIONS
                  }
                })
              }}
              size={"small"}
              text={"Delete"}
              icon={IconOption.TRASH}
            />
          </FooterButtons>
        </div>
      </ResponsivePopup>
    </div>
  );
}
