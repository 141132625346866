import { NotificationType, sendNotification } from "backend/functions";
import type { OrganizationRole } from "backend/resources/orgRole";
import { useUpdateOrgRole } from "backend/resources/orgRole";
import type { UserRole } from "backend/resources/userRole";
import { useUpdateUserRole } from "backend/resources/userRole";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
// Usage
export default function DeactivateMemberButton({
  role,
}: {
  role: UserRole | OrganizationRole;
}) {
  const isOrgInvite = "organization_id" in role;

  // Mutation
  const updateRole = isOrgInvite
    ? useUpdateOrgRole().mutateAsync
    : useUpdateUserRole().mutateAsync;

  return (
    <div className="flex gap-2">
      {role.is_deactivated ?
        <ButtonWithIcon
          onClick={() => updateRole({
            roleId: role.id,
            isDeactivated: false,
          })}
          text={""}
          icon={IconOption.RESTORE}
        />
        :
        <ButtonWithIcon onClick={async () => {
          const updatedRole = await updateRole({
            roleId: role.id,
            isDeactivated: true,
          });
          if (updatedRole) {
            sendNotification(
              updatedRole.id,
              NotificationType.CARESPACE_DEACTIVATION
            );
          }
        }}
          text={""}
          icon={IconOption.TRASH}
        />
      }
    </div>
  );
}
