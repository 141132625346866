import { NotificationChannel, useUpdateNotificationPreferenceForSectionMutation } from "backend/resources/notificationPreferences/notificationPreferences";
import { useUpdateUserMutation } from "backend/resources/user";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user";

export default function SMSNotificationsApprovalModal({ onClose }: { onClose: () => void }) {
  const [wouldLikeToReceiveSMSNotifications, setWouldLikeToReceiveSMSNotifications] = useState<boolean | undefined>()
  const updateNotificationPreferences = useUpdateNotificationPreferenceForSectionMutation().mutateAsync;
  const networkId = useActiveNetworkId();

  const authUser = useUserStore((state) => state.user);
  const updateUser = useUpdateUserMutation().mutateAsync
  const setUser = useUserStore((state) => state.setUser);

  async function submit() {
    if (wouldLikeToReceiveSMSNotifications && authUser) {
      setUser({ ...authUser, has_approved_sms_notifs: true })
      await Promise.all([
        updateNotificationPreferences({ user_id: authUser.id, channel: NotificationChannel.SMS, enabled: true }),
        updateUser({ ...authUser, has_approved_sms_notifs: true })
      ]);

    }
    onClose()
  }

  return <ResponsiveModal
    isOpen={true}
    title="SMS Notifications"
    onClose={onClose}
    closeText="Back"
    footerButtons={
      <ButtonWithIcon
        onClick={submit}
        text="Done"
        icon={IconOption.CHECKMARK}
        disabled={wouldLikeToReceiveSMSNotifications === undefined}
      />
    }>
    <div className="flex flex-col gap-4">
      <p>
        {`If you want to receive SMS notifications for alerts and reminders for things like your tasks, please select the yes option. If you aren’t sure, select Maybe later and then go into Profile -> Settings to enable them at any time.`}
      </p>

      <DefaultCheckbox label="I agree to receive SMS based on my data" checked={wouldLikeToReceiveSMSNotifications === true} onChange={() => setWouldLikeToReceiveSMSNotifications(true)} />
      <p className="text-xs pl-8">By subscribing you agree to receive marketing text messages at the phone number provided. Reply STOP to cancel. Msg rates may apply.</p>
      <DefaultCheckbox label="Maybe later" checked={wouldLikeToReceiveSMSNotifications === false} onChange={() => setWouldLikeToReceiveSMSNotifications(false)} />
    </div>
  </ResponsiveModal>
}

