import * as Sentry from "@sentry/react";
import { renewPlanEntry } from "backend/functions";
import { BOOKMARKS_TABLE, FetchOptions, IDX_PLAN_ENTRY_UNIQUE_NOT_STARTED_IN_PROGRESS, PlanEntry, PlanEntryUpdate, PlanEntryWithBookmarks, TABLE, TaskStatus, TaskWithGuideInfo } from "backend/resources/planEntry";
import { supabase } from "clients/supabaseClient";


export async function fetchAllPlanEntriesForUser(options: FetchOptions): Promise<PlanEntryWithBookmarks[] | null> {
  const { userId, parentPlanEntryId, includeAll } = options;
  if (!userId) return null;

  // fetch entry
  let queryBuilder = supabase
    .from(TABLE)
    .select("*,plan_entry_to_user_bookmark(user_bookmark_id), plan_entry_attendee(user_id), network(*), user!plan_entry_user_id_fkey(first_name, last_name)")
    .eq("is_service_ticket", false)
    .is("guide_task_id", null);

  if (!includeAll) {
    if (parentPlanEntryId) {
      queryBuilder = queryBuilder.eq("parent_plan_entry_id", parentPlanEntryId);
    } else {
      queryBuilder = queryBuilder.is("parent_plan_entry_id", null);
    }
  }

  const { data: planEntries, error } = await queryBuilder;
  
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  const planEntriesWithBookmarks = planEntries.map((planEntry) => {
    return { ...planEntry, plan_entry_to_user_bookmark: null, user_bookmark_ids: planEntry.plan_entry_to_user_bookmark.map(bookmark => bookmark.user_bookmark_id) }
  })


  return planEntriesWithBookmarks.sort((a: PlanEntry, b: PlanEntry) => {
    // Handle cases where scheduled_date_time is null
    if (a.scheduled_date_time === null && b.scheduled_date_time === null) {
      return 0; // both are null, so they are equal for sorting purposes
    }
    if (a.scheduled_date_time === null) {
      return -1; // a is null, so it comes before b
    }
    if (b.scheduled_date_time === null) {
      return 1; // b is null, so a comes before b
    }
    // Sort in ascending order for non-null scheduled_date_time
    return a.scheduled_date_time.localeCompare(b.scheduled_date_time);
  });
}

export async function fetchPlanEntryById(
  entryId: string | undefined | null,
  userId?: string
): Promise<PlanEntryWithBookmarks | null> {
  if (!userId || !entryId) return null;

  const { data: planEntryData, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("id", entryId)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    return null;
  }

  if (planEntryData) {
    // fetch bookmarks for each plan_entry
    let planEntryWithBookmarks: PlanEntryWithBookmarks;

    const { data: bookmarksData, error } = await supabase
      .from(BOOKMARKS_TABLE)
      .select("*")
      .eq("plan_entry_id", planEntryData.id);

    if (bookmarksData && bookmarksData.length > 0) {
      planEntryWithBookmarks = {
        ...planEntryData,
        user_bookmark_ids: [
          ...bookmarksData.map((user_bookmark) => {
            return user_bookmark.user_bookmark_id;
          }),
        ],
      };
      return planEntryWithBookmarks;
    } else {
      planEntryWithBookmarks = {
        ...planEntryData,
        user_bookmark_ids: [],
      };
      return planEntryWithBookmarks;
    }
  }

  return null;
}

export async function savePlanEntry({ upsertRecord }: { upsertRecord: PlanEntryUpdate | TaskWithGuideInfo }) {
  // Conditionally add the id if it exists
  if (upsertRecord.id) {
    // await delete plan entry bookmarks by plan entry id
    await supabase
    .from(BOOKMARKS_TABLE)
    .delete()
    .eq("plan_entry_id", upsertRecord.id)
    .select();
    await updatePlanEntryStartedAtAndCompletedAt(upsertRecord);
  }

  // TODO instead of "deleting", lets explicitly take each field and set it

  let data: PlanEntry | null, error;
  let user_bookmark_ids: string[] | undefined;
  let upsertRecordWithoutUserBookmarkIds = { ...upsertRecord };
  let wasEntryAlreadyDone = false;
  // delete properties not in PlanEntry
  const propertiesToDelete = ['user_bookmark_ids', 'guide_task', 'network', 'user', "plan_entry_attendee", "subRows", "started_at", "completed_at"];
  propertiesToDelete.forEach(property => {
    if (property in upsertRecordWithoutUserBookmarkIds) {
      if (property === 'user_bookmark_ids') {
        user_bookmark_ids = (upsertRecordWithoutUserBookmarkIds as Record<string, any>)[property];
      }
      delete (upsertRecordWithoutUserBookmarkIds as Record<string, any>)[property];
    }
  });

  if (upsertRecord.recurring_interval === "None") {
    upsertRecordWithoutUserBookmarkIds.recurring_interval = null;
  }


  if (upsertRecordWithoutUserBookmarkIds.id) {
    const { data: existingEntry } = await supabase
      .from(TABLE).select("status").eq('id', upsertRecordWithoutUserBookmarkIds.id)
      .select("*")
      .limit(1)
      .maybeSingle();
    if (existingEntry?.status === TaskStatus.Done) {
      wasEntryAlreadyDone = true
    }
    // Perform an update if id is provided but name or user_id are not
    ({ data, error } = await supabase
      .from(TABLE)
      .update(upsertRecordWithoutUserBookmarkIds)
      .eq('id', upsertRecordWithoutUserBookmarkIds.id)
      .select("*")
      .maybeSingle());
  } else if (!upsertRecordWithoutUserBookmarkIds.name || !upsertRecordWithoutUserBookmarkIds.user_id) {
    throw new Error("name and user_id must be defined");
  } else {
    // Perform an insert otherwise
    ({ data, error } = await supabase
      .from(TABLE)
      .insert(upsertRecordWithoutUserBookmarkIds as PlanEntry)
      .select("*")
      .maybeSingle())
  }



  if (error || !data) {
    if (error?.message.includes(IDX_PLAN_ENTRY_UNIQUE_NOT_STARTED_IN_PROGRESS)) {
      Sentry.captureMessage(error.message, "warning");
      return null;
    }
    Sentry.captureException(error);
    throw new Error(error?.message);
  }
  // if the user marks it as done, then restart it (backend will filter out non-recurring)
  if (data?.status === TaskStatus.Done && !wasEntryAlreadyDone) {
    renewPlanEntry({ plan_entry_id: data.id })
  }

  if (user_bookmark_ids) {
    const plan_entry_id = data.id;
    const bookmarkInsertRecords = user_bookmark_ids?.map((user_bookmark_id) => {
      return {
        plan_entry_id,
        user_bookmark_id,
      };
    });
    await supabase
      .from(BOOKMARKS_TABLE)
      .insert([...bookmarkInsertRecords]);
  }

  return data;
}


/**
 * Updates the started_at and completed_at timestamps of a plan entry based on its status.
 * This is used to determine if a carespace is eligible to be paid for a given time period.
 * 
 * @param {PlanEntryUpdate} upsertRecord - The plan entry update object containing the status and id.
 * @returns {Promise<void>} - A promise that resolves when the update is complete.
 * 
 * If the status is 'InProgress', the started_at timestamp is set to the current date and time.
 * If the status is 'Done', the completed_at timestamp is set to the current date and time.
 * If the status is 'Done' and there is no existing started_at timestamp, it is set to the current date and time.
 */
async function updatePlanEntryStartedAtAndCompletedAt(upsertRecord: PlanEntryUpdate) {
  // If the upsertRecord does not have an id, return early
  if (!upsertRecord.id) {
    return;
  }

  const { status, id } = upsertRecord;
  let startedAt = null;
  let completedAt = null;

  // If the status is InProgress, set startedAt to the current date and time
  if (status === TaskStatus.InProgress) {
    startedAt = new Date().toISOString();
  } else if (status === TaskStatus.Done) {
    // If the status is Done, set completedAt to the current date and time
    completedAt = new Date().toISOString();

    // Check the current started_at value if the status is Done
    const { data: existingEntry } = await supabase
      .from(TABLE)
      .select("started_at")
      .eq("id", id)
      .single();

    // If there is an existing started_at value, use it; otherwise, set it to the current date and time
    startedAt = existingEntry?.started_at ?? new Date().toISOString();
  }

  // Update the plan entry with the new started_at and completed_at values
  await supabase
    .from(TABLE)
    .update({ started_at: startedAt, completed_at: completedAt })
    .eq("id", id)
    .select();
}

export async function updatePlanEntryBookmarks({
  planEntryIds,
  bookmarkId,
}: {
  planEntryIds: string[];
  bookmarkId: string;
}) {
  const bookmarkInsertRecords = planEntryIds.map((planEntryId) => {
    return {
      plan_entry_id: planEntryId,
      user_bookmark_id: bookmarkId,
    };
  });

  const { data: bookmarksData, error } = await supabase
    .from(BOOKMARKS_TABLE)
    .upsert([...bookmarkInsertRecords], {
      onConflict: "plan_entry_id, user_bookmark_id",
    });
}

export async function deletePlanEntry(planEntryId: string) {
  // 1 - delete all related plan_entry_to_user_bookmark
  const { data: bookmarksData, error: bookmarksError } = await supabase
    .from(BOOKMARKS_TABLE)
    .delete()
    .eq("plan_entry_id", planEntryId)
    .select();

  if (bookmarksError) {
    Sentry.captureException(bookmarksError);
    return {
      error: `Failed to delete plan entry boomarks: ${bookmarksError.message}`,
    };
  }

  // 2 - delete plan_entry
  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .eq("id", planEntryId)
    .select();

  if (error) {
    Sentry.captureException(error);
    return { error: `Failed to delete plan entry: ${error.message}` };
  }

  return { data };
}
