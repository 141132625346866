import { RespiteServiceActivityLocation } from "shared/forms/AddRespiteServiceActivityForm";
import { DyadTier } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";

export type DCMPData = {
  withCaregiver: DCMPDataRow[];
  withoutCaregiver: DCMPDataRow[];
  subtotalCount: number;
  subtotalAmount: number;
}


export type DCMPDataRow = {
  dyadTier: string;
  dcmpCode: string;
  baseRate: number;
  patientCount: number;
  baseAmount: number;
  adjustedWithGAF: number;
  totalAdjustedWithPBA: number;
}


export type RespiteServicesData = {
  withCaregiver: RespiteServicesDataRow[];
  withoutCaregiver: RespiteServicesDataRow[];
  subtotalCount: number;
  subtotalAmount: number;
}


export type RespiteServicesDataRow = {
  location: string;
  respiteCode: string;
  units: number;
  rate: number;
  patientCount: number;
  amount: number;
}


export type BillingPageData = {
  dcmp: {
    newPatients: DCMPData,
    establishedPatients: DCMPData,
    total: {
      patientCount: number,
      amount: number,
    },
  },
  respite: {
    newPatients: RespiteServicesData,
    establishedPatients: RespiteServicesData,
    total: {
      patientCount: number,
      amount: number,
    },
  },
  grandTotal: number,
  new_patients_count: number,
  established_patients_count: number,
  total_patients_count: number,
  patient_list: CarespaceDataWithBillingPageInfo[];
}


export type CarespaceDataWithBillingPageInfo = {
  id: string;
  is_eligible: boolean;
  created_at: Date;
  dyad_tier: DyadTier;
  has_caregiver: boolean;
  name: string
}

export enum DCMPLabel {
  Low = DyadTier.Low,
  Moderate = DyadTier.Moderate,
  High = DyadTier.High,
  "Low-NC" = "Low-NC",
  "Mod-High-NC" = "Mod-High-NC",
}


export const DCMPLabelColor: Record<DCMPLabel, string> = {
  [DCMPLabel.Low]: "#7DC3F6",
  [DCMPLabel.Moderate]: "#78D08F",
  [DCMPLabel.High]: "#E06666",
  [DCMPLabel["Low-NC"]]: "#8C90F1",
  [DCMPLabel["Mod-High-NC"]]: "#FCD39C",
}


export enum RespiteLabels {
  "In Home" = RespiteServiceActivityLocation["In Home"],
  "Adult Day Center" = RespiteServiceActivityLocation["Adult Day Center"],
  "Facility Based" = RespiteServiceActivityLocation["Facility Based"],
  "In Home-NC" = "In Home-NC",
  "Adult Day Center-NC" = "Adult Day Center-NC",
  "Facility Based-NC" = "Facility Based-NC",
}

export const RespiteLabelColors: Record<RespiteLabels, string> = {
  [RespiteLabels["In Home"]]: "#7DC3F6",
  [RespiteLabels["Adult Day Center"]]: "#78D08F",
  [RespiteLabels["Facility Based"]]: "#E06666",
  [RespiteLabels["In Home-NC"]]: "#8C90F1",
  [RespiteLabels["Adult Day Center-NC"]]: "#FCD39C",
  [RespiteLabels["Facility Based-NC"]]: "#DDA0DD",
}