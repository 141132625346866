import useIntersectionObserver from "@react-hook/intersection-observer";
import { forwardRef, useId, useRef } from "react";

interface Props {
  url: string;
  className?: string;
  disabled?: boolean;
}

export const LazyIframe = forwardRef<HTMLIFrameElement, Props>(
  ({ url, className, disabled }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const lockRef = useRef(false);
    const { isIntersecting } = useIntersectionObserver(containerRef);

    if (isIntersecting) {
      lockRef.current = true;
    }
    const titleId = useId();

    return (
      <div
        className={`w-full h-full`}
        ref={containerRef}>
        {lockRef.current && (
          <iframe
            ref={ref}
            className={` h-full w-full object-top ${className} ${
              disabled ? "pointer-events-none" : ""
            }`}
            title={titleId}
            src={url}
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}></iframe>
        )}
      </div>
    );
  }
);
