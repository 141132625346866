import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "App";
import { supabase } from "clients/supabaseClient";
import { QUERY_KEYS } from "backend/queryKeys";
import { useActiveUserAdlo } from "backend/resources/userAdlo";
import type { RecommendationType } from "backend/resources/userRecommendation";

const TABLE = "goal_user_recommendation_status";

export type GoalUserRecommendationStatus =
  Database["public"]["Tables"]["goal_user_recommendation_status"]["Row"] & {
    userRecommendation: RecommendationType;
  };

export function useUserRecommendationsForGoal(goalId?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.goalUserRecommendationStatus, { goalId }],
    queryFn: () => fetchRecommendationsFromGoalId(goalId)
  });
}

// Fetch user recommendations for a specific recommendation
export function useRecommendationStatusForShareableRecommendation(
  recommendation_id?: string
) {
  const activeAdlo = useActiveUserAdlo();

  return useQuery({
    queryKey: [
      QUERY_KEYS.goalUserRecommendationStatus,
      { recommendation_id },
    ],
    queryFn: async () => {
      if (!recommendation_id || !activeAdlo?.id) return;
      const { data, error } = await supabase
        .from("recommendation_status")
        .select("*")
        .eq("user_adlo_id", activeAdlo.id)
        .eq("recommendation_id", recommendation_id)
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      return data;
    },
  });
}

// Mutation hook to flip the marked_is_done value
export function useToggleMarkedAsDone(recommendationId?: string) {
  const activeAdlo = useActiveUserAdlo();
  const { data: currentStatus } = useRecommendationStatusForShareableRecommendation(recommendationId)

  return useMutation({
    mutationFn: async () => {
      if (!recommendationId || !activeAdlo?.id) return
      const { error } = await supabase
        .from("recommendation_status")
        .upsert({
          is_marked_done: !currentStatus?.is_marked_done,
          recommendation_id: recommendationId,
          user_adlo_id: activeAdlo?.id
        },
          { onConflict: "recommendation_id, user_adlo_id" }
        )
        .eq("recommendation_id", recommendationId)
        .eq("user_adlo_id", activeAdlo.id);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.goalUserRecommendationStatus,
        ]
      });
    },
  }
  );
}

// Mutation hook to update the rating
export function useUpdateRating(recommendationId?: string) {
  const activeAdlo = useActiveUserAdlo();

  return useMutation({
    mutationFn: async ({
      newRating,
    }: {
      newRating: number;
    }) => {
      if (!recommendationId || !activeAdlo?.id) return
      const { error } = await supabase
        .from("recommendation_status")
        .upsert({
          rating: newRating,
          recommendation_id: recommendationId,
          user_adlo_id: activeAdlo?.id
        }, {
          onConflict: "recommendation_id, user_adlo_id"
        }
        )
        .eq("recommendation_id", recommendationId)
        .eq("user_adlo_id", activeAdlo.id);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.goalUserRecommendationStatus,
        ]
      });
    },
  }
  );
}

// Add a list of user recommendations to a specific goal
export function useAddUserRecommendationsToGoal() {
  return useMutation({
    mutationFn: async ({
      goalId,
      userRecommendations,
    }: {
      goalId: string;
      userRecommendations: string[];
    }) => {
      const { error } = await supabase.from(TABLE).insert(
        userRecommendations.map((rec) => ({
          goal_id: goalId,
          user_recommendation_id: rec,
        }))
      );

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return { goalId };
    },
    onSuccess: (data) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.goalUserRecommendationStatus,
          { goalId: data.goalId },
        ]
      });
    },
  }
  );
}

export function useSaveUserRecommendationsToGoal(goalId?: string) {
  const { data: existingUserRecommendations } =
    useUserRecommendationsForGoal(goalId);
  return useMutation({
    mutationFn: async ({
      userRecommendations,
      goalId,
    }: {
      userRecommendations: string[];
      goalId: string;
    }) => {
      const existingUserRecommendationIds = new Set(
        existingUserRecommendations?.map((rec) => rec.id)
      );
      const newUserRecommendationIds = new Set(userRecommendations);

      const toDelete = [...existingUserRecommendationIds].filter(
        (userRecommendationId) =>
          !newUserRecommendationIds.has(userRecommendationId)
      );
      const toInsert = [...newUserRecommendationIds].filter(
        (userRecommendationId) =>
          !existingUserRecommendationIds.has(userRecommendationId)
      );

      await deleteUserRecommendations(goalId, toDelete);
      await insertUserRecommendations(goalId, toInsert);

      return { goalId };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.goalUserRecommendationStatus,
          { goalId: data.goalId },
        ]
      });
    },
  }
  );
}

export async function deleteUserRecommendations(
  goalId: string | undefined,
  userRecommendationIds: string[]
) {
  if (userRecommendationIds.length === 0 || !goalId) return;

  const { data, error } = await supabase
    .from(TABLE)
    .delete()
    .in("user_recommendation_id", userRecommendationIds)
    .eq("goal_id", goalId);

  if (error) {
    Sentry.captureException(error);
  }
}

export async function insertUserRecommendations(
  goalId: string | undefined,
  userRecommendationIds: string[]
) {
  if (userRecommendationIds.length === 0 || !goalId) return;

  const itemsToInsert = userRecommendationIds.map((userRecommendationId) => ({
    goal_id: goalId,
    user_recommendation_id: userRecommendationId,
  }));

  const { data, error } = await supabase.from(TABLE).insert(itemsToInsert);

  if (error) {
    Sentry.captureException(error);
  }
}

export async function fetchRecommendationsFromGoalId(goalId?: string) {
  if (!goalId) return []
  const { data, error } = await supabase
    .from(TABLE)
    .select("user_recommendation(*, recommendation(*), interventions:user_intervention(*))")
    .eq("goal_id", goalId);

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data.map(
    (item) => item.user_recommendation
  ) as RecommendationType[];
}
