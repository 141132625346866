import { cva } from "class-variance-authority";

export const displayStyles = cva([], {
  variants: {
    display: { inline: ["inline"], flex: ["flex"] },
   
  },
  compoundVariants: [],
  defaultVariants: {
    display: "flex",
  },
});

export const textStyles = cva([], {
  variants: {
    truncate: {
      true: "truncate",
      false: ""
    }
  },
  compoundVariants: [],
  defaultVariants: {
    truncate: true,
  },
});