import PrivacyPolicyIcon from "assets/nav/privacy_policy_icon_orange.png";
import TermsOfServiceICon from "assets/nav/terms_and_conditions_icon_orange.png";
import { useActiveOrg, useActiveOrgRole, useOrgs } from "backend/resources/orgRole";
import {
  useUserAdlos
} from "backend/resources/userAdlo";
import { useActiveUserRole } from "backend/resources/userRole";
import type { NetworkRoleType } from "backend/resources/userRole/types";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  CollapseSidebarButton,
  CollapsibleNavItem,
  NavItem,
} from "components/NavBar";
import {
  CraniometrixProduct,
  useProductAccess,
} from "hooks/product/product";
import { ExternalRoute } from "lib/routing";
import { useEffect } from "react";
import { useSideNavStore } from "state/navItems";
import { NAV_ITEMS_CONFIG } from "state/navItems/navItemModels";

export function SideNav() {
  const isMinimized = useSideNavStore((state) => state.isMinimized);
  const { userRole } = useActiveUserRole();
  const { data: orgRole } = useActiveOrgRole();
  const { isSuperSuperUser } = useOrgs();
  const { data: activeOrg } = useActiveOrg()

  const items = useSideNavStore((state) => state.items);
  const configureNavContext = useSideNavStore(
    (state) => state.configureNavContext
  );
  const { data: productAccess } = useProductAccess();
  const { data: userAdlosData } = useUserAdlos();
  const userAdloData = userAdlosData?.[0]; // TODO this must be a selection

  useEffect(() => {
    configureNavContext(
      {
        role: userRole?.role as NetworkRoleType || orgRole?.role as NetworkRoleType,
        isMobile: false,
        product: productAccess,
        isSuperUser: orgRole?.is_superuser,
        isSuperSuperUser: isSuperSuperUser,
      }
    );
  }, [userRole, userRole?.role, productAccess, orgRole, orgRole?.is_superuser, isSuperSuperUser]);

  return (
    <div
      className={`${isMinimized ? "w-16" : "w-44"
        } border-r border-faint-gray h-full shrink-0 relative`}>
      {/* content */}
      <div className="flex flex-col h-full gap-5 p-3">
        <div className={`flex w-full ${isMinimized ? "justify-start" : "justify-end"}`}>
          <CollapseSidebarButton />
        </div>
        {/* main content */}
        {userAdloData || productAccess === CraniometrixProduct.CARE_CENTRAL ? (
          <div className="flex flex-col flex-grow gap-5">
            {items.map((item) => {
              if (item.subNavItems) {
                return (
                  <CollapsibleNavItem
                    key={item.name}
                    name={item.name}
                    icon={item.icon}
                    subNavItems={item.subNavItems}
                    isExpanded={!!item.isExpanded}
                  />
                );
              } else {
                return (
                  <NavItem
                    key={item.name}
                    name={item.name}
                    icon={item.icon}
                    pathname={NAV_ITEMS_CONFIG[item.name].route}
                  />
                );
              }
            })}
          </div>
        ) : (
          <div className="flex flex-col flex-grow items-center justify-center gap-5">
            <LoadingSpinner className="w-8 h-8" />
          </div>
        )}

        {/* bottom nav  */}
        <div className="flex flex-col gap-5 pb-5">
          <NavItem
            key="Terms of Service"
            name="Terms of Service"
            icon={<img src={TermsOfServiceICon} className="w-6 h-6" />}
            pathname={ExternalRoute.TERMS_OF_SERVICE}
            isExternalLink
            size={"small"}
          />
          <NavItem
            key={"Privacy Policy"}
            name="Privacy Policy"
            icon={<img src={PrivacyPolicyIcon} className="w-6 h-6" />}
            pathname={ExternalRoute.PRIVACY_POLICY}
            isExternalLink
            size={"small"}
          />
        </div>
      </div>
    </div>
  );
}
