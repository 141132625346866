import { zodResolver } from "@hookform/resolvers/zod";
import { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { useForm } from "react-hook-form";
import { z } from "zod";

// hook to use the zod form with onChange event handler
export const usePAFSubmissionForm = (defaultValues: z.infer<typeof PAFSchema>) => {
  return useForm<z.infer<typeof PAFSchema>>({
    resolver: zodResolver(PAFSchema),
    mode: "onSubmit",
    defaultValues: defaultValues,
    shouldUnregister: false, 
  });
};

