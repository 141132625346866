import { getSupabaseAccessToken } from "backend/functions";
import { downloadBlob } from "backend/functions/util";
const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export async function downloadPafFile(networkId: string | undefined, fileName: string) {
  if (!networkId) {
    return null;
  }
  
  const response = await fetch(`${BACKEND_URL}/generate-paf-file?network_id=${networkId}`, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': `Bearer ${await getSupabaseAccessToken()}`,
      "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });

  if (!response.ok) {
    console.error('Failed to download the file:', response.statusText);
    return null;
  }

  const blob = await response.blob();
  downloadBlob(blob, `${fileName}.xlsx`)
}
