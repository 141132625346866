import type { VariantProps } from "class-variance-authority";
import type { ChatGptMessage } from "backend/resources/chatGptMessage";
import type { UserBookmarkWithGuidanceResource } from "backend/resources/userBookmark";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import AlfredIcon from "components/Alfred/Alfred";
import type { cardStyles } from "components/Card/Card";

interface Props {
  bookmark: UserBookmarkWithGuidanceResource & {
    chat_gpt_message: Pick<
      ChatGptMessage,
      "content" | "id" | "chat_gpt_conversation_id" | "metadata"
    >;
  };
}

export function ChatGptMessageBookmark({
  bookmark,
  ...variantProps
}: Props & VariantProps<typeof cardStyles>) {
  const navigate = useAppNavigate();

  const videoId: string | undefined =
    (bookmark.chat_gpt_message.metadata as any)?.resource_metadata
      ?.youTubeMetadata?.id;
  const videoURL = makeYoutubeVideoURL(videoId);
  const isTextDisplayed = !videoURL;

  const text = isTextDisplayed
    ? bookmark.chat_gpt_message.content?.replaceAll("<br />", "\n")
    : undefined;
  const isThumbnailMode = variantProps.size === "thumbnail";
  return (
    <>
      {text && (
        <button
          onClick={() =>
            navigate({
              path: CarePilotRoute.BOOKMARK,
              params: {
                id: bookmark.id
              }
            })
          }
          className="flex px-1 gap-10 items-center py-4">
          {!isThumbnailMode ? (
            <AlfredIcon className="flex-shrink-0 w-[6rem] mt-1" />
          ) : null}
          <span
            className={`text-sm ${isThumbnailMode ? "text-center" : "text-left"
              } line-clamp-[4] md:line-clamp-[5]`}>
            {text}
          </span>
        </button>
      )}
    </>
  );

  function makeYoutubeVideoURL(youtubeVideoId: string | undefined) {
    if (youtubeVideoId) {
      return `https://www.youtube.com/embed/${youtubeVideoId}`;
    }
  }
}
