import { Cell, flexRender, Row } from '@tanstack/react-table';
import { ReactComponent as MeetingSVG } from "assets/meeting.svg";
import { ReactComponent as PaperClipIcon } from "assets/paper-clip.svg";
import RecurringIcon from "assets/recurring_icon.png";
import { ServiceStatusColor, TaskStatusColor, TaskStatusLabel } from 'backend/resources/planEntry';
import Who, { UserType } from 'components/MyPlanPage/components/Who';
import { GuideCategoryColorStyle, isTaskOverdue } from 'components/TaskNavigatorPage/taskTableUtils';

import { TaskTableFields, TaskTableRowType, TaskTableType, TaskTypeColor } from "components/Tables/TaskTable/TaskTableTypes";

export function TaskTableCellContent({ cell, row }: { cell: Cell<TaskTableType, unknown>, row: Row<TaskTableType> }) {
  if (!row.original[TaskTableFields.Id]) return null;
  switch (cell.column.id) {
    case TaskTableFields.Who:
      return (
        cell.row.original.Who ? <div className='flex w-[100px]'>
          <Who
            userType={UserType.ORG_USERS}
            entry={null}
            selectedPlanEntryOwnerId={cell.row.original.Who}
            setPlanEntryOwner={() => { }}
          />
        </div> : null
      );
    case TaskTableFields.Status:
      const rawData = cell.row.original[TaskTableFields.RawData]
      return (
        <div className='flex gap-2 items-center justify-center w-[150px] max-w-[150px]'>
          <p style={{
            background: TaskStatusColor[cell.row.original.Status]
          }}
            className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
            {TaskStatusLabel[cell.row.original.Status]}
          </p>
          {(rawData.plan_entry_attendee?.length || rawData.google_meeting_code) && <MeetingSVG className="w-4 h-4" />}
        </div>
      );
    case TaskTableFields.TaskType:
      return <p
        className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4  w-[120px] max-w-[120px]`}
        style={{
          background: TaskTypeColor[cell.row.original[TaskTableFields.TaskType] as TaskTableRowType]
        }}>{cell.row.original[TaskTableFields.TaskType]}</p>
    case TaskTableFields.Due:
      const isOverdue = isTaskOverdue(cell.row.original.Due, cell.row.original.Status);
      return <p
        className={
          `flex gap-1 rounded-full truncate text-left  w-[90px] w-min-[90px] max-w-[150px] ${isOverdue ? 'text-red-500' : ''}`
        } >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </p>
    case TaskTableFields.RecurringInterval:
      return <p
        className={
          `flex gap-1 rounded-full truncate text-left w-[20px] -ml-9`
        } >
        {row.original[TaskTableFields.RecurringInterval] &&
          <img src={RecurringIcon} alt="Recurring Icon" className='w-5' />}
      </p>
    case TaskTableFields.Category:
      const category = row.original[TaskTableFields.CategoryFullTitle] ?? "N/A";
      return (
        <p
          style={{
            background: GuideCategoryColorStyle[category]
          }}
          className={
            `rounded-full truncate text-xs py-1 text-center ${category === "N/A" ? "text-black" : "text-white"} px-4 w-min-[150px]  w-[150px] max-w-[150px]`
          } >
          {row.original[TaskTableFields.Category]}
        </p>
      );
    case TaskTableFields.TaskName:
      const taskName = cell.row.original[TaskTableFields.TaskName]
      const hasDocumentAttached = cell.row.original[TaskTableFields.HasDocumentAttached];
      return (
        <p
          className={
            `rounded-full text-left w-[160px] min-w-[160px] max-w-[160px] md:w-[300px] md:w-min-[300px] md:max-w-[300px]`
          }>
            <div className='flex items-center'>
              <span className="truncate mr-1">{taskName}</span>
              {hasDocumentAttached && <PaperClipIcon className="w-4 h-4 flex-shrink-0" />}
            </div>
        </p>
      );
    case TaskTableFields.Carespace:
      return (
        <p
          className={
            `rounded-full truncate text-left w-[150px] w-min-[150px] max-w-[150px]`
          } >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );

    case TaskTableFields.ServiceStatus:
      return (
        <p style={{
          background: ServiceStatusColor[(cell.row.original[TaskTableFields.ServiceStatus])]
        }}
          className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
    default:
      return (
        <p
          className={
            `truncate text-left max-w-[100px] w-[100px]`
          } >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
  }
}
