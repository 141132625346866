import { AssessmentType } from "components/AssessmentComponent/AssessmentComponent";
/**
 * @fileoverview All code related to the assessment table.
 */
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "backend/queryKeys";
import { supabase } from "clients/supabaseClient";
import type { Database } from "../../../../types/supabase";

const TABLE = "assessment";




export type Assessment = Database["public"]["Tables"]["assessment"]["Row"] & {
  subject: "patient" | "caregiver";
};

export async function fetchAllAssessments(newAssessmentType?: AssessmentType) {
  let query = supabase
    .from(TABLE)
    .select()
    .eq("active", true);

  if (newAssessmentType !== undefined) {
    query = query.eq("is_for_screening", newAssessmentType === AssessmentType.SCREENINGS);
  }

  const { data, error } = await query;

  if (data && data.length > 0) {
    return (data as Assessment[]).sort(function (a, b) {
      if (a.slug === "Cranio") {
        return 1;
      } else if (b.slug === "Cranio") {
        return -1;
      } else {
        return a.name.localeCompare(b.name);
      }
      ;
    });
  }
  if (error) {
    throw new Error(error.message);
  }


  return null;
}

export const useAssessments = ({
  newAssessmentType
}: {
  newAssessmentType?: AssessmentType;
}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.assessments, { newAssessmentType }],
    queryFn: () => fetchAllAssessments(newAssessmentType),
  });
};

export type AssessmentMetadata = Pick<
  Assessment,
  "id" | "name" | "subject" | "slug" | "description"
>;



export const useAssessment = (id?: string | null) => {
  return useQuery({
    queryKey: [QUERY_KEYS.assessment, { id }],
    queryFn: () => fetchAssessmentById(id),
  });
};


export async function fetchAssessmentById(id?: string | null) {
  if (!id) return null
  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("id", id)
    .limit(1)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data as Assessment;
}


export function useAssessmentIdBySlug(slug?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.assessmentBySlug, { slug }],
    queryFn: async () => {
      if (!slug) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("id")
        .eq("slug", slug)
        .limit(1)
        .maybeSingle();
      if (error) {
        throw new Error(error.message);
      }
      return data?.id;
    },
  });
}