import { useGuideCategory } from "backend/resources/guide/guideCategory";
import { useNetworkName } from "backend/resources/network/network";
import { useActiveOrg } from "backend/resources/orgRole";
import { TaskStatus, TaskStatusLabel, useAllGuidePlanEntriesForCategoryAndNetwork, useUpdatePlanEntry } from "backend/resources/planEntry";
import BackButton from "components/BackButton/BackButton";
import { DefaultCheckbox } from "components/Checkbox";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import { Select } from "components/Select";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields } from "components/Tables/TaskTable/TaskTableTypes";
import { TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { useNavigateBack } from "lib/routing";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useNetworkStore } from "state/network/network";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

export default function GuideCategoryPage() {
  const navigateBack = useNavigateBack()
  const { network_id, category: categoryName } = useParams();

  // Stores
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const { setStatus, status, hideDone, setHideDone, setCarespace: setNetwork } = useTaskFilterStore();

  // queries
  const { data: guideCategory } = useGuideCategory(categoryName)
  const { networkName } = useNetworkName(network_id ?? undefined)
  const { data: activeOrg } = useActiveOrg()
  const { data: allPlanEntries } = useAllGuidePlanEntriesForCategoryAndNetwork(categoryName, network_id);
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  // Effects
  useEffect(() => {
    if (network_id) {
      setActiveNetworkId(network_id)
      setNetwork(network_id)
    }
  }, [network_id])

  // ----------------- RENDER -----------------
  return <PageContainer>
    <BackButton className="mb-2" />

    <div className="flex flex-col gap-4 pb-20">
      <div className="flex gap-2 mt-2">
        <HeaderNamePill text={activeOrg?.name} color="#5D63BA" />
        <HeaderNamePill text={networkName} color="#176590" />
      </div>
      <PageMainHeader text={categoryName} />
      <p>{guideCategory?.description}</p>
      {/* Status  */}
      <div className="flex gap-5 items-center">
        <p>Status</p>
        <Select
          currentOption={status ? { value: status, label: status } : { value: "All", label: "All" }}
          options={[
            { value: "All", label: "All" },
            ...Object.values(TaskStatus).map((status) => ({ label: TaskStatusLabel[status], value: status })),
            { value: "Overdue", label: "Overdue" }
          ]}
          onChange={(status) => setStatus(status)}
        />
        {status === "All" &&
          <div className="flex gap-2 items-center">
            <DefaultCheckbox checked={hideDone} onChange={(isChecked: boolean) => setHideDone(isChecked)} />
            <p className="text-sm">Hide Done</p>
          </div>
        }
      </div>
      {/* Introduction */}
      <TaskTable
        updateTask={updatePlanEntry}
        data={allPlanEntries}
        filterConfig={{
          [TaskFilterTypes.STATUS]: true,
          [TaskFilterTypes.HIDE_DONE]: true,
        }}
        hiddenColumns={[TaskTableFields.Account, TaskTableFields.Carespace, TaskTableFields.Category, TaskTableFields.ServiceStatus, TaskTableFields.CreatedAt]}
      />
      {/* {
        // Check if guideCategory has sub categories
        (guideCategory?.guide_sub_category?.length ?? 0) > 0 ?
          // If it has, sort and map through each sub category
          guideCategory?.guide_sub_category
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((subCategory) => (
              <div className="flex flex-col gap-1">
                <p className="text-xl">{subCategory.title}</p>
                <TaskTable
                  updateTask={updatePlanEntry}
                  data={allPlanEntries?.filter((entry) => entry.guide_task?.guide_sub_category_id === subCategory.id) ?? []}
                  filterConfig={{
                    [TaskFilterTypes.STATUS]: true,
                    [TaskFilterTypes.HIDE_DONE]: true,
                  }}
                  hiddenColumns={[TaskTableFields.Account, TaskTableFields.Carespace, TaskTableFields.Category, TaskTableFields.ServiceStatus, TaskTableFields.CreatedAt]} />
              </div>
            )) :
          // If guideCategory has no sub categories, render GuideTaskTable with filter based on category title and network id
          <TaskTable
            updateTask={updatePlanEntry}
            data={allPlanEntries}
            filterConfig={{
              [TaskFilterTypes.STATUS]: true,
              [TaskFilterTypes.HIDE_DONE]: true,
            }}
            hiddenColumns={[TaskTableFields.Account, TaskTableFields.Carespace, TaskTableFields.Category, TaskTableFields.ServiceStatus, TaskTableFields.CreatedAt]} />
          } */}
    </div>
  </PageContainer >
}