import { useWindowSize } from "hooks/useWindowSize";
import AlfredIcon from "components/Alfred/Alfred";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarUserUpdatesPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"userUpdatePage"}
        pageType="userUpdatePage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 text-sm">
        {/* intro PP */}
        <p>
          This is the Family Hub updates page. As care is being provided to your
          loved one by one or more caregivers, you will see their updates on
          their progress, along with any additional information related to the
          care they provided and why.
        </p>
        <p>
          In addition, you can discuss and ask questions so that all
          conversations can be found here to make sure everyone is in the loop.
        </p>
      </div>
    </div>
  );
}
