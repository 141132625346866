import { DatePicker } from "@mui/x-date-pickers";
import { AssessmentSlug, useQueryLatestUserAssessmentBySlug } from "backend/resources/userAssessment";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { caregiverYearsInRoleText } from "components/CarespacePage/PAFSubmission/constants";
import { HasPrimaryCareGiver, PhoneType, Race, Relationship, Sex, YesNo } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getCurrentValueForSelect, getOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import { Select } from "components/Select/Select";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { useActiveNetworkId } from "state/network/network";
import { z } from "zod";


interface PrimaryCareGiverInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
  openAssessment: (assessment_id: string | undefined) => void;
}

export const PrimaryCareGiverInputs: React.FC<PrimaryCareGiverInputsProps<z.infer<typeof PAFSchema>>> = ({ form, isReadOnly, openAssessment }) => {
  const formValues = form.watch();
  const networkId = useActiveNetworkId();
  const has_pcg = [HasPrimaryCareGiver.YES_MULTIPLE, HasPrimaryCareGiver.YES_ONE].includes(formValues.has_pcg);
  const { data: latestZbiAssessment } = useQueryLatestUserAssessmentBySlug(networkId, AssessmentSlug.ZARIT);

  useEffect(() => {
    if (has_pcg && latestZbiAssessment?.id) {
      form.setValue("zbi_id", latestZbiAssessment?.id);
    }
  }, [has_pcg, latestZbiAssessment]);

  return (
    <>
      {/* PCG First Name */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="First Name"
        fieldName="pcg_first_name"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* PCG Last Name */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="Last Name"
        fieldName="pcg_last_name"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* PCG Email */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="200px"
        labelName="Email"
        fieldName="pcg_email"
        children={(field) => (
          <Input {...field} className="max-w-[400px]" />
        )}
      />
      {/* PCG Address */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="100px"
        labelName="Address"
        fieldName="pcg_address_line"
        children={(field) => (
          <Input {...field} className="max-w-[500px]" />
        )}
      />
      {/* PCG City */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="100px"
        labelName="City"
        fieldName="pcg_address_city"
        children={(field) => (
          <Input {...field} className="max-w-[500px]" />
        )}
      />
      <div className="grid gap-4 grid-cols-2 max-w-[615px]">
        {/* PCG State */}
        <CustomFormField
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="State"
          fieldName="pcg_address_state"
          children={(field) => (
            <Input {...field} className="w-[175px]" />
          )}
        />
        {/* PCG Zip Code */}
        <CustomFormField
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="Zip Code"
          fieldName="pcg_address_postalCode"
          children={(field) => (
            <Input {...field} className="w-[175px]" />
          )}
        />
      </div>
      <div className="grid gap-4 grid-cols-2 max-w-[615px]">
        {/* PCG Phone */}
        <CustomFormField
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="Phone"
          fieldName="pcg_phone"
          children={(field) => (
            <PhoneNumberFormInput {...field} className="max-w-[400px]" />
          )}
        />
        {/* PCG Phone Type */}
        <CustomFormField
          form={form}
          disabled={isReadOnly}
          inlineLabel={true}
          labelWidth="100px"
          labelName="Phone Type"
          fieldName="pcg_phone_type"
          children={(field) => (
            <Select
              classNames="max-w-[185px] w-[185px]"
              options={getOptionsFromEnum(PhoneType)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
      </div>
      {/* PCG Date of Birth */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        labelName="Date of Birth"
        fieldName="pcg_date_of_birth"
        children={(field) => (
          <DatePicker
            {...field}
            value={field.value ? dayjs(field.value) : null}
            onChange={(date: Dayjs | null) => field.onChange(date ? date.format("YYYY-MM-DD") : null)}
          />
        )}
      />
      {/* PCG Sex */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Gender"
        fieldName="pcg_sex"
        children={(field) => (
          <Select
            classNames="max-w-[400px] w-[400px]"
            options={getOptionsFromEnum(Sex)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Race */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Race/Ethnicity"
        fieldName="pcg_race"
        children={(field) => (
          <Select
            classNames="max-w-[400px] w-[400px]"
            options={getOptionsFromEnum(Race)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Relationship */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Relationship to patient"
        fieldName="pcg_relationship"
        children={(field) => (
          <Select
            classNames="max-w-[400px] w-[400px]"
            options={getOptionsFromEnum(Relationship)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Lives with patient */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Does primary caregiver live with patient?"
        fieldName="pcg_residence"
        children={(field) => (
          <Select
            classNames="max-w-[200px] w-[200px]"
            options={getOptionsFromEnum(YesNo)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Is Medicare Beneficiary */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Is primary caregiver a Medicare beneficiary? "
        fieldName="pcg_medicare_status"
        children={(field) => (
          <Select
            classNames="max-w-[200px] w-[200px]"
            options={getOptionsFromEnum(YesNo)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Medicare Beneficiary Identifier */}
      <CustomFormField
        form={form}
        disabled={isReadOnly || formValues.pcg_medicare_status == YesNo.NO}
        inlineLabel={true}
        labelWidth="400px"
        labelName="PCG Medicare Beneficiary Identifier"
        fieldName="pcg_mbi"
        children={(field) => (
          <Input className="max-w-[200px]" {...field} />
        )}
      />
      {/* PCG Zarit Burden Interview Score */}
      <div className="flex space-x-4">
        <CustomFormField
          form={form}
          disabled={isReadOnly ?? latestZbiAssessment}
          inlineLabel={true}
          labelWidth="400px"
          labelName="Primary caregiver Zarit Burden Interview score: "
          fieldName="zbi_id"
          children={(field) =>
            <Input className="max-w-[200px]" {...field} value={latestZbiAssessment?.total_score} disabled />
          }
        />
        <ButtonWithIcon
          onClick={() => openAssessment(AssessmentSlug.ZARIT)}
          text={"Take Zarit Assessment"}
          icon={IconOption.PLUS}
        />
      </div>
      <div className="mt-4 mb-4">{caregiverYearsInRoleText}</div>
      {/* PCG Years in Role */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Years in Role (if unknown leave blank)"
        fieldName="pcg_role_years"
        children={(field) => (
          <Input className="max-w-[200px]" type={"number"} {...field} />
        )}
      />
      {/* PCG Months in Role */}
      <CustomFormField
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth="400px"
        labelName="Months in Role (if unknown leave blank)"
        fieldName="pcg_role_months"
        children={(field) => (
          <Input className="max-w-[200px]" type={"number"} {...field} />
        )}
      />
    </>
  )
}