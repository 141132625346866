import { CaregiverStatus, ReassessmentReason } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getCurrentValueForSelect, getOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { RadioButtonsGroup } from "components/RadioGroup";
import { Select } from "components/Select/Select";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { z } from "zod";


interface ReassessmentInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
  // reassessmentReason: ReassessmentReason
}

export const ReassessmentInputs: React.FC<ReassessmentInputsProps<z.infer<typeof PAFSchema>>>  = ({ form, isReadOnly }) => {
  const formValues = form.watch()
  return (
    <>
      {/* Reassessment Reason */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        inlineLabel={true}
        labelWidth={"200px"}
        labelName="Reassessment Reason"
        fieldName="reassessment_reason"
        children={(field) => (
          <Select 
            classNames="items-center"
            options={getOptionsFromEnum(ReassessmentReason)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* Caregiver Status */}
      {formValues.reassessment_reason == ReassessmentReason.REASSESSMENT_DUE_TO_CHANGE_IN_CAREGIVER_STATUS && (
        <div className="">
          <CustomFormField 
            form={form}
            disabled={isReadOnly}
            labelName="Caregiver Status"
            fieldName="caregiver_status"
            children={(field) => (
              <RadioButtonsGroup 
                options={getOptionsFromEnum(CaregiverStatus)}
                row={false}
                value={field.value}
                {...field}
              />
            )}
          />
          {/* Caregiver Status */}
          <CustomFormField 
            form={form}
            disabled={isReadOnly}
            labelName="If Caregiver Status is other, please specify"
            fieldName="caregiver_status_os"
            inlineLabel={true}
            children={(field) => (
              <Input {...field} value={field.value}/>
            )}
          />
        </div>
      )}
    </>
  )
}