// ... other imports

import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useActiveOrg, useOrgs } from "backend/resources/orgRole";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import DiscussionsComponent from "components/DiscussionComponent/DiscussionsComponent";
import { DEFAULT_HELPLINE_PHONE_NUMBER } from "components/OrganizationPage/tabs/GeneralOrganizationTab";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";

export default function DiscussionsPage() {
  useAlfredPageSideBar("discussionsPage")
  const { data: activeOrg } = useActiveOrg()
  const helplineNumber = activeOrg?.helpline_phone_number ?? DEFAULT_HELPLINE_PHONE_NUMBER
  const { hasCareCentralAccess } = useOrgs();

  return (
    <PageContainer>
      <div className="max-w-4xl pb-20">
        <PageMainHeader text={"Discussions"} />
        {!hasCareCentralAccess && < div className="flex  gap-5 mt-4 w-min line-clamp-1 whitespace-nowrap">
          <p className="font-bold">24/7 Helpline</p>
          <ButtonWithIcon
            onClick={() => {
              window.location.href = `tel:${helplineNumber}`;
            }}
            text={helplineNumber}
            size={"small"}
            icon={IconOption.PHONE}
          />
        </div>}
        <DiscussionsComponent />
      </div>
    </PageContainer >
  );
}