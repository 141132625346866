import { cva } from "class-variance-authority";

export const navItemStyles = cva([], {
  variants: {
    size: {
      small: ["text-sm", " h-2"],
      medium: ["text-base"],
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: "medium",
  },
});

export const iconWrapperStyles = cva([], {
  variants: {
    size: {
      small: ["w-4", "h-4"],
      medium: ["w-6", "h-6"],
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: "medium",
  },
});
