import { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

export default function Tabs({
  tabs,
  currentTab,
  setCurrentTab,
  className,
  queryParam = "tab",
  showBorder
}: {
  tabs: string[];
  currentTab: string;
  setCurrentTab: (tab: any) => void;
  className?: string;
  queryParam?: string;
  showBorder?: boolean
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const [myNavigationType, setMyNavigationType] = useState<
    typeof navigationType | null
  >(navigationType); // Create a state variable

  const resetNavigationType = () => setMyNavigationType(null); // Function to reset navigationType
  const queryParams = new URLSearchParams(location.search);

  // Update the state variable whenever navigationType changes
  useEffect(() => {
    setMyNavigationType(navigationType);
  }, [navigationType]);

  useEffect(() => {
    const urlTab = queryParams.get(queryParam);
    if (urlTab === currentTab) {
      return;
    }
    // Check if the last action was a POP action
    const isPop = myNavigationType === "POP";
    const isPush = myNavigationType === "PUSH";
    const isReplace = myNavigationType === "REPLACE";

    // TODO: clean this up
    if (isPop || isPush) {
      if (urlTab) {
        setCurrentTab(urlTab);
      } else {
        queryParams.set(queryParam, currentTab);
        navigate(`${location.pathname}?${queryParams.toString()}`, {
          replace: true,
        });
      }
      resetNavigationType();
    } else if (isReplace && urlTab) {
      setCurrentTab(urlTab);
    }
  }, [currentTab, location, queryParam, queryParams]);

  return (
    <div className={`flex w-auto gap-4 justify-start pr-5 ${className}`}>
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`
          md:text-lg 
          ${showBorder ? "px-1 border-l-2" : ""}           
          ${currentTab === tab ? "text-brand-orange border-brand-orange" : "text-black border-white"}
          `}
          onClick={() => {
            setCurrentTab(tab);
            queryParams.set(queryParam, tab);
            navigate(`${location.pathname}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
          style={{
            color: currentTab === tab ? "#F8A01B" : "black",
          }}>
          {tab}
        </button>
      ))}
    </div>
  );
}