import { useClickOutside } from "hooks/useClickOutside";
import type { MouseEvent, ReactNode, RefObject } from "react";
import { useRef } from "react";
import { createPortal } from "react-dom";

interface Props {
  children: ReactNode;
  close: (e?: MouseEvent<HTMLButtonElement>) => void;
  excludedElementRefs?: (RefObject<HTMLElement> | null)[];
  containerClassName?: string;
  closeOnClickOutside?: boolean;
  showCloseButton?: boolean;
  title?: string;
}

export function Popup({
  children,
  close,
  excludedElementRefs,
  title,
  containerClassName,
  closeOnClickOutside = true,
  showCloseButton = true,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside({
    ref,
    isEnabled: closeOnClickOutside,
    onClickOutside: close,
    excludedElementRefs,
  });

  return createPortal(
    <div className={`z-20 max-h-full fixed top-0 left-0 w-screen h-full bg-modal-gray overflow-auto flex justify-center items-center`}>
      <div
        ref={ref}
        className={`bg-white rounded-md text-sm border border-faint-gray shadow-sm flex flex-col p-2 ${containerClassName} ${!showCloseButton ? "pt-4" : ""
          }`}>
        {showCloseButton && (
          <button
            type="button"
            className="self-end hover:scale-[1.15] transition-transform -mt-1"
            onClick={close}>
            <span
              className="text-2xl text-brand-orange"
              aria-hidden="true">
              &times;
            </span>
          </button>
        )}
        <div className="px-3 py-2 pt-0 overflow-y-scroll">
          <h1 className="text-2xl">{title}</h1>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
}
