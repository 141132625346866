/**
 * @fileoverview Shared page header component, used as the main header for all page routes.
 */

import { useEffect, useRef, type ReactNode } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
  noPadding?: boolean;
  height?: string;
}

export function PageContainer({
  children,
  noPadding,
  height = "h-full",
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { pathname, search } = useLocation();

  useEffect(() => {
    const id = setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
    }, 0);

    return () => {
      clearTimeout(id);
    };
  }, [pathname, search]);

  return (
    <div
      ref={containerRef}
      className={`w-full ${height} box-border min-w-0 ${
        !noPadding ? "px-6 md:px-10 py-4 md:py-8 pb-20" : ""
      } overflow-y-scroll`}>
      {children}
    </div>
  );
}
