import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserAssessmentChatGptIntervention, useDeleteUserInterventions, useInterventionsForRecommendation } from "backend/resources/userIntervention";
import { useRecommendation, useUpdateUserRecommendation } from "backend/resources/userRecommendation";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import ActionButtons from "shared/ui/action-buttons";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CloseButton } from "components/CloseButton/CloseButton";
import ModalInMobileView from "components/ModalView/ModalInMobileView";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import InterventionCard from "components/GuidancePage/Recommendations/Recommendation/Interventions/Intervention/InterventionCard";

export default function EditRecommendationPage() {
  const navigateBack = useNavigateBack();
  const navigate = useAppNavigate();
  const { id: recommendationId } = useParams();

  // states
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [details, setDetails] = useState<string | undefined>(undefined);
  const [localInterventions, setLocalInterventions] = useState<UserAssessmentChatGptIntervention[]>([]);

  // Queries
  const { recommendation } = useRecommendation(recommendationId);
  const { data: interventions } = useInterventionsForRecommendation(recommendationId);

  // Mutations
  const updateUserRecommendation = useUpdateUserRecommendation().mutateAsync;
  const deleteUserInterventions = useDeleteUserInterventions().mutateAsync;



  useEffect(() => {
    setTitle(recommendation?.title);
    setDetails(recommendation?.details);
    setLocalInterventions(interventions ?? []);
  }, [recommendation, interventions]);

  return <ModalInMobileView title="Edit Recommendation" onClose={() => { }}>
    <div className="flex flex-col gap-4 font-light pb-20">
      <div className="flex w-full justify-end gap-5 -mt-10">
        <ActionButtons>
          <>
            <ButtonWithIcon
              text={"Cancel"}
              size={"small"}
              className="flex  my-4"
              icon={IconOption.CANCEL}
              onClick={navigateBack}
            />
            <ButtonWithIcon
              text={"Save"}
              size={"small"}
              className="flex  my-4"
              icon={IconOption.CHECKMARK}
              onClick={async () => {
                await Promise.all([
                  updateUserRecommendation({
                    id: recommendationId,
                    recommendation_id: recommendation?.recommendation?.id,
                    title: title,
                    details: details,
                  }),
                  deleteUserInterventions({
                    userInterventionIds: interventions?.filter(
                      (intervention) => !localInterventions.some((local) => local.id === intervention.id)
                    ).map((i) => i.id) ?? []
                  })
                ]);
                navigateBack();
              }}
            />
          </>
        </ActionButtons>
      </div>
      <label>Title</label>
      <TextInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      {/* Details */}
      <label>Details</label>
      <TextArea
        value={details}
        onChange={(e) => setDetails(e.target.value)}
      />
      <ButtonWithIcon
      onClick={() => {
        if(!recommendationId) return;
        navigate({
          path: CareCentralRoute.INTERVENTION_CREATE,
          params: {
            user_recommendation_id: recommendationId
          }
        })
      }}
      text={"Suggestions"}
      size={"small"}
      className="flex flex-row-reverse justify-end my-4"
      icon={IconOption.PLUS}
    />
      {localInterventions?.map((intervention) => (
        <div className="flex gap-2" key={intervention.id}>
          <button
            type="button"
            className="hover:scale-[1.15] transition-transform z-[2]"
            onClick={(event) => {
              event.preventDefault();
              setLocalInterventions(localInterventions?.filter((i) => i.id !== intervention.id));
            }}>
            <CloseButton />
          </button>
          {intervention && <InterventionCard intervention={intervention as UserAssessmentChatGptIntervention} />}
        </div>
      ))}

    </div>
  </ModalInMobileView>
}