import PCPForm, { usePCPForm, useSubmitPCPForm } from "shared/forms/CarespaceCreationWizard/PCPForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useActiveNetworkId } from "state/network/network";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";


export default function ChangePCPView({ onClose }: { onClose: () => void }) {
  const networkId = useActiveNetworkId()
  const pcpForm = usePCPForm()

  // Queries
  const submitPCPForm = useSubmitPCPForm().mutateAsync

  return <ResponsiveModal
    isOpen={true}
    onClose={onClose}
    title="Change PCP"
    closeText="Cancel"
    footerButtons={
      <>
        <ButtonWithIcon
          icon={IconOption.ARROW}
          onClick={pcpForm.handleSubmit((values) => {
            if (networkId) {
              submitPCPForm({ data: values, networkId })
              onClose()
            }
          })}
          text="Change PCP"
        />
      </>
    }
  >
    <PCPForm form={pcpForm} />
  </ResponsiveModal >
}

