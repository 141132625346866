import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QUERY_KEYS } from "backend/queryKeys";
import { fetchAdloFirstName } from "backend/resources/userAdlo";
import type { Video } from "backend/resources/video/video";
import { supabase } from "clients/supabaseClient";
import { useUserStore } from "state/user";
import { VideosWithCategory } from "components/VideoLibraryPage/VideosWithCategory";

export function VideoLibraryPage() {
  const { videoId } = useParams<{ videoId?: string }>();
  const authUser = useUserStore((state) => state.user);
  const [currentVideo, setCurrentVideo] = useState<Pick<
    Video,
    "id" | "slug" | "url"
  > | null>();
  const { data: adloPreferredName, isLoading: isLoadingAdloPreferredName } =
    useQuery({
      queryKey: [QUERY_KEYS.adloPreferredName, { userId: authUser?.id }],
      queryFn: () => fetchAdloFirstName(authUser?.id),
    });
  const [contentCategories, setContentCategories] = useState<
    {
      created_at: string;
      id: string;
      name: string;
    }[]
  >();

  async function fetchContentCategories() {
    const { data, error } = await supabase.from("content_category").select("*");
    if (data && data.length > 0) {
      setContentCategories(data);
    }
    // TODO(jen): handle error
  }

  useEffect(() => {
    fetchContentCategories();
  }, []);

  async function fetchVideo() {
    if (!videoId) return;
    const { data } = await supabase
      .from("video")
      .select("id, url, slug")
      .eq("id", videoId)
      .limit(1)
      .single();

    if (data) {
      setCurrentVideo(data);
    }
  }

  useEffect(() => {
    if (videoId) {
      fetchVideo();
    }
  }, [videoId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
      className="py-8 relative pb-24 w-full">
      {contentCategories?.map((category) => (
        <>
         

          <VideosWithCategory
            category={category.name}
            showAsCarousel={true}
          />
        </>
      ))}
    </motion.div>
  );
}
