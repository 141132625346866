import type { VariantProps } from "class-variance-authority";
import { ButtonIconMap, IconOption, IconSize, displayStyles, textStyles } from "components/ButtonWithIcon";
import { renderIcon } from "utils";

interface ButtonWithIconProps {
  onClick: (() => void) | any;
  disabled?: boolean;
  text: string;
  icon: IconOption;
  className?: string;
  form?: string;
  type?: "submit" | "button"
  size?: "extra_small" | "small" | "medium" | "large"
}

export function ButtonWithIcon({
  onClick,
  disabled,
  text,
  icon,
  className,
  type = "button",
  size = "medium",
  ...variantProps
}: ButtonWithIconProps & 
  VariantProps<typeof displayStyles>
  & VariantProps<typeof textStyles>) {
  const displayStyle = displayStyles(variantProps);

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`pointer-events-auto items-center gap-2 ${disabled ? "opacity-30" : "opacity-100"
        } ${displayStyle} ${className}`}>
      <div className={`flex items-center`}>
        {renderIcon(ButtonIconMap[icon], IconSize[size as keyof typeof IconSize])}
      </div>
      {text ? <p className={`${variantProps.truncate ? "truncate" : ""}`}>{text}</p> : null}
    </button>
  );
}