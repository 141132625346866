import * as d3 from 'd3';
import { useEffect, useRef } from "react";

// Define the data type for the donut chart
export type DonutChartData = { section: string, value: number };

// Define types for D3 selections and paths
type ArcType = d3.Selection<SVGGElement, d3.PieArcDatum<DonutChartData>, SVGGElement, unknown>
type PathType = d3.Arc<any, d3.DefaultArcObject>

// Define constants for the donut chart
const DEFAULT_RADIUS = 80;
const PAD_ANGLE = 0.01;
const SVG_SIZE_MULITPLIER = 2;



export function DonutChart({ data, radius = DEFAULT_RADIUS, colorMapping }: { data: DonutChartData[], radius?: number, colorMapping: Record<string, string> }) {
  const ref = useRef<SVGSVGElement | null>(null);
  const total = data.reduce((sum, entry) => sum + entry.value, 0);

  // Use effect to create the chart when the data or radius changes
  useEffect(() => {
    if (ref.current) {
      createChart(ref.current);
    }
  }, [data, radius]);

  function createChart(svgElement: SVGSVGElement) {
    const svg = d3.select(svgElement);
    svg.selectAll("*").remove();

    const svgWidth = radius * SVG_SIZE_MULITPLIER; // Add extra width for labels
    const svgHeight = radius * SVG_SIZE_MULITPLIER; // Add extra height for labels

    svg.attr("width", svgWidth)
      .attr("height", svgHeight);

    // Create a group element and center it in the SVG
    const g = svg.append("g")
      .attr("transform", `translate(${svgWidth / 2}, ${svgHeight / 2})`);

    if (data.length === 0 || total === 0) {
      g.append("path")
        .attr("d", d3.arc()
          .outerRadius(radius)
          .innerRadius(radius / 2)
          .startAngle(0)
          .endAngle(2 * Math.PI) as any)
        .attr("fill", "#D1D5DB");
      return
    }

    const pie = d3.pie<DonutChartData>().value((d) => d.value);

    // Define the arc for the donut chart
    const path = d3.arc()
      .outerRadius(radius)
      .innerRadius(radius / 2)
      .padAngle(PAD_ANGLE)
      .padRadius(radius);

    // Create the arcs for the donut chart
    const arc = g.selectAll(".arc")
      .data(pie(data))
      .enter().append("g")
      .attr("class", "arc");

    drawArcs(arc, path);
    // drawLabels(arc);
    // drawLinesToLabels(arc, path)
  }


  function drawArcs(arc: ArcType, path: PathType) {
    arc.append("path")
      .attr("d", d => path(d as unknown as d3.DefaultArcObject) as string)
      .attr("fill", d => (colorMapping[d.data.section] ?? "#AAAAAA"));
  }

  return <svg ref={ref} />;
};
