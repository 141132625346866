import { useWindowSize } from "hooks/useWindowSize";
import AlfredIcon from "components/Alfred/Alfred";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarNewAssessmentPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"newAssessmentPage"}
        pageType="newAssessmentPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {

  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6">
        {/* intro PP */}
        <p>
          In order to provide the most relevant and impactful interventions and
          other recommendations, I need to understand how your loved one is
          currently doing with respect to four areas: behavior, function,
          cognition and safety.
        </p>
        <p>
          This shouldn’t take more than 5 minutes and you can retake it anytime
          you want if there are any changes in Nana’s condition. If you want to
          come back and finish it later, just click Finish Later and you’re all
          set.
        </p>
      </div>
    </div>
  );
}
