import { useEffect, useRef, useState } from "react";
import { ReactComponent as Send } from "assets/send.svg";
import { useMutateChatGptMessages } from "backend/resources/chatGptMessage";
import { useWindowSize } from "hooks/useWindowSize";
import { useGptStore } from "state/gpt";
import { useUserStore } from "state/user";
import { ProfileImage } from "components/Profile/ProfileImage";
import { TextInput } from "components/TextInput";

type Props = {
  closeEventSource: () => void;
  persistMessage: () => void;
  persistUserMessage: (userMessage: string) => void;
  isDiscussion?: boolean;
  inputPlaceholderText?: string;
};

export function ChatGptSideBarInput({
  closeEventSource,
  persistMessage,
  persistUserMessage,
  isDiscussion,
  inputPlaceholderText,
}: Props) {
  /**
   * Stores
   */

  const [userMessage, setUserMessage] = useState("");

  const authUser = useUserStore((state) => state.user);
  const conversationId = useGptStore((state) => state.conversationId);
  const streamingMessage = isDiscussion
    ? undefined
    : useGptStore((state) => state.streamingMessage);

  const recommendationId = useGptStore(
    (state) => state.userAssessmentChatGptRecommendationId
  );
  const shouldDisableInput =
    useGptStore((state) => state.shouldDisableInput) && !isDiscussion;
  const messageError = useGptStore((state) => state.messageError);
  const setMessageError = useGptStore((state) => state.setMessageError);

  // mobile behavior to handle virtual keyboard interactions
  const inputRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useWindowSize();

  const [bottomOffset, setBottomOffset] = useState(false);

  const handleFocus = () => setBottomOffset(true); // or whatever value works for you

  const [isButtonPressed, setIsButtonPressed] = useState(false);

  const handleBlur = () => {
    // Delay the setBottomOffset action to let button click register first
    setTimeout(() => {
      if (!isButtonPressed) {
        setBottomOffset(false);
      }
    }, 0);
  };

  const handleMouseDown = () => {
    setIsButtonPressed(true);
  };

  const handleMouseUp = () => {
    setIsButtonPressed(false);
    persistMessage();
    persistUserMessage(userMessage);
    setUserMessage("");
  };

  useEffect(() => {
    const handleGlobalBlur = () => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
      handleBlur();
    };

    window.addEventListener("blur", handleGlobalBlur);

    return () => {
      window.removeEventListener("blur", handleGlobalBlur);
    };
  }, []);

  /**
   * Mutations
   */

  const chatGptMessagesMutation = useMutateChatGptMessages();

  /**
   * Effects
   */

  useEffect(() => {
    if (messageError) {
      const timeout = setTimeout(() => {
        setMessageError();
      }, 3000);

      return () => clearTimeout(timeout); // Clear the timeout when the component unmounts
    }
  }, [messageError]);

  return (
    <div className="flex flex-col w-full gap-2 shrink-0">
      {/* system messages */}
      <div className="flex flex-col min-h-10 pt-2">
        {streamingMessage && streamingMessage.length > 80 && conversationId ? (
          <div className="w-full justify-center flex">
            <button
              className="bg-brand-orange rounded-lg text-white p-2"
              onClick={async () => {
                closeEventSource();
                persistMessage();
                await chatGptMessagesMutation.mutateAsync({
                  message: {
                    chat_gpt_conversation_id: conversationId,
                    role: "system",
                    content: "Ok, I'll stop.",
                    type: "ai",
                    send_to_chat_gpt: false,
                    user_id: authUser?.id,
                  },
                  threadId: recommendationId || "",
                });
              }}>
              Hey Alfred, that's enough
            </button>
          </div>
        ) : null}
        {messageError && (
          <div className="bg-brand-orange w-full p-4 flex flex-col font-poppins italic  text-sm -mt-12 relative">
            <button
              type="button"
              className="absolute top-0 right-1 "
              onClick={() => {
                setMessageError();
              }}>
              <span
                className="text-lg text-white"
                aria-hidden="true">
                &times;
              </span>
            </button>
            <label className="text-white h-fit">
              Sorry, there seems to be an issue with the system right now.
              Please try again or contact support if it persists.
            </label>
          </div>
        )}
      </div>
      {/* input */}
      <div className="flex w-full gap-3 p-4 justify-center items-center">
        <ProfileImage
          size={32}
          userId={authUser?.id}
        />
        <form
          onSubmit={persistMessage}
          className="flex w-full items-center gap-3">
          <TextInput
            ref={inputRef}
            data-hj-allow
            disabled={!!shouldDisableInput}
            placeholder={inputPlaceholderText || "Type a question..."}
            className={`w-full text-lg ${
              shouldDisableInput ? "bg-gray-100" : "bg-gray-50"
            } outline-none !leading-5`}
            onChange={(e) => {
              setMessageError();
              if (!streamingMessage) {
                setUserMessage(e.currentTarget.value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                inputRef.current?.blur();
                handleBlur();
                persistUserMessage(userMessage);
                setUserMessage("");
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={userMessage}
          />
          <button
            type="button"
            disabled={!!shouldDisableInput}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}>
            <Send className="w-6 h-6 hover:scale-[1.15] transition-transform hover:-translate-y-1" />
          </button>
        </form>
      </div>
      {/* SCROLL AWARE VIEW BOTTOM PADDING */}
      <div className={isMobile && bottomOffset ? "mb-72" : ""} />
    </div>
  );
}
