import { useLaunchDarklyInfoQuery } from "backend/functions";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";
import { useUserStore } from "state/user";


export default function LaunchDarklyProvider({ children }: { children: ReactNode }) {
  const authUser = useUserStore((state) => state.user);
  const { data } = useLaunchDarklyInfoQuery();

  const attributes = data?.attributes;
  const LDProviderWithUser = withLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_KEY,
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
    user: {
      key: data?.encrypted_user_id,
      custom: attributes,
    },
  })(() => <>{children}</>);
  // If there is no authUser, don't initialize LD at all
  if (!authUser) {
    return <>{children}</>;
  }

  return <LDProviderWithUser />;
}