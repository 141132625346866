import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CraniometrixProduct,
  useProductAccess,
} from "hooks/product/product";
import { SharedRoute, createPath } from "lib/routing";
import { resetAssessmentStore } from "state/assessment";
import { LogoutReasonType, useLogoutReasonStore } from "state/logout/logout";
import { useUserStore } from "state/user";
import { ProfileImage } from "components/Profile/ProfileImage";
import { BottomSheet } from "components/Sheet";
import OrganizationSwitcher from "components/Header/ProfileMenu/OrganizationSwitcher/OrganizationSwitcher";

export function MobileHeaderMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const signOut = useUserStore((state) => state.signOut);
  const resetUserStore = useUserStore((state) => state.reset);
  const [isOrganizationSwitcherOpen, setIsOrganizationSwitcherOpen] =
    useState(false);
  const setLogoutReason = useLogoutReasonStore((state) => state.setLogoutReason)

  const { data: productAccess } = useProductAccess();
  const authUser = useUserStore((state) => state.user);

  return (
    <>
      <button
        className="h-full"
        onClick={() => setIsOpen(!isOpen)}>
        <ProfileImage
          size={28}
          userId={authUser?.id}
        />
      </button>
      <OrganizationSwitcher
        isOpen={isOrganizationSwitcherOpen}
        close={() => setIsOrganizationSwitcherOpen(false)}
      />
      <BottomSheet
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}>
        <ul className=" flex flex-col justify-center">
          <MobileMenuNavItem
            isFirst={true}
            setIsOpen={setIsOpen}
            href={
              createPath({
                path: SharedRoute.MY_ACCOUNT,
                queryParams: {
                  tab: "Profile"
                }
              })
            }
            label="My Account"
          />
          {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
            <li className="p-5 flex justify-center hover:bg-gray-100 border-b border-black">
              <button
                className=" w-full"
                onClick={() => {
                  setIsOrganizationSwitcherOpen(true);
                  setIsOpen(false);
                }}>
                Switch Organizations
              </button>
            </li>
          ) : null}
          <li className="p-8 flex justify-center hover:bg-gray-100">
            <button
              onClick={async () => {
                // TODO: handle error
                resetUserStore();
                resetAssessmentStore();
                setLogoutReason(LogoutReasonType.USER_INITIATED)
                await signOut();
              }}
              className=" w-full">
              Sign Out
            </button>
          </li>
        </ul>
      </BottomSheet>
    </>
  );
}

function MobileMenuNavItem({
  isFirst,
  href,
  label,
  setIsOpen,
}: {
  isFirst?: boolean;
  href: string;
  label: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();

  return (
    <li
      className={`p-5 ${isFirst ? "border-b-[1px]" : "border-y-[1px]"
        } border-black flex justify-center hover:bg-gray-100 rounded-t-lg`}>
      <button
        className=" w-full"
        onClick={() => {
          setIsOpen(false);
          navigate(href);
        }}>
        {label}
      </button>
    </li>
  );
}
