import { RouteObject } from "react-router-dom";

export type RoutLayoutObject = RouteObject & {
  routeLayout?: RouteLayout;
  hideSideNav?: boolean;
};

export enum RouteLayout {
  APP_LAYOUT,
  PROTECTED_ROUTE,
  PROTECTED_ROUTE_WITH_APP_LAYOUT
}