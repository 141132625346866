import { CarePlanGoalStatuses, CarePlanGoalWithProgress } from 'backend/resources/carePlan/carePlan';
import { Timeline } from 'components/GoalPage/utils';
import { useEffect, useMemo } from 'react';

export function useCarePlanComputations(
  currentCarePlanGoals: CarePlanGoalWithProgress[],
  suggestedGoals: CarePlanGoalWithProgress[] | undefined,
  archivedGoals: CarePlanGoalWithProgress[] | undefined,
  goalsInSavedDraft: CarePlanGoalWithProgress[] | undefined,
  goalsInPublishedPlan: CarePlanGoalWithProgress[] | undefined,
  userAdlo: { id: string } | undefined,
  setCurrentCarePlanGoalsByAdlo: (id: string, goals: CarePlanGoalWithProgress[]) => void
) {
  // Initialize current care plan goals if not already set
  useEffect(() => {
    if (
      (goalsInSavedDraft || goalsInPublishedPlan) &&
      userAdlo &&
      currentCarePlanGoals.length === 0
    ) {
      setCurrentCarePlanGoalsByAdlo(userAdlo.id, goalsInSavedDraft ?? goalsInPublishedPlan ?? []);
    }
  }, [goalsInSavedDraft, goalsInPublishedPlan, userAdlo, currentCarePlanGoals.length, setCurrentCarePlanGoalsByAdlo]);

  // Compute goals by timeline
  const goalsByTimeline = useMemo(() => {
    const filteredCurrentCarePlanGoals = currentCarePlanGoals.filter((carePlanGoal) =>
      [CarePlanGoalStatuses.INCLUDED, CarePlanGoalStatuses.ARCHIVED].includes(
        carePlanGoal.status as CarePlanGoalStatuses
      )
    );

    return {
      short: filteredCurrentCarePlanGoals.filter(
        (goal) => goal.timeline === Timeline.SHORT_TERM
      ),
      mid: filteredCurrentCarePlanGoals.filter(
        (goal) => goal.timeline === Timeline.MID_TERM
      ),
      long: filteredCurrentCarePlanGoals.filter(
        (goal) => goal.timeline === Timeline.LONG_TERM
      ),
    };
  }, [currentCarePlanGoals]);

  // Compute draft goals by timeline
  const draftGoalsByTimeline = useMemo(() => {
    if (!suggestedGoals) return { short: [], mid: [], long: [] };

    const filteredSuggestedGoals = suggestedGoals.filter(
      (goal) => !currentCarePlanGoals.some((g) => g.id === goal.id)
    );

    return {
      short: filteredSuggestedGoals.filter(
        (goal) => goal.timeline === Timeline.SHORT_TERM
      ) ?? [],
      mid: filteredSuggestedGoals.filter((goal) => goal.timeline === Timeline.MID_TERM) ??
      [],
      long: filteredSuggestedGoals.filter(
        (goal) => goal.timeline === Timeline.LONG_TERM
      ) ?? [],
    };
  }, [suggestedGoals, currentCarePlanGoals]);

  // Compute archived goals by timeline
  const archivedGoalsByTimeline = useMemo(() => {
    if (!archivedGoals) return { short: [], mid: [], long: [] };

    return {
      short:
        archivedGoals.filter((goal) => goal.timeline === Timeline.SHORT_TERM) ?? [],
      mid:
        archivedGoals.filter((goal) => goal.timeline === Timeline.MID_TERM) ?? [],
      long:
        archivedGoals.filter((goal) => goal.timeline === Timeline.LONG_TERM) ?? [],
    };
  }, [archivedGoals]);

  return {
    goalsByTimeline,
    draftGoalsByTimeline,
    archivedGoalsByTimeline
  };
}