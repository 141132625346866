import logo from "assets/logo.png";
import {
  CraniometrixProduct,
  useProductAccess,
} from "hooks/product/product";

const ENV_NAME = import.meta.env.VITE_ENV_NAME;
interface Props {
  size?: "small" | "large";
  isDisabled?: boolean;
  product?: CraniometrixProduct | "base";
  isCentered?: boolean;
  onClick?: () => void;
}
function shouldShowEnvName() {
  if (ENV_NAME === "prod") return false;
  else return true;
}

export default function Logo(props: Props) {
  const { data: activeProduct } = useProductAccess();

  return (
    <button
      disabled={props.isDisabled}
      className={`flex flex-col justify-center ${props.isCentered ? "items-center" : "md:items-start"
        } w-full`}
      onClick={props.onClick}>
      <img
        className={`ml-0.5 ${props.size === "small"
            ? "w-8"
            : props.size === "large"
              ? "w-20"
              : "w-12"
          }`}
        src={logo}
        alt="logo"
      />
      {activeProduct === CraniometrixProduct.CARE_CENTRAL ||
        props.product === CraniometrixProduct.CARE_CENTRAL ? (
        <span
          className={`${props.size === "small"
              ? "text-base"
              : props.size === "large"
                ? "text-2xl"
                : "text-xl"
            } flex items-center`}>
          Care<span className="font-bold">Central</span>
          {shouldShowEnvName() && <p className="text-xs">({ENV_NAME})</p>}
        </span>
      ) : activeProduct === CraniometrixProduct.CARE_PILOT ||
        props.product === CraniometrixProduct.CARE_PILOT ? (
        <span
          className={`${props.size === "small"
              ? "text-base"
              : props.size === "large"
                ? "text-2xl"
                : "text-xl"
            } flex items-center`}>
          Care<span className="font-bold">Pilot</span>
          {shouldShowEnvName() && <p className="text-xs">({ENV_NAME})</p>}
        </span>
      ) : null
      }
    </button >
  );
}
