import { useWindowSize } from "hooks/useWindowSize";
import type { ReactNode } from "react";

interface Props {
  leftSlot?: ReactNode;
  centerSlot?: ReactNode;
  hideAlfred?: boolean;
}

/**
 * @deprecated This component is being replaced by ActionButtons
 */

export default function BottomActionBar(props: Props) {
  const { isMobile } = useWindowSize();

  if (!isMobile) return null;

  return (
    (<div className="flex justify-center w-full fixed bottom-20 z-10 left-0 pointer-events-none">
      <div className="w-[80%] flex justify-end items-center h-8 py-5 pointer-events-none">
        <div className="w-full grid grid-cols-3">
          {/* left slot */}
          {props.leftSlot ? (
            <div className="flex justify-center pointer-events-none">
              {props.leftSlot}
            </div>
          ) : (
            // spacer
            (<div className="pointer-events-none" />)
          )}
          {/* center slot */}
          {props.centerSlot ? (
            <div className="flex justify-center pointer-events-none">
              {props.centerSlot}
            </div>
          ) : (
            // spacer
            (<div className="pointer-events-none" />)
          )}
          {/* alfred button spacer */}
          <div className="pointer-events-none" />
        </div>
      </div>
    </div>)
  );
}
