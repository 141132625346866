import { AddBookarksModalProps, AddBookmarksModalContent } from "components/AddBookmarksModal";
import { ResponsiveModal } from "shared/ui/responsive-modal";

const TITLE = "Library";

export function AddBookmarksModal(props: AddBookarksModalProps) {
  return (
    <ResponsiveModal
      onClose={() => props.setShowPopover(false)}
      isOpen={true}
      closeText={props.closeText}
      customWidth="w-[90%]"
      customMaxWidth="max-w-[90%]"
      title={TITLE}
    >
      <AddBookmarksModalContent {...props} />
    </ResponsiveModal>
  );
}

