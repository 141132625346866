import dayjs from "dayjs";

export function getPluralDrugDoseDesc(drugDoseDesc: string) {
  const formDesc = drugDoseDesc.split(",")[0];
  return formDesc.endsWith("s") ? formDesc : formDesc + "s";
}

export function getCurrentYYYYMMDD() {
  const today = new Date();
  const date = today.toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
  return date;
}

/**
 * Returns a timezone agnostic timestamp in Postgres compatible format
 */
export function localTimeForPostgres(
  date: dayjs.Dayjs | null,
  yyyyMMdd: string
) {
  if (!date) return null;

  const hours = String(date.hour()).padStart(2, "0");
  const minutes = String(date.minute()).padStart(2, "0");
  const seconds = String(date.second()).padStart(2, "0");

  return `${yyyyMMdd} ${hours}:${minutes}:${seconds}`;
}
