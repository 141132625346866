import { useOrgs } from "backend/resources/orgRole";
import { useNetworkIds } from "backend/resources/userRole";
import { useAppOnboardingStore } from "state/appOnboarding";
import { useNetworkStore } from "state/network/network";
import { useUserStore } from "state/user";
import { useProductAccess } from "hooks/product/product";

export function useUserOnboarding() {
  const authUser = useUserStore((state) => state.user);
  const { isLoading: isOrgsLoading, hasOneOrMoreOrgIdentities } = useOrgs();

  const { activeNetworkId } = useNetworkStore();
  const { networkIdsIsLoading, networkIds } = useNetworkIds()

  const { data: productAccess, isLoading: isLoadingProductAccess } =
    useProductAccess();

  const selectedOrgRole = useAppOnboardingStore(
    (state) => state.selectedOrgRole
  );

  const currentCareCentralOnboardingPage = useAppOnboardingStore(
    (state) => state.currentCareCentralOnboardingPage
  );

  const currentCarePilotOnboardingPage = useAppOnboardingStore(
    (state) => state.currentCarePilotOnboardingPage
  );

  function checkIsCareCentralOnboardingShowing() {
    return !!currentCareCentralOnboardingPage;
  }


  function checkIsCarePilotOnboardingShowing() {
    const isCarePilotOnboardingOngoing = !!currentCarePilotOnboardingPage && currentCarePilotOnboardingPage !== "completed"
    return (
      isCarePilotOnboardingOngoing ||
      (!!authUser &&
        productAccess === "care pilot" &&
        !isLoadingProductAccess &&
        activeNetworkId !== undefined
        && networkIds && networkIds.length > 0)
    );
  }

  return {
    isLoading: isLoadingProductAccess || isOrgsLoading || networkIdsIsLoading,
    isUserRoleSelectionShowing:
      authUser &&
      !isOrgsLoading &&
      !hasOneOrMoreOrgIdentities &&
      !selectedOrgRole &&
      !activeNetworkId &&
      !networkIdsIsLoading &&
      networkIds && networkIds.length === 0,
    isCareCentralOnboardingShowing: checkIsCareCentralOnboardingShowing(),
    isCarePilotOnboardingShowing: checkIsCarePilotOnboardingShowing(),
  };
}
