import { useUserAdlos } from "backend/resources/userAdlo";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { useCarePlanComputations } from "components/CarePlan/carePlanHooks";
import { CarePlanTimelineComponent } from "components/CarePlan/subComponents/CarePlanTimeLineComponent";
import UpdateGoalPopup from "components/CarePlan/UpdateGoalPopup/UpdateGoalPopup";
import { Timeline } from "components/GoalPage/utils";
import ModalInMobileView from "components/ModalView/ModalInMobileView";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useCallback, useState } from "react";
import ActionButtons from "shared/ui/action-buttons";
import { useCarePlanStore } from "state/carePlan/carePlan";
import { NotificationType, sendNotification } from "../../backend/functions";
import type { CarePlanGoalWithProgress } from "../../backend/resources/carePlan/carePlan";
import {
  CarePlanGoalStatuses,
  useCurrentCarePlanGoalsQuery,
  useDraftCarePlanGoalsQuery,
  usePublishCarePlanMutation,
  useSaveDraftCarePlan,
} from "../../backend/resources/carePlan/carePlan";
import { setAlfredsPickOfTheDayInLocaleStrage } from "../../backend/resources/carePlanHighlights/carePlanHighlights";
import type { GoalWithStatus } from "../../backend/resources/goal/goal";
import { useArchivedGoalQuery, useGoalQuery } from "../../backend/resources/goal/goal";
import { useUserStore } from "../../state/user";

export default function EditCarePlanPage() {
  const authUser = useUserStore((state) => state.user);
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();

  // Queries
  const { data: userAdlos } = useUserAdlos();
  const userAdlo = userAdlos?.[0];
  const { suggestedGoals } = useGoalQuery();
  const { goals: archivedGoals } = useArchivedGoalQuery();
  const {
    data: goalsInSavedDraft,
    refetch: refetchGoalsInSavedDraft,
  } = useDraftCarePlanGoalsQuery();
  const { data: goalsInPublishedPlan } = useCurrentCarePlanGoalsQuery();

  // Mutations

  const saveDraftCarePlan = useSaveDraftCarePlan().mutateAsync;
  const publishCarePlan = usePublishCarePlanMutation().mutateAsync;
  
  // Local state
  const [updatePopup, setUpdatePopup] = useState<{
    isOpen: boolean;
    suggestedUpdate: CarePlanGoalWithProgress | null;
    existingGoal: CarePlanGoalWithProgress | null;
  }>({ isOpen: false, suggestedUpdate: null, existingGoal: null });

  // Global state
  const {
    reset: resetCarePlanStore,
    currentCarePlanGoalsByAdlo,
    setCurrentCarePlanGoalsByAdlo,
  } = useCarePlanStore();

  const currentCarePlanGoals =
    userAdlo && currentCarePlanGoalsByAdlo[userAdlo.id]
      ? currentCarePlanGoalsByAdlo[userAdlo.id]
      : [];

  const {
    goalsByTimeline,
    draftGoalsByTimeline,
    archivedGoalsByTimeline,
  } = useCarePlanComputations(
    currentCarePlanGoals,
    suggestedGoals,
    archivedGoals,
    goalsInSavedDraft,
    goalsInPublishedPlan,
    userAdlo,
    setCurrentCarePlanGoalsByAdlo
  );

  // Functions

  const hasDuplicateTitle = useCallback(
    (goal: CarePlanGoalWithProgress, goals?: CarePlanGoalWithProgress[]): boolean => {
      return goals !== undefined && goals.some((g) => g.title === goal.title);
    },
    []
  );

  const handleAddGoal = useCallback(
    (goal: GoalWithStatus) => {
      if (hasDuplicateTitle(goal, currentCarePlanGoals)) {
        setUpdatePopup({
          isOpen: true,
          suggestedUpdate: goal,
          existingGoal: currentCarePlanGoals.find((g) => g.title === goal.title) ?? null,
        });
      } else {
        setCurrentCarePlanGoalsByAdlo(userAdlo?.id ?? "", [
          ...currentCarePlanGoals,
          { ...goal, status: CarePlanGoalStatuses.INCLUDED },
        ]);
      }
    },
    [hasDuplicateTitle, currentCarePlanGoals, setCurrentCarePlanGoalsByAdlo, userAdlo?.id]
  );

  const handleToggleGoalStatus = useCallback(
    (goal: GoalWithStatus) => {
      setCurrentCarePlanGoalsByAdlo(
        userAdlo?.id ?? "",
        currentCarePlanGoals.map((carePlanGoalWithProgress) =>
          carePlanGoalWithProgress.id === goal.id
            ? {
              ...goal,
              status:
                carePlanGoalWithProgress?.status === CarePlanGoalStatuses.ARCHIVED
                  ? CarePlanGoalStatuses.INCLUDED
                  : CarePlanGoalStatuses.ARCHIVED,
            }
            : carePlanGoalWithProgress
        )
      );
    },
    [currentCarePlanGoals, setCurrentCarePlanGoalsByAdlo, userAdlo?.id]
  );

  const saveGoalsAsDraft = useCallback(async () => {
    await saveDraftCarePlan();
    navigateBack();
    await refetchGoalsInSavedDraft();
    resetCarePlanStore();
  }, [saveDraftCarePlan, navigateBack, refetchGoalsInSavedDraft, resetCarePlanStore]);

  const onCancel = useCallback(() => {
    resetCarePlanStore();
    navigateBack();
  }, [resetCarePlanStore, navigateBack]);

  const saveAndPublishCarePlan = useCallback(async () => {
    const newPlan = await publishCarePlan();
    setAlfredsPickOfTheDayInLocaleStrage([], authUser?.id); // reset it
    if (newPlan) {
      sendNotification(newPlan.id, NotificationType.DOCTOR_REVIEWED);
    }
    navigateBack();
    await refetchGoalsInSavedDraft();
    resetCarePlanStore();
  }, [publishCarePlan, authUser?.id, navigateBack, refetchGoalsInSavedDraft, resetCarePlanStore]);



  return (
    <ModalInMobileView title="Edit Care Plan" onClose={onCancel}>
      <UpdateGoalPopup
        suggestedUpdate={updatePopup.suggestedUpdate}
        existingGoal={updatePopup.existingGoal}
        isOpen={updatePopup.isOpen && !!updatePopup.suggestedUpdate && !!updatePopup.existingGoal}
        close={() => setUpdatePopup({ ...updatePopup, isOpen: false })}
      />

      {/* Action Buttons */}
      <div className="flex justify-end gap-5 my-5 w-full">
        <ActionButtons>
          <>
            <ButtonWithIcon
              onClick={() =>
                navigate({
                  path: CarePilotRoute.GOAL_EDIT,
                  queryParams: {
                    user_adlo_id: userAdlo?.id ?? "",
                  },
                })
              }
              size="small"
              text="Add Custom Goal"
              icon={IconOption.PLUS}
            />
            <ButtonWithIcon
              onClick={onCancel}
              text="Cancel"
              size="small"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={saveGoalsAsDraft}
              text="Save as Draft"
              size="small"
              icon={IconOption.CHECKMARK}
            />
            <ButtonWithIcon
              onClick={saveAndPublishCarePlan}
              text="Publish"
              size="small"
              icon={IconOption.UPLOAD}
            />
          </>
        </ActionButtons>
      </div>

      {/* Suggested vs Current Care Plan  */}
      <div className="grid grid-cols-2 gap-4">
        <p>Suggested New Goals</p>
        <p>Current Care Plan</p>
        {Object.values(Timeline).map((timeline) => (
          <>
            {/* Suggested  */}
            <CarePlanTimelineComponent
              timeline={timeline}
              goals={draftGoalsByTimeline[timeline]}
              buttonAction={handleAddGoal}
              isNewGoal={() => false}
              hasDuplicateTitle={(goal) => hasDuplicateTitle(goal, currentCarePlanGoals)}
            />
            {/* Current Care Plan */}
            <CarePlanTimelineComponent
              timeline={timeline}
              goals={goalsByTimeline[timeline]}
              buttonAction={handleToggleGoalStatus}
              middleColumn="Progress"
              isNewGoal={(goal) =>
                !!goalsInPublishedPlan && !goalsInPublishedPlan.some((g) => g.id === goal.id)
              }
              hasDuplicateTitle={(goal) =>
                hasDuplicateTitle(
                  goal,
                  suggestedGoals?.filter(
                    (g) => !currentCarePlanGoals.some((currentGoal) => currentGoal.id === g.id)
                  )
                )
              } />
          </>
        ))}
      </div>
      {/* Archived Goals */}

      <div className="grid grid-cols-2 gap-4">
        {/* We do grid-cols-2 to match section above */}
        <p>Archived Goals</p>
        <p/>
        {Object.values(Timeline).map((timeline) => (
          <>
            <CarePlanTimelineComponent
              timeline={timeline}
              goals={archivedGoalsByTimeline[timeline]}
              buttonAction={handleAddGoal}
              isNewGoal={() => false}
              hasDuplicateTitle={(goal) => hasDuplicateTitle(goal, currentCarePlanGoals)} />
              <p/>
          </>
        ))}
      </div>
    </ModalInMobileView>
  );
}