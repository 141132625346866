import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import type { Database } from "../../../../types/supabase";
import { queryClient } from "App";
import { supabase } from "clients/supabaseClient";
import { getUserInitials } from "components/MyPlanPage/utils";
import type { SideBarPageType } from "state/gpt";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user";
import { QUERY_KEYS } from "backend/queryKeys";
import { ConversationType, useServiceDiscussion, useServiceRequestDiscussion, useUserUpdateDiscussion } from "backend/resources/chatGptConversation";
import { useActiveNetwork, useNetworksInOrganization } from "backend/resources/network/network";
import { useOrgs } from "backend/resources/orgRole";

const TABLE = "chat_gpt_message";

// queries

export type ChatGptMessage =
  Database["public"]["Tables"]["chat_gpt_message"]["Row"] & {
    read_message: ReadMessage[]
  };

type ReadMessage = Database["public"]["Tables"]["read_message"]["Row"]


async function fetchChatGptMessagesByConversationId(
  conversationId?: string,
) {
  if (!conversationId) return null;
  const { data: messages, error: conversationError } = await supabase
    .from("chat_gpt_message")
    .select("*,read_message(*)")
    .eq("chat_gpt_conversation_id", conversationId)

  return messages?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  ) as ChatGptMessage[];
}


async function fetchChatGptMessagesByInterventionId(
  userInterventionId?: string,
  userId?: string
) {
  if (!userInterventionId || !userId) return null;
  const { data: conversationData, error: conversationError } = await supabase
    .from("user_intervention")
    .select("chat_gpt_conversation(id, chat_gpt_message(*,read_message(*)))")
    .eq("id", userInterventionId)
    .limit(1)
    .maybeSingle();
  return conversationData?.chat_gpt_conversation?.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  ) as ChatGptMessage[];
}



async function fetchChatGptMessagesByRecommendationId(
  user_recommendation_id?: string,
  userId?: string
) {
  if (!user_recommendation_id || !userId) return null;
  const { data: conversationData, error: conversationError } = await supabase
    .from("user_recommendation")
    .select("*, chat_gpt_conversation!user_recommendation_conversation_id_fkey(id, chat_gpt_message(*,read_message(*)))")
    .eq("id", user_recommendation_id)
    .limit(1)
    .maybeSingle();
  return conversationData?.chat_gpt_conversation?.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  ) as ChatGptMessage[];
}

export async function fetchChatGptMessagesByPageId(
  pageId?: string,
  userId?: string,
  networkId?: string | null | undefined
) {
  if (!pageId || !userId || !networkId) return null;
  // Execute the queries in parallel
  const { data: pageData, error: pageError } = await supabase
    .from("page_chat_gpt_conversation")
    .select(
      `
      id,
      chat_gpt_conversation_id,
      chat_gpt_conversation!inner (
        chat_gpt_message!inner(*)
      )
    `
    )
    .eq("page", pageId)
    .eq("user_id", userId)
    .eq("network_id", networkId);

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  let allMessages: ChatGptMessage[] = [];

  // Helper function to ensure data is in array format
  const ensureArray = (data: any) => (Array.isArray(data) ? data : [data]);

  // Helper function to extract messages
  const extractMessages = (data: any) => {
    for (const item of ensureArray(data)) {
      const chatGptConversations = ensureArray(item.chat_gpt_conversation);

      for (const chatGptConversation of chatGptConversations) {
        const messages = ensureArray(chatGptConversation?.chat_gpt_message);
        if (messages[0]) {
          // Check if the first item is not null or undefined
          allMessages = [...allMessages, ...messages];
        }
      }
    }
  };

  if (pageData) {
    // Process interventionData
    for (const pageConvo of pageData) {
      const pageConversations = ensureArray(pageConvo);
      extractMessages(pageConversations);
    }
  }

  allMessages.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );

  return allMessages;
}

async function fetchMessagesByUserUpdateId(userUpdateId?: string) {
  if (!userUpdateId) return null;
  // Execute the queries in parallel
  const { data: pageData, error: pageError } = await supabase
    .from("user_update")
    .select(
      "chat_gpt_conversation(chat_gpt_message(*,read_message(*)))"
    )
    .eq("id", userUpdateId)
    .limit(1)
    .returns<
      { chat_gpt_conversation: { chat_gpt_message: ChatGptMessage[] } }[]
    >()
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );
}


export type DiscussionMessage = ChatGptMessage & {
  read_message: ReadMessage[];
  network_id: string;
  title: string
  external_participant_id?: string
  type: ConversationType
  id: string
  conversation_id: string
  sent_by: string
}


async function markMessagesAsRead(
  conversationId: string | undefined | null,
  userId: string | undefined
) {
  if (!userId || !conversationId) return null;
  const { data: messages, error: messageError } = await supabase
    .from("chat_gpt_message")
    .select("id")
    .eq("chat_gpt_conversation_id", conversationId);

  if (messageError) {
    Sentry.captureException(messageError);
    throw new Error(messageError.message);
  }

  return supabase.from("read_message").upsert(
    messages.map((message) => ({
      user_id: userId,
      message_id: message.id,
    })),
    {
      onConflict: "user_id, message_id",
    }
  );
}

export function useMarkMessagesAsReadMutation() {
  const authUser = useUserStore((state) => state.user);

  return useMutation({
    mutationFn: async ({
      conversationId,
    }: {
      conversationId: string | undefined | null;
    }) => {
      await markMessagesAsRead(conversationId, authUser?.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.latestMessages],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.latestServiceMessages],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.latestServiceRequestMessages],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptMessages],
      });
    },
  });
}

async function fetchMessagesByServiceId(serviceId?: string, isExternal: boolean = false) {
  if (!serviceId) return null
  // Execute the queries in parallel

  const externalKey = isExternal ? "public_service_engagement_external_conversation_id_fkey" : "service_engagement_conversation_id_fkey";
  const { data: pageData, error: pageError } = await supabase
    .from("service_engagement")
    .select(
      `*, chat_gpt_conversation!${externalKey} (
        chat_gpt_message!inner(*,read_message(*))
      )
    `
    )
    .eq("id", serviceId)
    .limit(1)
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation?.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );
}


async function fetchMessagesByServiceRequestId(serviceRequestId?: string, isExternal: boolean = false) {
  if (!serviceRequestId) return null
  // Execute the queries in parallel

  const externalKey = isExternal ? "public_plan_entry_external_conversation_id_fkey" : "public_plan_entry_conversation_id_fkey";
  const { data: pageData, error: pageError } = await supabase
    .from("plan_entry")
    .select(
      `*, chat_gpt_conversation!${externalKey} (
        chat_gpt_message!inner(*,read_message(*))
      )
    `
    )
    .eq("id", serviceRequestId)
    .returns<
      { chat_gpt_conversation: { chat_gpt_message: ChatGptMessage[] } }[]
    >()
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );
}

async function fetchMessagesByNetworkId(networkId?: string) {
  if (!networkId) return null;
  // Execute the queries in parallel
  const { data: pageData, error: pageError } = await supabase
    .from("network")
    .select(
      `chat_gpt_conversation!network_conversation_id_fkey(chat_gpt_message(*,read_message(*)))`
    )
    .eq("id", networkId)
    .limit(1)
    .returns<
      { chat_gpt_conversation: { chat_gpt_message: ChatGptMessage[] } }[]
    >()
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
  );
}

async function fetchChatGptMessageById(chatGptMessageId?: string | null) {
  if (!chatGptMessageId) return null;
  const { data, error } = await supabase
    .from("chat_gpt_message")
    .select("*,read_message(*)")
    .eq("id", chatGptMessageId)
    .limit(1)
    .maybeSingle();

  // Check for errors and throw if necessary
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return data;
}

/**
 * Hook to fetch all messages for the provided thread.
 * Determines which query function (& conversationId aggregation strategy) to use using pageType.
 */
export const useChatGptMessage = (messageId?: string | null) => {
  const { isLoading, isFetching, data, error, refetch } = useQuery({
    queryKey: [
      QUERY_KEYS.chatGptMessages,
      {
        messageId,
      },
    ],
    queryFn: () => fetchChatGptMessageById(messageId),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingMessage: isLoading,
    isFetchingMessage: isFetching,
    message: data,
    messageError: error,
    refetchMessage: refetch,
  };
};

// TODO: instead of threading... can we just use conversationId? Need to simplify
export const useChatGptMessages = (
  threadId: string | undefined,
  pageType: SideBarPageType
) => {
  const authUser = useUserStore((state) => state.user);
  const networkId = useActiveNetworkId();
  const {
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
    data: messages,
    error: messagesError,
    refetch: refetchMessages,
  } = useQuery({
    queryKey: [
      QUERY_KEYS.chatGptMessages,
      {
        threadId,
        pageType,
      },
    ],
    queryFn: () => {
      switch (pageType) {
        case "service": {
          return fetchMessagesByServiceId(threadId);
        }
        case "serviceExternal": {
          return fetchMessagesByServiceId(threadId, true);
        }
        case "serviceRequest": {
          return fetchMessagesByServiceRequestId(threadId);
        }
        case "serviceRequestExternal": {
          return fetchMessagesByServiceRequestId(threadId, true);
        }
        case "carespace": {
          return fetchMessagesByNetworkId(threadId);
        }
        case "userUpdate": {
          return fetchMessagesByUserUpdateId(threadId);
        }
        case "recommendationConversationPage": {
          return fetchChatGptMessagesByRecommendationId(threadId, authUser?.id);
        }
        case "interventionConversationPage": {
          return fetchChatGptMessagesByInterventionId(threadId, authUser?.id);
        }
        case "private": {
          return fetchChatGptMessagesByConversationId(threadId);
        }
        case "mobilePage":
        case "homePage":
        case "myLibraryPage":
        case "todosPage":
        case "newAssessmentPage":
        case "localSearchPage":
        case "educationPage":
        case "discussionsPage":
        case "myCarePage":
        case "adminPage":
        case "userUpdatePage":
        case "nanasDayPage":
        case "servicesPage": {
          return fetchChatGptMessagesByPageId(
            threadId,
            authUser?.id,
            networkId
          );
        }
        default: {
          return [];
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  let liveConversationId: string | null | undefined;
  switch (pageType) {
    case "userUpdate":
      liveConversationId = useUserUpdateDiscussion(threadId)?.data?.conversation_id;
      break;
    case "carespace":
      liveConversationId = useActiveNetwork()?.data?.conversation_id
      break;
    case "serviceRequest":
      liveConversationId = useServiceRequestDiscussion(threadId)?.data;
      break;
    case "serviceRequestExternal":
      liveConversationId = useServiceRequestDiscussion(threadId, true)?.data;
      break;
    case "service":
      liveConversationId = useServiceDiscussion(threadId)?.data;
      break;
    case "serviceExternal":
      liveConversationId = useServiceDiscussion(threadId, true)?.data;
      break;
    case "private":
      liveConversationId = threadId;
      break;
    default:
      liveConversationId = null;
  }

  // subscribe to insert changes in the user_to_notification table
  useEffect(() => {
    const realtimeUpdatesPageTypes = ["carespace", "userUpdate", "serviceRequest", "serviceRequestExternal", "service", "serviceExternal", "private"];
    if (realtimeUpdatesPageTypes.includes(pageType)) {
      const subscription = supabase
        .channel("table-filter-changes")
        .on(
          "postgres_changes",
          {
            event: "INSERT", schema: "public", table: "chat_gpt_message",
            filter: `chat_gpt_conversation_id=eq.${liveConversationId}`
          },
          () => {
            refetchMessages();
          }
        )
        .subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [liveConversationId]);

  return {
    // messages
    isLoadingMessages,
    isFetchingMessages,
    messages,
    messagesError,
    refetchMessages,
  };
};

export const useLatestMessages = () => {
  const { networks } = useNetworksInOrganization();
  const networkIds = networks?.map((network) => network.id) ?? []
  const { hasCareCentralAccess } = useOrgs();
  const authUser = useUserStore((state) => state.user);

  // TODO: create a view for this. Will be simpler and can group latest messages better
  return useQuery({
    queryKey: [QUERY_KEYS.latestMessages, networkIds],
    queryFn: async () => {
      let query = supabase
        .from("chat_gpt_conversation")
        .select(`id, chat_gpt_message(*,read_message(*)), network_id,  type, external_participant_id,
         service_engagement_ext:service_engagement!public_service_engagement_external_conversation_id_fkey(name,id),
         service_engagement_internal:service_engagement!service_engagement_conversation_id_fkey(name, id),
          plan_entry_ext:plan_entry!public_plan_entry_external_conversation_id_fkey(name,id),
          plan_entry_internal:plan_entry!public_plan_entry_conversation_id_fkey(name,id),
          user_update!user_update_conversation_id_fkey(summary,id),
          conversation_participant(user(first_name, last_name)),
          user(first_name, last_name)
          `)
        .neq("type", ConversationType.Alfred)
        .in("network_id", networkIds)
      if (hasCareCentralAccess) {
        query = query.neq("type", ConversationType.Family)
      }

      const { data: networkData } = await query;

      const conversations = networkData?.filter((conversation) => conversation.chat_gpt_message.length > 0)
      return conversations?.map((conversation) => {
        const sortedMessages = conversation.chat_gpt_message.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
        const latestMessage = sortedMessages[sortedMessages.length - 1]
        const participants = conversation.conversation_participant.map((participant) => participant.user).map((participant) => `${participant?.first_name} ${participant?.last_name}`).join(", ")
        return {
          ...latestMessage,
          title: conversation.service_engagement_ext?.[0]?.name ?? conversation.service_engagement_internal?.[0]?.name ?? conversation.plan_entry_ext?.[0]?.name ?? conversation.plan_entry_internal?.[0]?.name ?? conversation.user_update?.[0]?.summary ??
            (conversation.type === ConversationType.Private ? participants : "General"),
          id: conversation.plan_entry_ext?.[0]?.id ?? conversation.plan_entry_internal?.[0]?.id ?? conversation.user_update?.[0]?.id ?? conversation.service_engagement_ext?.[0]?.id ?? conversation.service_engagement_internal?.[0]?.id ?? conversation.network_id,
          type: conversation.type,
          network_id: conversation.network_id,
          external_participant_id: conversation.external_participant_id,
          conversation_id: conversation.id,
          sent_by: getUserInitials(conversation.user, authUser)
        } as DiscussionMessage
      })

    },
  });
};

export type ChatGptMessageInsert =
  Database["public"]["Tables"]["chat_gpt_message"]["Insert"];

export async function saveChatGptMessage(message: ChatGptMessageInsert) {
  const { data, error } = await supabase.from(TABLE).insert(message).select();
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data;
}

export function useMutateChatGptMessages() {
  return useMutation({
    mutationFn: ({
      message,
      threadId,
    }: {
      message: ChatGptMessageInsert;
      threadId: string;
    }) => {
      return saveChatGptMessage(message);
    },
    // When mutate is called:
    onMutate: async ({
      message,
      threadId,
    }: {
      message: ChatGptMessageInsert;
      threadId: string;
    }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.chatGptMessages, { threadId }],
      });

      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        QUERY_KEYS.chatGptMessages,
        { threadId },
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        // query key
        [QUERY_KEYS.chatGptMessages, { threadId }],
        // updater fn
        (oldMessages) => {
          if (oldMessages) {
            return [...(oldMessages as ChatGptMessageInsert[]), message];
          } else {
            return [message];
          }
        }
      );

      // Return a context object with the snapshotted value
      return { previousMessages };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (
      err,
      {
        message,
        threadId,
      }: {
        message: ChatGptMessageInsert;
        threadId: string;
      },
      context
    ) => {
      queryClient.setQueryData(
        // query key
        [QUERY_KEYS.chatGptMessages, { threadId }],
        // what we're setting the key to
        context?.previousMessages
      );
    },
    // Always refetch after error or success:
    onSettled: (
      data,
      error,
      {
        message,
        threadId,
      }: {
        message: ChatGptMessageInsert;
        threadId: string;
      }
    ) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptMessages, { threadId }],
      });
    },
  });
}
