import ModalInMobileView from "components/ModalView/ModalInMobileView";
import { IntakeView } from "components/NanasDay/IntakeView";
import { useNavigateBack } from "lib/routing";


interface MedicationIntakePageProps {}

export const MedicationIntakePage = (props: MedicationIntakePageProps) => {
  const navigateBack = useNavigateBack();
  return (
    <ModalInMobileView 
      onClose={() => {}}
      title="Medication Intake"
    >
      <IntakeView /> 
    </ModalInMobileView>
  )
}