/**
 * @fileoverview Sets up the Supabase client.
 */
import { createClient } from "@supabase/supabase-js";
import type { Database } from "../../types/supabase";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

/**
 * Initializes the Supabase client.
 */
export let supabase = createClient<Database>(supabaseUrl, supabaseAnonKey );

/**
 * Resets the Supabase client.
 */
export const resetSupabase = () => {
  supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);
}
