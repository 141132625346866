import type { AssessmentQuestion } from "backend/resources/assessmentQuestion";
import { getTitleWithAdlo } from "components/AssessmentComponent/utils";

interface Props {
  question: AssessmentQuestion;
  adlo: any;
  isLoadingAdloPreferredName: boolean;
}

export function Question({ question, adlo }: Props) {
  if (question.type === "radio") {
    return (
      <div className="grid grid-cols-[1fr,3fr,3fr] gap-4 text-sm ">
        <p className="text-center">{question.raw_data.stage ?? question.question_number}</p>
        <p/>
        <p>{question.title}</p>
      </div>)
  } else if ((question.type === "select" && question.raw_data.type !== "vertical") || question.type === "multi_select") {
    return <div style={{ gridTemplateRows: `repeat(${(question.raw_data.options?.length ?? 0) + 1}, minmax(0, 1fr))` }}
      className="grid mt-5 gap-1">
      <p>{question.title}</p>
      {question.raw_data.options?.map((option) => (
        <ul className="flex flex-col justify-center list-disc ml-3 ">
          <li className="list-dic text-sm">
            <p> {option}</p>
          </li>
        </ul>
      ))}
    </div>;
  }
  return (
    <div className="flex flex-col text-sm pr-10">
      <p
        className="text-sm md:text-base"
        dangerouslySetInnerHTML={{
          // For formatting (adding bold / italics to a question)
          __html: getTitleWithAdlo(
            question.title,
            adlo?.first_name || "your loved one"
          ),
        }}>
      </p>
    </div>
  );
}
