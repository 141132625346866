export function PrivacyPolicyPage() {
  return (
    <div className="w-full h-full">
      <iframe
        className="w-full h-full"
        title="privacy policy page"
        src="./privacy-policy.html"
      />
    </div>
  );
}
