import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { PlanEntry, makeCalendarObjectsForPlanEntries } from "backend/resources/planEntry";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useGptStore } from "state/gpt";
import { useSideNavStore } from "state/navItems";

export function CalendarView({
  planEntries,
}: {
  planEntries: PlanEntry[];
}) {
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize();

  /* cal view logic */
  const calendarViews = [
    "dayGridMonth",
    "timeGridWeek",
    "timeGridDay",
    "listWeek",
  ] as const;
  type CalendarView = (typeof calendarViews)[number];

  const [calView, setCalView] = useState<CalendarView>(getDefaultCalView());

  function getDefaultCalView() {
    if (isMobile) {
      return "timeGridWeek";
    } else {
      return "dayGridMonth";
    }
  }

  /* resizing logic */
  // force rerender when open state of navbar or chatgpt sidebar changes
  const isOpen = useGptStore((state) => state.isOpen);
  const isSideNavMinimized = useSideNavStore((state) => state.isMinimized);
  // force rerender when container width changes
  // https://github.com/fullcalendar/fullcalendar/issues/7075#issuecomment-1353782223
  const { width } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [key, setKey] = useState(Date.now());

  const updateKey = () => {
    setKey(Date.now());
  };

  useEffect(() => {
    updateKey();
  }, [width]);

  useEffect(() => {
    updateKey();
  }, [isOpen, isSideNavMinimized]);

  return (
    <div
      ref={containerRef}
      className="w-full h-[40rem] pb-24 md:pb-0">
      <FullCalendar
        datesSet={(arg) => {
          setCalView(arg.view.type as CalendarView);
        }}
        key={key}
        height={"100%"}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: "today",
          center: "prev title next",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        buttonText={{
          list: "agenda",
        }}
        initialView={calView}
        editable={true}
        nowIndicator={true}
        selectable={true}
        selectMirror={true}
        dayMaxEventRows={true} // for all non-TimeGrid views
        scrollTime={getScrollToTime()} // scrolls calendar to 30 mins before now
        displayEventTime={true}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        }}
        events={makeCalendarObjectsForPlanEntries(planEntries)} // alternatively, use the `events` setting to fetch from a feed
        eventClick={(info) => {
          const { event } = info;
          navigate({
            path: CarePilotRoute.PLAN_ID,
            params: {
              id: event.id
            }
          })
        }}
      />
    </div>
  );
}

function getScrollToTime() {
  // Get the current date and time
  const now = new Date();
  // Subtract 30 minutes from the current time
  now.setMinutes(now.getMinutes() - 30);
  // Format the adjusted time as a string in the 'HH:mm:ss' format.
  // The padStart(2, '0') function is used to ensure that the hours and minutes are always two digits long.
  const scrollTime = `${now.getHours().toString().padStart(2, "0")}:${now
    .getMinutes()
    .toString()
    .padStart(2, "0")}:00`;

  return scrollTime;
}
