import React, { useState } from "react";
import { InvitationStatus, useInvitationQuery } from "backend/resources/invitation/invitation";
import {
  UserRole,
  useUserRolesInNetwork
} from "backend/resources/userRole";
import {
  NetworkRoleType,
  NetworkRoleTypeToLabel,
  OrgRoleType,
} from "backend/resources/userRole/types";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user";
import DeleteInvitationButton from "components/AdminPage/DeleteInvitationButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { UserDetailView } from "components/CarespacePage/OrgMemberDetailPage";
import DeactivateMemberButton from "components/EditMemberButtons/EditMemberButton";
import { LoadingSpinner } from "components/LoadingSpinner";



export default function CarespaceMembers({
  rolesToFilterBy,
  disableEditingCarespaceMember,
  hideSuperSuperUsers
}: {
  rolesToFilterBy?: (NetworkRoleType | OrgRoleType)[];
  disableEditingCarespaceMember?: boolean;
  hideSuperSuperUsers?: boolean;
}) {
  const navigate = useAppNavigate();
  const authUser = useUserStore((state) => state.user);
  const [focusedUserId, setFocusedUserId] = useState<string | null>(
    null
  );

  /// ///////
  // hooks
  /// ///////
  const activeNetworkId = useActiveNetworkId();

  const { data: invitations, isLoading: isLoadingInviations } =
    useInvitationQuery();
  const { data: userRoles, isLoading: isLoadingUserRoles } =
    useUserRolesInNetwork();
  const filteredInvitations = invitations?.filter((invitation) => !rolesToFilterBy || rolesToFilterBy?.includes(invitation.role_type as NetworkRoleType));
  const filteredUserRoles = userRoles?.filter((userRole) => !rolesToFilterBy || rolesToFilterBy?.includes(userRole.role as (OrgRoleType | NetworkRoleType)));
  const activeUserRole = filteredUserRoles?.find(
    (userRole) => userRole.user_id === authUser?.id
  );


  if (isLoadingInviations || isLoadingUserRoles) {
    return (
      <div className="flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }
  return (
    <div className="grid grid-cols-[4fr,.5fr] gap-2 max-w-3xl">
      {focusedUserId ? (
        <UserDetailView
          userId={focusedUserId}
          setUserId={setFocusedUserId}
          DMOptions={{ selectedCarespaceId: activeNetworkId }}
        />
      ) : null}

      {/* HEADER */}
      <>
        <div
          className={
            "gap-4 grid grid-cols-[2fr,1fr,1fr] w-full  items-center text-xs md:text-base py-2 "
          }>
          <p className="font-medium">Name</p>
          <p className="font-medium text-center">Role</p>
          <p className="font-medium text-center">Status</p>
        </div>
        <p></p>
      </>

      {/* ME ROW */}
      {activeUserRole ? (
        <>
          <div className="gap-4 grid grid-cols-[2fr,1fr,1fr] w-full">
            {authUser?.first_name ? (
              <p className="overflow-wrap break-words items-center">{`${authUser?.first_name} ${authUser?.last_name}`}</p>
            ) : (
              <p>Me</p>
            )}
            {/* <p>{authUser?.email}</p> */}
            <p className="text-center">
              {NetworkRoleTypeToLabel[activeUserRole?.role as NetworkRoleType]}
            </p>
            <p className="text-center">Active</p>
          </div>
          <p></p>
        </>
      ) : null}


      {/* PENDING INVITATIONS */}
      {filteredInvitations
        ?.filter((invitation) => invitation.status === InvitationStatus.PENDING)
        ?.map((invitation, index) => (
          <React.Fragment key={`invitation-fragment-${invitation.id}-${index}`}>
            <button
              onClick={() =>
                navigate({
                  path: SharedRoute.VIEW_INVITATION,
                  params: {
                    id: invitation.id
                  }
                })
              }
              className={`gap-4 grid grid-cols-[2fr,1fr,1fr] w-full  items-center text-base py-1 hover:bg-neutral-50`}>
              <div className="flex justify-start">{`${invitation.first_name} ${invitation?.last_name}`}</div>
              <div className="flex justify-center text-center line-clamp-1">
                {NetworkRoleTypeToLabel[invitation.role_type as NetworkRoleType]}
              </div>
              <div className="flex justify-center">
                Pending
              </div>
            </button>
            <DeleteInvitationButton invitationId={invitation.id} />
          </React.Fragment>
        ))}

      {/* ACTIVE ROLES */}
      {filteredUserRoles
        ?.filter((userRole) => userRole.user_id !== authUser?.id)
        .sort((a, b) =>
          (a.user?.first_name || "").localeCompare(b.user?.first_name || "")
        )
        ?.map((userRole, index) => (
          <React.Fragment key={`active-role-fragment-${userRole.id}-${index}`}>
            <button
              className="gap-4 grid grid-cols-[2fr,1fr,1fr] w-full py-1 hover:bg-neutral-50"
              onClick={() => setFocusedUserId(userRole.user_id)}>
              <p className="text-start overflow-wrap break-words items-center">{`${userRole.user?.first_name} ${userRole.user?.last_name}`}</p>
              <p className="text-center line-clamp-1">
                {NetworkRoleTypeToLabel[userRole.role as NetworkRoleType]}
              </p>
              <p className="text-center">{userRole.is_deactivated ? "Disabled" : "Active"}</p>
            </button>
            <div
              className="flex items-center gap-2">
              <DeactivateMemberButton role={userRole} />
              {!disableEditingCarespaceMember ? (
                <ButtonWithIcon
                  onClick={() =>
                    navigate({
                      path: SharedRoute.TEAM_EDIT_TEAM_MEMBER,
                      params: {
                        id: userRole.id
                      }
                    })
                  }
                  text={""}
                  disabled={userRole.is_deactivated || isButtonDisabled(userRole, activeUserRole)}
                  size={"small"}
                  icon={IconOption.EDIT}
                />
              ) : null}
            </div>
          </React.Fragment>
        ))}
    </div>
  );
}

function isButtonDisabled(userRole: UserRole, activeUserRole?: UserRole) {
  return (activeUserRole?.role === NetworkRoleType.PRIMARY_CAREGIVER
    && [NetworkRoleType.ADMIN, NetworkRoleType.DOCTOR, NetworkRoleType.CARE_NAVIGATOR]
      .includes(userRole.role as NetworkRoleType))
}
