import { useEffect } from "react";
import {
  ConversationType,
  useMutateChatGptConversations,
  useUserUpdateDiscussion,
} from "backend/resources/chatGptConversation";
import { useAddUserUpdateDiscussion } from "backend/resources/userUpdates/userUpdates";
import { userUpdatesStore } from "state/userUpdate/userUpdate";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function UserUpdateDiscussion() {
  const createConversation = useMutateChatGptConversations().mutateAsync;
  const addUserUpdateDiscussion = useAddUserUpdateDiscussion().mutateAsync;
  const activeUserUpdateId = userUpdatesStore(
    (state) => state.activeUserUpdateId
  );
  const { data, isLoading: isLoadingDiscussion } =
    useUserUpdateDiscussion(activeUserUpdateId);



  useEffect(() => {
    async function openUpdateDiscussionThread() {
      if (!data?.conversation_id && !isLoadingDiscussion) {
        const newConversation = await createConversation({ type: ConversationType.Family, networkId: data?.network_id });
        if (newConversation) {
          const newConversationId = newConversation.id;
          await addUserUpdateDiscussion({
            userUpdateId: activeUserUpdateId,
            conversationId: newConversationId,
          });
        }
      }
    }
    openUpdateDiscussionThread();
  }, [data, activeUserUpdateId]);

  return (
    <div className="flex flex-col max-h-[40rem] flex-grow w-full h-full">
      <MessagesThread
        threadId={activeUserUpdateId}
        pageType="userUpdate">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex flex-col pt-6">
      {/* welcome message */}
      <div className="flex min-w-0 gap-3 pb-6">
        {/* text and action buttons */}
        <div className="flex flex-col gap-6">
          {/* intro PP */}
          <p>Welcome to the family discussion!</p>
        </div>
      </div>
    </div>
  );
}
