import type { GoalTemplate } from "backend/resources/goalTemplate/goalTemplate";
import {
  useDeleteGoalTemplateMutation,
  useGoalTemplateQuery,
} from "backend/resources/goalTemplate/goalTemplate";
import { type ShareableRecommendationType } from "backend/resources/shareableRecommendation/shareableRecommendation";
import {
  useDeleteUserCreatedRecommendationMutation,
  useUserCreatedRecommendationsQuery,
} from "backend/resources/userCreatedRecommendations/userCreatedRecommendations";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CloseButton } from "components/CloseButton/CloseButton";
import GoalTemplateCard from "components/GoalPage/GoalTemplate/GoalTemplateCard";
import { ShareableRecommendation } from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableRecommendation";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import Tabs from "components/Tabs/Tabs";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ActionButtons from "shared/ui/action-buttons";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import create from "zustand";

export enum TemplateTabs {
  GOALS = "Goals",
  RECOMMENDATIONS = "Recommendations",
}
type Store = {
  currentTab: TemplateTabs;
  setCurrentTab: (tab: TemplateTabs) => void;
};
const useStore = create<Store>((set) => ({
  currentTab: TemplateTabs.GOALS,
  setCurrentTab: (tab: TemplateTabs) => set(() => ({ currentTab: tab })),
}));

export default function TemplatesPage() {
  const navigate = useAppNavigate();
  const editTemplatesRef = useRef<null | EditTemplatesRef>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { isMobile } = useWindowSize();
  const { currentTab, setCurrentTab } = useStore();

  const { goalTemplates } = useGoalTemplateQuery();
  const { data: recommendationTemplates } =
    useUserCreatedRecommendationsQuery();

  return (
    <PageContainer>
      <div className="flex flex-col gap-3 pb-20 max-w-3xl">
        <PageMainHeader text="Templates" />
        <div className="flex gap-5">
          <Tabs
            tabs={Object.values(TemplateTabs)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {/* Action Buttons */}
          <ActionButtons title={"Template Actions"}>
            <>
              <ButtonWithIcon
                onClick={() => {
                  setIsEditing(true);
                }}
                className="text-left"
                size="small"
                text={`Remove ${currentTab}`}
                icon={IconOption.TRASH}
              />
              <ButtonWithIcon
                onClick={() => {
                  if (currentTab === TemplateTabs.GOALS) {
                    navigate({
                      path: CareCentralRoute.GOAL_TEMPLATE_EDIT
                    })
                  }
                  else {
                    navigate({
                      path: CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD
                    })
                  }
                }}
                className="text-left"
                size="small"
                text={`Add New ${currentTab}`}
                icon={IconOption.PLUS}
              />
            </>
          </ActionButtons>
        </div>
        <div className="mt-8">
          {/* Goals tab */}
          {currentTab === TemplateTabs.GOALS ? (
            goalTemplates && goalTemplates?.length > 0 ? (
              <div className="flex flex-wrap gap-4 justify-around md:justify-start">
                {goalTemplates?.map((goalTemplate) => (
                  <GoalTemplateCard goalTemplate={goalTemplate} />
                ))}
              </div>
            ) : (
              <p className="mt-4">
                There are no goal templates. Click add to create one, or go to an
                existing care plan to copy one from there
              </p>
            )
          ) : null}

          {/* Recs Tab */}
          {currentTab === TemplateTabs.RECOMMENDATIONS ? (
            recommendationTemplates && recommendationTemplates?.length > 0 ? (
              <div className="flex flex-wrap gap-4 justify-around md:justify-start">
                {recommendationTemplates?.map((recommendationTemplate) => (
                  <ShareableRecommendation
                    recommendation={recommendationTemplate}
                  />
                ))}
              </div>
            ) : (
              <p className="mt-4">
                There are no recommendation templates. Click add to create one.
              </p>
            )
          ) : null}
        </div>
      </div>
      <ResponsiveModal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        title={`Remove ${currentTab}`}
        footerButtons={
          <>
            <ButtonWithIcon
              onClick={() => setIsEditing(false)}
              text={"Cancel"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={() => {
                editTemplatesRef?.current?.saveTemplates();

              }}
              text={"Save"}
              icon={IconOption.CHECKMARK}
            />
          </>
        }>
        <EditTemplatesComponent
          close={() => setIsEditing(false)}
          isOpen={isEditing}
          currentTab={currentTab}
          ref={editTemplatesRef}
        />
      </ResponsiveModal>
    </PageContainer>
  );



}



interface EditTemplatesRef {
  saveTemplates: () => void;
}
interface Props {
  close: any;
  isOpen: boolean;
  currentTab: TemplateTabs;
}
const EditTemplatesComponent = forwardRef((props: Props, ref: any) => {
  const deleteGoalTemplate = useDeleteGoalTemplateMutation().mutateAsync;
  const deleteRecommendation =
    useDeleteUserCreatedRecommendationMutation().mutateAsync;
  const { goalTemplates } = useGoalTemplateQuery();
  const [localGoals, setLocalGoals] = useState(goalTemplates);
  const { data: recommendationTemplates } =
    useUserCreatedRecommendationsQuery();
  const [localRecs, setLocalRecs] = useState(recommendationTemplates);
  const [goalsToDelete, setGoalsToDelete] = useState<GoalTemplate[]>([]);
  const [recsToDelete, setRecsToDelete] = useState<
    ShareableRecommendationType[]
  >([]);

  useImperativeHandle(ref, () => ({
    saveTemplates,
  }));

  const saveTemplates = () => {
    if (goalsToDelete.length > 0)
      for (const goal of goalsToDelete) {
        deleteGoalTemplate(goal.id);
      }
    if (recsToDelete.length > 0)
      for (const rec of recsToDelete) {
        deleteRecommendation(rec.id);
      }
    props.close();
  };

  return (
    <PageContainer>
      <div className="h-[50vh]">
        {props.currentTab === TemplateTabs.GOALS ? (
          <div className="flex flex-wrap gap-4 justify-around md:justify-start">
            {localGoals
              ?.filter((goal) => !goalsToDelete.includes(goal))
              .map((goalTemplate) => (
                <div className="relative w-min">
                  <button
                    type="button"
                    className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
                    onClick={(event) => {
                      event.preventDefault();
                      setGoalsToDelete([...goalsToDelete, goalTemplate]);
                    }}>
                   <CloseButton/>
                  </button>
                  <GoalTemplateCard goalTemplate={goalTemplate} />
                </div>
              ))}
          </div>
        ) : null}
        {props.currentTab === TemplateTabs.RECOMMENDATIONS ? (
          <div className="flex flex-wrap gap-4 justify-around md:justify-start">
            {localRecs
              ?.filter((rec) => !recsToDelete.includes(rec))
              .map((recommendationTemplate) => (
                <div className="relative w-min">
                  <button
                    type="button"
                    className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
                    onClick={(event) => {
                      event.preventDefault();
                      setRecsToDelete([
                        ...recsToDelete,
                        recommendationTemplate,
                      ]);
                    }}>
                   <CloseButton/>
                  </button>
                  <ShareableRecommendation
                    recommendation={recommendationTemplate}
                  />
                </div>
              ))}
          </div>
        ) : null}
      </div>
    </PageContainer>
  );
})
