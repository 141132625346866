import { Popup } from "components/Popup";

interface Props {
  close: () => void;
  tags?: any[] | null;
}

export function ShowAllTagsPopup({ close, tags }: Props) {
  return (
    <Popup
      containerClassName="w-[380px]"
      closeOnClickOutside={false}
      close={close}>
      <h1 className="text-xl">Tags</h1>
      <div className="flex flex-wrap gap-2 px-2 py-2">
        {tags &&
          tags.length > 0 &&
          tags.map(({ tag }) => (
            <p
              key={tag.id}
              className="bg-white rounded-full text-xs text-brand-orange font-semibold px-4 py-1 border-2 border-brand-orange">
              {tag.name}
            </p>
          ))}
      </div>
    </Popup>
  );
}
