import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useGptStore } from "state/gpt";
import AlfredIcon from "components/Alfred/Alfred";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarTodosPage() {
  const { isMobile } = useWindowSize();
  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"todosPage"}
        pageType="todosPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  const navigate = useAppNavigate();
  const { isMobile } = useWindowSize();
  const setIsOpen = useGptStore((state) => state.setIsOpen);

  return (
    <div className="flex min-w-0 gap-3 pb-6">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      {/* text and action buttons */}
      <div className="flex flex-col gap-6 bg-gray-50  text-sm px-4 py-4 rounded-md">
        {/* intro PP */}
        <p>
          This is your Tasks page, a place where you can create and organize tasks to
          help you take care of your loved one.
        </p>
        <p>
          When you create a task, you can attach bookmarks from your library to
          it so that you have all the necessary related information right there.
        </p>

        {/* action buttons */}
        <div className="flex flex-col gap-3">
          <ButtonWithIcon
            onClick={async () => {
              if (isMobile) setIsOpen(false);
              navigate({
                path: CarePilotRoute.PLAN_NEW
              })
            }}
            disabled={false}
            text={"Add task"}
            size={"small"}
            icon={IconOption.ARROW}
          />
        </div>
      </div>
    </div>
  );
}
