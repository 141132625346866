import { DefaultCheckbox } from "components/Checkbox";
import { useEffect, useState } from "react";

interface SelectionOptionProps {
  currentAnswer: number[];
  updateAnswer: (obj: { answer?: number[]; context?: string }) => void;
  disabled: boolean;
  options: string[];
  isVertical?: boolean;
}

export default function AssessmentMultiSelectInput({
  currentAnswer,
  updateAnswer,
  disabled,
  options,
  isVertical
}: SelectionOptionProps) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number[]>([]);
  useEffect(() => {
    if (currentAnswer) {
      setSelectedOptionIndex(currentAnswer);
    }
  }, [currentAnswer]);

  const handleCheckboxChange = (optionIndex: number) => {
    if (selectedOptionIndex.includes(optionIndex)) {
      setSelectedOptionIndex(selectedOptionIndex.filter(index => index !== optionIndex));
      updateAnswer({ answer: undefined });
    } else {
      setSelectedOptionIndex([...selectedOptionIndex, optionIndex]);
      updateAnswer({ answer: [...selectedOptionIndex, optionIndex] });
    }
  };

  return (
    <div
      style={{ [isVertical ? "gridTemplateColumns" : "gridTemplateRows"]: `repeat(${options.length + 1}, minmax(0, 1fr))` }}
      className={`grid ${isVertical ? "" : "mt-5"} gap-x-16 gap-y-5 w-full`}>

      {isVertical && options.map((option) => <div className="flex justify-center w-[60px]">
        <p className="text-center flex text-xs  font-bold">{option}</p>
      </div>)}
      <div />
      {options.map((_, index) => (
        <div className="flex items-center justify-end  w-full">
          <DefaultCheckbox
            key={index}
            checked={selectedOptionIndex.includes(index)}
            disabled={disabled}
            onChange={() => handleCheckboxChange(index)}
          />
        </div>
      ))}
    </div>
  );
}

