import { create } from 'zustand';
import { DyadTier } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";


const AHC_HSRN_ASSESSMENT_ID = "1bdf920a-1ff8-46e9-acf2-348b49e26184"
export enum AnalyticsPageFilterTypes {
  ASSESSMENT_ID = "Assessment ID",
  QUESTION_ID = "Question ID",
  DYAD = "Dyad",
  DATE_RANGE = "Date Range",
  AGE_RANGE = "Age Range",
  HAS_CAREGIVER = "Has Caregiver",
  SEX = "Sex",
}

export enum Month {
  January = "January",
  February = "February",
  March = "March",
  April = "April",
  May = "May",
  June = "June",
  July = "July",
  August = "August",
  September = "September",
  October = "October",
  November = "November",
  December = "December"
}

export type AnalyticsPageFilterState = {
  assessmentId: string;
  questionId: string | undefined;
  dyad: DyadTier | undefined | "All";
  monthRange: Month| "YTD";
  ageRange: string | undefined;
  hasCaregiver: "True" | "False" | "All" 
  sex: string;
  setAssessmentId: (assessmentId: string) => void;
  setQuestionId: (questionId: string | undefined) => void;
  setDyad: (dyad: DyadTier | undefined | "All") => void;
  setMonthRange: (dateRange: Month | "YTD") => void;
  setAgeRange: (ageRange: string | undefined) => void;
  setHasCaregiver: (hasCaregiver: "True" | "False" | "All") => void;
  setSex: (sex: string) => void;
};

export const useLocalAnalyticsPageFilterStore = create<AnalyticsPageFilterState>((set) => {
  return {
    assessmentId: AHC_HSRN_ASSESSMENT_ID,
    questionId: undefined,
    dyad: "All",
    monthRange: "YTD",
    ageRange: "All",
    hasCaregiver: "All",
    sex: "All",
    setAssessmentId: (assessmentId: string) => set(() => ({ assessmentId })),
    setQuestionId: (questionId: string | undefined) => set(() => ({ questionId })),
    setDyad: (dyad: DyadTier | undefined | "All") => set(() => ({ dyad })),
    setMonthRange: (monthRange: Month | "YTD") => set(() => ({ monthRange })),
    setAgeRange: (ageRange: string | undefined) => set(() => ({ ageRange })),
    setHasCaregiver: (hasCaregiver: "True" | "False" | "All") => set(() => ({ hasCaregiver })),
    setSex: (sex: string) => set(() => ({ sex })),
  };
});

export const useAnalyticsPageFilterStore = () => {
  return useLocalAnalyticsPageFilterStore();
}