export function getTitleWithAdlo(title: string, name: string) {
  // Replace dynamic "[adlo]" with the user's actual Loved One Living with Dementia
  return title?.replace(/\[adlo]/g, name);
}

export const SLIDER_COLORS = [
  "#33ff77", // Green
  "#5af78e",
  "#82ef9f",
  "#a9e7b1",
  "#c7e78b", // Transition color between green and yellow
  "#fada38", // Yellow
  "#f9c339",
  "#f9ac39",
  "#f99539",
  "#f97e39",
  "#ff3333"  // Red
]

export function getColor(index: number | undefined, maxIndex: number) {
  // Define an array of colors, removing the blue shades
 ;
  if (!index) return SLIDER_COLORS[0]

  // Calculate the effective index based on the ratio of index to maxIndex
  const ratio = index / maxIndex;
  const effectiveIndex = Math.round(ratio * (SLIDER_COLORS.length - 1));

  // Ensure the effective index is within the bounds of the colors array
  const safeIndex = Math.max(0, Math.min(effectiveIndex, SLIDER_COLORS.length - 1));

  // Return the color at the calculated index
  return SLIDER_COLORS[safeIndex];
}